import "./index.css";
import "url-search-params-polyfill";

import { Route, Router, Switch } from "react-router-dom";
import { initializeTokenRefresh, retrieveToken } from "api/auth";
import jwtDecode from "jwt-decode";

import App2 from "./routes/app/new-ui";
import AppContainer from "./containers/App";
import { IntlProvider } from "react-intl";
import React, { Component } from "react";
import { TunnelProvider } from "utils/tunnels";
import history from "utils/history";
import { render } from "react-dom";
import * as Sentry from "@sentry/browser";
import { SnackbarProvider } from "components/Snackbar";

import { AuthProvider, useAuth } from "hooks/data/auth";
import { DisplayNewUIProvider } from "hooks/useUISwitch";

import { ApolloProvider } from "@apollo/react-hooks";
import { createClient } from "./apollo";

initializeTokenRefresh();

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
  });
}

function GlobalProviders({ children }) {
  const { loggedIn } = useAuth();
  return (
    <ApolloProvider client={createClient(loggedIn)}>
      <TunnelProvider>
        <SnackbarProvider>
          <IntlProvider locale="en-US">
            <Router history={history}>
              <AppContainer>
                <DisplayNewUIProvider>{children}</DisplayNewUIProvider>
              </AppContainer>
            </Router>
          </IntlProvider>
        </SnackbarProvider>
      </TunnelProvider>
    </ApolloProvider>
  );
}

class ErrorBoundary extends Component {
  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV === "production") {
      Sentry.withScope((scope) => {
        try {
          const user = jwtDecode(retrieveToken());
          scope.setUser({
            email: user.email,
            username: user.username,
            id: user.user_id,
          });
        } catch (e) {}
        scope.setExtras(errorInfo);
        Sentry.captureException(error);
      });
    }
  }

  render() {
    return this.props.children;
  }
}

function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={App2} />
      <Route path="/:viewport?" component={App2} />
    </Switch>
  );
}

function MainAppWrapper() {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <GlobalProviders>
          <Routes />
        </GlobalProviders>
      </AuthProvider>
    </ErrorBoundary>
  );
}

render(<MainAppWrapper />, document.getElementById("root"));
