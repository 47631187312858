import React, { useState, useReducer, useContext, useRef } from "react";
import Modal, { Layout } from "components/Modal";
import TextInput from "components/TextInput";
import { TextareaInput } from "components/TextInput";
import FileInputButton from "components/FileInputButton";
import Button from "components/Button";
import styled from "styled-components";
import { retrieveToken } from "api/auth";
import { BASE_URL } from "api/constants";
import intersects from "@turf/boolean-intersects";
import { Context as MapStateContext } from "../../../state";
import bbox from "@turf/bbox";
import bboxPolygon from "@turf/bbox-polygon";
import ProgressBar from "components/ProgressBar";
import { CREATE_LAYER_SELECTION } from "./panels/LayerBrowserPanel";
import { CREATE_LAYER } from "../../../../layers/components/CreateLayer";
import { getClient } from "apollo";
import gql from "graphql-tag";
import { sendEvent } from "utils/ga";
import { Context as AppStateContext } from "appState";

export function uploadLayer({
  file,
  layer,
  scenarioId,
  onProgress,
  refetchQueries = [],
}) {
  const xhr = new XMLHttpRequest();

  async function executeUpload() {
    const client = getClient();
    onProgress({ type: "creating" });
    const result = await client.mutate({
      mutation: CREATE_LAYER,
      variables: {
        input: {
          title: layer.title,
          description: layer.description,
          type: "V",
        },
      },
    });
    const layerId = result.data.createLayer.layer.id;

    onProgress({ type: "uploading", progress: 0 });
    xhr.upload.onprogress = (e) => {
      onProgress({
        type: "uploading",
        progress: Math.floor((e.loaded / e.total) * 100),
      });
    };

    xhr.upload.onload = (e) => {
      onProgress({ type: "processing_upload" });
    };

    xhr.onload = async (e) => {
      if (xhr.status >= 200 && xhr.status < 300) {
        onProgress({ type: "finished_upload" });
        const interval = setInterval(async () => {
          const { data } = await client.query({
            query: gql`
              query getLayerStatus($id: ID!) {
                layer(id: $id) {
                  id
                  metadata
                  extent
                }
              }
            `,
            variables: { id: layerId },
            fetchPolicy: "network-only",
          });
          const { layer } = data;
          if (
            layer.metadata.status === "finished" &&
            "geometry_type" in layer.metadata
          ) {
            clearInterval(interval);

            onProgress({ type: "adding" });
            await client.mutate({
              mutation: CREATE_LAYER_SELECTION,
              variables: { input: { scenarioId, layerId } },
              awaitRefetchQueries: true,
              refetchQueries,
            });

            onProgress({ type: "success", layer });
          } else if (
            layer.metadata.status === "error" ||
            !("geometry_type" in layer.metadata)
          ) {
            onProgress({
              type: "error",
              message: "An error ocurred when trying to save layer",
            });
            clearInterval(interval);
          }
        }, 2000);
      } else {
        onProgress({
          type: "error",
          message: "A network related error ocurred",
        });
      }
    };

    xhr.onerror = (e) => onProgress({ type: "error" });

    const formData = new FormData();
    formData.append("file", file);

    xhr.open("POST", `${BASE_URL}layers/${layerId}/load/`, true);
    xhr.setRequestHeader("Authorization", `JWT ${retrieveToken()}`);
    xhr.send(formData);
  }

  executeUpload();

  return () => {
    xhr.abort();
  };
}

export function useUploadLayerModalHandler() {
  const [modalOpen, setModalOpen] = useState(false);
  const dragCounter = useRef(0); // Lame
  const dropTargetHandlers = {
    onDragEnter: (e) => {
      e.preventDefault();
      e.stopPropagation();
      dragCounter.current++;
      setModalOpen(true);
    },
    onDragLeave: (e) => {
      dragCounter.current--;
      e.preventDefault();
      e.stopPropagation();
      if (dragCounter.current === 0) {
        setModalOpen(false);
      }
    },
  };

  return { dropTargetHandlers, modalOpen, setModalOpen };
}

const DropZoneContainer = styled.div`
  border: dashed 3px #ddd;
  margin: 2em;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DropZoneMessage = styled.div`
  margin: 1em auto;
  color: ${(props) => (props.muted ? "#888" : "inherit")};
`;

function FileDropView({ onFileLoad }) {
  const onDrop = (e) => {
    e.preventDefault();
    onFileLoad(e.dataTransfer.files[0]);
  };
  const onDragOver = (e) => {
    e.preventDefault();
  };
  return (
    <Layout.Container>
      <DropZoneContainer onDrop={onDrop} onDragOver={onDragOver}>
        <DropZoneMessage>Drop files or</DropZoneMessage>
        <FileInputButton onChange={(e) => onFileLoad(e.target.files[0])}>
          Add from computer
        </FileInputButton>
        <DropZoneMessage muted>
          GeoJSON, CSV or zipped shapefiles are supported
        </DropZoneMessage>
      </DropZoneContainer>
    </Layout.Container>
  );
}

function LayerInfoView({
  title,
  setTitle,
  description,
  setDescription,
  onUploadClick,
}) {
  return (
    <Layout.Container>
      <Layout.Content>
        <TextInput
          label="Title"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
            sendEvent("uploadLayerModal", "changeLayerTitle");
          }}
        />
        <TextareaInput
          label="Description"
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
            sendEvent("uploadLayerModal", "changeLayerDescription");
          }}
        />
      </Layout.Content>
      <Layout.Footer>
        <Button primary onClick={onUploadClick}>
          Upload
        </Button>
      </Layout.Footer>
    </Layout.Container>
  );
}

const UploadingContent = styled(Layout.Content)`
  padding: 2em;
  padding-top: 6em;
`;

const Message = styled.div`
  padding-top: 2em;
  text-align: center;
`;

function UploadingView({ status, progress, message, onCancel }) {
  return (
    <Layout.Container>
      <UploadingContent>
        {progress !== false && <ProgressBar value={progress} />}
        <Message>{message}</Message>
      </UploadingContent>
      {status === "uploading" && (
        <Layout.Footer>
          <Button onClick={onCancel}>Cancel</Button>
        </Layout.Footer>
      )}
    </Layout.Container>
  );
}

function UploadLayerModal({ onClose }) {
  const [step, setStep] = useState("drop");
  const [file, setFile] = useState();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const { view, setParams } = useContext(MapStateContext);
  const { scenario } = useContext(AppStateContext);

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "uploading":
        return {
          progress: action.progress,
          status: "uploading",
          message: "Uploading file. Don't close this window.",
        };
      case "processing_upload":
        return {
          status: "processing_upload",
          progress: null,
          message: "Uploading file. Don't close this window.",
        };
      case "finished_upload":
        return {
          status: "finished_upload",
          progress: null,
          message: "Processing file. This may take a few minutes.",
        };
      case "error":
        return {
          status: "error",
          progress: false,
          message: "An error occurred.",
        };
      default:
        return state;
    }
  });

  const cancelUploadRef = useRef();

  const onUploadClick = async () => {
    setStep("uploading");
    cancelUploadRef.current = uploadLayer({
      file,
      layer: { title, description },
      scenarioId: scenario,
      refetchQueries: ["getLayerSelections", "getLayersPanelData"],
      onProgress: (e) => {
        if (e.type === "success") {
          const viewportPolygon = bboxPolygon(view.split(",").map(Number));
          if (e.layer.extent && !intersects(e.layer.extent, viewportPolygon)) {
            setParams({ view: bbox(e.layer.extent).join(",") });
          }
          onClose();
          sendEvent("uploadLayerModal", "layerFileUploaded");
        } else {
          dispatch(e);
        }
      },
    });
  };

  const onCancel = () => {
    cancelUploadRef.current();
    onClose();
  };

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      size="dialog"
      title="Upload layer"
      contentStyle={{ height: 350 }}
    >
      {step === "drop" && (
        <FileDropView
          onFileLoad={(file) => {
            setFile(file);
            setStep("layer_info");
            setTitle(file.name);
          }}
        />
      )}
      {step === "layer_info" && (
        <LayerInfoView
          {...{ title, setTitle, description, setDescription, onUploadClick }}
        />
      )}
      {step === "uploading" && <UploadingView {...state} onCancel={onCancel} />}
    </Modal>
  );
}

export default UploadLayerModal;
