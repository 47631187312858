import t from "prop-types";
import styled from "styled-components/macro";
import ProgressBar from "react-toolbox/lib/progress_bar/ProgressBar";

const Spinner = styled(ProgressBar).attrs(({ size }) => ({
  type: "circular",
  size: size || "18",
}))`
  width: ${({ size }) => `${size}px`}!important;
  height: ${({ size }) => `${size}px`}!important;
  svg circle {
    stroke: ${({ theme, color }) => color || theme.colors.muted};
  }
`;

export default Spinner;

Spinner.propTypes = {
  color: t.string,
  size: t.number,
};
