import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "components/Button";
import { Document, Page } from "react-pdf";
import { isPDF, isCSV } from "../../../../utils/validation";
import Lightbox from "react-image-lightbox";
import { pdfjs } from "react-pdf";
import { CsvToHtmlTable } from "react-csv-to-table";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const LightBox = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.85);
  outline: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
`;

const Modal = styled.div`
  position: absolute;
  left: 350px;
  top: 10px;
`;

const Container = styled.div`
  position: relative;
  text-align: center;
`;

const Header = styled.div`
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.muted};
  font-size: 11px;
  letter-spacing: 0.5px;
  font-weight: 600;
`;

const ControlsButton = styled(Button)`
  margin: 8px;
`;

const ChangeAttachmentArrow = styled.div`
  position: absolute;
  bottom: 8px;
  top: 50%;
  color: ${(props) => props.theme.colors.muted};
  opacity: 0.7;
  cursor: pointer;
  width: 20px;
  height: 34px;
  &:hover {
    opacity: 1;
  }
`;

const RightArrow = styled(ChangeAttachmentArrow)`
  right: 16px;
  background: rgba(0, 0, 0, 0.2)
    url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDEsMyAyLC0yIDE2LDE2IC0xNiwxNiAtMSwtMSAxNSwtMTUgLTE1LC0xNSB6IiBmaWxsPSIjRkZGIi8+PC9zdmc+")
    no-repeat center;
`;

const LeftArrow = styled(ChangeAttachmentArrow)`
  left: 16px;
  background: rgba(0, 0, 0, 0.2)
    url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDE5LDMgLTIsLTIgLTE2LDE2IDE2LDE2IDEsLTEgLTE1LC0xNSAxNSwtMTUgeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==")
    no-repeat center;
`;

const FileContainer = styled.div`
  display: block;
  user-select: none;
  height: 75vh;
  width: 50vw;
  background-color: white;
  color: black;
  overflow: scroll;
  text-overflow: ellipsis;
  word-wrap: break-word;
  font-size: 1vmin;
  white-space: pre-line;
  padding: 5vmin;
`;

function PDFPreviewer({
  src,
  onCloseRequest,
  onMovePrevRequest,
  onMoveNextRequest,
}) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  return (
    <>
      <LightBox>
        <Modal>
          <Container>
            <Header>
              Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
              <ControlsButton disabled={pageNumber <= 1} onClick={previousPage}>
                Previous
              </ControlsButton>
              <ControlsButton
                disabled={pageNumber >= numPages}
                onClick={nextPage}
              >
                Next
              </ControlsButton>
              <ControlsButton onClick={onCloseRequest}>Close</ControlsButton>
            </Header>
            <Document file={src} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} />
            </Document>
          </Container>
        </Modal>
        <LeftArrow onClick={onMovePrevRequest}></LeftArrow>
        <RightArrow onClick={onMoveNextRequest}></RightArrow>
      </LightBox>
    </>
  );
}

function CSVPreviewer({
  src,
  onCloseRequest,
  onMovePrevRequest,
  onMoveNextRequest,
}) {
  const [csvFile, setCsvFile] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      await fetch(src, { mode: "cors" })
        .then((response) => response.text())
        .then((data) => setCsvFile(data))
        .catch((e) => setError(e));
    }
    fetchData();
  }, [src]);

  return (
    <>
      <LightBox>
        <Modal>
          <Container>
            <Header>
              <ControlsButton onClick={onCloseRequest}>Close</ControlsButton>
            </Header>
            <FileContainer>
              {error ??
                (csvFile ? (
                  <CsvToHtmlTable data={csvFile} csvDelimiter="," />
                ) : (
                  "Loading file..."
                ))}
            </FileContainer>
          </Container>
        </Modal>
        <LeftArrow onClick={onMovePrevRequest}></LeftArrow>
        <RightArrow onClick={onMoveNextRequest}></RightArrow>
      </LightBox>
    </>
  );
}

function Previewer({
  src,
  onCloseRequest,
  onMovePrevRequest,
  onMoveNextRequest,
  nextSrc,
  prevSrc,
}) {
  return isPDF(src) ? (
    <PDFPreviewer
      src={src}
      onCloseRequest={onCloseRequest}
      onMovePrevRequest={onMovePrevRequest}
      onMoveNextRequest={onMoveNextRequest}
    />
  ) : isCSV(src) ? (
    <CSVPreviewer
      src={src}
      onCloseRequest={onCloseRequest}
      onMovePrevRequest={onMovePrevRequest}
      onMoveNextRequest={onMoveNextRequest}
    />
  ) : (
    <Lightbox
      mainSrc={src}
      nextSrc={nextSrc}
      prevSrc={prevSrc}
      onCloseRequest={onCloseRequest}
      onMovePrevRequest={onMovePrevRequest}
      onMoveNextRequest={onMoveNextRequest}
    />
  );
}

export default Previewer;
