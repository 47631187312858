import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { createPortal } from "react-dom";

const TunnelContext = React.createContext();

export function TunnelProvider({ children }) {
  const [nodeRefs, setNodeRefs] = useState({});

  const registerPlaceholder = useCallback((id, nodeRef) => {
    setNodeRefs((p) => ({ ...p, [id]: nodeRef }));
  }, []);

  const unregisterPlaceholder = useCallback((id) => {
    setNodeRefs((nodeRefs) => {
      const { [id]: deletedNode, ...nodeRefsWithoutId } = nodeRefs;
      return nodeRefsWithoutId;
    });
  }, []);

  const value = useMemo(
    () => ({ unregisterPlaceholder, registerPlaceholder, nodeRefs }),
    [nodeRefs, registerPlaceholder, unregisterPlaceholder]
  );

  return (
    <TunnelContext.Provider value={value}>{children}</TunnelContext.Provider>
  );
}

export function TunnelPlaceholder({ id, as = "div", ...props }) {
  const { registerPlaceholder, unregisterPlaceholder } = useContext(
    TunnelContext
  );
  const nodeRef = useRef(null);
  useEffect(() => {
    registerPlaceholder(id, nodeRef.current);
    return () => unregisterPlaceholder(id);
  }, [unregisterPlaceholder, registerPlaceholder, id]);
  return React.createElement(as, {
    ref: nodeRef,
    style: { display: "contents" },
    ...props,
  });
}

export function Tunnel({ id, children }) {
  const { nodeRefs } = useContext(TunnelContext);
  const nodeRef = nodeRefs[id];
  if (!nodeRef) {
    return null;
  }
  return createPortal(children, nodeRef);
}
