import React from "react";
import styled from "styled-components";
import Icon from "./Icon";

import { createGlobalStyle } from "styled-components";

const FixedPopupGlobalStyle = createGlobalStyle`
  div .mapboxgl-ctrl-top-left {
    left: 330px;
  }
`;

export const FixedPopupWrapper = styled.div`
  position: absolute;
  width: 320px;
  top: 10px;
  right: calc(-100% - 14px);
  z-index: 3;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 2px;
  border: 2px solid ${({ theme }) => theme.colors.darkerBackground};
`;

const FixedPopupSectionWrapper = styled.div`
  padding: 16px;

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lighterBackground};
  }
`;

const FixedPopupSectionTitle = styled.div`
  font-weight: 600;
`;

const FixedPopupSectionHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  > *:not(:last-child) {
    margin-right: 16px;
  }
`;

export const FixedPopupSectionIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.muted};
  user-select: none;
`;

export function FixedPopupSection({ icon, title, children, ...props }) {
  return (
    <FixedPopupSectionWrapper {...props}>
      <FixedPopupSectionHeader>
        {icon}
        <FixedPopupSectionTitle>{title}</FixedPopupSectionTitle>
      </FixedPopupSectionHeader>
      {children}
    </FixedPopupSectionWrapper>
  );
}

export function FixedPopup({ children, belowZoomControls, ...props }) {
  return (
    <FixedPopupWrapper {...props}>
      {!belowZoomControls && <FixedPopupGlobalStyle />}
      {children}
    </FixedPopupWrapper>
  );
}
