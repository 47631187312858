import React, { useState, useContext, useMemo } from "react";

const TasksContext = React.createContext();

export function useSelectedTask() {
  return useContext(TasksContext);
}

export function SelectedTaskProvider({ children }) {
  const [selectedTaskId, setSelectedTaskId] = useState(null);

  const value = useMemo(() => {
    function selectTask(id) {
      setSelectedTaskId(id);
    }

    return {
      selectedTaskId,
      selectTask,
    };
  }, [selectedTaskId]);

  return (
    <TasksContext.Provider value={value}>{children}</TasksContext.Provider>
  );
}
