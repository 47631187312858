import styled from "styled-components";

const ButtonLink = styled.button`
  display: inline;
  border: none;
  color: ${(props) => props.theme.colors.input};
  text-decoration: underline;
  font-size: 14px;
  background: none;
  padding: 0;
  cursor: pointer;
`;

export default ButtonLink;
