import React from "react";

import Icon from "components/Icon";
import styled from "styled-components/macro";
import useToggle from "hooks/useToggle";

const PickerWrapper = styled.div`
  position: absolute;
  top: 65px;
  left: 35px;
  z-index: 1000;
  background: rgb(255, 255, 255);
  border: 0px solid rgba(0, 0, 0, 0.25);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px;
  border-radius: 4px;
`;

const DialogPointer1 = styled.div`
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0px 9px 10px;
  border-color: transparent transparent rgba(0, 0, 0, 0.1);
  position: absolute;
  top: -11px;
  left: 12px;
`;

const DialogPointer2 = styled.div`
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0px 9px 10px;
  border-color: transparent transparent rgb(255, 255, 255);
  position: absolute;
  top: -10px;
  left: 12px;
`;

const Picker = styled.div`
  padding: 15px 9px;
`;

const TaskIcon = styled(Icon).attrs({ size: 23 })`
  margin: 0 0.25em;
  cursor: pointer;
`;

export default function IconPicker({ icon, color, onChange, disabled }) {
  const [open, toggleOpen] = useToggle(false);

  const icons = [
    "place",
    "location_city",
    "terrain",
    "flight",
    "photo_camera",
    "favorite",
    "grade",
    "build",
  ];
  return (
    <>
      <TaskIcon
        disabled={disabled}
        color={color}
        onClick={() => !disabled && toggleOpen()}
      >
        {icon}
      </TaskIcon>
      {open && (
        <PickerWrapper>
          <DialogPointer1 />
          <DialogPointer2 />
          <Picker>
            {icons.map((i) => (
              <TaskIcon
                key={i}
                color={color}
                onClick={() => {
                  toggleOpen(false);
                  onChange(i);
                }}
              >
                {i}
              </TaskIcon>
            ))}
          </Picker>
        </PickerWrapper>
      )}
    </>
  );
}
