import React from "react";
import * as RL from "@reach/listbox";
import styled, { css } from "styled-components/macro";
import Icon from "components/Icon";
import { ReactComponent as Transparent } from "../Icons/transparent.svg";

const Indicator = styled.div`
  cursor: pointer;
  position: relative;
  width: 16px;
  height: 16px;
  background-color: ${(props) => props.color};
  ${(props) =>
    props.active &&
    css`
      border: 4px solid #2b2f34;
      box-sizing: content-box;
      margin: -4px;
    `};
`;

const ButtonIcon = styled(Icon)`
  display: block;
  margin-left: 8px;
`;

const CheckIcon = styled(Icon)`
  font-weight: bold;
  ${(props) =>
    !props.display &&
    css`
      display: none;
    `}
`;

const ListboxPopover = styled(RL.ListboxPopover)`
  &[data-reach-listbox-popover] {
    border: none;
    background: ${(props) => props.theme.colors.darkerBackground};
    border-radius: 4px;
    color: ${(props) => props.theme.colors.text};
    margin-top: 1em;
  }
  &[data-reach-listbox-popover]:focus-within {
    box-shadow: none;
    outline: none;
  }
`;

const ListboxList = styled(RL.ListboxList)`
  &[data-reach-listbox-list] {
    display: flex;
    padding: 16px;
  }
`;

const ListboxButton = styled(RL.ListboxButton)`
  &[data-reach-listbox-button] {
    background: ${(props) => props.theme.colors.darkerBackground};
    border: 1px solid ${(props) => props.theme.colors.border};
    box-sizing: border-box;
    border-radius: 4px;
    padding: 8px;
    color: ${(props) => props.theme.colors.text};
  }
  &[data-reach-listbox-button]:focus-within {
    box-shadow: none;
    outline: none;
  }
`;

const ListboxOption = styled(RL.ListboxOption)`
  &[data-reach-listbox-option] {
    display: flex;
    padding: 0;
    :not(:last-child) {
      padding-right: 16px;
    }
  }
  &[data-reach-listbox-option][aria-selected="true"] {
    background: none;
  }
`;

const DialogArrow1 = styled.div`
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0px 9px 10px;
  border-color: transparent transparent rgba(0, 0, 0, 0.1);
  position: absolute;
  top: -11px;
  left: 12px;
`;

const DialogArrow2 = styled.div`
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0px 9px 10px;
  border-color: transparent transparent
    ${(props) => props.theme.colors.darkerBackground};
  position: absolute;
  top: -10px;
  left: 12px;
`;

const colors = [
  "#ffeb3b",
  "#ff9800",
  "#f44336",
  "#e91e63",
  "#9c27b0",
  "#3f51b5",
  "#2196f3",
  "#4caf50",
  "transparent",
];

export default function ColorPicker({ value, onChange, disabled = false }) {
  return (
    <>
      <RL.ListboxInput disabled={disabled} onChange={onChange}>
        <ListboxButton>
          {value === "transparent" ? (
            <Transparent />
          ) : (
            <Indicator color={value} />
          )}
          <ButtonIcon size={20}>keyboard_arrow_down</ButtonIcon>
        </ListboxButton>
        <ListboxPopover>
          <DialogArrow1 />
          <DialogArrow2 />
          <ListboxList>
            {colors.map((color) => (
              <ListboxOption key={color} value={color}>
                <Indicator active={color === value} color={color}>
                  {color === "transparent" ? (
                    <Transparent />
                  ) : (
                    <CheckIcon display={color === value}>check</CheckIcon>
                  )}
                </Indicator>
              </ListboxOption>
            ))}
          </ListboxList>
        </ListboxPopover>
      </RL.ListboxInput>
    </>
  );
}
