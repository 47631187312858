import React from "react";
import { ThemeProvider } from "styled-components/macro";
import { styledComponentsTheme } from "theme";
import RTThemeProvider from "react-toolbox/lib/ThemeProvider";

import "normalize.css/normalize.css";
import "../toolbox/theme.css";
import RTtheme from "../toolbox/theme";
import "./global.css";

const App = ({ children }) => (
  <RTThemeProvider theme={RTtheme}>
    <ThemeProvider theme={styledComponentsTheme}>{children}</ThemeProvider>
  </RTThemeProvider>
);

export default App;
