import { ApolloClient } from "apollo-client";
import { onError } from "apollo-link-error";
import { ApolloLink, Observable } from "apollo-link";
import { createUploadLink } from "apollo-upload-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import { toIdValue } from "apollo-utilities";

import { BASE_URL } from "api/constants";
import { retrieveToken, logout } from "api/auth";
import history from "utils/history";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [], // no types provided
    },
  },
});

const cacheRedirects = {
  Query: {
    scenario: (_, { id }) =>
      toIdValue(cache.config.dataIdFromObject({ __typename: "Scenario", id })),
  },
};

const cache = new InMemoryCache({ fragmentMatcher, cacheRedirects });

const request = (operation) => {
  operation.setContext({
    headers: {
      Authorization: `JWT ${retrieveToken()}`,
    },
  });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle;
      Promise.resolve(operation)
        .then((oper) => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

let client = null;

export function createClient(isLoggedIn = true) {
  client = new ApolloClient({
    defaultOptions: {
      query: {
        errorPolicy: "all",
      },
    },
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          );
        if (networkError) {
          console.log(`[Network error]: ${networkError}`);

          if (networkError.statusCode === 401) {
            logout();
            history.push("/login");
          }
        }
      }),
      requestLink,
      createUploadLink({
        uri: isLoggedIn ? `${BASE_URL}graphql/` : `${BASE_URL}graphql-public/`,
      }),
    ]),
    cache,
  });
  return client;
}

export function getClient() {
  return client;
}
window.__APOLLO_CLIENT__ = client;
