import React, { lazy, Suspense } from "react";
import LoadingScreen from "components/LoadingScreen";

const Loadable = (opts) => {
  const Component = lazy(opts.loader);
  return (props) => (
    <Suspense fallback={React.createElement(opts.loading)}>
      <Component {...props} />
    </Suspense>
  );
};

const MyLoadable = (opts) =>
  Loadable({
    loading: LoadingScreen,
    ...opts,
  });

export default MyLoadable;
