import React from "react";
import { TunnelPlaceholder } from "utils/tunnels";
import UserInfo from "./UserInfo";
import logo from "assets/images/logo.png";
import styled from "styled-components/macro";
import history from "utils/history";

const Wrapper = styled.div`
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  grid-area: topbar;
  align-items: stretch;
`;

const Fill = styled.div`
  flex: 1;
`;

const Logo = styled.img.attrs({ src: logo })`
  margin: 13px;
  height: 32px;
  cursor: pointer;
`;

const Topbar = () => (
  <Wrapper>
    <Logo
      title={`Version: ${process.env.REACT_APP_CIRCLE_SHA1}`}
      onClick={() => history.push(`/app/`)}
    />
    <TunnelPlaceholder id="search-tunnel" />
    <Fill />
    <TunnelPlaceholder id="mode-selection" />
    <UserInfo />
  </Wrapper>
);

export default Topbar;
