import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import ModeSelection from "components/ModeSelection";
import Account from "./routes/account";
import Admin from "./routes/admin";
import { RouteParamsProvider } from "../../appState";
import Company from "./routes/company";
import FullScreenMessage from "components/FullScreenMessage";
import Layers from "./routes/layers";
import MainApp from "./components/MainApp";
import Map from "./routes/map";
import Profile from "./routes/profile";
import Support from "./routes/support";
import Tasks from "./routes/tasks";
import modes from "modes";
import Restricted, { isSlingshot } from "utils/Restricted";
import { Tunnel } from "utils/tunnels";
import { Link as SidebarLink } from "components/Sidebar";
import LoadingScreen from "components/LoadingScreen";
import { SelectedTaskProvider } from "./SelectedTaskContext";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { isNil } from "lodash-es";

const GET_FIRST_SCENARIO = gql`
  query getFirstScenario {
    scenarios(first: 1) {
      edges {
        node {
          id
        }
      }
    }
  }
`;

export const GET_SCENARIO = gql`
  query getScenario($scenarioId: ID!) {
    scenario(id: $scenarioId) {
      id
    }
  }
`;

function useDefaultScenario({ mode, scenario }) {
  const savedScenarioId = localStorage.getItem("selectedScenarioId");
  const firstScenario = useQuery(GET_FIRST_SCENARIO, {
    skip: (mode && scenario) || Boolean(savedScenarioId),
  });
  const savedScenario = useQuery(GET_SCENARIO, {
    skip: !savedScenarioId,
    variables: { scenarioId: savedScenarioId },
  });

  if (savedScenarioId) {
    return savedScenario;
  } else {
    return firstScenario;
  }
}

const WorkspacesAppRoutes = ({ match }) => {
  let { mode, scenario } = match.params;
  const defaultScenario = useDefaultScenario({ mode, scenario });
  const validScenario = useQuery(GET_SCENARIO, {
    skip: !scenario,
    variables: { scenarioId: scenario },
  });

  useEffect(() => {
    if (scenario) {
      localStorage.setItem("selectedScenarioId", scenario);
    }
  }, [scenario]);

  if (defaultScenario.loading || validScenario.loading) {
    return <LoadingScreen />;
  }

  if (!mode || !scenario) {
    if (!defaultScenario.data.scenario) {
      localStorage.removeItem("selectedScenarioId");
    }
    const url =
      match.url.slice(-1)[0] === "/" ? match.url.slice(0, -1) : match.url;
    const scenarioId =
      defaultScenario.data &&
      (defaultScenario.data.scenarios
        ? defaultScenario.data.scenarios.edges[0].node.id
        : defaultScenario.data.scenario.id);
    return scenarioId ? (
      <Redirect to={`${url}/Tasks/${scenarioId}/`} />
    ) : (
      <Redirect to={`${url}`} />
    );
  }

  const isValidScenario = validScenario.loading
    ? true
    : !isNil(validScenario.data?.scenario);

  return (
    <SelectedTaskProvider>
      <Tunnel id="sidebar-links">
        <SidebarLink to={`${match.url}/map`} icon="public" name="Map" />
        {mode === modes.TASKS && (
          <SidebarLink
            to={`${match.url}/tasks`}
            icon="list"
            data-test="sidebar-tasks"
          />
        )}
      </Tunnel>
      <Tunnel id="mode-selection">
        <Restricted to={isSlingshot}>
          <ModeSelection />
        </Restricted>
      </Tunnel>
      {isValidScenario ? (
        <Switch>
          <Route path={`${match.url}/map`} component={Map} />
          {mode === modes.TASKS ? (
            <Route path={`${match.url}/tasks`} component={Tasks} />
          ) : (
            <Redirect from={`${match.url}/tasks`} to={`${match.url}/map`} />
          )}
          <Redirect to={`${match.url}/map`} />
        </Switch>
      ) : (
        <FullScreenMessage>
          Invalid scenario. If you were given a link, ask the owner of the
          workspace for permission.
        </FullScreenMessage>
      )}
    </SelectedTaskProvider>
  );
};

const GET_CUSTOMER = gql`
  query getMe {
    me {
      id
      isSlingshotMember
    }
  }
`;

const AppRoutes = ({ match }) => {
  const { data } = useQuery(GET_CUSTOMER);

  if (!data) return <LoadingScreen />;
  const customer = data.me;

  return (
    <MainApp>
      <Switch>
        <Route path={`${match.url}/profile`} component={Profile} />
        <Route path={`${match.url}/company`} component={Company} />
        <Route path={`${match.url}/account`} component={Account} />
        <Route path={`${match.url}/support`} component={Support} />
        <Route path={`${match.url}/layers`} component={Layers} />
        {customer.isSlingshotMember && (
          <Route path={`${match.url}/admin`} component={Admin} />
        )}
        <Route
          path={`${match.url}/:mode/:scenario/`}
          render={(props) => (
            <RouteParamsProvider route={props}>
              <WorkspacesAppRoutes {...props} />
            </RouteParamsProvider>
          )}
        />
        <Route // If going to /app/ exactly, we should redirect to some
          // usable route. The most used one is /map/, so let's
          // render that and let that component redirect.
          exact
          path={match.url}
          render={(props) => (
            <RouteParamsProvider route={props}>
              <WorkspacesAppRoutes {...props} />
            </RouteParamsProvider>
          )}
        />
        <SidebarLink to={`${match.url}/map`} icon="public" name="Map" />
        <Route path={`${match.url}/:mode/:scenario/`}>
          {({ match: workspacesAppMatch }) =>
            // If we're outside the workspaces routes (map/tasks),
            // render a button in the sidebar with the map icon to take
            // you to them. Default is map.
            !workspacesAppMatch && (
              <Tunnel id="sidebar-links">
                <SidebarLink to={`/app/`} icon="public" name="Map" />
              </Tunnel>
            )
          }
        </Route>
        )}
        <Route
          render={() => <FullScreenMessage>Not found</FullScreenMessage>}
        />
      </Switch>
    </MainApp>
  );
};

export default AppRoutes;
