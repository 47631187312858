import React, { useState } from "react";
import useOnClickOutside from "hooks/useOnClickOutside";

import Icon from "components/Icon";
import { Link } from "react-router-dom";
import ProfilePic from "components/ProfilePic";
import styled from "styled-components/macro";
import Restricted, { isSlingshot } from "utils/Restricted";
import { getName } from "components/UserSelect";
import { useAuth } from "../hooks/data/auth";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useUISwitch } from "hooks/useUISwitch";

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  padding-right: 13px;
  height: 100%;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: #eee;
  }
`;

const Name = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.mutedTextColor};
  margin-right: 10px;
  white-space: nowrap;
`;

const Popover = styled.div`
  position: absolute;
  width: 100%;
  background-color: white;
  z-index: 3;
  top: 100%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
`;

const MenuItem = styled.div`
  padding: 1em;
  cursor: pointer;

  &:hover {
    background-color: #eee;
  }
`;

const MenuLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const GET_CUSTOMER = gql`
  query getMe {
    me {
      id
      picture
      user {
        id
        firstName
        lastName
        username
        email
      }
    }
  }
`;

function MenuPopover({ setShowPopover }) {
  const { logout } = useAuth();
  const { switchToNewUI } = useUISwitch();
  const ref = useOnClickOutside(() => setShowPopover(false));

  return (
    <Popover ref={ref}>
      <MenuLink to={`/app/profile`}>
        <MenuItem onClick={() => setShowPopover(false)}>Profile</MenuItem>
      </MenuLink>
      <Restricted to={isSlingshot}>
        <MenuLink to={`/app/admin`}>
          <MenuItem onClick={() => setShowPopover(false)}>Admin</MenuItem>
        </MenuLink>
      </Restricted>
      <Restricted to={isSlingshot}>
        <MenuItem onClick={switchToNewUI}>Switch to new UI</MenuItem>
      </Restricted>
      <MenuItem onClick={logout}>Logout</MenuItem>
    </Popover>
  );
}

function UserInfo() {
  const [showPopover, setShowPopover] = useState(false);
  const { data } = useQuery(GET_CUSTOMER);

  if (!data) return <div />;
  const customer = data.me;

  return (
    <Container>
      <Wrapper onClick={() => setShowPopover((val) => !val)}>
        <ProfilePic src={customer.picture} />
        <Name>{getName(customer.user)}</Name>
        <Icon size={18}>expand_more</Icon>
      </Wrapper>
      {showPopover && <MenuPopover setShowPopover={setShowPopover} />}
    </Container>
  );
}

export default UserInfo;
