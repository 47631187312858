import jwtDecode from "jwt-decode";
import { BASE_URL } from "./constants";
import { checkStatus, parseJSON } from "./utils";

// Token handling helpers
const storeToken = (token) => localStorage.setItem("token", token);
const removeToken = () => localStorage.removeItem("token");
export const retrieveToken = () => localStorage.getItem("token");
const tokenIsValid = (token) => {
  try {
    const data = jwtDecode(token);
    const expirationDate = new Date(data.exp * 1000);
    const now = new Date();
    return expirationDate > now;
  } catch (e) {
    return false;
  }
};
const secondsTillExpiration = (token) => {
  if (!tokenIsValid(token)) {
    throw new Error("Token is not valid");
  }
  const data = jwtDecode(token);
  const expirationDate = new Date(data.exp * 1000);
  const now = new Date();
  return (expirationDate - now) / 1000;
};

export const isLoggedIn = () => {
  const token = retrieveToken();
  return token && tokenIsValid(token);
};

/*
  Retrieves the token from localStorage, checks its expiration time,
  and schedules a refresh for a minute before the expiration time.
*/
let refreshTimeout = null;
const scheduleTokenRefresh = () => {
  // Make sure there's only one timeout scheduling a token refresh at once
  if (refreshTimeout) {
    return;
  }

  const token = retrieveToken();
  const ttl = secondsTillExpiration(token);

  // One minute before the token expires, refresh it
  refreshTimeout = setTimeout(() => {
    fetch(BASE_URL + "api-token-refresh/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((data) => {
        // We have a new token. Store it and schedule another refresh
        storeToken(data.token);
        scheduleTokenRefresh();
      });
  }, Math.max(ttl - 60) * 1000);
};

/*
  Checks if there's already a stored token and, if it's valid,
  schedule a token refresh.
  This function is meant to be called at application bootstraping.
*/
export const initializeTokenRefresh = () => {
  if (isLoggedIn()) {
    scheduleTokenRefresh();
  }
};

export const login = (username, password) =>
  fetch(BASE_URL + "api-token-auth/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username, password }),
  })
    .then(checkStatus)
    .then(parseJSON)
    .then((data) => {
      storeToken(data.token);
      scheduleTokenRefresh();
    })
    .catch((e) => {
      if (e.response.status === 400) {
        // If it was a login error, then we make the message error
        // the message coming from the Django auth
        return e.response.json().then((data) => {
          throw new Error(data.non_field_errors[0]);
        });
      } else {
        // If it was another problem (like a 500) just throw that
        throw e;
      }
    });

export const logout = removeToken;

export const sendPasswordResetToken = (email) =>
  fetch(BASE_URL + "password-reset-token/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  })
    .then(checkStatus)
    .then(parseJSON);

export const resetPassword = ({ token, password }) =>
  fetch(BASE_URL + "reset-password/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token, password }),
  })
    .then(checkStatus)
    .then(parseJSON);

export const sendCustomerInquiry = (values) =>
  fetch(BASE_URL + "customer-inquiry/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${retrieveToken()}`,
    },
    body: JSON.stringify(values),
  })
    .then(checkStatus)
    .then(parseJSON);

export const getCompanyInfo = () =>
  fetch(BASE_URL + "company-info/", {
    headers: {
      Authorization: `JWT ${retrieveToken()}`,
    },
  })
    .then(checkStatus)
    .then(parseJSON);

window.getCompanyInfo = getCompanyInfo;
