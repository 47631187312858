import styled, { css } from "styled-components/macro";

export const Button = styled.button`
  font-size: ${(props) => props.theme.sizes.label};
  color: ${(props) => (props.disabled ? "#aaa" : props.theme.colors.input)};
  background-color: #eee;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: ${(props) => (props.disabled ? "inherit" : "pointer")};
  ${(props) =>
    props.primary &&
    css`
      color: white;
      background-color: ${(props) =>
        props.disabled ? "#bbb" : props.theme.colors.input};
    `};
  ${(props) =>
    props.danger &&
    css`
      color: white;
      background-color: red;
    `};
`;

export default Button;
