import { getType } from "@turf/invariant";
import buffer from "@turf/buffer";
import bbox from "@turf/bbox";

export function proportionalBuffer(extent, size) {
  const distanceX = (extent[2] - extent[0]) * size;
  const distanceY = (extent[3] - extent[1]) * size;

  const minX = extent[0] - distanceX;
  const minY = extent[1] - distanceY;
  const maxX = extent[2] + distanceX;
  const maxY = extent[3] + distanceY;

  return [
    Math.max(-180, minX),
    Math.max(-90, minY),
    Math.min(180, maxX),
    Math.min(90, maxY),
  ];
}
export function getOverviewBbox(geometry) {
  if (getType(geometry) === "Point") {
    return bbox(buffer(geometry, 0.6));
  } else {
    return proportionalBuffer(bbox(geometry), 0.3);
  }
}
