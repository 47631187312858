import React from "react";
import styled from "styled-components/macro";
import ProgressBar from "react-toolbox/lib/progress_bar/ProgressBar";

const Wrapper = styled.div`
  display: flex;
  padding-top: 3em;
  height: 100%;
`;

const Spinner = styled(ProgressBar)`
  margin: auto;
`;

const LoadingScreen = (props) => (
  <Wrapper {...props}>
    <Spinner type="circular" mode="indeterminate" />
  </Wrapper>
);

export default LoadingScreen;
