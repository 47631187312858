import { useRef } from "react";
import { useRect } from "@reach/rect";

export default function useMapPopupHeight() {
  const containerRef = useRef();
  const rect = useRect(containerRef);
  return {
    containerRef,
    maxPopupHeight: rect ? rect.height / 2 - 40 : 400,
  };
}
