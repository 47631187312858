import React, { useContext, useEffect } from "react";
import styled from "styled-components/macro";
import { Popup as MPopup } from "react-mapbox-gl";
import { Wrapper, CloseIcon } from "components/MapPopup";
import useOnClickOutside from "hooks/useOnClickOutside";
import { MaxPopupHeightContext } from "./Map";

const CloseButton = styled(CloseIcon)`
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 0;
`;

export function useOnEscapeKeyPress(callback) {
  useEffect(() => {
    document.body.addEventListener("keyup", (e) => {
      if (e.code === "Escape") {
        e.preventDefault();
        callback();
      }
    });
  }, [callback]);
}

function Popup({ coordinates, onClose, children }) {
  const maxPopupHeight = useContext(MaxPopupHeightContext);
  useOnEscapeKeyPress(onClose);

  function clse() {
    onClose();
  }
  const ref = useOnClickOutside(clse);
  return (
    <MPopup
      coordinates={coordinates}
      style={{
        padding: 0,
        width: "300px",
        borderRadius: 3,
        boxShadow: "none",
      }}
    >
      <Wrapper ref={ref} maxHeight={maxPopupHeight}>
        <CloseButton onClick={onClose}>close</CloseButton>
        {children}
      </Wrapper>
    </MPopup>
  );
}

export default Popup;
