import "react-tagsinput/react-tagsinput.css";

import {
  HasWorkspacePermission,
  RestrictedByWorkspacePermission,
} from "utils/Restricted";
import React, { useState, useRef } from "react";

import ActivitySection from "./ActivitySection";
import AttachmentsSection from "./AttachmentsSection";
import Button from "components/Button";
import ColorPicker from "./ColorPicker";
import Description from "./Description";
import Header from "./Header";
import IconPicker from "./IconPicker";
import Layout from "./Layout";
import LoadingScreen from "components/LoadingScreen";
import Modal from "react-modal";
import SideSection from "./SideSection";
import TagsInput from "react-tagsinput";
import TaskSection from "./TaskSection";
import Title from "./Title";
import { includes } from "lodash-es";
import styled from "styled-components/macro";
import tags from "tags";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { useTasksMutations } from "hooks/mutations/useTasksMutations";
import { useSelectedTask } from "routes/app/SelectedTaskContext";
import { sendEvent } from "utils/ga";
import Previewer from "../../routes/app/new-ui/components/Previewer";

Modal.setAppElement("#root");

const QuickTagButton = styled(Button)`
  margin-top: 1em;
  text-transform: none;
`;

const HeaderTitle = styled.h6`
  font-size: 0.9em;
  margin: 1em 0 0 0;
  text-transform: uppercase;
`;

function usePreview() {
  const [index, setIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  return { index, setIndex, isOpen, setIsOpen };
}

const GET_TASK = gql`
  query getTask($id: ID!) {
    task(id: $id) {
      id
      created
      geometry
      radius
      status
      title
      description
      tags
      color
      icon
      taskName
      assignedTo {
        id
        picture
        user {
          id
          username
          firstName
          lastName
        }
      }
      createdBy {
        id
        user {
          id
          username
          firstName
          lastName
        }
      }
      dueDate
      scenario {
        id
      }
      attachments {
        id
        name
        image
        thumbnailLarge
      }
      actions {
        id
        verb
        timestamp
        data
        actor {
          id
          username
          firstName
          lastName
          customer {
            id
            picture
          }
        }
        actionObject {
          __typename
          ... on Comment {
            id
            text
          }
          ... on Attachment {
            id
            image
            thumbnailLarge
          }
        }
        target {
          id
          geometry
        }
      }
    }
  }
`;

export default function TaskModal({ isOpen, onClose }) {
  const { selectedTaskId } = useSelectedTask();
  const preview = usePreview();
  const tagsInput = useRef();
  const { data } = useQuery(GET_TASK, {
    variables: { id: String(selectedTaskId) },
  });
  const { updateTask, deleteTask } = useTasksMutations();

  if (!data) {
    return <LoadingScreen />;
  }

  const { task } = data;

  function openAttachment(id) {
    preview.setIsOpen(true);
    preview.setIndex(
      task.attachments.findIndex((attachment) => attachment.id === id)
    );
  }

  const images = task.attachments.map((at) => at.image);
  const quickTags = Object.values(tags);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      closeTimeoutMS={0}
      style={{
        overlay: {
          zIndex: 3,
          display: "flex",
          backgroundColor: "rgba(0,0,0,0.5)",
        },
        content: {
          position: "relative",
          width: 900,
          margin: " 4em auto",
          padding: 0,
          bottom: 0,
          top: 0,
          left: 0,
          right: 0,
          overflowY: "overlay",
        },
      }}
      contentLabel="Modal"
    >
      <HasWorkspacePermission type="rw">
        {(hasWriteWorkspacePermission) => (
          <Layout.Wrapper>
            {preview.isOpen && (
              <Previewer
                src={images[preview.index]}
                nextSrc={images[(preview.index + 1) % images.length]}
                prevSrc={
                  images[(preview.index + images.length - 1) % images.length]
                }
                onCloseRequest={() => preview.setIsOpen(false)}
                onMovePrevRequest={() =>
                  preview.setIndex(
                    (preview.index + images.length - 1) % images.length
                  )
                }
                onMoveNextRequest={() =>
                  preview.setIndex((preview.index + 1) % images.length)
                }
              />
            )}
            <Header onCloseClick={onClose}>
              <ColorPicker
                disabled={!hasWriteWorkspacePermission}
                color={task.color}
                onChange={(color) => {
                  updateTask(task.id, { color });
                  sendEvent({
                    eventCategory: "task",
                    eventAction: "changeColor",
                    eventValue: color,
                  });
                }}
              />
              {task.geometry.type === "Point" && (
                <IconPicker
                  disabled={!hasWriteWorkspacePermission}
                  icon={task.icon}
                  color={task.color}
                  onChange={(icon) => {
                    updateTask(task.id, { icon });
                    sendEvent({
                      eventCategory: "task",
                      eventAction: "changeIcon",
                      eventValue: icon,
                    });
                  }}
                />
              )}
              <Title
                disabled={!hasWriteWorkspacePermission}
                value={task.title}
                onChange={(value) => {
                  if (task.title !== value) {
                    updateTask(task.id, { title: value });
                    sendEvent("task", "changeTaskTitle");
                  }
                }}
              />
            </Header>
            <Layout.Content>
              <Description
                disabled={!hasWriteWorkspacePermission}
                value={task.description}
                onChange={(value) => {
                  updateTask(task.id, { description: value });
                  sendEvent("task", "changeTaskDescription");
                }}
              />
              <TaskSection
                task={task}
                disabled={!hasWriteWorkspacePermission}
              />
              <AttachmentsSection
                task={task}
                onAttachmentClick={(i) => {
                  preview.setIsOpen(true);
                  preview.setIndex(i);
                  sendEvent("task", "openAttachment");
                }}
              />
              <ActivitySection task={task} openAttachment={openAttachment} />
            </Layout.Content>
            <Layout.Side>
              <SideSection title="Tags" icon="label_outline">
                <TagsInput
                  inputProps={
                    !hasWriteWorkspacePermission ? { placeholder: "" } : {}
                  }
                  disabled={!hasWriteWorkspacePermission}
                  value={task.tags || []}
                  onChange={(tags) => {
                    updateTask(task.id, { tags });
                    sendEvent({
                      eventCategory: "task",
                      eventAction: "addTag",
                      eventValue: tags.filter(
                        (tag) => !task.tags.includes(tag)
                      ),
                    });
                  }}
                  ref={tagsInput}
                />
                <RestrictedByWorkspacePermission type="rw">
                  <HeaderTitle>Quick tags</HeaderTitle>
                  {quickTags.map((tag, index) => {
                    return (
                      <div key={index}>
                        <QuickTagButton
                          onClick={() => {
                            tagsInput.current.addTag(tag);
                            sendEvent({
                              eventCategory: "task",
                              eventAction: "addQuickTag",
                              eventValue: tag,
                            });
                          }}
                          disabled={includes(task.tags, tag)}
                        >
                          {tag}
                        </QuickTagButton>
                      </div>
                    );
                  })}
                </RestrictedByWorkspacePermission>
              </SideSection>
            </Layout.Side>
            <Layout.Footer>
              <RestrictedByWorkspacePermission type="rw">
                <Button
                  data-test="delete-task"
                  onClick={() => {
                    if (window.confirm("Are you sure?")) {
                      onClose();
                      deleteTask(task.id);
                      sendEvent("task", "deleteTask");
                    }
                  }}
                >
                  Delete
                </Button>
              </RestrictedByWorkspacePermission>
            </Layout.Footer>
          </Layout.Wrapper>
        )}
      </HasWorkspacePermission>
    </Modal>
  );
}
