import React from "react";
import styled from "styled-components";
import * as RMB from "@reach/menu-button";
import "@reach/menu-button/styles.css";
import Icon from "components/Icon";

export const MenuItem = RMB.MenuItem;

export const MenuButton = styled(RMB.MenuButton)`
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
  padding: 0;
  color: ${(props) => props.theme.colors.muted};
`;

const MenuItems = styled(RMB.MenuItems)`
  &[data-reach-menu-items] {
    font-family: Inter;
    font-size: 13px;
    padding: 2px;
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.darkerBackground};
    color: ${(props) => props.theme.colors.text};
  }

  [data-reach-menu-item] {
    padding: 6px 30px 6px 14px;
    &[data-selected] {
      background-color: transparent;
      background-color: ${(props) => props.theme.colors.lighterBackground};
    }
  }
`;

function positionLeft(targetRect, popoverRect) {
  if (!targetRect || !popoverRect) {
    return {};
  }

  return {
    left: targetRect.right - popoverRect.width,
    top: `${targetRect.top + targetRect.height + window.pageYOffset}px`,
  };
}

export function Menu({ children }) {
  return (
    <RMB.Menu>
      <MenuButton>
        <Icon size={20}>more_vert</Icon>
      </MenuButton>
      <RMB.MenuPopover position={positionLeft}>
        <MenuItems>{children}</MenuItems>
      </RMB.MenuPopover>
    </RMB.Menu>
  );
}
