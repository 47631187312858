import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useTasks } from "hooks/useTasks";
import LoadingScreen from "components/LoadingScreen";
import ProfilePic from "components/ProfilePic";
import gql from "graphql-tag";
import {
  PanelContainer,
  PanelContent,
  PanelHeader,
  PanelSection,
  PanelIcon,
  PanelListItemWrapper,
  PanelListItemLabel,
  PanelListItemIcon,
} from "./layout";
import styled from "styled-components";
import { getName } from "components/UserSelect";
import moment from "moment";
import { Menu, MenuItem } from "components/MoreMenu";
import { FixedPopup } from "../components/FixedPopup";
import history from "utils/history";
import { useBaseUrl, useViewport } from "./../URLState";
import Tags from "../components/Tags";
import { PanelButton } from "../panels/layout";
import DateRangePicker from "../components/DateRangePicker";
import { Select } from "../components/ListBox";
import { sendEvent } from "utils/ga";
import bbox from "@turf/bbox";

const GET_CUSTOMER = gql`
  query getMe {
    me {
      id
    }
  }
`;

const FilterIcon = styled(PanelIcon)`
  color: ${({ theme }) => theme.colors.muted};

  :hover {
    color: ${({ theme }) => theme.colors.text};
  }
`;

const SectionTitle = styled.div`
  font-weight: 600;
  padding-bottom: 8px;
`;

const FilterControlsSection = styled.div`
  padding: 16px;
`;

const FilterControlsWrapper = styled.div`
  padding: 0 0 16px 0;
`;

const FilterControlsFooter = styled.div`
  display: flex;
  align-items: center;
`;

const ResetFilters = styled.div`
  margin-left: auto;
  font-weight: 600;
  line-height: 19px;
  cursor: pointer;
`;

function FilterPopup({
  tagsOptions,
  tags,
  setTags,
  status,
  setStatus,
  statusOptions,
  asigneesOptions,
  asignee,
  setAsignee,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  applyFilter,
  resetFilters,
}) {
  return (
    <FixedPopup>
      <FilterControlsSection>
        <FilterControlsWrapper>
          <SectionTitle>Status</SectionTitle>
          <Select
            value={status}
            options={statusOptions}
            onChange={(status) => {
              setStatus(status);
              sendEvent(
                "taskFilters",
                "filterTaskStatus",
                status,
                statusLabels(status)
              );
            }}
          />
        </FilterControlsWrapper>
        <FilterControlsWrapper>
          <SectionTitle>Tags</SectionTitle>
          <Tags
            value={tags}
            onChange={setTags}
            options={tagsOptions}
            disableNewTag
          />
        </FilterControlsWrapper>
        <FilterControlsWrapper>
          <SectionTitle>Asignee</SectionTitle>
          <Select
            value={asignee}
            options={asigneesOptions}
            onChange={(asignee) => {
              setAsignee(asignee);
              sendEvent("taskFilters", "filterTaskAsignee", asignee);
            }}
          />
        </FilterControlsWrapper>
        <FilterControlsWrapper>
          <SectionTitle>Date</SectionTitle>
          <DateRangePicker
            dateFrom={dateFrom}
            setDateFrom={(date) => {
              setDateFrom(date);
              sendEvent("taskFilters", "filterTaskDateFrom", dateFrom);
            }}
            dateTo={dateTo}
            setDateTo={(date) => {
              setDateTo(date);
              sendEvent("taskFilters", "filterTaskDateTo", dateTo);
            }}
          />
        </FilterControlsWrapper>
        <FilterControlsFooter>
          <PanelButton
            onClick={() => {
              applyFilter();
              sendEvent("taskFilters", "filterApplyFilter");
            }}
          >
            Apply filters
          </PanelButton>
          <ResetFilters
            onClick={() => {
              resetFilters();
              sendEvent("taskFilters", "filterResetFilters");
            }}
          >
            Reset filters
          </ResetFilters>
        </FilterControlsFooter>
      </FilterControlsSection>
    </FixedPopup>
  );
}

const TaskListItemWrapper = styled(PanelListItemWrapper)`
  display: block;
`;

const TaskInfoWrapper = styled.div`
  color: ${(props) => props.theme.colors.muted};
  display: flex;
  align-items: center;
  padding-top: 8px;
`;

const TaskDateWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const TaskProfilePic = styled(ProfilePic)`
  margin: 0 8px 0 0;
`;

const TaskItem = ({ task }) => {
  const baseUrl = useBaseUrl();
  const { updateViewport } = useViewport();
  return (
    <TaskListItemWrapper
      onClick={() => {
        history.push(`${baseUrl}/annotation/${task.id}`);
        updateViewport(bbox(task.geometry));
      }}
    >
      <PanelListItemLabel bold>
        {task.taskName || "No task name"}
      </PanelListItemLabel>
      <TaskInfoWrapper>
        <TaskProfilePic
          border={false}
          size={16}
          src={task.assignedTo?.picture}
        />
        <PanelListItemLabel>
          {task.assignedTo ? getName(task.assignedTo.user) : "Unassigned"}
        </PanelListItemLabel>
        <TaskDateWrapper>
          <PanelListItemIcon muted>event</PanelListItemIcon>
          <PanelListItemLabel>
            {(task.dueDate && moment(task.dueDate).format("MMMM D, YYYY")) ||
              "No due date"}
          </PanelListItemLabel>
        </TaskDateWrapper>
      </TaskInfoWrapper>
    </TaskListItemWrapper>
  );
};

function TasksList({ title, tasks }) {
  return (
    <PanelSection title={`${title} (${tasks.length})`}>
      {tasks.map((t) => (
        <TaskItem key={t.id} task={t} />
      ))}
    </PanelSection>
  );
}

const statusLabels = (statusCode) =>
  ({
    S: "Not Started",
    IP: "In Progress",
    OH: "On Hold",
    C: "Complete",
  }[statusCode]);

function TasksPanel() {
  const baseUrl = useBaseUrl();
  const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
  const toggleIsFilterPopupOpen = () =>
    setIsFilterPopupOpen(!isFilterPopupOpen);
  const { data, loading: customerLoading } = useQuery(GET_CUSTOMER);
  const {
    loading,
    tasks,
    filteredTasks,
    filteringMethods: {
      setTags,
      setStatus,
      setAsignee,
      setDateFrom,
      setDateTo,
      applyFilter,
      resetFilters,
    },
    tasksFilters: { tags, status, asignee, dateFrom, dateTo },
  } = useTasks();

  if (loading || customerLoading) {
    return (
      <PanelContainer>
        <LoadingScreen />
      </PanelContainer>
    );
  }

  const currentUser = data?.me;
  const tagsOptions = tasks && [...new Set(tasks.flatMap((t) => t.tags))];
  const statusOptions = [{ label: "All status", value: "All status" }];
  const asigneesOptions = tasks && [
    { label: "All asignees", value: "All asignees" },
  ];
  if (tasks) {
    let map = new Map();
    for (const task of tasks) {
      if (!map.has(task.status)) {
        map.set(task.status, true);
        statusOptions.push({
          value: task.status,
          label: statusLabels(task.status),
        });
      }
    }
    map = new Map();
    for (const task of tasks) {
      if (task.assignedTo && !map.has(task.assignedTo.id)) {
        map.set(task.assignedTo.id, true);
        asigneesOptions.push({
          value: task.assignedTo.id,
          label: `${task.assignedTo.user.firstName} ${task.assignedTo.user.lastName}`,
        });
      }
    }
  }

  return (
    <PanelContainer data-test="tasks-panel">
      {isFilterPopupOpen && (
        <FilterPopup
          tagsOptions={tagsOptions}
          tags={tags}
          setTags={setTags}
          statusOptions={statusOptions}
          status={status}
          setStatus={setStatus}
          asigneesOptions={asigneesOptions}
          asignee={asignee}
          setAsignee={setAsignee}
          dateFrom={dateFrom}
          setDateFrom={setDateFrom}
          dateTo={dateTo}
          setDateTo={setDateTo}
          applyFilter={applyFilter}
          resetFilters={resetFilters}
        />
      )}
      <PanelHeader
        title={"Tasks"}
        iconRight={
          isFilterPopupOpen ? (
            <FilterIcon onClick={toggleIsFilterPopupOpen}>clear</FilterIcon>
          ) : (
            <Menu>
              <MenuItem
                onSelect={() => {
                  toggleIsFilterPopupOpen();
                  sendEvent("taskFilters", "openTaskFiltersPanel");
                }}
              >
                Filter Tasks
              </MenuItem>
              <MenuItem
                onSelect={() => {
                  history.push(`${baseUrl}/tasks/new`);
                  sendEvent("task", "openCreateNewTask");
                }}
              >
                Create Task
              </MenuItem>
            </Menu>
          )
        }
      />
      <PanelContent>
        {filteredTasks && (
          <TasksList
            title="My tasks"
            tasks={filteredTasks.filter(
              (task) => task.assignedTo && task.assignedTo.id === currentUser.id
            )}
          />
        )}
        {filteredTasks && <TasksList title="All tasks" tasks={filteredTasks} />}
      </PanelContent>
    </PanelContainer>
  );
}

export default TasksPanel;
