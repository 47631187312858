import React from "react";
import styled, { keyframes } from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  height: 18px;
  border-radius: 3px;
  border: 1px solid #ddd;
  background-color: #eee;
`;

const progress = keyframes`
  from {
    background-position-x: 300%;
  }

  to {
    background-position-x: 0%;
  }
`;

const Value = styled.div`
  width: ${(props) => props.value}%;
  height: 100%;
  background-image: linear-gradient(
    90deg,
    hsl(210, 100%, 50%),
    hsl(210, 100%, 75%),
    hsl(210, 100%, 50%)
  );
  background-color: rgb(56, 149, 241);
  background-size: 150%;
  animation: ${progress} 2s linear infinite;
`;

export default function ProgressBar({ value }) {
  return (
    <Wrapper>
      <Value value={value === undefined ? 100 : value} />
    </Wrapper>
  );
}
