import styled from "styled-components/macro";

const Label = styled.span`
  font-size: ${(props) => props.theme.sizes.label};
  color: ${(props) => props.theme.colors.input};
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 5px;
  display: inline-block;
`;

export default Label;
