import React, { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { getClient } from "apollo";
import gql from "graphql-tag";
import useRecentList from "hooks/useRecentList";

const RecentProjectsContext = React.createContext();

async function isProjectValid(id) {
  const client = getClient();
  const result = await client.query({
    query: gql`
      query isProjectValid($id: ID!) {
        scenario(id: $id) {
          id
        }
      }
    `,
    variables: { id },
  });
  return Boolean(result.data);
}

// We are tracking recent projects at the top instead of the projects
// panel so we can track projects opened even with the projects panel
// closed, e.g. via URL

// The implementation is a simple LRU that puts new project ids at
// the beginning of the array and purging the ones at the end if
// the length is greater than LENGTH.
export function RecentProjectsProvider({ children }) {
  const { items: projects, add, remove } = useRecentList({
    key: "recentProjects",
  });
  const { project: projectId } = useParams();

  useEffect(() => {
    // Only execute the effect if the project actually changed
    if (projectId && projectId !== projects[0]) {
      isProjectValid(projectId).then((isValid) => {
        if (isValid) {
          add(projectId);
        }
      });
    }
  }, [add, projectId, projects]);

  return (
    <RecentProjectsContext.Provider
      value={{ projects, removeRecentProject: remove }}
    >
      {children}
    </RecentProjectsContext.Provider>
  );
}

export function useRecentProjectsIds() {
  const { projects, removeRecentProject } = useContext(RecentProjectsContext);
  return { projects, removeRecentProject };
}
