import React, { useContext, useEffect } from "react";
import { MapContext } from "react-mapbox-gl";
import VectorMetadataPopup from "./VectorMetadataPopup";
import useMapPopup from "hooks/useMapPopup";

export default function PopupOnVectorLayers() {
  const map = useContext(MapContext);
  const vectorMetadataPopup = useMapPopup();
  useEffect(() => {
    function onMouseMove(e) {
      const interactiveLayersIds = map
        .getStyle()
        .layers.filter(l => l.metadata && l.metadata["slingshot:interactive"])
        .map(l => l.id);

      const features = map.queryRenderedFeatures(e.point, {
        layers: interactiveLayersIds
      });

      if (features.length > 0) {
        vectorMetadataPopup.open(features[0], e.lngLat);
      } else {
        vectorMetadataPopup.close();
      }
    }

    window.map = map;

    map.on("mousemove", onMouseMove);

    return () => map.off("mousemove", onMouseMove);
  }, [map, vectorMetadataPopup]);

  if (vectorMetadataPopup.value !== null) {
    return <VectorMetadataPopup {...vectorMetadataPopup.popupProps} />;
  } else return null;
}
