import React, { useEffect, useRef } from "react";

function ColorThemeThumbnail({ theme, ...rest }) {
  let canvas = useRef();

  useEffect(() => {
    const draw = () => {
      const ctx = canvas.current.getContext("2d");
      const width = canvas.current.width;
      const height = canvas.current.height;
      const sampleHeight = Math.max(Math.ceil(height / 255), 1);
      for (let i = 0; i < 255; i++) {
        let sampleOffset = Math.floor((height / 255) * i);
        let color = theme[i].map((comp) => comp * 255);
        ctx.fillStyle = `rgb(${color.join()})`;
        ctx.fillRect(0, sampleOffset, width, sampleHeight);
      }
    };
    draw();
  }, [theme]);

  return <canvas ref={canvas} {...rest} />;
}

export default ColorThemeThumbnail;
