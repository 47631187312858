import { useState } from "react";

export default function useToggle(initialValue) {
  const [value, setValue] = useState(initialValue);
  return [
    value,
    (value) =>
      setValue((prevValue) =>
        typeof value === "boolean" ? value : !prevValue
      ),
  ];
}
