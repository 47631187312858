import styled, { css } from "styled-components/macro";
import RTCheckbox from "react-toolbox/lib/checkbox";

const Checkbox = styled(RTCheckbox).attrs({ theme: { checked: "checked" } })`
  ${(props) =>
    props.color &&
    css`
      .checked {
        background-color: ${(props) => props.color || "inherit"} !important;
        border-color: ${(props) => props.color || "inherit"} !important;
      }
    `};
`;

export default Checkbox;
