import React from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { useForm } from "utils/form";
import styled from "styled-components";
import * as v from "utils/validation";
import {
  PanelContainer,
  PanelContent,
  PanelHeader,
  PanelButton,
} from "./layout";
import { TextAreaInput, Field } from "../components/Input";
import { Select } from "../components/ListBox";
import { sendEvent } from "utils/ga";
import ScreenMessage from "../components/ScreenMessage";

const FeedbackContainer = styled.div`
  padding: 16px;
`;

const StyledScreenMessage = styled(ScreenMessage)`
  padding: 3em;
  background-color: ${(props) => props.theme.colors.background};
`;

const SEND_FEEDBACK = gql`
  mutation sendBugReport($input: EmailSupportInput!) {
    emailSupport(input: $input) {
      success
    }
  }
`;

export default function SendFeedbackPanel() {
  const [sendBugReport] = useMutation(SEND_FEEDBACK);
  const { fields, success, isValid, onSubmit } = useForm({
    fields: ["issue", "feedback"],
    initialValues: { issue: "sn" },
    validate: v.createValidator({ feedback: v.required, issue: v.required }),
    onSubmit: async ({ issue, feedback }) => {
      await sendBugReport({
        variables: { input: { subject: `Feedback: ${issue}`, body: feedback } },
      });
      sendEvent("support", "sendSupport", issue, feedback);
    },
  });
  const supportOptions = [
    { label: "Support Needed", value: "sn" },
    { label: "Report Bug", value: "rb" },
  ];
  return (
    <PanelContainer>
      <PanelHeader
        title={"Submit feedback"}
        iconRight={
          success ? null : (
            <PanelButton disabled={!isValid} onClick={onSubmit}>
              Submit
            </PanelButton>
          )
        }
      />
      <PanelContent>
        <FeedbackContainer>
          {success ? (
            <StyledScreenMessage>Thank you!</StyledScreenMessage>
          ) : (
            <>
              <Field label="Issue">
                <Select options={supportOptions} {...fields.issue} />
              </Field>
              <Field label="Feedback">
                <TextAreaInput
                  type="text"
                  placeholder="Your feedback"
                  {...fields.feedback}
                />
              </Field>
            </>
          )}
        </FeedbackContainer>
      </PanelContent>
    </PanelContainer>
  );
}
