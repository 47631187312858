import React, { useState, useEffect, useContext } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { useSelectedProject } from "../routes/app/new-ui/URLState";
import { useAuth } from "hooks/data/auth";
import moment from "moment";

const GET_TASKS = gql`
  query getTasks($scenarioId: ID!) {
    scenario(id: $scenarioId) {
      id
      tasks(first: 5000) {
        edges {
          node {
            id
            title
            geometry
            radius
            color
            icon
            status
            taskName
            dueDate
            tags
            attachments {
              id
              image
            }
            assignedTo {
              id
              picture
              user {
                id
                username
                firstName
                lastName
              }
            }
          }
        }
      }
    }
  }
`;

const Context = React.createContext();

export function useTasks() {
  return useContext(Context);
}

export function TasksProvider({ children }) {
  const { projectId } = useSelectedProject();
  const { loggedIn } = useAuth();
  const { data, loading } = useQuery(GET_TASKS, {
    variables: { scenarioId: projectId },
    skip: !projectId || !loggedIn,
  });
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [tags, setTags] = useState([]);
  const [status, setStatus] = useState("All status");
  const [asignee, setAsignee] = useState("All asignees");
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [editingFeature, setEditingFeature] = useState(null);

  useEffect(() => {
    const tasks =
      data?.scenario.tasks.edges
        .map((e) => e.node)
        .filter((node) => node.status) || [];
    setTasks(tasks);
    setFilteredTasks(tasks);
  }, [data]);

  if (loading) {
    return (
      <Context.Provider
        value={{
          loading: true,
          tasks: [],
          annotations: [],
          filteredTasks: [],
          filteringMethods: {},
          tasksFilters: {},
          editingFeature,
          setEditingFeature,
        }}
      >
        {children}
      </Context.Provider>
    );
  }
  let annotations =
    data?.scenario.tasks.edges
      .map((e) => e.node)
      .filter((node) => node.status === null) || [];
  let filteringMethods = {
    setFilteredTasks,
    setTags,
    setStatus,
    setAsignee,
    setDateFrom,
    setDateTo,
    applyFilter: () => {
      let filteredTasks = tasks;
      if (tags.length > 0) {
        filteredTasks = filteredTasks.reduce((result, task) => {
          if (task.tags.some((tag) => tags.includes(tag))) {
            result.push(task);
          }
          return result;
        }, []);
      }
      if (status && status !== "All status") {
        filteredTasks = filteredTasks.filter((task) => task.status === status);
      }
      if (asignee && asignee !== "All asignees") {
        filteredTasks = filteredTasks.filter(
          (task) => task.assignedTo?.id === asignee
        );
      }
      if (dateFrom) {
        filteredTasks = filteredTasks.filter(
          (task) =>
            moment(task.dueDate).isAfter(dateFrom) ||
            moment(task.dueDate).isSame(dateFrom)
        );
      }
      if (dateTo) {
        filteredTasks = filteredTasks.filter(
          (task) =>
            moment(task.dueDate).isBefore(dateTo) ||
            moment(task.dueDate).isSame(dateTo)
        );
      }
      setFilteredTasks(filteredTasks);
    },
    resetFilters: () => {
      setFilteredTasks(tasks);
      setTags([]);
      setStatus("All status");
      setAsignee("All asignees");
      setDateFrom(null);
      setDateTo(null);
    },
  };
  let tasksFilters = {
    tags,
    status,
    asignee,
    dateFrom,
    dateTo,
  };
  return (
    <Context.Provider
      value={{
        loading: loading,
        tasks: tasks,
        annotations: annotations,
        filteredTasks: filteredTasks,
        filteringMethods: filteringMethods,
        tasksFilters: tasksFilters,
        editingFeature,
        setEditingFeature,
      }}
    >
      {children}
    </Context.Provider>
  );
}
