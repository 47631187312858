import styled from "styled-components/macro";
import React from "react";

const BaseIconSpan = styled.span`
  font-size: ${(props) => (props.size ? props.size + "px" : "inherit")};
  color: ${(props) => (props.color ? props.color : "inherit")};
`;

export default function Icon({ children = "", className = "", ...props }) {
  if (children.startsWith("fa-")) {
    return (
      <BaseIconSpan className={`fas ${children} ${className}`} {...props} />
    );
  } else {
    return (
      <BaseIconSpan className={`material-icons ${className}`} {...props}>
        {children}
      </BaseIconSpan>
    );
  }
}
