import React from "react";
import styled from "styled-components";
import { useViewport, useSelectedProject } from "./URLState";
import { useQuery } from "@apollo/react-hooks";
import logo from "assets/images/logo.svg";
import logo211 from "assets/images/211_logo.svg";
import gql from "graphql-tag";
import Restricted, { isSlingshot } from "utils/Restricted";
import Icon from "components/Icon";
import { useNotification } from "./components/Notification";
import { sendEvent } from "utils/ga";
import { useScenarioMutations } from "./../routes/map/components/maps/Base/WorkspaceScenarioIndicator";
import { useUISwitch } from "hooks/useUISwitch";
import { isLoggedIn } from "api/auth";
import domtoimage from "dom-to-image";
import { useScreenshot } from "../../../hooks/useScreenshot";

const GET_PROJECT_NAME = gql`
  query getProjectName($projectId: ID!) {
    scenario(id: $projectId) {
      id
      name
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  grid-area: topbar;
  background-color: ${(props) => props.theme.colors.background};
  padding: 0 16px;
`;

const Logo211Wrapper = styled.a`
  cursor: pointer;
  text-decoration: none;
`;
const Logo211 = styled.img.attrs({ src: logo211 })`
  height: 32px;
  padding-right: 16px;
`;

const Logo = styled.img.attrs({ src: logo })`
  height: 12px;
`;

const SlingshotLogoWrapper = styled.a`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  text-decoration: none;
`;

const Text = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.text};
`;

const OldUiButton = styled.div.attrs({ role: "button" })`
  cursor: pointer;
  margin-right: 24px;
`;

const SaveViewButton = styled(Icon).attrs({ title: "Save view as default" })`
  cursor: pointer;
  font-size: 21px;
  color: ${({ theme }) => theme.colors.muted};

  &:hover {
    color: ${({ theme }) => theme.colors.text};
  }
`;

export function Logos() {
  return (
    <>
      <Logo211Wrapper href={"https://211la.org/"} target="_blank">
        <Logo211 title={"211 LA County"} />
      </Logo211Wrapper>
      <SlingshotLogoWrapper
        href={"https://slingshotaerospace.com/"}
        target="_blank"
      >
        <Text>{"Powered by"}</Text>
        <Logo />
      </SlingshotLogoWrapper>
    </>
  );
}

function Topbar() {
  const { projectId } = useSelectedProject();
  const { data } = useQuery(GET_PROJECT_NAME, {
    variables: { projectId },
    skip: !projectId,
  });
  const { viewport } = useViewport();
  const { setScenarioView } = useScenarioMutations();
  const { fireNotification } = useNotification();
  const { switchToOldUI } = useUISwitch();
  const {
    screenShotMap,
    screenShotLoading,
    setScreenShotLoading,
  } = useScreenshot();

  if (!data?.scenario)
    return (
      <Wrapper>
        <Logos />
      </Wrapper>
    );

  // eslint-disable-next-line no-unused-vars
  const onPrinterClick = async () => {
    const mapCanvas = screenShotMap?.getCanvas();
    if (!mapCanvas || screenShotLoading) return;

    setScreenShotLoading(true);

    const screenshotCanvas = document.createElement("canvas");
    const ctx = screenshotCanvas.getContext("2d");
    screenshotCanvas.width = mapCanvas.width;
    screenshotCanvas.height = mapCanvas.height;

    const topbarDataURL = await domtoimage.toPng(
      document.getElementById("topbar-wrapper")
    );

    let mapLegendDataURL = null;
    const legendDiv = document.getElementById("map-legend-wrapper");
    if (legendDiv) mapLegendDataURL = await domtoimage.toPng(legendDiv);

    let imagesLoaded = 0;

    const generatePrint = async () => {
      imagesLoaded += 1;
      if (imagesLoaded === 3 || (imagesLoaded === 2 && !mapLegendDataURL)) {
        ctx.drawImage(mapImage, 0, 0);
        ctx.drawImage(
          topbarImage,
          0,
          0,
          mapCanvas.width,
          topbarImage.height * 2
        );
        if (mapLegendDataURL) {
          ctx.beginPath();
          ctx.rect(
            mapCanvas.width - legendImage.width - 30,
            mapCanvas.height - legendImage.height - 30,
            legendImage.width,
            legendImage.height
          );
          ctx.fillStyle = "black";
          ctx.fill();

          const legendMargin = 20;
          const legendItems = document.querySelectorAll("[data-legend-item]");
          let heightLegendDraw =
            mapCanvas.height - legendImage.height - legendMargin;
          for (let i = 0; i < legendItems.length; i++) {
            const legendItem = await domtoimage.toPng(legendItems[i]);
            const legendItemImage = await loadImage(legendItem);
            await ctx.drawImage(
              legendItemImage,
              mapCanvas.width - legendImage.width - legendMargin,
              heightLegendDraw
            );
            heightLegendDraw += legendItemImage.height + legendMargin / 2;
          }
        }

        const link = document.createElement("a");
        link.download = "screenshot.png";
        link.href = screenshotCanvas.toDataURL("image/png");
        link.click();
      }
    };

    const loadImage = (src, onload) => {
      if (!src) return null;
      let img = new Image();
      img.onload = onload;
      img.src = src;
      return img;
    };

    const mapImage = loadImage(mapCanvas.toDataURL(), generatePrint);
    const topbarImage = loadImage(topbarDataURL, generatePrint);
    const legendImage = loadImage(mapLegendDataURL, generatePrint);

    setScreenShotLoading(false);
  };

  return (
    <Wrapper>
      <Logos />
      <Restricted to={isSlingshot}>
        <OldUiButton onClick={switchToOldUI}>Switch to old UI</OldUiButton>
      </Restricted>
      <Restricted to={isLoggedIn}>
        <SaveViewButton
          onClick={(e) => {
            setScenarioView({
              id: data.scenario.id,
              defaultView: decodeURIComponent(viewport).split(",").map(Number),
            });
            fireNotification({
              type: "success",
              content: "Default view saved successfully",
            });
            sendEvent("projects", "Save view as default");
          }}
        >
          bookmark
        </SaveViewButton>
      </Restricted>
    </Wrapper>
  );
}

export default Topbar;
