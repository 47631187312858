import React from "react";
import useToggle from "hooks/useToggle";
import MobilePanel from "./MobilePanel";
import {
  PanelContent,
  PanelIcon,
  PanelSectionWrapper,
  PanelListItemWrapper,
  PanelListItemLabel,
  IconsWrapper
} from "../panels/layout";
import { useSelectedFeature } from "hooks/useSelectedFeature";
import {
  parseFeatureProperties,
  TwoElevenContent
} from "../panels/VectorMetadataPanel";
import Icon from "../components/Icon";
import styled from "styled-components";

const TextWrapper = styled.div`
  word-wrap: break-word;
  padding: 8px 16px;
  color: ${({ muted, theme }) => (muted ? theme.colors.muted : "inherit")};
`;

const HeaderWrapper = styled.div`
  padding-bottom: 8px;
  border-bottom: 1px solid ${props => props.theme.colors.lighterBackground};
`;

const TextLine = styled.div`
  font-weight: ${({ bold, theme }) => (bold ? "600" : "inherit")};
  flex: 1;
`;

const Label = styled(PanelListItemLabel)`
  text-transform: capitalize;
  font-weight: 600;
`;

const CollapseBar = styled.div`
  background-color: ${({ theme }) => theme.colors.muted};
  border-radius: 32px;
  height: 4px;
  margin: 0 auto;
  width: 32px;
`;

const PanelListItem = ({ label, muted, rightIcon, leftIcon }) => {
  return (
    <PanelListItemWrapper muted={muted}>
      {leftIcon && <IconsWrapper>{leftIcon}</IconsWrapper>}
      <Label>{label.toLowerCase()}</Label>
      <IconsWrapper>{rightIcon}</IconsWrapper>
    </PanelListItemWrapper>
  );
};

function VectorMetadataPanel() {
  const { feature, close } = useSelectedFeature();
  const properties = parseFeatureProperties({ feature });
  const [collapsed, toggleCollapsed] = useToggle(true);

  return (
    <MobilePanel bottom collapsible collapsed={collapsed}>
      <PanelSectionWrapper>
        <PanelContent>
          <CollapseBar />
          <HeaderWrapper onClick={toggleCollapsed}>
            <PanelListItem
              label={properties.name}
              leftIcon={
                <Icon color={feature.layer.paint["circle-color"]}>room</Icon>
              }
              rightIcon={
                <PanelIcon muted onClick={close}>
                  clear
                </PanelIcon>
              }
            />
            <TextWrapper muted>
              {properties.address ? (
                <>
                  <TextLine>{properties.address.address}</TextLine>
                  <TextLine>{`${properties.address.city}, ${properties.address.county} ${properties.address.zip}`}</TextLine>
                </>
              ) : (
                <TextLine>No address available</TextLine>
              )}
            </TextWrapper>
          </HeaderWrapper>
          <TwoElevenContent feature={feature} mobile />
        </PanelContent>
      </PanelSectionWrapper>
    </MobilePanel>
  );
}

export default VectorMetadataPanel;
