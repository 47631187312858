import area from "@turf/area";

const isEmpty = (value) =>
  value === undefined || value === null || value === "";
const join = (rules) => (value, data) =>
  rules.map((rule) => rule(value, data)).filter((error) => !!error)[0];

export function email(value) {
  if (
    !isEmpty(value) &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  ) {
    return "Invalid e-mail address";
  }
}

export function noSpaces(value) {
  if (value.includes(" ")) {
    return "No spaces allowed";
  }
}

export function slug(value) {
  if (!isEmpty(value) && !/^[A-Z0-9._-]+$/i.test(value)) {
    return "Enter a valid name consisting of letters, numbers, underscores or hyphens";
  }
}

export function setRequired(value) {
  if (value.size === 0) {
    return "This field is required";
  }
}

export function required(value) {
  if (isEmpty(value)) {
    return "This field is required";
  }
}

export function minLength(min) {
  return (value) => {
    if (!isEmpty(value) && value.length < min) {
      return `It must be at least ${min} characters`;
    }
  };
}

export function maxLength(max) {
  return (value) => {
    if (!isEmpty(value) && value.length > max) {
      return `It must be at most ${max} characters`;
    }
  };
}

export function integer(value) {
  if (!Number.isInteger(Number(value))) {
    return "It must be an integer number";
  }
}

export function isNumber(value) {
  if (isNaN(value)) {
    return "It must be a valid number";
  }
}

export function oneOf(enumeration) {
  return (value) => {
    if (!~enumeration.indexOf(value)) {
      return `It must be one of: ${enumeration.join(", ")}`;
    }
  };
}

export function match(field) {
  return (value, data) => {
    if (data) {
      if (value !== data[field]) {
        return "It must match";
      }
    }
  };
}

export function validEndDate(startDate) {
  return (value, data) => {
    if (data) {
      if (data[startDate] >= value) {
        return "The End date must be greater than the Start date";
      }
    }
  };
}

export function validMinRes(maxRes) {
  return (value, data) => {
    if (data) {
      if (value >= data[maxRes]) {
        return "The Min resolution must be smaller than the Max resolution";
      }
    }
  };
}

export function validSkywatchPolygon(value) {
  if (!value) {
    return "No polygon";
  }
  if (area(value) / 1000000 > 500) {
    return "Polygon area must be smaller than 500km2";
  }
}

export const validBbox = (bbox) => {
  try {
    const coords = bbox.split(",");
    return coords && coords.length === 4 && coords.every((c) => !isNaN(c));
  } catch (e) {
    return false;
  }
};

export function validateBbox(value) {
  if (!validBbox(value)) {
    return "The given bounding box is invalid";
  }
}

export function createValidator(rules) {
  return (data = {}) => {
    const errors = {};
    Object.keys(rules).forEach((key) => {
      const rule = join([].concat(rules[key])); // concat enables both functions and arrays of functions
      const error = rule(data[key], data);
      if (error) {
        errors[key] = error;
      }
    });
    return errors;
  };
}

export function isValid(errors) {
  return Object.keys(errors).find((key) => errors[key] !== null) === undefined;
}

export function isPDF(fileName) {
  return fileName.toLowerCase().includes(".pdf");
}

export function isImage(fileName) {
  const lower = fileName.toLowerCase();
  return (
    lower.includes(".jpg") ||
    lower.includes(".jpeg") ||
    lower.includes(".png") ||
    lower.includes(".gif")
  );
}

export function isCSV(fileName) {
  return fileName.toLowerCase().includes(".csv");
}
