import React from "react";
import styled from "styled-components/macro";
import Icon from "../components/Icon";

const MarketplaceCard = styled.div`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.colors.lighterBackground};
  line-height: 19px;
  padding-left: 16px;
`;

const InfoSection = styled.div`
  flex-direction: column;
`;

const Image = styled.img`
  width: 96px;
  margin: 16px 16px 16px 0;
`;

const Title = styled.div`
  padding-top: 18px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.text};
`;

const Subtitle = styled.div`
  padding-top: 1px;
  color: ${(props) => props.theme.colors.muted};
`;

const IconContainer = styled.div`
  padding-top: 13px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.muted};
`;

const Value = styled.div`
  padding-left: 8px;
  padding-right: 30px;
`;

const CreditSection = styled.div`
  font-weight: 600;
  color: ${(props) => props.theme.colors.primary};
  padding: 12px 0 17px 0;
`;

function MarketplacePanelCard({
  title = "February 22, 2020",
  subtitle = "PlanetScape",
  cloudCoverage = "4%",
  resolution = "50m",
  credits = "1 credit",
  imageSrc = "http://via.placeholder.com/96x96",
}) {
  return (
    <MarketplaceCard>
      <Image src={imageSrc} size={96} />
      <InfoSection>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <IconContainer>
          <Icon svg size={15}>
            cloud
          </Icon>
          <Value>{cloudCoverage}</Value>
          <Icon svg size={15}>
            resolution
          </Icon>
          <Value>{resolution}</Value>
        </IconContainer>
        <CreditSection>{credits}</CreditSection>
      </InfoSection>
    </MarketplaceCard>
  );
}

export default MarketplacePanelCard;
