import React from "react";
import styled, { css } from "styled-components";

const MobilePanelWrapper = styled.div`
  position: absolute;
  width: 100%;
  z-index: 10;
  background-color: ${({ theme }) => theme.colors.background};
  ${props =>
    props.collapsible &&
    css`
      top: ${({ collapsed }) => (collapsed ? "75%" : "0")};
      transition: all 1s;
    `}
  bottom: ${({ bottom }) => (bottom ? "0" : "auto")};
  box-shadow: 0px -2px 0px ${props =>
    props.theme.colors.darkerBackground}, 0px 1px 0px ${props =>
  props.theme.colors.darkerBackground};
  max-height: ${({ bottom }) => (bottom ? "none" : "200px")};
  overflow-y: auto;
`;

function MobilePanel({ children, ...props }) {
  return <MobilePanelWrapper {...props}>{children}</MobilePanelWrapper>;
}
export default MobilePanel;
