import React, { useState, useRef, useEffect } from "react";
import {
  PanelContainer,
  PanelContent,
  PanelHeader,
  PanelSection,
  PanelIcon,
  PanelListItem,
  PanelListItemWrapper,
} from "./layout";
import LoadingScreen from "components/LoadingScreen";
import styled from "styled-components";
import gql from "graphql-tag";
import { useSelectedProject } from "../URLState";
import { useQuery, useMutation } from "@apollo/react-hooks";

import useOnClickOutside from "hooks/useOnClickOutside";
import { useScenariosMutations } from "routes/app/routes/map/components/maps/Base/panels/WorkspacesPanel";
import { useRecentProjectsIds } from "../RecentProjects";

import { getClient } from "apollo";
import { sendEvent } from "utils/ga";
import { groupBy } from "lodash-es";

const GET_PROJECTS = gql`
  query getProjects {
    scenarios {
      edges {
        node {
          id
          name
          workspace {
            id
            myPermission
            owner {
              __typename
              ... on Customer {
                id
              }
              ... on Company {
                id
              }
            }
          }
        }
      }
    }
    me {
      id
      companies {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

const GET_PROJECT = gql`
  query getProject($id: ID!) {
    scenario(id: $id) {
      id
      name
    }
  }
`;

// We're using client.query instead of useQuery so we can execute
// the query an arbitrary amount of times, in parallel.
async function getRecentProjects(ids) {
  const client = getClient();
  const promises = ids.map((id) =>
    client.query({ query: GET_PROJECT, variables: { id } })
  );
  return await Promise.all(promises).then((results) =>
    results
      .filter((result) => result.data.scenario)
      .map((result) => result.data.scenario)
  );
}

function useRecentProjects() {
  const [projects, setProjects] = useState(null);
  const { projects: ids } = useRecentProjectsIds();

  useEffect(() => {
    getRecentProjects(ids).then(setProjects);
  }, [ids]);

  return projects;
}

const CREATE_PROJECT = gql`
  mutation createProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      success
      scenario {
        id
        name
      }
    }
  }
`;

const NamingProjectInput = styled.input`
  border: none;
  background-color: transparent;
  outline: 0;
  color: inherit;
  font-weight: inherit;

  ::placeholder {
    color: ${(props) => props.theme.colors.muted};
  }
`;

const NamingProjectForm = styled.form`
  flex: 1;
`;

function NamingProject({ initialName, active, onChange, onCancel, ...props }) {
  const ref = useOnClickOutside(onCancel);
  const inputRef = useRef();
  const [value, setValue] = useState(initialName ? initialName : "");

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <PanelListItemWrapper ref={ref} active={active} {...props}>
      <NamingProjectForm
        // Using a form so typing and hitting enter also triggers submission.
        onSubmit={(e) => {
          e.preventDefault();
          onChange(value);
        }}
      >
        <NamingProjectInput
          data-test="naming-project-input"
          ref={inputRef}
          placeholder="Project name..."
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </NamingProjectForm>
      <>
        <PanelIcon title="Save" onClick={() => onChange(value)}>
          done
        </PanelIcon>
        <PanelIcon title="Cancel" onClick={onCancel}>
          clear
        </PanelIcon>
      </>
    </PanelListItemWrapper>
  );
}

function Project({
  project,
  active,
  editable = true,
  onSelect,
  onRenameClick,
}) {
  return (
    <PanelListItem
      key={project.id}
      label={project.name}
      active={active}
      onClick={onSelect}
      rightIcon={(isHovered) =>
        isHovered &&
        editable && (
          <PanelIcon
            data-test="rename-project"
            title="Rename"
            onClick={onRenameClick}
          >
            create
          </PanelIcon>
        )
      }
    />
  );
}

function ProjectsList({
  projects,
  title,
  companyId,
  selectProject,
  projectId,
  enableCreation = true,
  ...props
}) {
  const [renamingProjectId, setRenamingProjectId] = useState(null);
  const [creatingProject, setCreatingProject] = useState(false);
  const { renameScenario: renameProject } = useScenariosMutations();
  const [createProject] = useMutation(CREATE_PROJECT, {
    refetchQueries: [{ query: GET_PROJECTS }],
  });
  return (
    <PanelSection
      title={title}
      icon={
        enableCreation && (
          <PanelIcon
            data-test="create-project"
            muted
            onClick={() => {
              setCreatingProject(true);
              setRenamingProjectId(null);
            }}
          >
            add
          </PanelIcon>
        )
      }
      {...props}
    >
      {creatingProject && (
        <NamingProject
          key="creating-project"
          onCancel={() => setCreatingProject(false)}
          onChange={async (name) => {
            const result = await createProject({
              variables: {
                input: {
                  name,
                  companyId,
                },
              },
            });
            setCreatingProject(false);
            selectProject(result.data.createProject.scenario.id);
            sendEvent(
              "project",
              "createdNewProject",
              result.data.createProject.scenario.id
            );
          }}
        />
      )}
      {projects.map((p) =>
        p.id === renamingProjectId ? (
          <NamingProject
            key={p.id}
            initialName={p.name}
            active={p.id === projectId}
            onCancel={() => setRenamingProjectId(null)}
            onChange={(name) => {
              renameProject({ id: p.id, name });
              setRenamingProjectId(null);
            }}
          />
        ) : (
          <Project
            key={p.id}
            project={p}
            active={p.id === projectId}
            onSelect={() => {
              selectProject(p.id);
              sendEvent("project", "selectProject", p.id);
            }}
            onRenameClick={() => setRenamingProjectId(p.id)}
          />
        )
      )}
    </PanelSection>
  );
}

function ProjectsPanel() {
  // Apollo doesn't seem to refetch queries that are not being used at the moment.
  // So when the projects panel it's not open, any mutation that specifies
  // refetchQueries: ["GET_PROJECTS"] won't refetch this query. So we need to
  // make sure we always try to get the latest data.
  const { data } = useQuery(GET_PROJECTS, { fetchPolicy: "cache-and-network" });
  const { projectId, selectProject } = useSelectedProject();
  const recentProjects = useRecentProjects();
  if (!data) {
    return (
      <PanelContainer>
        <LoadingScreen />
      </PanelContainer>
    );
  }
  const projects = data.scenarios.edges.map((e) => e.node);
  const customer = data.me;
  const myProjects = projects.filter(
    (s) =>
      s.workspace.owner.__typename === "Customer" &&
      s.workspace.owner.id === customer.id
  );
  const sharedWithMe = projects.filter(
    (s) => s.workspace.myPermission !== "ow"
  );

  const companies = customer.companies.edges.map((e) => e.node);
  const emptyByCompany = {};
  companies.forEach(({ id }) => {
    emptyByCompany[id] = [];
  });
  const byCompany = {
    ...emptyByCompany,
    ...groupBy(
      projects.filter(
        (s) =>
          s.workspace.owner.__typename === "Company" &&
          companies.map((e) => e.id).includes(s.workspace.owner.id)
      ),
      (s) => s.workspace.owner.id
    ),
  };
  return (
    <PanelContainer data-test="projects-panel">
      <PanelHeader title="Projects" />
      <PanelContent>
        {recentProjects && (
          <PanelSection title="Recent projects">
            {recentProjects.map((project) => (
              <Project
                key={project.id}
                editable={false}
                project={project}
                active={project.id === projectId}
                onSelect={() => {
                  selectProject(project.id);
                  sendEvent("project", "selectProject", project.id);
                }}
              />
            ))}
          </PanelSection>
        )}
        {Object.entries(byCompany).map(([companyId, projects]) => (
          <ProjectsList
            title={`${
              companies.find(({ id }) => id === companyId).name
            } projects`}
            projects={projects}
            companyId={companyId}
            {...{ selectProject, projectId }}
          />
        ))}
        <ProjectsList
          title="Projects shared with me"
          projects={sharedWithMe}
          enableCreation={false}
          {...{ selectProject, projectId }}
        />
        <ProjectsList
          title="My Projects"
          projects={myProjects}
          {...{ selectProject, projectId }}
          data-test="my-projects-section"
        />
      </PanelContent>
    </PanelContainer>
  );
}

export default ProjectsPanel;
