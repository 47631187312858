import React from "react";
import styled, { css } from "styled-components";
import useHover from "hooks/useHover";
import Icon from "../components/Icon";
import Spinner from "../components/Spinner";

const Header = styled.div`
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.muted};
  font-size: 11px;
  letter-spacing: 0.5px;
  font-weight: 600;
`;

export const PanelContainer = styled.div`
  width: 320px;
  background-color: ${(props) => props.theme.colors.background};
  border-right: 2px solid ${(props) => props.theme.colors.darkerBackground};
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`;

export const PanelContent = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const PanelHeaderWrapper = styled.div`
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  border-bottom: 1px solid ${(props) => props.theme.colors.lighterBackground};
`;

export const PanelHeaderTitle = styled.div`
  flex: 1;
  margin: 0 7px;
  font-size: 15px;
  font-weight: 600;
`;

export function PanelHeader({ title, iconLeft = null, iconRight = null }) {
  return (
    <PanelHeaderWrapper>
      {iconLeft}
      <PanelHeaderTitle>{title}</PanelHeaderTitle>
      {iconRight}
    </PanelHeaderWrapper>
  );
}

const PanelSectionHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;

  & + * {
    margin-top: 16px;
  }
`;

const PanelSectionHeaderTitle = styled(Header)`
  flex: 1;
`;

export function PanelSectionHeader({ title, icon, ...props }) {
  return (
    <PanelSectionHeaderWrapper {...props}>
      <PanelSectionHeaderTitle>{title}</PanelSectionHeaderTitle>
      {icon}
    </PanelSectionHeaderWrapper>
  );
}

export const PanelSectionWrapper = styled.div`
  padding: 16px 0;
  border-bottom: ${({ borderless }) => (borderless ? 0 : 1)}px solid
    ${(props) => props.theme.colors.lighterBackground};
`;

export const PanelSectionContainer = styled.div`
  padding: 0 16px;
`;

export function PanelSection({ title, icon, children, ...props }) {
  return (
    <PanelSectionWrapper {...props}>
      <PanelSectionHeader title={title} icon={icon} />
      {children}
    </PanelSectionWrapper>
  );
}

export const PanelIcon = styled(Icon)`
  font-size: 20px;
  cursor: pointer;
  color: ${({ muted, theme, active }) =>
    active
      ? theme.colors.primary
      : muted
      ? theme.colors.muted
      : theme.colors.text};
`;

export const PanelListItemIcon = styled(PanelIcon)`
  color: ${({ muted, theme }) => (muted ? theme.colors.muted : "inherit")};
  font-size: 18px;

  :not(:last-child) {
    margin-right: 8px;
  }
`;

export const PanelListItemWrapper = styled.div`
  padding: 8px 16px;
  cursor: pointer;

  display: flex;
  align-items: center;

  ${(props) =>
    props.muted &&
    css`
      color: ${(props) => props.theme.colors.muted};
    `}

  ${(props) =>
    props.active &&
    css`
      box-shadow: inset 4px 0px 0px ${(props) => props.theme.colors.primary};
      font-weight: 600;
      background-color: ${(props) => props.theme.colors.lighterBackground};
    `}

  :hover {
    background-color: ${(props) => props.theme.colors.lighterBackground};
  }
`;

export const PanelTextSection = styled.div`
  padding: 8px 16px;
`;

export const PanelListItemLabel = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  flex: 1;
  ${(props) =>
    props.muted &&
    css`
      color: ${(props) => props.theme.colors.muted};
    `}
  ${(props) =>
    props.bold &&
    css`
      font-weight: 600;
    `}
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  :first-child {
    margin-right: 8px;
  }

  :last-child {
    margin-left: 8px;
  }
`;

const StyledPanelButton = styled.button`
  color: ${(props) => props.theme.colors.text};
  position: relative;
  padding: 9px 16px;
  font-weight: 600;
  background: ${(props) =>
    props.disabled ? props.theme.colors.muted : props.theme.colors.primary};
  border-radius: 4px;
  cursor: ${(props) => (props.disabled ? "inherit" : "pointer")};
  border: none;
  outline: none;
`;

const Loading = styled(Spinner).attrs(({ theme }) => ({
  size: "12",
  color: theme.colors.text,
}))`
  margin-left: 10px;
`;

export function PanelButton({ loading, loadingText, children, ...props }) {
  return (
    <StyledPanelButton disabled={loading} {...props}>
      {loading && loadingText ? loadingText : children}
      {loading && <Loading />}
    </StyledPanelButton>
  );
}

export function PanelListItem({
  label,
  muted,
  active,
  leftIcon,
  rightIcon,
  ...props
}) {
  const [hoverProps, isHovered] = useHover();
  // We don't want clicks on action button triggering an item
  // click, so stop propagation for clicks on icons.
  const stopPropagation = (e) => e.stopPropagation();

  return (
    <PanelListItemWrapper
      active={active}
      muted={muted}
      {...hoverProps}
      {...props}
    >
      {leftIcon && (
        <IconsWrapper onClick={stopPropagation}>
          {typeof leftIcon === "function" ? leftIcon(isHovered) : leftIcon}
        </IconsWrapper>
      )}
      <PanelListItemLabel>{label}</PanelListItemLabel>
      {rightIcon && (
        <IconsWrapper onClick={stopPropagation}>
          {typeof rightIcon === "function" ? rightIcon(isHovered) : rightIcon}
        </IconsWrapper>
      )}
    </PanelListItemWrapper>
  );
}
