import { useState } from "react";

export default function useMapPopup() {
  const [value, setValue] = useState(null);
  const open = (feature, lngLat = null) => {
    setValue({
      coordinates: lngLat,
      feature,
    });
  };
  const close = () => setValue(null);
  return {
    value,
    open,
    close,
    popupProps: value && {
      coordinates: value.coordinates,
      onClose: close,
      feature: value.feature,
    },
  };
}
