import React, { useContext } from "react";
import styled from "styled-components/macro";
import { Title } from "components/MapPopup";
import { Popup as MPopup } from "react-mapbox-gl";
import { Wrapper } from "components/MapPopup";
import useOnClickOutside from "hooks/useOnClickOutside";
import { MaxPopupHeightContext } from "../routes/map/components/maps/Base/Map";
import { useOnEscapeKeyPress } from "../routes/map/components/maps/Base/MapboxPopup";
import Icon from "./components/Icon";
import { parseFeatureProperties } from "./panels/VectorMetadataPanel";

const PTitle = styled(Title)`
  text-transform: capitalize;
  font-weight: 600;
  flex: 1;
  font-size: 15px;
  padding-bottom: 8px;
`;

const TextLine = styled.div`
  color: ${({ theme }) => theme.colors.muted};
  overflow-x: hidden;
`;

const IconWrapper = styled.div`
  margin-right: 8px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Popup = styled(MPopup)`
  > .mapboxgl-popup-content {
    background-color: ${({ theme }) => theme.colors.background};
    padding: 16px;
  }
  > .mapboxgl-popup-tip {
    display: none;
  }
`;

const ModalTextSection = styled.div``;

const HeaderContent = ({ label, icon }) => {
  return (
    <HeaderWrapper>
      <IconWrapper>{icon}</IconWrapper>
      <PTitle>{label.toLowerCase()}</PTitle>
    </HeaderWrapper>
  );
};

function VectorMetadataPopup({ coordinates, onClose, feature }) {
  const maxPopupHeight = useContext(MaxPopupHeightContext);
  useOnEscapeKeyPress(onClose);

  function clse() {
    onClose();
  }
  const ref = useOnClickOutside(clse);
  const properties = parseFeatureProperties({ feature });
  return (
    <Popup
      coordinates={coordinates}
      style={{
        padding: "16px",
        maxWidth: "400px",
        minWidth: "300px",
        borderRadius: 3,
        boxShadow: "none",
        zIndex: 4
      }}
    >
      <Wrapper ref={ref} maxHeight={maxPopupHeight}>
        <HeaderContent
          label={properties.name || "metadata"}
          icon={
            <Icon size={20} color={feature.layer.paint["circle-color"]}>
              room
            </Icon>
          }
        />
        <ModalTextSection>
          {properties.address ? (
            <>
              <TextLine>{properties.address.address}</TextLine>
              <TextLine>
                {`${properties.address.city}, ${properties.address.county} ${properties.address.zip}`}
              </TextLine>
            </>
          ) : (
            <TextLine>No address available</TextLine>
          )}
        </ModalTextSection>
      </Wrapper>
    </Popup>
  );
}

export default VectorMetadataPopup;
