import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

const CREATE_TASK = gql`
  mutation createTask($input: CreateTaskInput!) {
    createTask(input: $input) {
      success
      task {
        id
      }
    }
  }
`;

const UPDATE_TASK = gql`
  mutation updateTask($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      success
      task {
        id
        title
        description
        taskName
        icon
        color
        status
        dueDate
        radius
        geometry
      }
    }
  }
`;

const UPDATE_TASK_GEOMETRY = gql`
  mutation updateTaskGeometry($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      success
      task {
        id
        radius
        geometry
      }
    }
  }
`;

const DELETE_TASK = gql`
  mutation deleteTask($id: ID!) {
    deleteTask(id: $id) {
      success
    }
  }
`;

const ADD_ATTACHMENT = gql`
  mutation addTaskAttachment($input: AddTaskAttachmentInput!) {
    addTaskAttachment(input: $input) {
      success
    }
  }
`;

const REMOVE_ATTACHMENT = gql`
  mutation removeTaskAttachment($input: RemoveTaskAttachmentInput!) {
    removeTaskAttachment(input: $input) {
      success
    }
  }
`;

export function useTasksMutations() {
  const [createTaskMutation] = useMutation(CREATE_TASK, {
    refetchQueries: ["getTasks", "getScenarioActivity"],
  });
  const [updateTaskMutation] = useMutation(UPDATE_TASK, {
    refetchQueries: ["getTasks", "getScenarioActivity"],
  });
  const [updateTaskGeometryMutation] = useMutation(UPDATE_TASK_GEOMETRY);
  const [deleteTaskMutation] = useMutation(DELETE_TASK, {
    refetchQueries: ["getTasks", "getScenarioActivity"],
  });
  const [addAttachment] = useMutation(ADD_ATTACHMENT, {
    refetchQueries: ["getTask", "getScenarioActivity"],
  });
  const [removeAttachment] = useMutation(REMOVE_ATTACHMENT, {
    refetchQueries: ["getTask", "getScenarioActivity"],
  });

  return {
    createTask(data) {
      return createTaskMutation({ variables: { input: data } });
    },
    updateTask(id, data) {
      return updateTaskMutation({
        variables: { input: { id, ...data } },
      });
    },
    updateTaskGeometry(id, geometry, radius) {
      return updateTaskGeometryMutation({
        variables: { input: { id, geometry, radius } },
        optimisticResponse: {
          __typename: "Mutation",
          updateTask: {
            __typename: "TaskMutationPayload",
            success: true,
            task: {
              __typename: "Task",
              id,
              geometry,
              radius,
            },
          },
        },
      });
    },
    deleteTask(id) {
      return deleteTaskMutation({ variables: { id } });
    },
    addAttachment(id, file) {
      return addAttachment({ variables: { input: { id: id, file } } });
    },
    removeAttachment(id) {
      return removeAttachment({ variables: { input: { id: id } } });
    },
  };
}
