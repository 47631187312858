import React from "react";
import { ThemeProvider as SCThemeProvider } from "styled-components";

export const theme = {
  colors: {
    darkerBackground: "#111518",
    background: "#1C1F22",
    lighterBackground: "#2B2F34",
    text: "#FFFFFF",
    muted: "#969898",
    primary: "#FF5722",
    border: "#5b5d60",
    success: "#4CAF50",
    warning: "#FFEE58",
    error: "#F44336",
    info: "#969898",
  },
};

export function ThemeProvider({ children }) {
  return <SCThemeProvider theme={theme}>{children}</SCThemeProvider>;
}
