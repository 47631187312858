import React from "react";
import RDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import moment from "moment";
import { PanelIcon } from "../panels/layout";
import { TextInput } from "./Input";

const DatePickerWrapper = styled.div`
  > div:first-child {
    width: 100%;
  }
  > div > div:first-child {
    width: 100%;
  }
`;

function DatePicker({ value, ...props }) {
  return (
    <DatePickerWrapper>
      <RDatePicker
        customInput={
          <TextInput
            value={value}
            rightIcon={<PanelIcon muted>event</PanelIcon>}
          />
        }
        selected={value ? moment(value) : null}
        {...props}
      />
    </DatePickerWrapper>
  );
}

export default DatePicker;
