import React, { useState, useMemo } from "react";

export const SearchResultContext = React.createContext({
  searchResultLocation: null,
});

export function SearchResultProvider({ children }) {
  const [searchResultLocation, setRearchResultLocation] = useState(null);
  const value = useMemo(
    () => ({
      searchResultLocation,
      setRearchResultLocation,
    }),
    [searchResultLocation]
  );
  return (
    <SearchResultContext.Provider value={value}>
      {children}
    </SearchResultContext.Provider>
  );
}
