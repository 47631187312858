import React, { useMemo } from "react";
import pathToRegexp from "path-to-regexp";

export const replaceParams = (match, params, location) => {
  const prevPath = location.pathname;

  const toPath = pathToRegexp.compile(match.path);
  const partialNextPath = toPath(
    { ...match.params, ...params },
    {
      encode: (value, token) => value,
    }
  );

  return prevPath.replace(match.url, partialNextPath.slice(0, -1));
};

export const createURLStateManager = () => {
  const Context = React.createContext({});

  const RouteParamsProvider = ({ base, route, children }) => {
    const value = useMemo(
      () => ({
        ...route.match.params,
        baseUrl: base,
        setParams: (params) => {
          route.history.push(
            replaceParams(route.match, params, route.location)
          );
        },
      }),
      [base, route.history, route.location, route.match]
    );
    return <Context.Provider value={value}>{children}</Context.Provider>;
  };

  return {
    RouteParamsProvider,
    Context,
  };
};

export const consume = (Context, name) => (WrappedComponent) => (props) => (
  <Context.Consumer>
    {(params) => <WrappedComponent {...props} {...{ [name]: params }} />}
  </Context.Consumer>
);
