import EditableValue from "components/EditableValue";
import React from "react";
import styled, { css } from "styled-components/macro";

const DescriptionWrapper = styled.div`
  padding: 0.5em;
  margin-top: 1em;
  color: ${(props) => props.theme.colors.mutedTextColor};
  border-radius: 3px;

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        background-color: #eee;
      }
    `};
`;

const DescriptionValue = ({ children, disabled, ...rest }) => (
  <DescriptionWrapper
    data-test="description-wrapper"
    disabled={disabled}
    {...rest}
    isPlaceholder={Boolean(children)}
  >
    {children
      ? children.split("\n").map((line, i) => <p key={i}>{line}</p>)
      : disabled
      ? "No description"
      : "Type a description"}
  </DescriptionWrapper>
);

const DescriptionInput = styled.textarea`
  height: 200px;
  margin-top: 1em;
  color: ${(props) => props.theme.colors.mutedTextColor};
  border: none;
  width: 100%;
  padding: 0.5em;

  &:focus {
    outline: none;
  }
`;

const Description = ({ value, onChange, disabled }) => (
  <EditableValue
    disabled={disabled}
    value={value}
    onChange={onChange}
    renderValue={(props) => <DescriptionValue disabled={disabled} {...props} />}
    renderInput={(props) => (
      <DescriptionInput data-test="task-description" {...props} />
    )}
  />
);

export default Description;
