import { useEffect, useRef } from "react";

export default function useDebouncedCallback(callback, ms) {
  const timeoutRef = useRef();
  const callbackRef = useRef();
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);
  function debouncedCallback(...args) {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      callbackRef.current(...args);
    }, ms);
  }
  function cancelTimeout() {
    clearTimeout(timeoutRef.current);
  }
  return [debouncedCallback, cancelTimeout];
}
