import React, { useState, useMemo, useContext } from "react";
import { Context as MapStateContext } from "../../../state";

export const MouseCoordsContext = React.createContext({
  coords: null,
});

export function MouseCoordsProvider({ children }) {
  const mapState = useContext(MapStateContext);

  const view = mapState.view.split(",");
  const [coords, setCoords] = useState({ lat: view[1], lng: view[0] });
  const value = useMemo(
    () => ({
      coords,
      setCoords,
    }),
    [coords]
  );
  return (
    <MouseCoordsContext.Provider value={value}>
      {children}
    </MouseCoordsContext.Provider>
  );
}
