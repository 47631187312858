import styled from "styled-components/macro";

export const Title = styled.div`
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  align-self: end;

  font-size: 1.1em;
`;

export const Subtitle = styled.div`
  grid-column: 1 / 2;
  grid-row: 2 / 3;

  font-size: 0.85em;
  color: #777;
`;

export const OptionWrapper = styled.div`
  padding: 0.5em 1em;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  &:hover {
    background-color: #ddd;
  }
  background-color: ${(props) => (props.disabled ? "#ddd" : "inherit")};
`;
