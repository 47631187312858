import React from "react";
import parseWkt from "wkt-parser";
import {
  Wrapper,
  Section,
  KeyValue,
  Header,
  Title as PTitle,
} from "components/MapPopup";
import MapboxPopup from "./MapboxPopup";
import styled from "styled-components/macro";
import proj4 from "proj4";

const Title = styled(PTitle)`
  padding: 0 1em;
  word-break: break-all;
`;

const getRangeFromDataType = (dataType) =>
  ({
    Byte: "0-255",
    UInt16: "0-65535",
  }[dataType]);

const getResolution = (gdalinfo) => {
  const fromProj = gdalinfo.coordinateSystem.wkt.replace("\n", "");
  const toProj = "EPSG:3857";

  let originalResolution = [gdalinfo.geoTransform[1], gdalinfo.geoTransform[5]];
  let { size } = gdalinfo;
  let originalTopLeft = [gdalinfo.geoTransform[0], gdalinfo.geoTransform[3]];
  let originalBottomRight = [
    gdalinfo.geoTransform[0] + originalResolution[0] * size[0],
    gdalinfo.geoTransform[3] + originalResolution[1] * size[1],
  ];
  let topLeft = proj4(fromProj, toProj, originalTopLeft);
  let bottomRight = proj4(fromProj, toProj, originalBottomRight);
  let resolution = [
    Math.abs(bottomRight[0] - topLeft[0]) / size[0],
    Math.abs(bottomRight[1] - topLeft[1]) / size[1],
  ];
  return resolution;
};

const ContentWrapper = styled.div`
  max-height: 300px;
  overflow-y: auto;
`;

const ErrorWrapper = styled.div`
  height: 100px;
  padding: 2em;
  font-size: 1.1em;
`;

const Content = ({ data }) => {
  const { s3_key, s3_bucket } = data;
  const gdalinfo = JSON.parse(data.metadata).gdalinfo;
  if (!gdalinfo) {
    return (
      <Wrapper>
        <ErrorWrapper>
          Sorry, we don't have metadata for this image.
        </ErrorWrapper>
      </Wrapper>
    );
  }
  const filename = s3_key.split("/").slice(-1)[0];
  const resolution = getResolution(gdalinfo);
  return (
    <Wrapper>
      <Header>
        <Title
          as="a"
          href={`https://s3.console.aws.amazon.com/s3/object/${s3_bucket}/${s3_key}`}
          target="_blank"
        >
          {filename}
        </Title>
      </Header>
      <ContentWrapper>
        <Section title="Image metadata">
          <KeyValue label="Width" value={gdalinfo.size[0]} />
          <KeyValue label="Height" value={gdalinfo.size[1]} />
          <KeyValue label="Resolution X" value={resolution[0].toFixed(4)} />
          <KeyValue label="Resolution Y" value={resolution[1].toFixed(4)} />
          <KeyValue
            label="Projection"
            value={parseWkt(gdalinfo.coordinateSystem.wkt).name}
          />
        </Section>
        <Section title="Image structure">
          {Object.entries(gdalinfo.metadata.IMAGE_STRUCTURE).map(([k, v]) => (
            <KeyValue label={k} value={v} />
          ))}
          <KeyValue
            label="Data type"
            value={`${gdalinfo.bands[0].type} (${getRangeFromDataType(
              gdalinfo.bands[0].type
            )})`}
          />
          <KeyValue
            label="Block size"
            value={`${gdalinfo.bands[0].block[0]} × ${gdalinfo.bands[0].block[1]}`}
          />
        </Section>
        <Section title={`Bands (${gdalinfo.bands.length})`}>
          {gdalinfo.bands.map((band) => (
            <KeyValue
              label={band.colorInterpretation}
              value={`${band.computedMin}-${band.computedMax}`}
            />
          ))}
        </Section>
      </ContentWrapper>
    </Wrapper>
  );
};

const Popup = ({ coordinates, onClose, feature }) => (
  <MapboxPopup {...{ coordinates, onClose, feature }}>
    <Content data={feature.properties} />
  </MapboxPopup>
);

export default Popup;
