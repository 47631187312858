import React, { useContext } from "react";
import { Context as AppStateContext } from "appState";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { useAuth } from "hooks/data/auth";

export const isSlingshot = (c) => c.isSlingshotMember;

export const isAdmin = (c) => c.user.isStaff;

const permissionLevels = {
  ro: 1,
  rw: 2,
  ad: 3,
  ow: 4,
};

export const hasWorkspacePermission = (workspace, type) => () => {
  const permissionType = workspace.myPermission;
  return permissionLevels[permissionType] >= permissionLevels[type];
};

const GET_CURRENT_WORKSPACE_PERMISSION = gql`
  query getCurrentWorkspacePermission($scenarioId: ID!) {
    workspace(scenarioId: $scenarioId) {
      id
      myPermission
    }
  }
`;

function useCurrentWorkspace() {
  const { scenario } = useContext(AppStateContext);
  const { data } = useQuery(GET_CURRENT_WORKSPACE_PERMISSION, {
    variables: { scenarioId: scenario },
  });

  if (!data) {
    return null;
  }

  return data.workspace;
}

export const RestrictedByWorkspacePermission = ({ children, type }) => {
  const workspace = useCurrentWorkspace();

  return workspace ? (
    <Restricted to={hasWorkspacePermission(workspace, type)}>
      {children}
    </Restricted>
  ) : null;
};

export const HasWorkspacePermission = ({ children, type }) => {
  const workspace = useCurrentWorkspace();

  return workspace ? children(hasWorkspacePermission(workspace, type)) : null;
};

const GET_CUSTOMER = gql`
  query getMe {
    me {
      id
      isSlingshotMember
      user {
        id
        isStaff
      }
    }
  }
`;

const Restricted = ({ to, children }) => {
  const { loggedIn } = useAuth();
  const { data } = useQuery(GET_CUSTOMER, { skip: !loggedIn });

  if (!data) {
    return null;
  }

  if (loggedIn && to(data.me)) {
    return React.Children.count(children) > 1 ? <>{children}</> : children;
  } else {
    return null;
  }
};

export const layerPermissionType = (layer) => {
  // CAUTION! It only works when the customer is the user of the session (me).
  // TODO: to get the permission type between a layer and a different
  // customer.

  return layer.myPermission;
};

export default Restricted;
