import React from "react";
import { Layer } from "react-mapbox-gl";

export default function Markerlayer({
  size = 28,
  color = "black",
  iconCode = String.fromCharCode("0xf3c5"),
  ...props
}) {
  return (
    <Layer
      type="symbol"
      layout={{
        "text-line-height": 1, // this is to avoid any padding around the "icon"
        "text-padding": 0,
        "text-anchor": "center", // center, so when rotating the map, the "icon" stay on the same location
        "text-offset": [0, -0.3], // give it a little offset on y, so when zooming it stay on the right place
        "text-allow-overlap": true,
        "text-ignore-placement": true,
        "text-field": iconCode,
        "icon-optional": true, // since we're not using an icon, only text.
        "text-font": ["Font Awesome 5 Free Solid"],
        "text-size": size,
      }}
      paint={{
        "text-translate-anchor": "viewport",
        "text-color": color,
      }}
      {...props}
    />
  );
}
