import { ActionIcon, ListItem, ListItemLabel } from "components/SidePanel";

import { EditableItem } from "components/SidePanel";
import React from "react";
import styled from "styled-components/macro";
import Restricted, { hasWorkspacePermission } from "utils/Restricted";

export const ScenarioItemWrapper = styled(ListItem)`
  &:not(:last-child) {
    border-bottom: none;
  }
  margin-left: 2em;

  border-radius: 2px;
`;

const ScenarioActionIcon = ({ selected, ...props }) => (
  <ActionIcon color={selected ? "white" : "undefined"} {...props} />
);

const ScenarioItemLabel = styled(ListItemLabel)`
  cursor: pointer;
`;

const ScenarioItem = ({
  selected,
  onSelect,
  scenario,
  disableDeletion,
  onDelete,
  onRename,
  workspace,
}) => (
  <EditableItem
    inputProps={{ placeholder: "Type a new name" }}
    onSubmit={onRename}
    renderWrapper={({ children }) => (
      <ScenarioItemWrapper>{children}</ScenarioItemWrapper>
    )}
    renderNotEditing={({ onClick }) => (
      <ScenarioItemWrapper selected={selected}>
        <ScenarioActionIcon>map</ScenarioActionIcon>
        <ScenarioItemLabel onClick={onSelect}>
          {scenario.name}
        </ScenarioItemLabel>
        <Restricted to={hasWorkspacePermission(workspace, "ad")}>
          <ScenarioActionIcon onClick={onClick}>create</ScenarioActionIcon>
          {!disableDeletion && (
            <ScenarioActionIcon onClick={onDelete}>delete</ScenarioActionIcon>
          )}
        </Restricted>
      </ScenarioItemWrapper>
    )}
  />
);

export default ScenarioItem;
