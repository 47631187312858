import React from "react";
import styled from "styled-components/macro";

export const SectionsContainer = styled.div``;

const SectionWrapper = styled.div`
  padding: 1em;
  padding-left: 6em;
  border-bottom: 1px solid #eee;
  margin-bottom: 1em;
`;

const SectionTitle = styled.h3``;

const SectionContent = styled.div``;

export const Form = styled.form`
  width: 500px;
`;

export const Section = ({ title, children, ...rest }) => (
  <SectionWrapper {...rest}>
    <SectionTitle>{title}</SectionTitle>
    <SectionContent>{children}</SectionContent>
  </SectionWrapper>
);
