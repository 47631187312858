import React from "react";
import RDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import styled from "styled-components";
import { TextInput } from "./Input";

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  > span {
    color: ${({ theme }) => theme.colors.muted};
  }

  > *:not(:last-child) {
    margin-right: 16px;
  }
`;

const DateInput = styled(TextInput)`
  flex: 1;
`;

function DatePicker({ value, setValue = () => {}, ...props }) {
  return (
    <RDatePicker
      customInput={<DateInput value={value} />}
      selected={value ? moment(value) : null}
      dateFormat="MMMM DD, YYYY"
      onChange={(value) => setValue(value)}
      {...props}
    />
  );
}

export default function DateRangePicker({
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
}) {
  return (
    <Wrapper>
      <DatePicker value={dateFrom} setValue={setDateFrom} />
      <span> - </span>
      <DatePicker value={dateTo} setValue={setDateTo} />
    </Wrapper>
  );
}
