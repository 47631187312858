import { ListItem, ListItemLabel } from "components/SidePanel";
import React, { useState } from "react";
import Restricted, { hasWorkspacePermission } from "utils/Restricted";

import ChangingOwnerModal from "./ChangingOwnerModal";
import { EditableItem } from "components/SidePanel";
import Icon from "components/Icon";
import ProfilePic from "components/ProfilePic";
import SharingSettingsModal from "./SharingSettingsModal";
import styled from "styled-components/macro";
import { sendEvent } from "utils/ga";

const WorkspaceTitle = styled.div`
  display: flex;
`;

export const WorkspaceItemWrapper = styled(ListItem)`
  &:not(:last-child) {
    border-bottom: none;
  }
`;

export const ColumnWorkspaceItemWrapper = styled(WorkspaceItemWrapper)`
  align-items: stretch;
  user-select: none;
  display: flex;
`;

const WorkspaceItemLabel = styled(ListItemLabel)`
  cursor: pointer;
  margin-left: 0.3em;
`;

const ActionIcon = styled(Icon).attrs({ size: 18 })`
  margin: 0 0.15em;
  cursor: pointer;
`;

const ListIcon = styled(Icon).attrs({ size: 18 })`
  margin: 0 0.15em;
  cursor: default;
`;

const Fill = styled.div`
  flex: 1;
`;

const OwnerPic = styled(ProfilePic)`
  margin: 0;
  border: none;
`;

const getOwnerName = (owner) =>
  owner.__typename === "Company"
    ? owner.slug
    : `${owner.user.firstName} ${owner.user.lastName}`;

function WorkspaceItem({
  workspace,
  selected,
  onSelect,
  onRename,
  onDelete,
  lastWorkspace,
  sharedWithMe = false,
  updateWorkspace,
  selectNextScenario,
}) {
  const [sharingModal, setSharingModal] = useState(false);
  const [chOwnModal, setChOwnModal] = useState(false);

  return (
    <EditableItem
      inputProps={{ placeholder: "Type a new name" }}
      onSubmit={onRename}
      renderWrapper={({ children }) => (
        <WorkspaceItemWrapper>{children}</WorkspaceItemWrapper>
      )}
      renderNotEditing={({ onClick }) => (
        <ColumnWorkspaceItemWrapper>
          <WorkspaceTitle>
            {sharedWithMe ? (
              <OwnerPic
                size={20}
                title={`Owner: ${getOwnerName(workspace.owner)}`}
                src={workspace.owner.picture}
              />
            ) : (
              <ActionIcon>{selected ? "folder" : "folder_open"}</ActionIcon>
            )}
            <WorkspaceItemLabel onClick={onSelect}>
              {workspace.name}
            </WorkspaceItemLabel>
          </WorkspaceTitle>
          <Fill />
          {!lastWorkspace && (
            <Restricted to={hasWorkspacePermission(workspace, "ow")}>
              <ActionIcon
                title="Change ownership"
                onClick={() => {
                  sendEvent("workspace", "showChangeOwnModal");
                  setChOwnModal(true);
                }}
              >
                people
              </ActionIcon>
            </Restricted>
          )}
          <Restricted to={hasWorkspacePermission(workspace, "ad")}>
            <ActionIcon title="Edit" onClick={onClick}>
              create
            </ActionIcon>
          </Restricted>
          {!lastWorkspace && (
            <Restricted to={hasWorkspacePermission(workspace, "ow")}>
              <ActionIcon title="Delete" onClick={onDelete}>
                delete
              </ActionIcon>
            </Restricted>
          )}
          <Restricted to={hasWorkspacePermission(workspace, "ad")}>
            <ActionIcon
              title="Share with"
              onClick={() => {
                setSharingModal(true);
                sendEvent("workspace", "showShareWithModal");
              }}
            >
              person_add
            </ActionIcon>
          </Restricted>
          {sharedWithMe && workspace.myPermission === "ro" && (
            <ListIcon title="Read only">lock</ListIcon>
          )}
          {sharingModal && (
            <SharingSettingsModal
              onClose={() => setSharingModal(false)}
              workspaceId={workspace.id}
              updateWorkspace={updateWorkspace}
              companyWorkspace={
                workspace.owner.__typename === "Company"
                  ? workspace.company.id
                  : null
              }
            />
          )}
          {chOwnModal && (
            <ChangingOwnerModal
              isOpen={chOwnModal}
              onClose={() => setChOwnModal(false)}
              workspaceId={workspace.id}
              selectNextScenario={() => selectNextScenario(workspace)}
            />
          )}
        </ColumnWorkspaceItemWrapper>
      )}
    />
  );
}

export default WorkspaceItem;
