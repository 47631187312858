import { useContext } from "react";
import { Context as AppStateContext } from "appState";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { arrayMove } from "react-sortable-hoc";
import produce from "immer";
import { isNil } from "lodash-es";

const SET_LAYER_SELECTION_VISIBILITY = gql`
  mutation setLayerSelectionVisibility(
    $input: SetLayerSelectionVisibilityInput!
  ) {
    setLayerSelectionVisibility(input: $input) {
      layerSelection {
        id
        on
      }
    }
  }
`;

const SET_LAYER_SELECTION_CACHE = gql`
  mutation setLayerSelectionCache($input: SetLayerSelectionCacheInput!) {
    setLayerSelectionCache(input: $input) {
      layerSelection {
        id
        metadata
      }
    }
  }
`;

const SET_LAYER_SELECTION_SEE_EXTENTS = gql`
  mutation setLayerSelectionSeeExtents(
    $input: SetLayerSelectionSeeExtentsInput!
  ) {
    setLayerSelectionSeeExtents(input: $input) {
      layerSelection {
        id
        metadata
      }
    }
  }
`;

const SET_LAYER_SELECTION_OPACITY = gql`
  mutation setLayerSelectionOpacity($input: SetLayerSelectionOpacityInput!) {
    setLayerSelectionOpacity(input: $input) {
      layerSelection {
        id
        metadata
      }
    }
  }
`;

const DELETE_LAYER_SELECTION = gql`
  mutation deleteLayerSelection($input: DeleteLayerSelectionInput!) {
    deleteLayerSelection(input: $input) {
      success
    }
  }
`;

const REORDER_LAYER_SELECTIONS = gql`
  mutation reorderLayerSelections($input: [ReorderLayerSelectionInput!]!) {
    reorderLayerSelections(input: $input) {
      layerSelections {
        id
        metadata
      }
    }
  }
`;

const ENABLE_DYNAMIC_STYLING = gql`
  mutation enableDynamicStyling($input: EnableDynamicStylingInput!) {
    enableDynamicStyling(input: $input) {
      layerSelection {
        id
        metadata
      }
    }
  }
`;

const SET_DYNAMIC_STYLING_PROPERTY = gql`
  mutation setDynamicStylingProperty($input: SetDynamicStylingPropertyInput!) {
    setDynamicStylingProperty(input: $input) {
      layerSelection {
        id
        metadata
      }
    }
  }
`;

const SET_DYNAMIC_STYLING_THEME = gql`
  mutation setDynamicStylingTheme($input: SetDynamicStylingThemeInput!) {
    setDynamicStylingTheme(input: $input) {
      layerSelection {
        id
        metadata
      }
    }
  }
`;

const SET_STATIC_STYLING_BASE = gql`
  mutation setStaticStylingBase($input: SetStaticStylingBaseInput!) {
    setStaticStylingBase(input: $input) {
      layerSelection {
        id
        metadata
      }
    }
  }
`;

export function useLayerSelectionMutations() {
  const [setLayerSelectionVisibilityMutation] = useMutation(
    SET_LAYER_SELECTION_VISIBILITY
  );
  const [setLayerSelectionCacheMutation] = useMutation(
    SET_LAYER_SELECTION_CACHE
  );
  const [setLayerSelectionSeeExtentsMutation] = useMutation(
    SET_LAYER_SELECTION_SEE_EXTENTS
  );
  const [setLayerSelectionOpacityMutation] = useMutation(
    SET_LAYER_SELECTION_OPACITY
  );
  const [deleteLayerSelectionMutation] = useMutation(DELETE_LAYER_SELECTION);
  const [reorderLayerSelectionsMutation] = useMutation(
    REORDER_LAYER_SELECTIONS
  );
  const [enableDynamicStylingMutation] = useMutation(ENABLE_DYNAMIC_STYLING);
  const [setDynamicStylingPropertyMutation] = useMutation(
    SET_DYNAMIC_STYLING_PROPERTY
  );
  const [setDynamicStylingThemeMutation] = useMutation(
    SET_DYNAMIC_STYLING_THEME
  );
  const [setStaticStylingBaseMutation] = useMutation(SET_STATIC_STYLING_BASE);
  const { scenario } = useContext(AppStateContext);

  return {
    setLayerSelectionVisibility: ({ id, on }) =>
      setLayerSelectionVisibilityMutation({
        variables: { input: { id, on } },
        optimisticResponse: {
          setLayerSelectionVisibility: {
            layerSelection: {
              id,
              on,
              __typename: "LayerSelection",
            },
            __typename: "LayerSelectionMutationPayload",
          },
        },
      }),
    setLayerSelectionCache: ({ id, metadata, disableCache }) => {
      const newMetadata = produce(metadata, (draft) => {
        draft.disableCache = disableCache;
      });
      setLayerSelectionCacheMutation({
        variables: { input: { id, disableCache } },
        optimisticResponse: {
          setLayerSelectionCache: {
            layerSelection: {
              id,
              metadata: newMetadata,
              __typename: "LayerSelection",
            },
            __typename: "LayerSelectionMutationPayload",
          },
        },
      });
    },
    setLayerSelectionSeeExtents: ({ id, metadata, seeExtents }) => {
      const newMetadata = produce(metadata, (draft) => {
        draft.seeExtents = seeExtents;
      });
      setLayerSelectionSeeExtentsMutation({
        variables: { input: { id, seeExtents } },
        optimisticResponse: {
          setLayerSelectionSeeExtents: {
            layerSelection: {
              id,
              metadata: newMetadata,
              __typename: "LayerSelection",
            },
            __typename: "LayerSelectionMutationPayload",
          },
        },
      });
    },
    setLayerSelectionOpacity: ({ id, metadata, opacity }) => {
      const newMetadata = produce(metadata, (draft) => {
        draft.opacity = opacity;
      });
      setLayerSelectionOpacityMutation({
        variables: { input: { id, opacity } },
        optimisticResponse: {
          setLayerSelectionOpacity: {
            layerSelection: {
              id,
              metadata: newMetadata,
              __typename: "LayerSelection",
            },
            __typename: "LayerSelectionMutationPayload",
          },
        },
      });
    },
    deleteLayerSelection: ({ id }) => {
      deleteLayerSelectionMutation({
        variables: { input: { id } },
        optimisticResponse: {},
        update: (store) => {
          const updateQuery = gql`
            query getLayerSelections($scenarioId: ID!) {
              scenario(id: $scenarioId) {
                id
                layerSelections {
                  id
                  __typename
                }
                __typename
              }
            }
          `;
          const data = store.readQuery({
            query: updateQuery,
            variables: { scenarioId: scenario },
          });
          const newData = produce(data, (draft) => {
            const index = draft.scenario.layerSelections.findIndex(
              (ls) => ls.id === id
            );
            draft.scenario.layerSelections.splice(index, 1);
          });
          store.writeQuery({ query: updateQuery, data: newData });
        },
      });
    },
    reorderLayerSelections: (oldIndex, newIndex, layerSelections) => {
      // Let's convert the object to array so we can
      // use the index parameters
      const layerSelectionsAsArray = layerSelections
        .slice()
        .sort((a, b) => (a.metadata.zIndex > b.metadata.zIndex ? -1 : 1));

      // Move the layer and assign the new zIndexes
      const reorderedLayerSelections = arrayMove(
        layerSelectionsAsArray,
        oldIndex,
        newIndex
      ).map((layerSelection, index) => ({
        ...layerSelection,
        __typename: "LayerSelection",
        layer: {
          ...layerSelection.layer,
          __typename: "Layer",
        },
        metadata: {
          ...layerSelection.metadata,
          zIndex: layerSelectionsAsArray.length - index + 1,
        },
      }));

      reorderLayerSelectionsMutation({
        variables: {
          input: reorderedLayerSelections.map((ls) => ({
            id: ls.id,
            zIndex: ls.metadata.zIndex,
          })),
        },
        optimisticResponse: {
          reorderLayerSelections: {
            __typename: "ReorderLayerSelectionsMutationsPayload",
            layerSelections: reorderedLayerSelections,
          },
        },
      });
    },
    enableDynamicStyling: ({ ls, enable, firstProperty = null }) => {
      const enableMetadata = produce(ls.metadata, (draft) => {
        if (enable) {
          draft.styling.property = firstProperty.name;
          if (firstProperty.type === "number") {
            draft.styling.theme = "magma";
            draft.styling.min = firstProperty.min;
            draft.styling.max = firstProperty.max;
          }
        } else {
          delete draft.styling.property;
        }
      });
      enableDynamicStylingMutation({
        variables: {
          input: { id: ls.id, enable },
        },
        optimisticResponse: {
          enableDynamicStyling: {
            layerSelection: {
              id: ls.id,
              metadata: enableMetadata,
              __typename: "LayerSelection",
            },
            __typename: "LayerSelectionMutationPayload",
          },
        },
      });
    },
    setDynamicStylingProperty: ({
      ls,
      property = null,
      min = null,
      max = null,
    }) => {
      const newMetadata = produce(ls.metadata, (draft) => {
        if (property) {
          draft.styling.property = property.name;
          if (property.type === "number") {
            draft.styling.theme = "magma";
            draft.styling.min = property.min;
            draft.styling.max = property.max;
          }
        } else {
          draft.styling.min = min;
          draft.styling.max = max;
        }
      });
      setDynamicStylingPropertyMutation({
        variables: {
          input: {
            id: ls.id,
            property: property ? property.name : null,
            min,
            max,
          },
        },
        optimisticResponse: {
          setDynamicStylingProperty: {
            layerSelection: {
              id: ls.id,
              metadata: newMetadata,
              __typename: "LayerSelection",
            },
            __typename: "LayerSelectionMutationPayload",
          },
        },
      });
    },
    setDynamicStylingTheme: ({ ls, theme }) => {
      const newMetadataTheme = produce(ls.metadata, (draft) => {
        draft.styling.theme = theme;
      });
      setDynamicStylingThemeMutation({
        variables: {
          input: { id: ls.id, theme },
        },
        optimisticResponse: {
          setDynamicStylingTheme: {
            layerSelection: {
              id: ls.id,
              metadata: newMetadataTheme,
              __typename: "LayerSelection",
            },
            __typename: "LayerSelectionMutationPayload",
          },
        },
      });
    },
    setStaticStylingBase: ({ ls, property, value }) => {
      const newMetadata = produce(ls.metadata, (draft) => {
        if (isNil(value)) {
          delete draft.styling.base[property];
        } else {
          draft.styling.base[property] = value;
        }
      });
      setStaticStylingBaseMutation({
        variables: {
          input: { id: ls.id, property, value },
        },
        optimisticResponse: {
          setStaticStylingBase: {
            layerSelection: {
              id: ls.id,
              metadata: newMetadata,
              __typename: "LayerSelection",
            },
            __typename: "LayerSelectionMutationPayload",
          },
        },
      });
    },
  };
}
