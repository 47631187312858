import React, { useState, useMemo } from "react";

export const HoveredLayerContext = React.createContext(null);

export function HoveredLayerProvider({ children }) {
  const [extent, setExtent] = useState(null);

  const value = useMemo(() => ({ extent, setExtent }), [extent]);

  return (
    <HoveredLayerContext.Provider value={value}>
      {children}
    </HoveredLayerContext.Provider>
  );
}
