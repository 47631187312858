import React from "react";
import { PanelContainer, PanelContent } from "./layout";
import { useLayerSelections } from "hooks/useLayerSelections";
import { PanelSectionWrapper, PanelListItem } from "../panels/layout";
import { sendEvent } from "utils/ga";
import { get } from "lodash-es";
import { useViewport } from "../URLState";
import bbox from "@turf/bbox";
import RTCheckbox from "components/Checkbox";
import styled from "styled-components/macro";

const Checkbox = styled(RTCheckbox)`
  margin-bottom: 0 !important;
`;

export function Categories() {
  const {
    layerSelections,
    layerSelectionMutations: { setLayerSelectionVisibility },
  } = useLayerSelections();
  const { updateViewport } = useViewport();
  return (
    <PanelSectionWrapper>
      {layerSelections &&
        layerSelections
          .slice()
          .sort((a, b) => (a.metadata.zIndex > b.metadata.zIndex ? -1 : 1))
          .map((layerSelection, index) => {
            return (
              <PanelListItem
                key={index}
                label={layerSelection.layer.title}
                onClick={() => {
                  if (layerSelection.layer.extent) {
                    updateViewport(bbox(layerSelection.layer.extent));
                    sendEvent(
                      "layers",
                      "zoomToLayer",
                      layerSelection.layer.title
                    );
                  }
                }}
                leftIcon={
                  <Checkbox
                    checked={layerSelection.on}
                    color={
                      get(
                        layerSelection.metadata.styling.base,
                        "fillColor",
                        "color"
                      ) || "#3388ff"
                    }
                    onChange={() => {
                      setLayerSelectionVisibility({
                        id: layerSelection.id,
                        on: !layerSelection.on,
                      });
                      sendEvent(
                        "layers",
                        "toggleLayervisibility",
                        layerSelection.layer.title
                      );
                    }}
                  />
                }
              />
            );
          })}
    </PanelSectionWrapper>
  );
}

function LayersPanel() {
  return (
    <PanelContainer data-test="layers-panel">
      <PanelContent>
        <Categories />
      </PanelContent>
    </PanelContainer>
  );
}

export default LayersPanel;
