import styled from "styled-components/macro";

const Wrapper = styled.div`
  display: grid;
  height: 100%;

  grid-template-rows: fit-content(15px) 1fr;
  grid-template-columns: 5fr 2fr;
  grid-template-areas:
    "header  header"
    "content side"
    "footer  footer";
`;

const Content = styled.div`
  grid-area: content;
  padding: 1em;
  overflow-y: auto;
`;

const Side = styled.div`
  grid-area: side;
  background-color: rgb(250, 250, 250);
  border-left: 1px solid #eee;
  overflow-y: auto;
`;

const Footer = styled.div`
  grid-area: footer;
  border-top: 1px solid #eee;
  padding: 1em;
`;

export default {
  Wrapper,
  Content,
  Side,
  Footer,
};
