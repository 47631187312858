import "react-tagsinput/react-tagsinput.css";
import React, { useState } from "react";
import TagsInput from "react-tagsinput";
import styled from "styled-components/macro";
import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox";
import "@reach/combobox/styles.css";

export const TagItem = styled.li`
  display: inline-block;
  padding: 0 8px;
  line-height: 24px;
  font-size: 11px;
  background: ${(props) => props.theme.colors.lighterBackground};
  color: ${(props) => props.theme.colors.text};
  border: solid 1px ${(props) => props.theme.colors.border};
  border-radius: 2px;

  & + & {
    margin-left: 10px;
  }
`;

const StyledTagsInput = styled(TagsInput)`
  .react-tagsinput-tag {
    background-color: ${(props) => props.theme.colors.lighterBackground};
    color: ${(props) => props.theme.colors.text};
    margin-bottom: 8px;
    margin-right: 8px;
    border: 1px solid ${(props) => props.theme.colors.lighterBackground};
    font-weight: normal;
    font-family: Inter;
    font-size: 13px;
    line-height: 19px;
  }
  .react-tagsinput-tag:hover {
    border: 1px solid ${(props) => props.theme.colors.border};
  }
  padding-left: 8px;
  padding-top: 8px;
`;

const Wrapper = styled.div`
  background: ${(props) => props.theme.colors.darkerBackground};
  border-radius: 4px;
`;

const StyledCombobox = styled(Combobox)`
  display: inline-block;
  width: 100%;
`;

const StyledComboboxInput = styled(ComboboxInput)`
  width: 100%;
  color: ${(props) => props.theme.colors.text};
  background-color: transparent;
  border: none;
  outline: none;
  font-weight: normal;
  ::placeholder {
    color: ${(props) => props.theme.colors.muted};
  }
  &[data-reach-combobox-input] {
    padding: 7px;
    margin-bottom: 8px;
  }
`;

const StyledComboboxPopover = styled(ComboboxPopover)`
  z-index: 4;
  &[data-reach-combobox-popover] {
    border: 0;
  }
`;

const StyledComboboxList = styled(ComboboxList)`
  &[data-reach-combobox-list] {
    background-color: ${(props) => props.theme.colors.darkerBackground};
  }
`;

const StyledComboboxOption = styled(ComboboxOption)`
  &[data-reach-combobox-option] {
    font-weight: normal;
    padding: 8px 16px;
    color: ${(props) => props.theme.colors.text};
    font-family: Inter;
    font-size: 13px;
    line-height: 19px;
    :hover,
    &[data-highlighted],
    &[data-highlighted]:hover {
      background-color: ${(props) => props.theme.colors.lighterBackground};
    }
    [data-user-value="true"] {
      font-weight: 600;
    }
    [data-suggested-value="true"] {
      font-weight: normal;
    }
  }
`;

function AutosuggestRenderInput({
  options,
  addTag,
  value,
  setValue,
  disableNewTag,
  ...props
}) {
  const onKeyDown = (event) => {
    if (event.keyCode === 9 || event.keyCode === 13) {
      addTag(value);
      setValue("");
    }
  };
  return (
    <StyledCombobox
      aria-label="Search tags..."
      onSelect={(tag) => {
        addTag(tag);
        setValue("");
      }}
    >
      <StyledComboboxInput
        placeholder="Search tags..."
        onChange={(val) => setValue(val.target.value)}
        value={value}
        onKeyDown={!disableNewTag ? onKeyDown : null}
      />
      <StyledComboboxPopover>
        <StyledComboboxList>
          {options
            .filter((o) => o.includes(value))
            .map((o) => (
              <StyledComboboxOption key={o} value={o} />
            ))}
        </StyledComboboxList>
      </StyledComboboxPopover>
    </StyledCombobox>
  );
}

function defaultRenderInput({ addTag, ...props }) {
  let { onChange, value, ...other } = props;
  return <input type="text" onChange={onChange} value={value} {...other} />;
}

function Tags({
  options,
  value,
  onChange,
  disabled = false,
  disableNewTag,
  ...rest
}) {
  const [inputValue, setInputValue] = useState("");

  return (
    <Wrapper>
      <StyledTagsInput
        {...rest}
        value={value || []}
        onChange={onChange}
        renderInput={options ? AutosuggestRenderInput : defaultRenderInput}
        disabled={disabled}
        inputProps={
          options
            ? {
                options,
                value: inputValue,
                setValue: setInputValue,
                disableNewTag: disableNewTag,
              }
            : { placeholder: "" }
        }
      />
    </Wrapper>
  );
}

export default Tags;
