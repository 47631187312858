import { useState, useRef, useEffect } from "react";
import { debounce } from "lodash-es";

export default function useDebouncedValue(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const debouncedUpdate = useRef();

  useEffect(() => {
    debouncedUpdate.current = debounce(setDebouncedValue, delay);
  }, [delay]);

  useEffect(() => {
    debouncedUpdate.current(value);
  }, [value]);

  return debouncedValue;
}
