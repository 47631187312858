import { Subtitle, Title } from "utils/SelectOption";
import React, { useState } from "react";

import Button from "components/Button";
import ConfirmationModal from "components/ConfirmationModal";
import FullScreenMessage from "components/FullScreenMessage";
import Icon from "components/Icon";
import Modal from "components/Modal";
import Select from "react-select";
import styled from "styled-components/macro";
import UserSelect, { getName } from "components/UserSelect";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { sendEvent } from "utils/ga";

const PermissionSelectWrapper = styled(Select)`
  width: 128px;
  text-transform: uppercase;
  font-size: 0.9em;
  font-weight: 600;
`;

const permissionOptions = [
  { value: "ro", label: "Read only" },
  { value: "rw", label: "Read/Write" },
  { value: "ad", label: "Admin" },
];

const permissionFromId = (id) => permissionOptions.find((o) => o.value === id);

const PermissionSelect = ({ onChange, ...props }) => (
  <PermissionSelectWrapper
    isSearchable={false}
    options={permissionOptions}
    onChange={(permissionType) => onChange(permissionType.value)}
    {...props}
  />
);

const UsersList = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const UsersListHeader = styled.div`
  border-bottom: 1px solid #eee;
  padding: 0.5em 0;
  margin: 0 1em;
`;

const UserWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-template-rows: auto auto;

  padding: 0.5em 0;
  margin: 0 1em;
  border-bottom: 1px solid #eee;
`;

const RevokePermissionIcon = styled(Icon)`
  grid-column: 3 / 4;
  grid-row: 1 / 3;
  place-self: center;

  margin: 0 1em;
  cursor: pointer;
`;

const UserPermissionSelect = styled(PermissionSelect)`
  grid-column: 2 / 3;
  grid-row: 1/ 3;
  place-self: center;
`;

const UserWithAccess = ({ customer, setPermission, permission }) => (
  <UserWrapper>
    <Title>{getName(customer.user)}</Title>
    <Subtitle>{customer.user.email}</Subtitle>
    <UserPermissionSelect
      value={permissionFromId(permission)}
      menuPlacement={"auto"}
      onChange={(permissionType) => {
        setPermission({ permissionType, customerId: customer.id });
        sendEvent(
          "sharingSettingsModal",
          "shareWith",
          permissionType,
          customer.id
        );
      }}
    />
    <RevokePermissionIcon
      onClick={() =>
        setPermission({ customerId: customer.id, permissionType: null })
      }
    >
      clear
    </RevokePermissionIcon>
  </UserWrapper>
);

const Footer = styled.div`
  background-color: #eee;
  padding: 1em;
`;

const InviteWrapper = styled.div`
  display: flex;

  > *:not(:last-child) {
    margin-right: 1em;
  }
`;

export const SelectInput = styled(Select)`
  flex: 1;
`;

function InvitePeople({ onSubmit, companyWorkspace }) {
  const [customerId, setCustomerId] = useState(null);
  const [permissionType, setPermissionType] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);

  const onInviteSubmit = () => {
    onSubmit({ customerId, permissionType });
    setCustomerId(null);
    setPermissionType(null);
  };

  const handleSubmit = () => {
    if (companyWorkspace) {
      setConfirmModal(true);
    } else {
      onInviteSubmit();
    }
  };

  return (
    <InviteWrapper>
      <UserSelect
        placeholder="Type a name"
        value={customerId}
        onChange={(customerId) => setCustomerId(customerId)}
        withoutMe={true}
        menuPlacement={"top"}
        isClearable
        companyWorkspace={companyWorkspace}
      />
      <PermissionSelect
        menuPlacement={"top"}
        value={permissionType ? permissionFromId(permissionType) : null}
        onChange={(permissionType) => setPermissionType(permissionType)}
      />
      <Button
        primary
        disabled={!(customerId && permissionType)}
        onClick={handleSubmit}
      >
        Invite
      </Button>
      <ConfirmationModal
        onAccept={onInviteSubmit}
        onClose={() => {
          setConfirmModal(false);
        }}
        isOpen={confirmModal}
        title={"Are you sure?"}
        header={"You are about to share this workspace with an outside user"}
        accept={"Share workspace"}
      />
    </InviteWrapper>
  );
}

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const GET_WORKSPACE_SHARED_WITH = gql`
  query getWorkspaceSharedWith($id: ID) {
    workspace(id: $id) {
      id
      sharedWith {
        edges {
          type
          node {
            id
            user {
              id
              email
              username
              firstName
              lastName
            }
          }
          cursor
        }
      }
    }
  }
`;

const SHARE_WORKSPACE_WITH = gql`
  mutation shareWorkspaceWith($input: ShareWorkspaceWithInput!) {
    shareWorkspaceWith(input: $input) {
      workspace {
        id
      }
    }
  }
`;

function useWorkspaceMutations() {
  const [shareWorkspaceWithMutation] = useMutation(SHARE_WORKSPACE_WITH, {
    refetchQueries: ["getWorkspaceSharedWith"],
  });
  return {
    shareWorkspaceWith: ({ id, customerId, permission }) => {
      shareWorkspaceWithMutation({
        variables: { input: { id, customerId, permission } },
      });
    },
  };
}

export default function SharingSettingsModal({
  onClose,
  workspaceId,
  companyWorkspace,
}) {
  const { data } = useQuery(GET_WORKSPACE_SHARED_WITH, {
    variables: { id: workspaceId },
  });
  const { shareWorkspaceWith } = useWorkspaceMutations();
  if (data) {
    const workspace = data.workspace;
    const setPermission = async ({ customerId, permissionType }) => {
      shareWorkspaceWith({
        id: workspace.id,
        customerId: customerId,
        permission: permissionType,
      });
    };
    return (
      <Modal
        title="Sharing Settings"
        isOpen={true}
        size="dialog"
        onClose={onClose}
      >
        <Content>
          {workspace &&
            (workspace.sharedWith.edges.length === 0 ? (
              <FullScreenMessage>
                This workspace is not shared with anyone yet
              </FullScreenMessage>
            ) : (
              <>
                <UsersListHeader>Who has access</UsersListHeader>
                <UsersList>
                  {workspace.sharedWith.edges
                    .slice()
                    .sort((a, b) => a.node.user.username < b.node.user.username)
                    .map((edge) => (
                      <UserWithAccess
                        key={edge.node.id}
                        customer={edge.node}
                        permission={edge.type}
                        setPermission={setPermission}
                      />
                    ))}
                </UsersList>
              </>
            ))}
          <Footer>
            <InvitePeople
              companyWorkspace={companyWorkspace}
              onSubmit={setPermission}
            />
          </Footer>
        </Content>
      </Modal>
    );
  } else return null;
}
