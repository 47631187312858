import themes from "./themes";

export { themes };

export const booleanColors = ["#242F40", "#CCA43B"];

export const categoryColors = [
  "#5899DA",
  "#E8743B",
  "#19A979",
  "#ED4A7B",
  "#945ECF",
  "#13A4B4",
  "#525DF4",
  "#BF399E",
  "#6C8893",
  "#EE6868",
  "#2F6497",
];
