import React, { useState, useMemo, useContext, useEffect } from "react";
import { Context as MapStateContext } from "../../../state";
import panels from "panels";

export const PreviewLayerContext = React.createContext(null);

export function PreviewLayerProvider({ children }) {
  const [previewLayer, setPreviewLayer] = useState(null);
  const { panel } = useContext(MapStateContext);

  const value = useMemo(() => ({ previewLayer, setPreviewLayer }), [
    previewLayer,
  ]);

  useEffect(() => {
    if (panel !== panels.LAYER_BROWSER) {
      setPreviewLayer(null);
    }
  }, [panel]);

  return (
    <PreviewLayerContext.Provider value={value}>
      {children}
    </PreviewLayerContext.Provider>
  );
}
