import React from "react";
import styled from "styled-components/macro";
import Icon from "components/Icon";

const SideSectionWrapper = styled.div`
  display: grid;
  padding: 1em;
  border-bottom: 1px solid #eee;

  grid-template-rows: min-content min-content;
  grid-template-columns: 1fr min-content;
  grid-template-areas:
    "title      icon"
    "content content";

  &:last-child {
    border-bottom: none;
  }
`;

const SideSectionTitle = styled.div`
  text-transform: uppercase;
  grid-area: title;
`;

const SideSectionIcon = styled.div`
  grid-area: icon;
`;

const SideSectionContent = styled.div`
  margin-top: 1em;
  grid-area: content;
`;

const SideSection = ({ title, icon, children }) => (
  <SideSectionWrapper>
    <SideSectionTitle>{title}</SideSectionTitle>
    <SideSectionIcon>
      <Icon size={18}>{icon}</Icon>
    </SideSectionIcon>
    <SideSectionContent>{children}</SideSectionContent>
  </SideSectionWrapper>
);

export default SideSection;
