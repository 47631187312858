import { ActionIcon, ListItemLabel } from "components/SidePanel";

import { EditableItem } from "components/SidePanel";
import React from "react";
import { ScenarioItemWrapper } from "./ScenarioItem";
import styled from "styled-components/macro";

const AddScenarioItemWrapper = styled(ScenarioItemWrapper)`
  cursor: pointer;
`;

const AddScenarioLabel = styled(ListItemLabel)`
  color: orange;
`;

const AddScenarioItem = ({ onSubmit }) => (
  <EditableItem
    inputProps={{ placeholder: "Type a name" }}
    onSubmit={onSubmit}
    renderNotEditing={({ onClick }) => (
      <AddScenarioItemWrapper onClick={onClick}>
        <ActionIcon color="orange">add_circle_outline</ActionIcon>
        <AddScenarioLabel>Add Scenario</AddScenarioLabel>
      </AddScenarioItemWrapper>
    )}
    renderWrapper={({ children }) => (
      <AddScenarioItemWrapper>{children}</AddScenarioItemWrapper>
    )}
  />
);

export default AddScenarioItem;
