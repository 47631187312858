import React from "react";
import styled from "styled-components";
import { Route, useRouteMatch, Switch } from "react-router-dom";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import MobileTopbar from "./MobileTopbar";
import Map from "./Map";
import ProjectsPanel from "./panels/ProjectsPanel";
import ShareProjectPanel from "./panels/ShareProjectPanel";
import LayersPanel from "./panels/LayersPanel";
import LayerDetailPanel from "./panels/LayerDetailPanel";
import AnnotationPanel from "./panels/AnnotationPanel";
import TasksPanel from "./panels/TasksPanel";
import SearchPanel from "./panels/SearchPanel";
import MarketplacePanel from "./panels/MarketplacePanel";
import AddLayerPanel from "./panels/AddLayerPanel";
import AddTasksPanel from "./panels/AddTaskPanel";
import UserProfilePanel from "./panels/UserProfilePanel";
import ActivityPanel from "./panels/ActivityPanel";
import VectorMetadataPanel from "./panels/VectorMetadataPanel";
import CategoriesPanel from "./mobile-panels/CategoriesPanel";
import MSearchPanel from "./mobile-panels/SearchPanel";
import MVectorMetadataPanel from "./mobile-panels/VectorMetadataPanel";
import { GET_SCENARIO } from "../AppRoutes";
import { useQuery } from "@apollo/react-hooks";
import { isNil } from "lodash-es";
import { ProjectViewportProvider, useSelectedProject } from "./URLState";
import { HoveredLayerProvider } from "../routes/map/components/maps/Base/HoveredLayerContext";
import { PreviewLayerProvider } from "../routes/map/components/maps/Base/PreviewLayerContext";
import { SelectedTaskProvider } from "../SelectedTaskContext";
import { TemporaryFeatureProvider } from "./panels/TemporaryFeatureContext";
import { SearchResultProvider } from "./panels/SearchResultContext";
import { LayerSelectionsProvider } from "hooks/useLayerSelections";
import { TasksProvider } from "hooks/useTasks";
import { Context as AppStateContext } from "appState";
import { RecentProjectsProvider } from "./RecentProjects";
import { ThemeProvider } from "./theme";
import { AnnotationsTasksVisibilityProvider } from "./AnnotationsTasksVisibility";
import { Notification, NotificationProvider } from "./components/Notification";
import { ScreenshotProvider } from "../../../hooks/useScreenshot";
import ScreenMessage from "./components/ScreenMessage";
import { useAuth } from "hooks/data/auth";
import SendFeedbackPanel from "./panels/SendFeedbackPanel";
import GetHelpPanel from "./panels/GetHelpPanel";
import MGetHelpPanel from "./mobile-panels/GetHelpPanel";
import { useIsMobile } from "hooks/useMatchMedia";
import {
  useSelectedFeature,
  SelectedFeatureProvider,
} from "hooks/useSelectedFeature";

const Wrapper = styled.div`
  display: grid;
  height: 100%;

  grid-gap: 2px;
  background-color: ${({ theme }) => theme.colors.darkerBackground};

  grid-template-rows: 48px 1fr;
  grid-template-columns: 56px 1fr;
  grid-template-areas:
    "topbar  topbar"
    "sidebar content";

  font-family: Inter, sans-serif;
  font-size: 13px;
  color: ${(props) => props.theme.colors.text};
  box-sizing: border-box;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "topbar"
      "content";
  }
`;

const Content = styled.div`
  grid-area: content;
  display: flex;
  position: relative;

  min-width: 0;
  min-height: 0;
`;

function MobilePanels() {
  const { path } = useRouteMatch();
  const { feature } = useSelectedFeature();

  if (feature) return <MVectorMetadataPanel />;
  return (
    <Switch>
      <Route path={`${path}/project`}>
        <CategoriesPanel />
      </Route>
      <Route path={`${path}/search`}>
        <MSearchPanel />
      </Route>
    </Switch>
  );
}

function Panels({ isValidScenario }) {
  const { path } = useRouteMatch();
  const { loggedIn } = useAuth();
  const { feature } = useSelectedFeature();

  if (feature) return <VectorMetadataPanel />;
  if (loggedIn) {
    return (
      <Switch>
        <Route path={`${path}/project/share`}>
          <ShareProjectPanel />
        </Route>
        <Route exact path={`${path}/projects`}>
          <ProjectsPanel />
        </Route>
        {isValidScenario && (
          <Switch>
            <Route path={`${path}/project`}>
              <LayersPanel />
            </Route>
            <Route exact path={`${path}/layer/new`}>
              <AddLayerPanel />
            </Route>
            <Route path={`${path}/layer/:id`}>
              <LayerDetailPanel />
            </Route>
            <Route path={`${path}/marketplace`}>
              <MarketplacePanel />
            </Route>
            <Route path={`${path}/search`}>
              <SearchPanel />
            </Route>
            <Route path={`${path}/tasks/new`}>
              <AddTasksPanel />
            </Route>
            <Route path={`${path}/tasks`}>
              <TasksPanel />
            </Route>
            <Route path={`${path}/annotation/add/:id`}>
              <AnnotationPanel
                placeholderTitle="Add annotation"
                initialStatus="editing"
              />
            </Route>
            <Route path={`${path}/annotation/:id`}>
              <AnnotationPanel />
            </Route>
            <Route path={`${path}/profile`}>
              <UserProfilePanel />
            </Route>
            <Route path={`${path}/feedback`}>
              <SendFeedbackPanel />
            </Route>
            <Route path={`${path}/activity`}>
              <ActivityPanel />
            </Route>
          </Switch>
        )}
      </Switch>
    );
  } else if (isValidScenario) {
    return (
      <Switch>
        <Route path={`${path}/project`}>
          <LayersPanel />
        </Route>
        <Route path={`${path}/layer/:id`}>
          <LayerDetailPanel />
        </Route>
        <Route path={`${path}/search`}>
          <SearchPanel />
        </Route>
        <Route path={`${path}/help`}>
          <GetHelpPanel />
        </Route>
      </Switch>
    );
  } else return null;
}

function MapProvider({ children }) {
  const { projectId } = useSelectedProject();

  return (
    <AppStateContext.Provider value={{ scenario: projectId }}>
      <SelectedTaskProvider>
        <HoveredLayerProvider>
          <AnnotationsTasksVisibilityProvider>
            <PreviewLayerProvider>{children}</PreviewLayerProvider>
          </AnnotationsTasksVisibilityProvider>
        </HoveredLayerProvider>
      </SelectedTaskProvider>
    </AppStateContext.Provider>
  );
}

function Providers({ children }) {
  return (
    <RecentProjectsProvider>
      <ProjectViewportProvider>
        <TemporaryFeatureProvider>
          <SearchResultProvider>
            <LayerSelectionsProvider>
              <TasksProvider>
                <ThemeProvider>
                  <NotificationProvider>
                    <ScreenshotProvider>
                      <SelectedFeatureProvider>
                        <MapProvider>{children}</MapProvider>
                      </SelectedFeatureProvider>
                    </ScreenshotProvider>
                  </NotificationProvider>
                </ThemeProvider>
              </TasksProvider>
            </LayerSelectionsProvider>
          </SearchResultProvider>
        </TemporaryFeatureProvider>
      </ProjectViewportProvider>
    </RecentProjectsProvider>
  );
}

function App() {
  const isMobile = useIsMobile();
  const validScenario = useQuery(GET_SCENARIO, {
    variables: { scenarioId: "2" },
  });

  const isValidScenario = validScenario.data
    ? !isNil(validScenario.data.scenario)
    : true;

  return (
    <Providers>
      <Wrapper openPanel>
        <Notification />
        {!isMobile && <Sidebar isValidScenario={isValidScenario} />}
        {isMobile ? <MobileTopbar /> : <Topbar />}
        <Content>
          {isMobile ? (
            <MobilePanels />
          ) : (
            !isMobile && <Panels isValidScenario={isValidScenario} />
          )}
          {isValidScenario ? (
            <Map />
          ) : (
            <ScreenMessage>
              Invalid scenario. If you were given a link, ask the owner of the
              workspace for permission.
            </ScreenMessage>
          )}
          {isMobile && <MGetHelpPanel />}
        </Content>
      </Wrapper>
    </Providers>
  );
}

export default App;
