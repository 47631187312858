import { createBrowserHistory } from "history";
import { createTracker, sendPageView } from "utils/ga";

export function getParentPath(path) {
  return path.split("/").slice(0, -1).join("/");
}

const history = createBrowserHistory();

function replaceIdsWithPlaceholder(pathname) {
  return pathname
    .replace(/(-?\d+\.\d+,?){4}/, "[viewport]")
    .replace(/layers\/(\d+)/, "layers/[id]")
    .replace(/\/app\/([a-zA-Z]+)\/(\d+)/, "/app/$1/[scenario_id]");
}

if (process.env.NODE_ENV === "production") {
  const page = replaceIdsWithPlaceholder(window.location.pathname);
  createTracker();
  sendPageView(page);

  history.listen(({ pathname }) => {
    const page = replaceIdsWithPlaceholder(pathname);
    sendPageView(page);
  });
}

export default history;
