import React, { useContext } from "react";
import styled from "styled-components/macro";
import Select from "react-select";
import modes from "modes";
import Label from "components/Label";
import { Context as AppStateContext } from "../appState";

const options = Object.values(modes).map((mode) => ({
  value: mode,
  label: mode,
}));

const Wrapper = styled.div`
  display: flex;
  align-self: center;
  align-items: center;
`;

const ModeSelect = styled(Select)`
  width: 160px;
  z-index: 3;
`;

const ModeLabel = styled(Label)`
  margin-right: 1em;
  padding: 0;
`;

function ModeSelection() {
  const { mode, setParams } = useContext(AppStateContext);

  return (
    <Wrapper>
      <ModeLabel>Mode:</ModeLabel>
      <ModeSelect
        options={options}
        value={{ label: mode }}
        onChange={({ value }) => setParams({ mode: value })}
        isSearchable={false}
      />
    </Wrapper>
  );
}

export default ModeSelection;
