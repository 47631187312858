import { Layers } from "./layers";
import React, { useContext, useEffect, useState } from "react";
import Restricted, { isSlingshot } from "utils/Restricted";
import { Context as AppStateContext } from "appState";
import Legend from "./Legend";
import { Context as MapStateContext } from "../../../state";
import { PanelToggle } from "components/Sidebar";
import Search from "components/Search";
import { Tunnel } from "utils/tunnels";
import panels from "panels";
import styled from "styled-components/macro";
import usePrevious from "hooks/usePrevious";
import useMapPopupHeight from "hooks/useMaxPopupHeight";
import ReactMapboxGl, { GeoJSONLayer, Marker } from "react-mapbox-gl";
import { HoveredLayerContext } from "./HoveredLayerContext";
import { SkywatchContext } from "./SkywatchContext";
import MapResizeObserver from "./MapResizeObserver";
import PointerCursorOnInteractiveLayers from "./PointerCursorOnInteractiveLayers";
import Icon from "components/Icon";
import { camelCase } from "lodash-es";
import MouseCoordsOnMove from "./MouseCoordsOnMove";

import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

export const Map = ReactMapboxGl({
  transformRequest: (url) =>
    url.includes("Font Awesome")
      ? { url: url.replace("mapbox/Font", "sanfilippopablo/Font") }
      : { url },
  accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
  preserveDrawingBuffer: true,
});

export const GET_LAYERS = gql`
  query getLayerSelections($scenarioId: ID!) {
    scenario(id: $scenarioId) {
      id
      defaultView
      basemap
      layerSelections {
        id
        on
        metadata
        layer {
          id
          title
          name
          type
          metadata
          extent
        }
      }
    }
  }
`;

export const MaxPopupHeightContext = React.createContext();

const MapContainer = styled.div`
  order: 2;
  flex: 1;
  z-index: 1;
`;

export function HoveredLayerExtentGeoJSON({ data }) {
  return (
    <GeoJSONLayer
      data={data}
      linePaint={{ "line-width": 3, "line-color": "#74BC82" }}
      fillPaint={{ "fill-color": "#74BC82", "fill-opacity": 0.2 }}
    />
  );
}

const MarkerIcon = styled(Icon).attrs({ color: "#377a9b", size: 40 })`
  cursor: pointer;
`;

export const createZXYBasemap = ({
  name,
  tilesUrls,
  minzoom = 0,
  maxzoom = 24,
  tileSize = 256,
}) => {
  const camelCaseName = camelCase(name);
  return {
    version: 8,
    name: name,
    sources: {
      [camelCaseName]: {
        type: "raster",
        tiles: tilesUrls,
        tileSize,
      },
    },
    layers: [
      {
        id: `${camelCaseName}-tiles`,
        type: "raster",
        source: camelCaseName,
        minzoom: minzoom,
        maxzoom: maxzoom,
      },
    ],
    sprite: "mapbox://sprites/mapbox/satellite-v9",
    glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
  };
};

export const basemaps = {
  Streets: "mapbox://styles/mapbox/light-v10",
  Satellite: "mapbox://styles/mapbox/satellite-v9",
  "USGS Topo Base": createZXYBasemap({
    name: "USGS Topo Base",
    tilesUrls: [
      "http://basemap.nationalmap.gov/arcgis/rest/services/USGSTopo/MapServer/tile/{z}/{y}/{x}",
    ],
  }),
};

const BaseMap = ({ children }) => {
  const appState = useContext(AppStateContext);
  const mapState = useContext(MapStateContext);
  const { extent: hoveredLayerExtent } = useContext(HoveredLayerContext);

  const [searchResultLocation, setSearchResultLocation] = useState(null);

  const { data } = useQuery(GET_LAYERS, {
    variables: { scenarioId: appState.scenario },
  });

  let filteredLayerSelections = [];
  let scenarioView;
  let basemap;

  if (data) {
    const { scenario } = data;
    filteredLayerSelections = scenario.layerSelections.filter((ls) => ls.on);
    scenarioView = scenario.defaultView;
    basemap = scenario.basemap || "Streets";
  }

  // Go to the scenario view on scenario change
  const prevScenarioView = usePrevious(scenarioView);
  useEffect(() => {
    if (scenarioView) {
      const view = scenarioView.join(",");
      if (prevScenarioView && prevScenarioView.join(",") !== view) {
        mapState.setParams({ view });
      }
    }
  }, [mapState, prevScenarioView, scenarioView]);

  const view = mapState.view.split(",").map(Number);

  function updateView(map, event) {
    if (event.fitboundUpdate) {
      return;
    }
    const bounds = map.getBounds();
    const view = [
      bounds.getWest(),
      bounds.getSouth(),
      bounds.getEast(),
      bounds.getNorth(),
    ]
      .map((n) => n.toFixed(6))
      .join(",");
    mapState.setParams({ view });
  }

  const { containerRef, maxPopupHeight } = useMapPopupHeight();
  const { polygon } = useContext(SkywatchContext);

  return (
    <MaxPopupHeightContext.Provider value={maxPopupHeight}>
      <MapContainer ref={containerRef}>
        <Legend layerSelections={filteredLayerSelections} />
        <Tunnel id="search-tunnel">
          <Search
            {...{ mapState, searchResultLocation, setSearchResultLocation }}
          />
        </Tunnel>
        <Tunnel id="sidebar-toggles">
          <PanelToggle
            name="Workspaces"
            icon="folder"
            onClick={() =>
              mapState.setParams({ panel: panels.WORKSPACES, layerId: null })
            }
            active={mapState.panel === panels.WORKSPACES}
          />
          <PanelToggle
            data-test="sidebar-layers"
            name="Layers"
            icon="layers"
            onClick={() =>
              mapState.setParams({ panel: panels.LAYERS, layerId: null })
            }
            active={mapState.panel === panels.LAYERS}
          />
          <PanelToggle
            name="Activity"
            icon="rss_feed"
            onClick={() =>
              mapState.setParams({ panel: panels.ACTIVITY, layerId: null })
            }
            active={mapState.panel === panels.ACTIVITY}
          />
          <PanelToggle
            name="Get Imagery"
            icon="cloud_download"
            onClick={() => mapState.setParams({ panel: panels.SKYWATCH })}
            active={mapState.panel === panels.SKYWATCH}
          />
          <Restricted to={isSlingshot}>
            <PanelToggle
              name="Run Analytics"
              icon="image_search"
              onClick={() => mapState.setParams({ panel: panels.ANALYTICS })}
              active={mapState.panel === panels.ANALYTICS}
            />
          </Restricted>
        </Tunnel>
        <Map
          style={basemaps[basemap]}
          containerStyle={{ height: "100%" }}
          onZoomEnd={updateView}
          onDragEnd={updateView}
          fitBounds={[
            [view[0], view[1]],
            [view[2], view[3]],
          ]}
        >
          {children}
          {hoveredLayerExtent && (
            <HoveredLayerExtentGeoJSON data={hoveredLayerExtent} />
          )}
          <MapResizeObserver />
          <PointerCursorOnInteractiveLayers />
          <MouseCoordsOnMove />
          <Layers layerSelections={filteredLayerSelections} />
          {searchResultLocation !== null && (
            <Marker coordinates={searchResultLocation} anchor="bottom">
              <MarkerIcon>room</MarkerIcon>
            </Marker>
          )}
          {polygon && (
            <GeoJSONLayer
              data={polygon}
              id="skywatch-draw"
              linePaint={{ "line-color": "green", "line-width": 2 }}
              fillPaint={{ "fill-color": "green", "fill-opacity": 0.2 }}
            />
          )}
        </Map>
      </MapContainer>
    </MaxPopupHeightContext.Provider>
  );
};

export default BaseMap;
