import Icon from "components/Icon";
import React from "react";
import styled from "styled-components/macro";

const HeaderWrapper = styled.div`
  grid-area: header;
  padding: 1.2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
`;

const Header = ({ onCloseClick, children }) => (
  <HeaderWrapper>
    {children}
    <Icon
      data-test="close-icon"
      color="#777"
      size={18}
      onClick={onCloseClick}
      style={{ cursor: "pointer" }}
    >
      close
    </Icon>
  </HeaderWrapper>
);

export default Header;
