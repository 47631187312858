import React from "react";
import styled, { css } from "styled-components";
import ProfilePic from "components/ProfilePic";
import { useLocation, matchPath } from "react-router";
import useHistory from "hooks/useHistory";
import { useRouteMatch } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useBaseUrl } from "./URLState";
import { isLoggedIn } from "api/auth";
import { useAuth } from "hooks/data/auth";
import Restricted from "utils/Restricted";
import Icon from "./components/Icon";
import { useSelectedFeature } from "hooks/useSelectedFeature";

const Wrapper = styled.div`
  grid-area: sidebar;
  height: 100%;
  background-color: ${(props) => props.theme.colors.background};
  display: flex;
  flex-direction: column;
`;

export const IconWrapper = styled.div`
  color: ${(props) => props.theme.colors.muted};
  height: 56px;
  display: grid;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  ${(props) =>
    props.selected &&
    css`
      background-color: ${(props) => props.theme.colors.primary};
      color: ${(props) => props.theme.colors.text};
    `}
  :hover {
    color: ${(props) => props.theme.colors.text};
  }
`;

const SidebarProfilePic = styled(ProfilePic)`
  margin: 0;
`;

const Fill = styled.div`
  flex: 1;
`;

const GET_CUSTOMER = gql`
  query getMe {
    me {
      id
      picture
    }
  }
`;

function Sidebar({ isValidScenario }) {
  const { loggedIn } = useAuth();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const baseUrl = useBaseUrl();
  const { data } = useQuery(GET_CUSTOMER, { skip: !loggedIn });
  const customer = data ? data.me : null;
  const { close: closeMetadataPanel } = useSelectedFeature();

  const changeSelection = (option) => {
    closeMetadataPanel();
    const optionUrl = `${baseUrl}/${option}`;
    history.push(location.pathname === optionUrl ? baseUrl : optionUrl);
  };
  return (
    <Wrapper>
      <IconWrapper
        id="projects"
        data-test="sidebar-projects"
        onClick={() => changeSelection(loggedIn ? "projects" : "project")}
        selected={
          matchPath(location.pathname, `${match.path}/projects`) ||
          matchPath(location.pathname, `${match.path}/project`) ||
          matchPath(location.pathname, `${match.path}/layer`)
        }
      >
        <Icon svg size={26}>
          project
        </Icon>
      </IconWrapper>
      {isValidScenario && (
        <>
          <Restricted to={isLoggedIn}>
            <IconWrapper
              id="tasks"
              onClick={() => changeSelection("tasks")}
              selected={matchPath(location.pathname, `${match.path}/tasks`)}
            >
              <Icon svg size={26}>
                tasks
              </Icon>
            </IconWrapper>
            <IconWrapper
              id="activity"
              onClick={() => changeSelection("activity")}
              selected={matchPath(location.pathname, `${match.path}/activity`)}
            >
              <Icon svg size={26}>
                activity
              </Icon>
            </IconWrapper>
            <IconWrapper
              id="marketplace"
              onClick={() => changeSelection("marketplace")}
              selected={matchPath(
                location.pathname,
                `${match.path}/marketplace`
              )}
            >
              <Icon svg size={26}>
                marketplace
              </Icon>
            </IconWrapper>
          </Restricted>
          <IconWrapper
            id="search"
            onClick={() => changeSelection("search")}
            selected={matchPath(location.pathname, `${match.path}/search`)}
          >
            <Icon svg size={26}>
              search
            </Icon>
          </IconWrapper>
        </>
      )}
      <Fill />
      <Restricted to={isLoggedIn}>
        <IconWrapper
          id="feedback"
          onClick={() => changeSelection("feedback")}
          selected={matchPath(location.pathname, `${match.path}/feedback`)}
        >
          <IconWrapper id="feedback">
            <Icon svg size={26}>
              contact_support
            </Icon>
          </IconWrapper>
        </IconWrapper>
        <IconWrapper
          id="profile"
          onClick={() => changeSelection("profile")}
          selected={matchPath(location.pathname, `${match.path}/profile`)}
        >
          <SidebarProfilePic src={customer?.picture} border={false} />
        </IconWrapper>
      </Restricted>
      {!loggedIn && (
        <IconWrapper
          id="help"
          onClick={() => changeSelection("help")}
          selected={matchPath(location.pathname, `${match.path}/help`)}
        >
          <IconWrapper id="help">
            <Icon svg size={26}>
              contact_support
            </Icon>
          </IconWrapper>
        </IconWrapper>
      )}
    </Wrapper>
  );
}

export default Sidebar;
