import CommentInput from "./CommentInput";
import Event, { cleanActions } from "./ActivityEvent";
import React from "react";
import styled from "styled-components/macro";
import { RestrictedByWorkspacePermission } from "utils/Restricted";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { sendEvent } from "utils/ga";

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  padding-bottom: 0.5em;
  margin-bottom: 1em;
`;

const HeaderTitle = styled.h6`
  font-size: 0.9em;
  margin: 0;
  text-transform: uppercase;
`;

const Header = () => (
  <HeaderWrapper>
    <HeaderTitle>Activity</HeaderTitle>
  </HeaderWrapper>
);

const Wrapper = styled.div`
  margin-top: 1em;
`;

const ADD_COMMENT = gql`
  mutation addComment($input: AddTaskCommentInput!) {
    addTaskComment(input: $input) {
      success
    }
  }
`;

const ActivitySection = ({ task }) => {
  const [addComment] = useMutation(ADD_COMMENT, {
    refetchQueries: ["getTask"],
  });
  return (
    <Wrapper>
      <Header />
      <RestrictedByWorkspacePermission type="rw">
        <CommentInput
          onSubmit={(text) => {
            addComment({ variables: { input: { id: task.id, text } } });
            sendEvent("task", "addActivityComment");
          }}
        />
      </RestrictedByWorkspacePermission>
      {cleanActions(task.actions).map((action, index) => (
        <Event key={index} action={action} />
      ))}
    </Wrapper>
  );
};

export default ActivitySection;
