import React, { useContext, useEffect, useRef, useState } from "react";
import {
  PanelSection,
  PanelSectionContainer,
  PanelIcon,
  PanelListItemLabel,
} from "../layout";
import { useSelectedProject, useViewport } from "../../URLState";
import { useQuery, useMutation } from "@apollo/react-hooks";
import bboxPolygon from "@turf/bbox-polygon";
import intersects from "@turf/boolean-intersects";
import bbox from "@turf/bbox";
import useDebouncedValue from "hooks/useDebouncedValue";
import useToggle from "hooks/useToggle";
import produce from "immer";
import {
  GET_LAYERS,
  GET_ALREADY_SELECTED_LAYER_SELECTIONS,
  CREATE_LAYER_SELECTION,
  useShouldLoadMore,
} from "../../../routes/map/components/maps/Base/panels/LayerBrowserPanel";
import { PreviewLayerContext } from "../../../routes/map/components/maps/Base/PreviewLayerContext";
import styled, { css } from "styled-components/macro";
import moment from "moment";
import { HoveredLayerContext } from "routes/app/routes/map/components/maps/Base/HoveredLayerContext";
import { sendEvent } from "utils/ga";
import { FixedPopup } from "../../components/FixedPopup";
import { PanelListItem } from "../layout";
import Toggle from "../../components/Toggle";

const LayerBrowserWrapper = styled(PanelSection)`
  flex: 1;
  overflow-y: auto;
`;

const LayersList = styled(PanelSectionContainer)`
  flex: 1;
  overflow-y: auto;
  padding: 0;
`;

const LayerWrapper = styled.div`
  user-select: none;
  cursor: pointer;
  display: grid;
  grid-template-columns: min-content auto min-content;
  grid-template-rows: min-content auto min-content;
  grid-template-areas:
    "left-icon title right-icon"
    "left-icon date right-icon";
  padding: 8px 16px;
  ${(props) =>
    props.selected &&
    css`
      background-color: ${(props) => props.theme.colors.lighterBackground};
    `}
  :hover {
    background-color: ${(props) => props.theme.colors.lighterBackground};
  }
`;

const LayerIcon = styled(PanelIcon)`
  align-self: center;
  align-items: center;
  margin-right: 9px;
  :first-child {
    grid-area: left-icon;
    margin-right: 9px;
  }

  :last-child {
    grid-area: right-icon;
    margin-left: 9px;
  }
`;

const LayerLabel = styled(PanelListItemLabel)`
  grid-area: title;
`;

const LayerDate = styled(PanelListItemLabel)`
  grid-area: date;
`;

function LayerItem({ layer, onAdd, previewLayer, onPreview, setExtent }) {
  const [showRightIcon, toggleShowRightIcon] = useToggle(false);

  const previewNotAllowed = !layer.extent || layer.alreadySelected;

  return (
    <LayerWrapper
      onMouseEnter={() => {
        setExtent(layer.extent);
        toggleShowRightIcon();
      }}
      onMouseLeave={() => {
        setExtent(null);
        toggleShowRightIcon();
      }}
      selected={previewLayer}
      onClick={previewNotAllowed ? undefined : onPreview}
    >
      <LayerIcon svg>{layer.type === "V" ? "vector" : "raster"}</LayerIcon>
      <LayerLabel bold>{layer.title}</LayerLabel>
      <LayerDate muted>
        {`Created ${moment(layer.created).format("MMMM D, YYYY")}`}
      </LayerDate>

      {layer.alreadySelected ? (
        <LayerIcon muted>check</LayerIcon>
      ) : (
        showRightIcon && (
          <LayerIcon
            onClick={() => {
              if (!layer.alreadySelected) onAdd();
            }}
          >
            add_box
          </LayerIcon>
        )
      )}
    </LayerWrapper>
  );
}

const SearchInput = styled.input`
  width: 100%;
  color: ${(props) => props.theme.colors.text};
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 13px;
  ::placeholder {
    color: ${(props) => props.theme.colors.muted};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: start;
  > *:not(:first-child) {
    margin-left: 16px;
  }
`;

const FilterItem = styled(PanelListItem)`
  font-weight: 600;
`;

const StyledFixedPopup = styled(FixedPopup)`
  top: 181px;
`;

function FilterPopup({ filterToViewport, toggleFilterToViewport }) {
  return (
    <StyledFixedPopup belowZoomControls>
      <PanelSection title="Filters" borderless>
        <FilterItem
          leftIcon={
            <Toggle
              value={filterToViewport}
              onChange={toggleFilterToViewport}
              label="Current View"
            />
          }
        />
      </PanelSection>
    </StyledFixedPopup>
  );
}

function LayerBrowserSection() {
  const { viewport, updateViewport } = useViewport();
  const { projectId } = useSelectedProject();
  const { setExtent } = useContext(HoveredLayerContext);
  const { previewLayer, setPreviewLayer } = useContext(PreviewLayerContext);
  const layersListRef = useRef();
  const inputRef = useRef(null);
  const [showSearch, toggleShowSearch] = useToggle(false);
  const [textFilter, setTextFilter] = useState("");
  const shouldLoadMore = useShouldLoadMore(layersListRef, 300);
  const debouncedTextFilter = useDebouncedValue(textFilter, 200);
  const [createLayerSelection] = useMutation(CREATE_LAYER_SELECTION, {
    refetchQueries: ["getLayerSelections", "getLayersPanelData"],
  });
  const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
  const [filterToViewport, toggleFilterToViewport] = useState(true);

  const debouncedView = useDebouncedValue(viewport, 200);

  const layers = useQuery(GET_LAYERS, {
    variables: {
      filter: {
        text: debouncedTextFilter,
        bbox: filterToViewport
          ? debouncedView.split(",").map(Number)
          : undefined,
      },
      first: 20,
      bbox: undefined,
    },
  });

  const alreadySelected = useQuery(GET_ALREADY_SELECTED_LAYER_SELECTIONS, {
    variables: { scenarioId: projectId },
    skip: !projectId,
  });

  useEffect(() => {
    return () => {
      setExtent(null);
      setPreviewLayer(null);
    };
  }, [setExtent, setPreviewLayer]);

  useEffect(() => {
    if (showSearch && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showSearch]);

  useEffect(() => {
    if (
      shouldLoadMore &&
      !layers.loading &&
      layers.data &&
      layers.data.layers.pageInfo.hasNextPage
    ) {
      async function fetchMore() {
        try {
          await layers.fetchMore({
            variables: {
              after: layers.data.layers.pageInfo.endCursor,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const newEdges = fetchMoreResult.layers.edges;
              const newPageInfo = fetchMoreResult.layers.pageInfo;

              if (newEdges.length > 0) {
                return produce(previousResult, (draft) => {
                  draft.layers.edges.push(...newEdges);
                  draft.layers.pageInfo = newPageInfo;
                });
              } else {
                return previousResult;
              }
            },
          });
        } catch {}
      }
      fetchMore();
    }
  }, [shouldLoadMore, layers.loading, layers]);

  const sectionHeaderProps = showSearch
    ? {
        title: (
          <SearchInput
            ref={inputRef}
            placeholder="Type to search"
            value={textFilter}
            onChange={(e) => {
              setTextFilter(e.target.value);
              sendEvent("layers", "searchBrowserLayer");
            }}
          />
        ),
        icon: (
          <PanelIcon
            muted
            onClick={() => {
              toggleShowSearch();
              setTextFilter("");
            }}
          >
            clear
          </PanelIcon>
        ),
      }
    : {
        title: <span onClick={toggleShowSearch}>Browse Layers</span>,
        icon: (
          <IconWrapper>
            <PanelIcon
              muted
              active={isFilterPopupOpen}
              svg
              onClick={() => setIsFilterPopupOpen(!isFilterPopupOpen)}
            >
              filter
            </PanelIcon>
            <PanelIcon muted svg onClick={toggleShowSearch}>
              search
            </PanelIcon>
          </IconWrapper>
        ),
      };
  let results = [];
  if (layers.data) {
    results = layers.data.layers.edges
      .map((edge) => edge.node)
      .filter(
        (l) => l.type === "R" || (l.type === "V" && l.metadata.geometry_type)
      );
    if (alreadySelected.data) {
      const alreadySelectedIds = alreadySelected.data.scenario.layerSelections.map(
        (ls) => ls.layer.id
      );
      results = results.map((layer) => ({
        ...layer,
        alreadySelected: alreadySelectedIds.includes(layer.id),
      }));
    }
  }

  const handleFilterToViewport = (e) => {
    toggleFilterToViewport(!filterToViewport);
    sendEvent("layers", "toggleFilterToViewport");
  };

  return (
    <LayerBrowserWrapper {...sectionHeaderProps}>
      <LayersList ref={layersListRef}>
        {results.map((layer) => (
          <LayerItem
            key={layer.id}
            layer={layer}
            previewLayer={previewLayer && previewLayer.id === layer.id}
            setExtent={setExtent}
            onPreview={() => {
              setExtent(null);
              if (previewLayer && previewLayer.id === layer.id) {
                setPreviewLayer(null);
              } else {
                setPreviewLayer(layer);
                updateViewport(bbox(layer.extent));
              }
            }}
            onAdd={async () => {
              await createLayerSelection({
                variables: {
                  input: { scenarioId: projectId, layerId: layer.id },
                },
              });
              if (
                layer.extent &&
                !intersects(
                  layer.extent,
                  bboxPolygon(viewport.split(",").map(Number))
                )
              ) {
                updateViewport(bbox(layer.extent));
              }
              setPreviewLayer(null);
              setExtent(null);
            }}
          />
        ))}
      </LayersList>
      {isFilterPopupOpen && (
        <FilterPopup
          filterToViewport={filterToViewport}
          toggleFilterToViewport={(e) => handleFilterToViewport(e)}
        />
      )}
    </LayerBrowserWrapper>
  );
}

export default LayerBrowserSection;
