import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import {
  PanelContainer,
  PanelContent,
  PanelHeader,
  PanelIcon,
  PanelSection,
  PanelListItem,
} from "./layout";

import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import useDebouncedValue from "hooks/useDebouncedValue";
import { uniqBy } from "lodash-es";
import { sendEvent } from "utils/ga";
import { provider, isValidPosition } from "components/Search";
import { validBbox } from "utils/validation";
import { getOverviewBbox } from "utils/geometry";
import { useViewport } from "../URLState";
import useRecentList from "hooks/useRecentList";
import center from "@turf/center";
import bboxPolygon from "@turf/bbox-polygon";
import { SearchResultContext } from "./SearchResultContext";

const SearchInput = styled(ComboboxInput)`
  width: 100%;
  color: ${(props) => props.theme.colors.text};
  background-color: transparent;
  border: none;
  outline: none;
  font-weight: normal;
  ::placeholder {
    color: ${(props) => props.theme.colors.muted};
  }
`;

const Result = styled(ComboboxOption)`
  &[data-reach-combobox-option] {
    font-weight: normal;
    padding: 8px 16px;

    :hover,
    &[data-highlighted],
    &[data-highlighted]:hover {
      background-color: ${(props) => props.theme.colors.lighterBackground};
    }

    [data-user-value="true"] {
      font-weight: 600;
    }
    [data-suggested-value="true"] {
      font-weight: normal;
    }
  }
`;

function parseBounds(b) {
  return [b[0][1], b[0][0], b[1][1], b[1][0]];
}

async function getSearchResults(query) {
  sendEvent("search", "typeSearch", query);

  // If it's a bounding box, return that result
  if (validBbox(query)) {
    return [{ label: query, bounds: query.split(",").map(Number) }];
  }

  // If it's some kind of coordinate, return that
  const position = isValidPosition(query);
  if (position) {
    const latitude = position.getLatitude();
    const longitude = position.getLongitude();
    const bbox = getOverviewBbox({
      type: "Point",
      coordinates: [longitude, latitude],
    });
    return [{ label: query, bounds: bbox }];
  }

  const items = uniqBy(await provider.search({ query }), "label");
  return items.map((item) => ({
    label: item.label,
    bounds: parseBounds(item.bounds),
  }));
}

function useSearch(query) {
  const [results, setResults] = useState([]);

  const debouncedQuery = useDebouncedValue(query, 200);

  useEffect(() => {
    getSearchResults(debouncedQuery).then(setResults);
  }, [debouncedQuery]);

  return results;
}

export function SearchContent() {
  const [query, setQuery] = useState("");
  const results = useSearch(query);
  const recentSearches = useRecentList({
    key: "recentSearches",
    identity: (item) => item.label,
  });
  const { setRearchResultLocation } = useContext(SearchResultContext);

  const { updateViewport } = useViewport();

  function select(result) {
    sendEvent("search", "selectSearchResult", result.label);
    const resultCenter = center(bboxPolygon(result.bounds));
    setRearchResultLocation(resultCenter.geometry.coordinates);
    updateViewport(result.bounds);
    recentSearches.add(result);
  }

  return (
    <Combobox
      onSelect={(value) => {
        const result = results.find((r) => r.label === value);
        select(result);
        setQuery(value);
      }}
    >
      <PanelHeader
        title={
          <SearchInput
            placeholder="Search here..."
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        }
        iconRight={
          <PanelIcon muted onClick={() => setQuery("")} title="Clear search">
            search_off
          </PanelIcon>
        }
      />
      <PanelContent>
        {query ? (
          <PanelSection title={`Results (${results.length})`}>
            <ComboboxList>
              {results.map((result, index) => (
                <Result key={index} value={result.label} />
              ))}
            </ComboboxList>
          </PanelSection>
        ) : (
          recentSearches.items.length > 0 && (
            <PanelSection title="Recent searches">
              {recentSearches.items.map((recentSearch, index) => (
                <PanelListItem
                  key={index}
                  label={recentSearch.label}
                  onClick={() => select(recentSearch)}
                />
              ))}
            </PanelSection>
          )
        )}
      </PanelContent>
    </Combobox>
  );
}

export default function SearchPanel() {
  return (
    <PanelContainer>
      <SearchContent />
    </PanelContainer>
  );
}
