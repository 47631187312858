import {
  Button,
  Fill,
  Footer,
  Header,
  PlaceIcon,
  Section,
  Tag,
  Tags,
  Title,
} from "components/MapPopup";
import Carousel, { ImageSlide } from "components/Carousel";
import React, { useState } from "react";

import Lightbox from "react-image-lightbox";
import download from "downloadjs";
import { includes } from "lodash-es";
import styled from "styled-components/macro";
import tags from "tags";
import rewind from "@mapbox/geojson-rewind";

import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

import MapboxPopup from "../Base/MapboxPopup";
import LoadingScreen from "components/LoadingScreen";

import { sendEvent } from "utils/ga";

const AttachmentWrapper = styled.div`
  background-color: #e2dce7;
`;

const GET_TASK = gql`
  query getTask($taskId: ID!) {
    task(id: $taskId) {
      id
      geometry
      radius
      color
      icon
      tags
      title
      taskName
      attachments {
        id
        image
      }
    }
  }
`;

const Attachments = ({ images, onClick }) => (
  <AttachmentWrapper>
    {images.length > 1 ? (
      <Carousel images={images} onImageClick={onClick} />
    ) : (
      <ImageSlide src={images[0]} alt={"0"} onClick={onClick} />
    )}
  </AttachmentWrapper>
);

const TaskBody = ({ task, downloadFile }) => (
  <>
    <Header>
      <PlaceIcon color={task.color}>place</PlaceIcon>
      <Title>{task.title || "No title"}</Title>
    </Header>
    <Section title="Tasks">
      {!task.taskName ? "No tasks associated with this object." : task.taskName}
    </Section>
    <Section title="Attachments">
      {task.attachments.length === 0
        ? "No attachments."
        : `${task.attachments.length} attachments`}
    </Section>
    <Section title="Tags">
      {task.tags ? (
        <Tags>
          {task.tags.map((tag) => (
            <Tag key={tag}>{tag}</Tag>
          ))}
        </Tags>
      ) : (
        "No tags"
      )}
    </Section>
  </>
);

export default function Popup({
  feature,
  onEdit,
  onView,
  onDelete,
  hasWorkspaceWritePermission,
  coordinates,
  onClose,
}) {
  const [previewIndex, setPreviewIndex] = useState(0);
  const [previewOpen, setPreviewOpen] = useState(false);

  const { data } = useQuery(GET_TASK, { variables: { taskId: feature.id } });

  if (!data) {
    return (
      <MapboxPopup coordinates={coordinates} onClose={onClose}>
        <LoadingScreen />
      </MapboxPopup>
    );
  }

  const task = data.task;

  function downloadFile() {
    const geoJSON = {
      type: "Feature",
      geometry: task.geometry,
      properties: {
        title: task.title || "",
        description: task.description || "",
        color: task.color,
        createdOn: task.created,
        modifiedOn: task.modified,
      },
    };
    download(
      JSON.stringify(rewind(geoJSON)),
      `${task.title || task.id}.geojson`,
      "application/geo+json"
    );
    sendEvent("taskPopup", "exportAsGeoJSONFile");
  }

  const images = task.attachments.map((at) => at.image);
  const isPhoto = includes(task.tags, tags.GEOTAGGED_PHOTO);

  return (
    <MapboxPopup coordinates={coordinates} onClose={onClose}>
      {previewOpen && (
        <Lightbox
          mainSrc={images[previewIndex]}
          nextSrc={images[(previewIndex + 1) % images.length]}
          prevSrc={images[(previewIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setPreviewOpen(false)}
          onMovePrevRequest={() =>
            setPreviewIndex((previewIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPreviewIndex((previewIndex + 1) % images.length)
          }
        />
      )}
      {isPhoto ? (
        task.attachments.length > 0 ? (
          <Attachments
            images={images}
            onClick={(i) => {
              setPreviewOpen(true);
              setPreviewIndex(0);
            }}
          />
        ) : (
          <Section>{"No photos"}</Section>
        )
      ) : (
        <TaskBody task={task} />
      )}
      <Footer>
        {hasWorkspaceWritePermission && (
          <Button
            color="red"
            onClick={(e) => {
              onDelete(e);
              sendEvent("taskPopup", "deleteTask");
            }}
          >
            Delete
          </Button>
        )}
        <Fill />
        <Button title="Export as KML file" onClick={downloadFile}>
          Export
        </Button>
        {hasWorkspaceWritePermission && (
          <Button
            onClick={(e) => {
              onEdit(e);
              sendEvent("taskPopup", "adjustTask");
            }}
          >
            Adjust
          </Button>
        )}
        <Button
          onClick={(e) => {
            onView(e);
            sendEvent("taskPopup", "viewTaskDetails");
          }}
        >
          Details
        </Button>
      </Footer>
    </MapboxPopup>
  );
}
