import React, { useContext, useState, useMemo } from "react";
import RTSnackbar from "react-toolbox/lib/snackbar/Snackbar";

const SnackbarContext = React.createContext();

export const useSnackbar = () => useContext(SnackbarContext);

export function SnackbarProvider({ children }) {
  const [text, setText] = useState(null);

  const closeSnackbar = () => setText(null);

  const value = useMemo(
    () => ({
      text,
      showSnackbar: setText,
      closeSnackbar,
    }),
    [text]
  );

  return (
    <SnackbarContext.Provider value={value}>
      {children}
    </SnackbarContext.Provider>
  );
}

export function Snackbar() {
  const { text, closeSnackbar } = useContext(SnackbarContext);
  return (
    <RTSnackbar
      action="Dismiss"
      active={Boolean(text)}
      label={text}
      timeout={3000}
      onClick={closeSnackbar}
      onTimeout={closeSnackbar}
      type="cancel"
    />
  );
}
