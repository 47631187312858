import React, { useState } from "react";
import styled from "styled-components/macro";
import Icon from "components/Icon";
import { get } from "lodash-es";
import { dynamicStylingItems } from "./layers";

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ColorThumb = styled.div`
  width: 15px;
  height: 15px;
  margin-right: 1em;
  background-color: ${(props) => props.color};
`;

function LegendItem({ property, layerSelectionStyling }) {
  return (
    <div>
      {dynamicStylingItems(property, layerSelectionStyling).map((item, i) => (
        <ItemWrapper key={i}>
          <ColorThumb color={item.color} />
          <span>{item.label}</span>
        </ItemWrapper>
      ))}
    </div>
  );
}

const Wrapper = styled.div`
  z-index: 401;
  width: 300px;
  padding: 0.85em;
  position: absolute;
  bottom: 2em;
  right: 1em;
  background-color: ${(props) => props.theme.colors.darkerBackground};
  max-height: 50%;
  overflow-y: auto;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
  font-size: 0.85em;
  border-radius: 4px;
  color: ${(props) => props.theme.colors.text};
`;

const HeaderWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  > *:not(:last-child) {
    margin-right: 1em;
  }
`;

const LayerLegendWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 1em;
  }
`;

const LayerName = styled.div`
  word-break: break-word;
  font-weight: 600;
`;

const StyledBy = styled.div`
  margin-left: auto;
`;

const ExpandedIndicator = ({ expanded }) => (
  <Icon size={20}>
    {expanded ? "keyboard_arrow_down" : "keyboard_arrow_left"}
  </Icon>
);

function LayerLegend({ layerSelection }) {
  const [expanded, setExpanded] = useState(true);
  const { layer } = layerSelection;

  return (
    <LayerLegendWrapper data-legend-item={layer.title}>
      <HeaderWrapper onClick={() => setExpanded(!expanded)}>
        <LayerName>{layer.title}</LayerName>
        {"styling" in layerSelection.metadata && (
          <StyledBy>{layerSelection.metadata.styling.property}</StyledBy>
        )}
        <ExpandedIndicator expanded={expanded} />
      </HeaderWrapper>
      {"styling" in layerSelection.metadata && expanded && (
        <LegendItem
          property={layer.metadata.styling.properties.find(
            (p) => p.name === layerSelection.metadata.styling.property
          )}
          layerSelectionStyling={layerSelection.metadata.styling}
        />
      )}
    </LayerLegendWrapper>
  );
}

function Legend({ layerSelections }) {
  const dynamicallyStyledLayerSelections = layerSelections.filter(
    (layerSelection) => get(layerSelection.metadata, "styling.property")
  );

  if (dynamicallyStyledLayerSelections.length === 0) {
    return null;
  }

  return (
    <Wrapper id={"map-legend-wrapper"}>
      {dynamicallyStyledLayerSelections.map((l) => (
        <LayerLegend key={l.id} layerSelection={l} />
      ))}
    </Wrapper>
  );
}

export function LayersLegend({ layerSelections }) {
  return (
    <Wrapper>
      {layerSelections.map(({ metadata, layer }, i) => (
        <ItemWrapper key={i}>
          <ColorThumb color={metadata.styling.base.fillColor} />
          <span>{layer.title}</span>
        </ItemWrapper>
      ))}
    </Wrapper>
  );
}

export default Legend;
