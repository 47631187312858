import React, { useState } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { useTasksMutations } from "hooks/mutations/useTasksMutations";
import { useTasks } from "hooks/useTasks";
import { useForm } from "utils/form";
import {
  PanelContainer,
  PanelContent,
  PanelHeader,
  PanelSection,
  PanelSectionContainer,
  PanelTextSection,
  PanelIcon,
  PanelButton,
} from "./layout";
import ScreenMessage from "../components/ScreenMessage";
import Spinner from "../components/Spinner";
import { Field, TextInput, TextAreaInput } from "../components/Input";
import { UploadSection } from "../components/UploadSection";
import Attachments from "../components/Attachments";
import Tags, { TagItem } from "../components/Tags";
import { useSelectedProject, useBaseUrl } from "./../URLState";
import { Menu, MenuItem } from "components/MoreMenu";
import { sendEvent } from "utils/ga";
import download from "downloadjs";
import rewind from "@mapbox/geojson-rewind";
import { RestrictedByWorkspacePermission } from "utils/Restricted";

const GET_TASK = gql`
  query getTask($id: ID!) {
    task(id: $id) {
      id
      geometry
      radius
      status
      title
      description
      color
      tags
      created
      modified
      attachments {
        id
        name
        image
        thumbnailLarge
      }
    }
  }
`;

const GET_TASKS = gql`
  query getTasks($scenarioId: ID!) {
    scenario(id: $scenarioId) {
      id
      tasks {
        edges {
          node {
            id
            tags
          }
        }
      }
    }
    me {
      id
    }
  }
`;

const useAnnotation = (id) => {
  const { data } = useQuery(GET_TASK, {
    variables: { id },
  });

  return data?.task;
};

function AnnotationPanel({ placeholderTitle, initialStatus = "viewing" }) {
  const history = useHistory();
  const baseUrl = useBaseUrl();
  const { id: annotationId } = useParams();
  const annotation = useAnnotation(annotationId);
  const { setEditingFeature } = useTasks();

  const [status, setStatus] = useState(initialStatus);
  const isViewing = status === "viewing";
  const {
    updateTask,
    addAttachment,
    removeAttachment,
    deleteTask,
  } = useTasksMutations();
  const { fields, onSubmit } = useForm(
    {
      fields: ["title", "description", "attachments", "tags"],
      initialValues: {
        title: annotation?.title,
        description: annotation?.description,
        tags: annotation?.tags,
      },
      onSubmit: async ({ title, description, attachments, tags }) => {
        setStatus("saving");

        await updateTask(annotationId, {
          title,
          description,
          tags,
        });

        if (attachments.length > 0) {
          for (let file of attachments) {
            await addAttachment(annotationId, file);
          }
        }

        setStatus("viewing");
      },
    },
    [annotation, isViewing]
  );

  const panelTitle = annotation?.title
    ? annotation?.title
    : placeholderTitle || "No name";

  const BackButton = (
    <PanelIcon
      muted
      onClick={(e) => {
        if (isViewing) {
          history.push(`${baseUrl}/projects/`);
        } else {
          setStatus("viewing");
        }
      }}
    >
      chevron_left
    </PanelIcon>
  );

  const SaveButton = (
    <PanelButton
      onClick={onSubmit}
      loading={status === "saving"}
      loadingText="Saving"
    >
      Save
    </PanelButton>
  );

  const ViewingSection = (
    <>
      <PanelSection title="Annotation name">
        {annotation?.title && (
          <PanelTextSection>{annotation.title || "No name"}</PanelTextSection>
        )}
      </PanelSection>
      <PanelSection title="Annotation description">
        {annotation?.description && (
          <PanelTextSection>{annotation.description}</PanelTextSection>
        )}
      </PanelSection>
      <PanelSection title="Attachments">
        {annotation?.attachments.length > 0 && (
          <PanelSectionContainer>
            <Attachments files={annotation.attachments} />
          </PanelSectionContainer>
        )}
      </PanelSection>
      <PanelSection title="Tags">
        {annotation?.tags.length > 0 && (
          <PanelSectionContainer>
            {annotation.tags.map((tag) => (
              <TagItem>{tag}</TagItem>
            ))}
          </PanelSectionContainer>
        )}
      </PanelSection>
    </>
  );

  const { projectId } = useSelectedProject();
  const { data } = useQuery(GET_TASKS, {
    variables: { scenarioId: projectId },
    skip: !projectId,
  });
  const tasks = data?.scenario.tasks.edges.map((e) => e.node) ?? [];
  const tagsOptions = [...new Set(tasks.flatMap((t) => t.tags))];

  const EditingSection = (
    <>
      <PanelSection>
        <PanelSectionContainer>
          <Field label="Annotation name">
            <TextInput placeholder="Annotation name" {...fields.title} />
          </Field>
        </PanelSectionContainer>
      </PanelSection>
      <PanelSection>
        <PanelSectionContainer>
          <Field label="Annotation description">
            <TextAreaInput
              placeholder="Annotation description"
              {...fields.description}
            ></TextAreaInput>
          </Field>
        </PanelSectionContainer>
      </PanelSection>
      <PanelSection>
        <PanelSectionContainer>
          <Field label="Attachments">
            <UploadSection multiple {...fields.attachments} />
            <Attachments
              files={annotation?.attachments}
              onDelete={(id) => {
                if (window.confirm("Delete attachment. Are you sure?")) {
                  removeAttachment(id);
                }
              }}
            />
          </Field>
        </PanelSectionContainer>
      </PanelSection>
      <PanelSection>
        <PanelSectionContainer>
          <Field label="Tags">
            <Tags {...fields.tags} options={tagsOptions} />
          </Field>
        </PanelSectionContainer>
      </PanelSection>
    </>
  );

  const LoadingSection = (
    <ScreenMessage>
      <Spinner />
    </ScreenMessage>
  );

  function downloadFile() {
    const geoJSON = {
      type: "Feature",
      geometry: annotation.geometry,
      properties: {
        title: annotation.title || "",
        description: annotation.description || "",
        color: annotation.color,
        createdOn: annotation.created,
        modifiedOn: annotation.modified,
      },
    };
    download(
      JSON.stringify(rewind(geoJSON)),
      `${annotation.title || annotation.id}.geojson`,
      "application/geo+json"
    );
    sendEvent("annotation", "exportAsGeoJSONFile");
  }

  const PanelHeaderIconRight = (
    <Menu>
      <MenuItem onSelect={() => setStatus("editing")}>Edit</MenuItem>
      <MenuItem onSelect={downloadFile}>Export</MenuItem>
      <RestrictedByWorkspacePermission type="rw">
        <MenuItem
          onClick={(e) => {
            setEditingFeature({
              type: "Feature",
              geometry: annotation.geometry,
              id: annotation.id,
              properties: {
                radius: annotation.radius,
              },
            });
            sendEvent("annotation", "adjust");
          }}
        >
          Adjust
        </MenuItem>
        <MenuItem
          onSelect={() => {
            history.push(`${baseUrl}/projects/`);
            deleteTask(annotationId);
            sendEvent("annotation", "deleteAnnotation");
          }}
        >
          Delete
        </MenuItem>
      </RestrictedByWorkspacePermission>
    </Menu>
  );

  return (
    <PanelContainer>
      <PanelHeader
        title={panelTitle}
        iconLeft={BackButton}
        iconRight={isViewing ? PanelHeaderIconRight : SaveButton}
      />
      <PanelContent>
        {annotation
          ? isViewing
            ? ViewingSection
            : EditingSection
          : LoadingSection}
      </PanelContent>
    </PanelContainer>
  );
}

export default AnnotationPanel;
