import React from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import Modal from "./Modal";
import { useForm } from "utils/form";
import styled from "styled-components";
import Button from "./Button";
import { TextareaInput } from "./TextInput";
import * as v from "utils/validation";
import LoadingScreen from "./LoadingScreen";

const Container = styled.form`
  width: 400px;
  display: flex;
  flex-direction: column;
  padding: 1em;
`;

const BodyInput = styled(TextareaInput)`
  height: 200px;
`;

const SubmitButton = styled(Button)`
  align-self: flex-end;
`;

const Success = styled.div`
  text-align: center;
  width: 100%;
  padding: 3em;
`;

const SEND_BUG_REPORT = gql`
  mutation sendBugReport($input: EmailSupportInput!) {
    emailSupport(input: $input) {
      success
    }
  }
`;

export default function BugReportModal({ onClose }) {
  const [sendBugReport] = useMutation(SEND_BUG_REPORT);
  const { fields, onSubmit, loading, success } = useForm({
    fields: ["body"],
    validate: v.createValidator({ body: v.required }),
    onSubmit: ({ body }) =>
      sendBugReport({
        variables: { input: { subject: "Bug report", body } },
      }),
  });

  return (
    <Modal isOpen={true} title="Report bug" onClose={onClose} size="flex">
      <Container onSubmit={onSubmit}>
        {loading ? (
          <LoadingScreen />
        ) : success ? (
          <Success>Thank you!</Success>
        ) : (
          <>
            <BodyInput placeholder="What happened?" {...fields.body} />
            <SubmitButton primary type="submit">
              Submit
            </SubmitButton>
          </>
        )}
      </Container>
    </Modal>
  );
}
