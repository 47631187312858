function send(...args) {
  process.env.NODE_ENV === "production" && window.ga(...args);
}

export function createTracker() {
  send("create", process.env.REACT_APP_GA_CODE, "auto");
}

export function sendPageView(page) {
  send("send", "pageview", page);
}

export function sendEvent(...args) {
  send("send", "event", ...args);
}
