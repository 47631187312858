import styled from "styled-components/macro";
import * as CB from "@reach/combobox";
import "@reach/combobox/styles.css";

export const ComboboxWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 4px;

  :focus-within {
    border-color: ${({ theme }) => theme.colors.text};
    background: ${({ theme }) => theme.colors.darkerBackground};
  }
`;

export const Combobox = styled(CB.Combobox)`
  width: 100%;
`;

export const ComboboxInput = styled(CB.ComboboxInput)`
  &[data-reach-combobox-input] {
    width: 100%;
    padding: 7px;
    background-color: transparent;
    border: 0;
    outline: none;
    color: ${({ theme }) => theme.colors.text};
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

export const ComboboxPopover = styled(CB.ComboboxPopover)`
  &[data-reach-combobox-popover] {
    z-index: 9;
    margin-top: ${({ portal }) => (portal ? "0px" : "7px")};
    padding-top: ${({ portal }) => (portal ? "7px" : 0)};
    font-size: 100%;
    background: ${({ theme }) => theme.colors.darkerBackground};
    color: ${({ theme }) => theme.colors.text};
    border: ${({ theme, portal }) =>
      portal ? `solid 1px ${theme.colors.text}` : 0};
    border-top: 0;
    border-radius: 0 0 4px 4px;
  }
`;

export const ComboboxOption = styled(CB.ComboboxOption)`
  &[data-reach-combobox-option] {
    padding: 7px;
  }

  &[data-reach-combobox-option]:hover,
  &[data-reach-combobox-option][aria-selected="true"] {
    background: ${({ theme }) => theme.colors.lighterBackground};
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
  }
`;

export const ComboboxList = styled(CB.ComboboxList)``;
