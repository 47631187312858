import React from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { useForm } from "utils/form";
import styled from "styled-components";
import * as v from "utils/validation";
import {
  PanelContainer,
  PanelContent,
  PanelSection,
  PanelHeader,
  PanelButton
} from "./layout";
import { TextAreaInput, Field, TextInput } from "../components/Input";
import { Select } from "../components/ListBox";
import { sendEvent } from "utils/ga";
import ScreenMessage from "../components/ScreenMessage";
import LoadingScreen from "components/LoadingScreen";

const FeedbackContainer = styled.div`
  padding: 16px;
`;

export const StyledScreenMessage = styled(ScreenMessage)`
  padding: 3em;
  background-color: ${props => props.theme.colors.background};
`;

export const SEND_FEEDBACK = gql`
  mutation sendBugReport($input: EmailSupportInput!) {
    emailSupport(input: $input) {
      success
    }
  }
`;

const InfoWrapper = styled.div`
  color: ${props => props.theme.colors.muted};
  display: flex;
  flex-direction: column;
  > div:not(:last-child) {
    padding-bottom: 8px;
  }
  > div > a {
    text-decoration: none;
    color: ${props => props.theme.colors.primary};
  }
`;

const HelpInfoWrapper = styled.div`
  padding: 0 16px;
  margin: 0;
`;

export const HelpInfo = () => (
  <InfoWrapper>
    <div>Use this box to get technical help or to submit feedback.</div>
    <div>
      To update the resource database, please email{" "}
      <a href="mailto:resourcemailinglist@211la.org">
        resourcemailinglist@211la.org
      </a>
      .
    </div>
    <div>For help finding the right resource for you, please call 2-1-1.</div>
  </InfoWrapper>
);

export default function SendFeedbackPanel() {
  const [sendBugReport] = useMutation(SEND_FEEDBACK);
  const { fields, success, isValid, onSubmit, loading } = useForm({
    fields: ["issue", "feedback", "email"],
    initialValues: { issue: "sn" },
    validate: v.createValidator({
      feedback: v.required,
      issue: v.required,
      email: [v.required, v.email]
    }),
    onSubmit: async ({ issue, feedback, email }) => {
      await sendBugReport({
        variables: {
          input: { subject: `Feedback: ${issue}`, body: feedback, email }
        }
      });
      sendEvent("support", "sendSupport", issue, feedback);
    }
  });
  const supportOptions = [
    { label: "Support Needed", value: "sn" },
    { label: "Report Bug", value: "rb" }
  ];

  if (loading) {
    return (
      <PanelContainer>
        <LoadingScreen />
      </PanelContainer>
    );
  }

  return (
    <PanelContainer>
      <PanelHeader
        title={"Submit feedback"}
        iconRight={
          success ? null : (
            <PanelButton disabled={!isValid} onClick={onSubmit}>
              Submit
            </PanelButton>
          )
        }
      />
      <PanelContent>
        <PanelSection>
          <HelpInfoWrapper>
            <HelpInfo />
          </HelpInfoWrapper>
        </PanelSection>
        <FeedbackContainer>
          {success ? (
            <StyledScreenMessage>Thank you!</StyledScreenMessage>
          ) : (
            <>
              <Field label="Issue">
                <Select options={supportOptions} {...fields.issue} />
              </Field>
              <Field label="Your email address">
                <TextInput type="email" {...fields.email} />
              </Field>
              <Field label="Feedback">
                <TextAreaInput
                  type="text"
                  placeholder="Your feedback"
                  {...fields.feedback}
                />
              </Field>
            </>
          )}
        </FeedbackContainer>
      </PanelContent>
    </PanelContainer>
  );
}
