import * as v from "utils/validation";

import React, { useCallback, useEffect, useState } from "react";

import produce from "immer";
import wrapDisplayName from "recompose/wrapDisplayName";
import fromEntries from "object.fromentries";

export function useForm(
  {
    initialValues = {},
    fields,
    onFieldChange,
    validate = () => ({}),
    onSubmit,
    onSuccess,
  },
  initialValuesDeps = []
) {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fullInitialValues = { ...initialValues };
    for (let field of fields) {
      if (typeof fullInitialValues[field] === "undefined") {
        fullInitialValues[field] = "";
      }
    }
    setValues(fullInitialValues);
    setTouched(fromEntries(fields.map((f) => [f, false])));

    // We let consumers of this hook specify the initialValues dependencies,
    // so we can reset the form when the initialValues changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, initialValuesDeps);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setSuccess(false);

      // Get the object with errors, if any
      const errors = validate(values);
      setErrors(errors);

      // If the form is valid, call the onSubmit function
      if (v.isValid(errors)) {
        // Set loading
        setLoading(true);

        try {
          await onSubmit(values);
          setLoading(false);
          setSuccess(true);
          onSuccess && onSuccess();
        } catch (e) {
          if (e.response) {
            setErrors(await e.response.json());
          } else {
            setErrors({ non_field_error: e.message });
          }
        }
      }
    },
    [onSubmit, validate, values, onSuccess]
  );

  const updateValue = useCallback(
    (field, value) => {
      if (onFieldChange) {
        onFieldChange(field, value);
      }
      setValues(
        produce((values) => {
          values[field] = value;
        })
      );
      setTouched(
        produce((touched) => {
          touched[field] = true;
        })
      );
      setSuccess(false);
      setLoading(false);
    },
    [onFieldChange]
  );

  const fieldsToPass = {};
  for (let field of fields) {
    fieldsToPass[field] = {
      value: values[field],
      errorText: errors[field],
      touched: touched[field],
      onChange: (e) => updateValue(field, e.target ? e.target.value : e),
    };
  }

  let isValid;
  try {
    isValid = v.isValid(validate(values));
  } catch (e) {
    isValid = false;
  }

  return {
    onSubmit: handleSubmit,
    fields: fieldsToPass,
    errors,
    success,
    loading,
    isValid,
    touched: Object.values(touched).some(Boolean),
  };
}

export function FormProvider({ onSubmit, children, initialValues, ...props }) {
  const form = useForm({
    onSubmit: (values) => onSubmit(values, props),
    initialValues:
      typeof initialValues === "function"
        ? initialValues(props)
        : initialValues,
    ...props,
  });
  return children(form);
}

export const withForm = (params) => (WrappedComponent) => {
  const ConnectedComponent = (props) => (
    <FormProvider {...params} {...props}>
      {(formProps) => <WrappedComponent {...formProps} {...props} />}
    </FormProvider>
  );

  ConnectedComponent.displayName = wrapDisplayName(
    WrappedComponent,
    "withForm"
  );
  return ConnectedComponent;
};
