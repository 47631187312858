import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useBaseUrl } from "./../URLState";
import {
  PanelContainer,
  PanelContent,
  PanelHeader,
  PanelSectionContainer,
  PanelSection,
  PanelIcon,
  PanelButton,
  PanelListItemLabel,
} from "./layout";
import UsersSelect from "../components/UsersSelect";
import Spinner from "../components/Spinner";
import ScreenMessage from "../components/ScreenMessage";
import { Field } from "../components/Input";
import { Select } from "../components/ListBox";
import styled, { css } from "styled-components/macro";
import ProfilePic from "../../../../components/ProfilePic";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { useSelectedProject } from "../URLState";
import { useMutation } from "@apollo/react-hooks";
import { useForm } from "utils/form";
import { sendEvent } from "utils/ga";

const SharedProfilePic = styled(ProfilePic)`
  align-self: center;
  margin-right: 9px;
  grid-area: left-icon;
`;

const SharedOptions = styled.div`
  align-self: center;
  margin-right: 9px;
  grid-area: right-icon;
  cursor: pointer;
`;

const SharedList = styled(PanelSectionContainer)`
  flex: 1;
  overflow-y: auto;
  padding: 0;
`;

const SharedWrapper = styled.div`
  user-select: none;
  cursor: pointer;
  display: grid;
  grid-template-columns: min-content auto min-content;
  grid-template-rows: min-content auto min-content;
  grid-template-areas:
    "left-icon title right-icon"
    "left-icon date right-icon";
  padding-bottom: 16px;
  ${(props) =>
    props.selected &&
    css`
      background-color: ${(props) => props.theme.colors.lighterBackground};
    `}
`;

const GET_PROJECT_SCENARIO = gql`
  query getProjectScenario($projectId: ID!) {
    scenario(id: $projectId) {
      id
      workspace {
        id
        owner {
          __typename
          ... on Company {
            id
            name
            slug
            picture
          }
        }
        sharedWith {
          edges {
            type
            node {
              id
              user {
                id
                email
                firstName
                lastName
                customer {
                  id
                  picture
                }
              }
            }
          }
        }
      }
    }
  }
`;

const SHARE_WORKSPACE_WITH = gql`
  mutation shareWorkspaceWith($input: ShareWorkspaceWithInput!) {
    shareWorkspaceWith(input: $input) {
      workspace {
        id
      }
    }
  }
`;

export const GET_CUSTOMERS = gql`
  query getCustomers($companyId: ID, $includeIsMemberOfCompany: Boolean!) {
    customers {
      id
      picture
      user {
        id
        username
        firstName
        lastName
        email
      }
      isMemberOfCompany(companyId: $companyId)
        @include(if: $includeIsMemberOfCompany)
    }
    me {
      id
    }
  }
`;

function CompanyUser({ owner: { name, slug, picture } }) {
  return (
    <SharedWrapper>
      <SharedProfilePic src={picture} border={false} size={16} />
      <PanelListItemLabel bold>{name || slug}</PanelListItemLabel>
    </SharedWrapper>
  );
}

function SharedUser({ user, setPermission, permissionType }) {
  const { firstName, lastName, email } = user;
  const customerId = user.customer.id;
  const imageSrc = user.customer.picture;

  const [permissionValue, setPermissionValue] = useState(permissionType);
  return (
    <SharedWrapper>
      <SharedProfilePic src={imageSrc} border={false} size={16} />
      <PanelListItemLabel bold>
        {firstName} {lastName}
      </PanelListItemLabel>
      <PanelListItemLabel muted>{email}</PanelListItemLabel>
      <SharedOptions>
        <Select
          value={permissionValue}
          options={[
            { label: "Can view", value: "ro" },
            { label: "Can edit", value: "rw" },
            { label: "Admin", value: "ad" },
            { label: "Remove", value: "rm" },
          ]}
          onChange={(permissionType) => {
            setPermissionValue(permissionType);
            setPermission({ customerId, permissionType });
            sendEvent(
              "sharingSettingsModal",
              "shareWith",
              permissionType,
              customerId
            );
          }}
          small
        />
      </SharedOptions>
    </SharedWrapper>
  );
}

function useWorkspaceMutations() {
  const [shareWorkspaceWithMutation] = useMutation(SHARE_WORKSPACE_WITH, {
    refetchQueries: ["getProjectScenario"],
  });
  return {
    shareWorkspaceWith: ({ id, customerId, permission }) => {
      shareWorkspaceWithMutation({
        variables: { input: { id, customerId, permission } },
      });
    },
  };
}

const useWorkspace = (projectId) => {
  const { data } = useQuery(GET_PROJECT_SCENARIO, {
    variables: { projectId },
  });

  return data?.scenario.workspace;
};

const useCustomers = (companyWorkspace, excludeIds = []) => {
  const { data } = useQuery(GET_CUSTOMERS, {
    variables: {
      companyId: companyWorkspace,
      includeIsMemberOfCompany: Boolean(companyWorkspace),
    },
  });

  return data?.customers.filter(
    (customer) => ![data?.me.id, ...excludeIds].some((id) => customer.id === id)
  );
};

function ShareProjectPanel() {
  const history = useHistory();
  const baseUrl = useBaseUrl();
  const { projectId } = useSelectedProject();

  const workspace = useWorkspace(projectId);

  const sharedUsers = workspace?.sharedWith.edges;
  const { shareWorkspaceWith } = useWorkspaceMutations();
  const setPermission = async ({ customerId, permissionType }) => {
    shareWorkspaceWith({
      id: workspace.id,
      customerId: customerId,
      permission: permissionType === "rm" ? null : permissionType,
    });
  };

  const customers = useCustomers(
    workspace?.owner.__typename === "Company" ? workspace?.company?.id : null,
    sharedUsers?.map(({ node: { id } }) => id)
  );

  const { fields, onSubmit } = useForm({
    fields: ["users", "permission"],
    initialValues: { users: [], permission: "ro" },
    onSubmit: ({ users, permission }) => {
      for (let user of users) {
        shareWorkspaceWith({
          id: workspace.id,
          customerId: user,
          permission,
        });
      }
    },
  });

  const isSaveEnabled = fields.users.value?.length > 0;
  const owner = workspace?.owner;

  const panelHeaderIconLeft = (
    <PanelIcon
      data-test="go-back"
      muted
      onClick={() => {
        history.push(`${baseUrl}/project`);
      }}
    >
      chevron_left
    </PanelIcon>
  );

  const panelHeaderIconRight = (
    <PanelButton disabled={!isSaveEnabled} onClick={onSubmit}>
      Share
    </PanelButton>
  );

  return (
    <PanelContainer data-test="share-project-panel">
      <PanelHeader
        title="Share project"
        iconLeft={panelHeaderIconLeft}
        iconRight={panelHeaderIconRight}
      />
      <PanelContent>
        <PanelSection title="Invite people">
          <PanelSectionContainer>
            <Field label="People">
              <UsersSelect data={customers} {...fields.users} />
            </Field>
            <Field label="Role">
              <Select
                value={"ro"}
                options={[
                  { label: "Can view", value: "ro" },
                  { label: "Can view/edit", value: "rw" },
                  { label: "Admin", value: "ad" },
                ]}
                {...fields.permission}
              />
            </Field>
          </PanelSectionContainer>
        </PanelSection>
        <PanelSection borderless title="Who has access">
          <SharedList>
            {owner && owner.__typename === "Company" && (
              <CompanyUser owner={owner} />
            )}
            {!sharedUsers ? (
              <ScreenMessage>
                <Spinner />
              </ScreenMessage>
            ) : sharedUsers.length ? (
              sharedUsers &&
              sharedUsers.map((edge) => (
                <SharedUser
                  key={edge.node.user.id}
                  user={edge.node.user}
                  setPermission={setPermission}
                  permissionType={edge.type}
                />
              ))
            ) : (
              <ScreenMessage>
                This workspace is not shared with anyone yet
              </ScreenMessage>
            )}
          </SharedList>
        </PanelSection>
      </PanelContent>
    </PanelContainer>
  );
}

export default ShareProjectPanel;
