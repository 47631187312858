import Icon from "components/Icon";
import RModal from "react-modal";
import React from "react";
import styled from "styled-components/macro";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const HeaderWrapper = styled.div`
  padding: 1em;
  border-bottom: 1px solid #eee;
  display: flex;
`;

const HeaderTitle = styled.div`
  flex: 1;
  font-size: 1.1em;
  font-weight: 600;
`;

const Wrapper = styled.div`
  flex: 1;
  min-height: 0;
`;

const CloseIcon = styled(Icon).attrs({ size: 20, color: "#888" })`
  cursor: pointer;
`;

const HeaderChildrenWrapper = styled.div`
  padding-right: 1em;
`;

const Header = ({ title, children, onClose }) => (
  <HeaderWrapper>
    <HeaderTitle>{title}</HeaderTitle>
    <HeaderChildrenWrapper>{children}</HeaderChildrenWrapper>
    <CloseIcon onClick={onClose}>close</CloseIcon>
  </HeaderWrapper>
);

const Content = styled.div`
  padding: 1em;
  flex: 1;
  overflow-y: auto;
`;

const Footer = styled.div`
  border-top: 1px solid #eee;
  padding: 1em;
  display: flex;
  align-items: flex-end;
`;

RModal.setAppElement("#root");

const sizes = {
  fullSize: {
    overlay: {
      display: "flex",
    },
    content: {
      width: "calc(100% - 3em)",
      height: "calc(100% - 6em)",
      margin: "auto",
    },
  },
  standard: {
    overlay: { display: "flex" },
    content: {
      width: 800,
      height: "calc(100% - 8em)",
      margin: "auto",
    },
  },
  dialog: {
    overlay: { display: "flex" },
    content: {
      margin: "auto",
      width: 500,
      height: 500,
    },
  },
  flex: {
    overlay: { display: "flex" },
    content: {
      margin: "auto",
      width: "auto",
      height: "auto",
    },
  },
};

const Modal = ({
  children,
  headerChildren,
  isOpen,
  size = "fullSize",
  title,
  onClose,
  contentStyle = {},
}) => (
  <RModal
    isOpen={isOpen}
    onRequestClose={onClose}
    closeTimeoutMS={0}
    style={{
      overlay: {
        zIndex: 2,
        display: "block",
        backgroundColor: "rgba(0,0,0,0.5)",
        ...sizes[size].overlay,
      },
      content: {
        position: "relative",

        // Override react-modal's defaults
        padding: 0,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,

        display: "flex",
        flexDirection: "column",
        ...sizes[size].content,
        ...contentStyle,
      },
    }}
    contentLabel="Modal"
  >
    <Header onClose={onClose} title={title}>
      {headerChildren}
    </Header>
    <Wrapper>{children}</Wrapper>
  </RModal>
);

export const Layout = {
  Container,
  Content,
  Footer,
};

export default Modal;
