import React, { useState } from "react";
import styled from "styled-components";

const oposite = (position) =>
  ({
    left: "right",
    right: "left",
    bottom: "top",
    top: "bottom",
  }[position]);

const direction = (position) =>
  ({
    left: "horizontal",
    right: "horizontal",
    bottom: "vertical",
    top: "vertical",
  }[position]);

const TooltipBase = styled.div`
  font-size: 0.9em;
  font-weight: 300;
  white-space: nowrap;
  z-index: 2;
  background-color: rgba(30, 30, 30, 0.95);
  padding: 0.8em 1.2em;
  border-radius: 5px;
  position: absolute;
  ${(props) => oposite(props.position)}: calc(100% + 15px);
  color: white;

  ::after {
    content: "";
    width: 0;
    height: 0;
    border-${(props) =>
      direction(props.position) === "horizontal"
        ? "top"
        : "left"}: 8px solid transparent;
    border-${(props) =>
      direction(props.position) === "horizontal"
        ? "bottom"
        : "right"}: 8px solid transparent;
    border-${(props) => props.position}: 8px solid rgba(30, 30, 30, 0.95);
    ${(props) => oposite(props.position)}: -8px;
    position: absolute;
  }
`;

function Tooltip({ content, children, position = "right", delay = 0 }) {
  const [visible, setVisible] = useState(false);
  let targetElement = React.Children.toArray(children)[0];
  targetElement = React.cloneElement(
    targetElement,
    {
      style: { position: "relative" },
      onMouseEnter: () => setVisible(true),
      onMouseLeave: () => setTimeout(() => setVisible(false), delay),
    },
    visible ? (
      <>
        {targetElement.props.children}
        <TooltipBase position={position}>{content}</TooltipBase>
      </>
    ) : (
      <>{targetElement.props.children}</>
    )
  );
  return targetElement;
}

export default Tooltip;
