import React from "react";
import { remove } from "lodash-es";
import { OptionWrapper, Subtitle, Title } from "utils/SelectOption";
import Select from "react-select";
import styled from "styled-components/macro";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

export const getName = (user) =>
  user.firstName && user.lastName
    ? `${user.firstName} ${user.lastName}`
    : user.username;

const SelectInput = styled(Select)`
  flex: 1;
`;

const customerToValue = (customer) => ({
  label: customer.user.email || customer.user.username,
  value: customer.id,
  username: getName(customer.user),
  isDisabled: customer.isMemberOfCompany,
});

const Option = (props) => {
  const { innerProps, innerRef, data } = props;
  return (
    <OptionWrapper
      ref={innerRef}
      {...innerProps}
      className="custom-option"
      disabled={data.isDisabled}
      title={data.isDisabled ? "User already has access" : null}
    >
      <Title>{data.username}</Title>
      <Subtitle>{data.label}</Subtitle>
    </OptionWrapper>
  );
};

export const GET_CUSTOMERS = gql`
  query getCustomers($companyId: ID, $includeIsMemberOfCompany: Boolean!) {
    customers {
      id
      picture
      user {
        id
        username
        firstName
        lastName
        email
      }
      isMemberOfCompany(companyId: $companyId)
        @include(if: $includeIsMemberOfCompany)
    }
    me {
      id
    }
  }
`;

function UserSelect({
  value,
  onChange,
  withoutMe,
  menuPlacement,
  companyWorkspace,
  ...rest
}) {
  const { data } = useQuery(GET_CUSTOMERS, {
    variables: {
      companyId: companyWorkspace,
      includeIsMemberOfCompany: Boolean(companyWorkspace),
    },
  });
  let customers = null;
  if (data) {
    customers = data.customers;
    withoutMe && remove(customers, { id: data.me.id });
  }
  if (customers) {
    return (
      <SelectInput
        openOnClick={false}
        menuPlacement={menuPlacement || "auto"}
        components={{ Option }}
        options={customers.map(customerToValue)}
        value={
          value ? customerToValue(customers.find((c) => c.id === value)) : null
        }
        onChange={(value) => onChange(value && value.value)}
        {...rest}
      />
    );
  } else {
    return null;
  }
}

export default UserSelect;
