import { Route, Switch } from "react-router-dom";

import Loadable from "utils/Loadable";
import React from "react";

const CompanyEdit = Loadable({
  loader: () => import("./components/CompanyEdit"),
});

const CompanyDetail = Loadable({
  loader: () => import("./components/CompanyDetail"),
});

const Entrypoint = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/:slug/edit`} component={CompanyEdit} />
    <Route path={`${match.url}/:slug`} component={CompanyDetail} />
  </Switch>
);

export default Entrypoint;
