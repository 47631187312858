import { useContext, useEffect } from "react";
import { MapContext } from "react-mapbox-gl";
import ResizeObserver from "resize-observer-polyfill";

export default function MapResizeObserver() {
  const map = useContext(MapContext);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      map.resize();
    });
    observer.observe(map._container);
    return () => observer.disconnect();
  }, [map]);

  return null;
}
