import React, { useState, useEffect, useMemo } from "react";
import t from "prop-types";
import styled from "styled-components/macro";
import memoize from "lodash/fp/memoize";
import {
  ComboboxWrapper as CBWrapper,
  Combobox as CB,
  ComboboxInput as CBInput,
  ComboboxPopover as CBPopover,
  ComboboxOption as CBOption,
  ComboboxList,
} from "./Combobox";
import Icon from "./Icon";
import Spinner from "./Spinner";

export const ComboboxWrapper = styled(CBWrapper)`
  display: flex;
  flex-wrap: wrap;
  width: 286px;
  min-height: 36px;
  padding-bottom: 4px;
`;

export const Combobox = styled(CB)`
  width: ${({ short }) => (short ? "auto" : "100%")};
`;

export const ComboboxInput = styled(CBInput)`
  &[data-reach-combobox-input] {
    display: ${({ hidden }) => (hidden ? "none" : "inline")};
    width: ${({ short }) => (short ? "120px" : "100%")};
    padding: 7px 7px 3px;
    line-height: 18px;
  }
`;

export const ComboboxPopover = styled(CBPopover)`
  border-radius: 4px;
`;

export const ComboboxOption = styled(CBOption)`
  width: 284px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const UserOptionEmail = styled.span`
  font-size: 85%;
  color: ${({ theme }) => theme.colors.muted};
  margin-left: 10px;
`;

const SelectedUser = styled.span`
  display: flex;
  overflow: hidden;
  margin: 4px 0 0 4px;
  padding: 3px 7px;
  font-size: 11px;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.lighterBackground};
  border-radius: 2px;

  &:after {
    content: "✕";
    display: inline-block;
    font-weight: bold;
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.muted};
  }
`;

const SelectedUserPicture = styled.img`
  position: relative;
  top: 1px;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  border-radius: 100%;
  background: ${({ theme }) => theme.colors.muted};
`;

const SelectedUserDefaultPicture = styled(Icon).attrs({
  children: "account_circle",
})`
  position: relative;
  top: 1px;
  margin-right: 10px;
  color: ${({ theme }) => theme.colors.muted};
`;

const PlaceholderOption = styled.div`
  padding: 7px;
  width: 284px;

  & > div {
    top: 4px;
    margin-right: 10px;
  }
`;

const getUserName = memoize(({ username, firstName, lastName, email }) =>
  firstName && lastName ? `${firstName} ${lastName}` : username || email
);

const getUsersById = memoize((xs = []) =>
  xs.reduce((acc, x) => ({ ...acc, [x.id]: x }), {})
);

const useResults = (term, usersById, selectedUsers) =>
  useMemo(
    () =>
      Object.keys(usersById).filter((id) => {
        if (selectedUsers.includes(id)) return false;
        const { user } = usersById[id];
        const props = ["username", "firstName", "lastName", "email"];
        return props.some((prop) => user[prop].includes(term.trim()));
      }),
    [term, usersById, selectedUsers]
  );

export function UsersSelect({
  data,
  maxUsers,
  initialSelectedUsers = [],
  resultsMaxLength = 10,
  portal = false,
  onChange = () => {},
}) {
  const usersById = getUsersById(data);
  const [term, setTerm] = useState("");
  const [selectedUsers, setSelectedUsers] = useState(initialSelectedUsers);
  const hasSelectedUsers = selectedUsers.length > 0;
  const maxUsersReached = maxUsers && selectedUsers.length >= maxUsers;
  const results = useResults(term, usersById, selectedUsers);

  useEffect(() => onChange(selectedUsers), [selectedUsers, onChange]);

  const placeholder = hasSelectedUsers
    ? "Add more people"
    : "Enter names or email adresses";

  return (
    <ComboboxWrapper>
      {hasSelectedUsers &&
        selectedUsers
          .filter((id) => usersById[id])
          .map((id) => {
            const { picture, user } = usersById[id];
            const name = getUserName(user);
            return (
              <SelectedUser
                key={id}
                onClick={() =>
                  setSelectedUsers(
                    selectedUsers.filter((currId) => currId !== id)
                  )
                }
              >
                {picture ? (
                  <SelectedUserPicture alt={name} src={picture} />
                ) : (
                  <SelectedUserDefaultPicture />
                )}
                {name}
              </SelectedUser>
            );
          })}
      <Combobox
        aria-labelledby={placeholder}
        short={hasSelectedUsers}
        onSelect={(id) => {
          setSelectedUsers(
            selectedUsers.includes(id) ? selectedUsers : [...selectedUsers, id]
          );
          setTerm("");
        }}
      >
        <ComboboxInput
          hidden={maxUsersReached}
          short={hasSelectedUsers}
          value={term}
          placeholder={placeholder}
          onChange={({ target: { value } }) => {
            setTerm(value);
          }}
        />
        <ComboboxPopover portal={portal}>
          <ComboboxList>
            {!data ? (
              <PlaceholderOption>
                <Spinner /> Loading users
              </PlaceholderOption>
            ) : results.length > 0 ? (
              results.slice(0, resultsMaxLength).map((id) => {
                const { user } = usersById[id];
                const name = getUserName(user);
                return (
                  <ComboboxOption key={id} value={id}>
                    {name}
                    {name !== user.email && (
                      <UserOptionEmail>{user.email}</UserOptionEmail>
                    )}
                  </ComboboxOption>
                );
              })
            ) : (
              <PlaceholderOption>No results found</PlaceholderOption>
            )}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </ComboboxWrapper>
  );
}

export default UsersSelect;

UsersSelect.propTypes = {
  data: t.number.isRequired,
  maxUsers: t.number,
  resultsMaxLength: t.number,
  portal: t.bool,
  onChange: t.func,
};
