import React from "react";
import Tooltip from "react-portal-tooltip";
import { withTheme } from "styled-components/macro";
import useOnClickOutside from "hooks/useOnClickOutside";

// const Wrapper = styled.div`
//   background-color: white;
//   z-index: 1;
//   position: absolute;
//   left: calc(100% + 1em);
//   border: 1px solid ${props => props.theme.colors.input};
//   min-width: 50px;
//   min-height: 50px;
//   top: -10px;
//   border-radius: 3px;

//   &::after {
//     top: 10px;
//     width: 12px;
//     height: 12px;
//     background-color: white;
//     border-left: 1px solid ${props => props.theme.colors.input};
//     border-bottom: 1px solid ${props => props.theme.colors.input};
//     position: absolute;
//     content: "";
//     top: 10px;
//     left: -7px;
//     transform: rotate(45deg);
//   }
// `;

const Popup = ({ children, parent, theme, onClose }) => {
  const ref = useOnClickOutside(onClose);

  return parent ? (
    <Tooltip
      style={{
        style: {
          padding: 0,
          border: `1px solid ${theme.colors.input}`,
          borderRadius: 3,
          boxShadow: "none",
        },
        arrowStyle: {
          color: "white",
          borderColor: theme.colors.input,
          borderWidth: 1,
        },
      }}
      active={true}
      parent={parent}
    >
      <div ref={ref}>{children}</div>
    </Tooltip>
  ) : null;
};

export default withTheme(Popup);
