import React, { useContext, useMemo, useCallback } from "react";
import useMapPopup from "hooks/useMapPopup";
import useHistory from "hooks/useHistory";
import { useBaseUrl } from "../routes/app/new-ui/URLState";

const FeatureContext = React.createContext();

export function useSelectedFeature() {
  return useContext(FeatureContext);
}

export function SelectedFeatureProvider({ children }) {
  const featurePopup = useMapPopup();
  const baseUrl = useBaseUrl();
  const history = useHistory();

  const setFeature = useCallback(
    (feature, coords) => {
      history.push(baseUrl);
      featurePopup.open(feature, coords);
    },
    [baseUrl, featurePopup, history]
  );

  const value = useMemo(
    () => ({
      feature: featurePopup.value?.feature,
      setFeature,
      close: featurePopup.close,
      featurePopup
    }),
    [featurePopup, setFeature]
  );

  return (
    <FeatureContext.Provider value={value}>{children}</FeatureContext.Provider>
  );
}
