import { HasWorkspacePermission } from "utils/Restricted";
import React, { useState, useEffect, useContext } from "react";
import { pick, without } from "lodash";

import BaseMap from "../Base/Map";
import useMapPopup from "hooks/useMapPopup";
import Popup from "./Popup";
import { SkywatchContext } from "../Base/SkywatchContext";
import TaskModal from "components/TaskModal";
import { WorkLayerVisibleContext } from "../Base/WorkLayerVisibleContext";
import { Context as AppStateContext } from "appState";
import { useTasksMutations } from "hooks/mutations/useTasksMutations";
import { useSelectedTask } from "../../../../../SelectedTaskContext";
import { Source, Layer, MapContext } from "react-mapbox-gl";
import circle from "@turf/circle";
import MapboxNavigationControl from "../Base/MapboxNavigationControl";
import MapboxDrawControl from "../Base/MapboxDrawControl";
import MarkerLayer from "../Base/MarkerLayer";
import useDebouncedCallback from "hooks/useDebouncedCallback";
import { sendEvent } from "utils/ga";

const filterByGeometryType = (filter) => (task) => {
  switch (filter) {
    case "polygon":
      return task.geometry.type.includes("Polygon");
    case "line":
      return task.geometry.type.includes("Line");
    case "marker":
      return task.geometry.type.includes("Point");
    default:
      return null;
  }
};

function tasksToFeatureCollection(tasks, filter) {
  return {
    type: "FeatureCollection",
    features: Object.values(tasks)
      .map((task) =>
        task.radius
          ? {
              ...task,
              geometry: circle(task.geometry, task.radius / 1000).geometry,
            }
          : task
      )
      .filter(filterByGeometryType(filter))
      .map((task) => ({
        type: "Feature",
        id: task.id,
        geometry: task.geometry,
        properties: {
          ...pick(task, without(Object.keys(task), "id", "geometry")),
        },
      })),
  };
}

const tasksLayerIds = [
  "tasks-line",
  "tasks-polygon-fill",
  "tasks-polygon-line",
  "tasks-marker",
];

export function TasksLayers({ tasks, onFeatureClick, onFeatureHover }) {
  const map = useContext(MapContext);
  const [onMouseMove, onMouseLeave] = useDebouncedCallback((e) => {
    const features = map.queryRenderedFeatures(e.point, {
      layers: tasksLayerIds,
    });
    if (features.length > 0) {
      onFeatureHover(features[0], e.lngLat);
    }
  }, 500);

  function onClick(e) {
    const features = map.queryRenderedFeatures(e.point, {
      layers: tasksLayerIds,
    });
    if (features.length > 0) {
      onFeatureClick(features[0]);
      sendEvent("map", "clickFeature");
    }
  }

  return (
    <>
      <Source
        id="tasks-line"
        geoJsonSource={{
          type: "geojson",
          data: tasksToFeatureCollection(tasks, "line"),
        }}
      />
      <Layer
        id="tasks-line"
        sourceId="tasks-line"
        type="line"
        metadata={{ "slingshot:interactive": true, "slingshot:zIndex": 1000 }}
        paint={{
          "line-color": ["get", "color"],
          "line-width": 3,
        }}
        onClick={onClick}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
      />

      <Source
        id="tasks-polygon"
        geoJsonSource={{
          type: "geojson",
          data: tasksToFeatureCollection(tasks, "polygon"),
        }}
      />
      <Layer
        id="tasks-polygon-fill"
        sourceId="tasks-polygon"
        type="fill"
        metadata={{ "slingshot:interactive": true, "slingshot:zIndex": 1000 }}
        onClick={onClick}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        paint={{
          "fill-color": ["get", "color"],
          "fill-opacity": 0.2,
        }}
      />
      <Layer
        id="tasks-polygon-line"
        sourceId="tasks-polygon"
        type="line"
        metadata={{ "slingshot:interactive": true, "slingshot:zIndex": 1000 }}
        onClick={onClick}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        paint={{
          "line-color": ["get", "color"],
          "line-width": 3,
        }}
      />

      <Source
        id="tasks-marker"
        geoJsonSource={{
          type: "geojson",
          data: tasksToFeatureCollection(tasks, "marker"),
        }}
      />
      <MarkerLayer
        id="tasks-marker"
        sourceId="tasks-marker"
        onClick={onClick}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        metadata={{ "slingshot:interactive": true, "slingshot:zIndex": 1000 }}
        color={["get", "color"]}
        // prettier-ignore
        iconCode={[
          "match",
          ["get", "icon"],

          "place", String.fromCharCode("0xf3c5"),
          "location_city", String.fromCharCode("0xf64f"),
          "terrain", String.fromCharCode("0xf6fc"),
          "flight", String.fromCharCode("0xf072"),
          "photo_camera", String.fromCharCode("0xf030"),
          "favorite", String.fromCharCode("0xf004"),
          "grade", String.fromCharCode("0xf005"),
          "build", String.fromCharCode("0xf0ad"),

          "0xf3c5" // fallback
        ]}
      />
    </>
  );
}

function Map({ tasks }) {
  const [taskModalOpen, setTaskModalOpen] = useState(false);
  const { workLayerVisible } = useContext(WorkLayerVisibleContext);
  const appState = useContext(AppStateContext);
  const { deleteTask, createTask, updateTaskGeometry } = useTasksMutations();
  const { selectTask } = useSelectedTask();
  const taskPopup = useMapPopup();
  const { drawingSkywatchAOI, setPolygon, setDrawingSkywatchAOI } = useContext(
    SkywatchContext
  );

  const [editingFeature, setEditingFeature] = useState(null);

  useEffect(() => {
    if (editingFeature) {
      taskPopup.close();
    }
  }, [editingFeature, taskPopup]);

  const onFeatureClick = (feature) => {
    selectTask(feature.id);
    setTaskModalOpen(true);
  };

  const onGeometrySave = (feature) => {
    updateTaskGeometry(feature.id, feature.geometry, feature.properties.radius);
    setEditingFeature(null);
  };

  const onObjectDelete = (id) => {
    deleteTask(id);
    taskPopup.close();
  };

  const onDrawCreated = async (feature, selectedScenarioId) => {
    const result = await createTask({
      geometry: feature.geometry,
      scenario: selectedScenarioId,
      radius: feature.properties.radius,
    });
    selectTask(result.data.createTask.task.id);
    setTaskModalOpen(true);
  };

  const selectedScenarioId = appState.scenario;
  return (
    <HasWorkspacePermission type="rw">
      {(hasWorkspaceWritePermission) => (
        <BaseMap>
          {taskModalOpen && (
            <TaskModal
              isOpen={taskModalOpen}
              onClose={() => setTaskModalOpen(false)}
            />
          )}

          {taskPopup.value !== null && (
            <Popup
              {...taskPopup.popupProps}
              hasWorkspaceWritePermission={hasWorkspaceWritePermission}
              onView={() => {
                selectTask(taskPopup.value.feature.id);
                setTaskModalOpen(true);
              }}
              onEdit={() => {
                const task = tasks.find(
                  (t) => t.id === String(taskPopup.value.feature.id)
                );
                setEditingFeature({
                  type: "Feature",
                  geometry: task.geometry,
                  id: task.id,
                  properties: {
                    radius: task.radius,
                  },
                });
              }}
              onDelete={() => {
                if (window.confirm("Are you sure?")) {
                  onObjectDelete(String(taskPopup.value.feature.id));
                }
              }}
            />
          )}

          {workLayerVisible && (
            <TasksLayers
              tasks={tasks.filter((t) =>
                editingFeature ? t.id !== editingFeature.id : true
              )}
              onFeatureClick={onFeatureClick}
              onFeatureHover={taskPopup.open}
            />
          )}

          <MapboxDrawControl
            onCreate={(feature) => {
              if (drawingSkywatchAOI) {
                setPolygon(feature);
                setDrawingSkywatchAOI(false);
              } else onDrawCreated(feature, selectedScenarioId);
            }}
            editFeature={editingFeature}
            onEdit={onGeometrySave}
            controls={{
              circle: true,
              point: true,
              polygon: true,
              rectangle: true,
              line_string: true,
            }}
            mode={drawingSkywatchAOI ? "draw_rectangle" : null}
          />
          <MapboxNavigationControl />
        </BaseMap>
      )}
    </HasWorkspacePermission>
  );
}

export default Map;
