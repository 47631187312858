import React, { useContext } from "react";
import { useLayerSelectionMutations } from "hooks/mutations/useLayerSelectionMutations";
import { useSelectedProject } from "../routes/app/new-ui/URLState";
import { set, merge, cloneDeep } from "lodash-es";
import { useQuery } from "@apollo/react-hooks";
import { useAuth } from "hooks/data/auth";
import gql from "graphql-tag";
import usePersistedState from "hooks/usePersistedState";
import produce from "immer";
import { useLocation, useHistory, Redirect } from "react-router-dom";
import { arrayMove } from "react-sortable-hoc";

const GET_LAYER_SELECTIONS = gql`
  query getLayerSelections($scenarioId: ID!) {
    scenario(id: $scenarioId) {
      id
      basemap
      name
      layerSelections {
        id
        on
        metadata
        layer {
          id
          title
          name
          description
          type
          metadata
          extent
        }
      }
    }
  }
`;

const Context = React.createContext();

export function useLayerSelections() {
  return useContext(Context);
}

export function LayerSelectionsProvider({ children }) {
  const location = useLocation();
  const history = useHistory();
  const [
    layerSelectionsOverrides,
    setLayerSelectionsOverrides,
  ] = usePersistedState("layerSelectionsOverrides", {});
  const { loggedIn } = useAuth();
  const { projectId } = useSelectedProject();
  const query = useQuery(GET_LAYER_SELECTIONS, {
    variables: { scenarioId: projectId },
  });
  let originalLayerSelectionMutations = useLayerSelectionMutations();
  let layerSelectionMutations = originalLayerSelectionMutations;

  function overrideLayerSelection(id, mutator) {
    const item = layerSelectionsOverrides[id];
    setLayerSelectionsOverrides((overrides) => ({
      ...overrides,
      [id]: produce(item, mutator),
    }));
  }

  const searchParams = new URLSearchParams(location.search);
  let layersInURL = [];

  if (query.loading || !query.data) {
    return (
      <Context.Provider
        value={{
          loading: true,
          layerSelections: [],
          layerSelectionMutations: {},
        }}
      >
        {children}
      </Context.Provider>
    );
  }
  let layerSelections = cloneDeep(query.data.scenario.layerSelections || []);
  if (!loggedIn) {
    if (searchParams.has("layers"))
      layersInURL = searchParams.get("layers")?.split(",");
    else
      return (
        <Redirect
          to={`${location.pathname}?layers=${layerSelections.map(
            (ls) => ls.id
          )}`}
        />
      );
    layerSelections
      .sort((a, b) => (a.layer.title > b.layer.title ? -1 : 1))
      .forEach((layerSelection, index) => {
        const ls = layerSelectionsOverrides[layerSelection.id] || {};
        merge(layerSelection, ls, {
          on: layersInURL.includes(layerSelection.id),
          metadata: { zIndex: index },
        });
      });
    layerSelectionMutations = {
      reorderLayerSelections: (oldIndex, newIndex, layerSelections) => {
        const layerSelectionsAsArray = layerSelections
          .slice()
          .sort((a, b) => (a.metadata.zIndex > b.metadata.zIndex ? -1 : 1));

        const reorderedLayerSelections = arrayMove(
          layerSelectionsAsArray,
          oldIndex,
          newIndex
        )
          .filter((ls) => layersInURL.includes(ls.id))
          .map((ls) => ls.id);
        history.push(
          `${location.pathname}?layers=${reorderedLayerSelections.join(",")}`
        );
      },
      setLayerSelectionVisibility: ({ id, on }) => {
        const searchParams = new URLSearchParams(location.search);
        let layers = [];
        if (searchParams.has("layers")) {
          layers = searchParams.get("layers").split(",");
        }
        if (on) {
          layers.push(id);
        } else {
          layers = layers.filter((ls) => ls !== id);
        }
        history.push(`${location.pathname}?layers=${layers.join(",")}`);
      },
      setLayerSelectionOpacity: ({ id, metadata, opacity }) => {
        overrideLayerSelection(id, (ls) => {
          set(ls, "metadata.opacity", opacity);
        });
      },
      enableDynamicStyling: ({ ls, enable, firstProperty = null }) => {
        overrideLayerSelection(ls.id, (ls) => {
          if (enable) {
            set(ls, "metadata.styling.property", firstProperty.name);
            if (firstProperty.type === "number") {
              ls.metadata.styling.theme = "magma";
              ls.metadata.styling.min = firstProperty.min;
              ls.metadata.styling.max = firstProperty.max;
            }
          } else {
            set(ls, "metadata.styling.property", null);
          }
        });
      },
      setDynamicStylingProperty: ({
        ls,
        property = null,
        min = null,
        max = null,
      }) => {
        overrideLayerSelection(ls.id, (ls) => {
          if (property) {
            set(ls, "metadata.styling.property", property.name);
            if (property.type === "number") {
              ls.metadata.styling.theme = "magma";
              ls.metadata.styling.min = property.min;
              ls.metadata.styling.max = property.max;
            }
          } else {
            ls.metadata.styling.min = min;
            ls.metadata.styling.max = max;
          }
        });
      },
      setDynamicStylingTheme: ({ ls, theme }) => {
        overrideLayerSelection(ls.id, (ls) => {
          set(ls, "metadata.styling.theme", theme);
        });
      },
      setStaticStylingBase: ({ ls, property, value }) => {
        overrideLayerSelection(ls.id, (ls) => {
          set(ls, `metadata.styling.base[${property}]`, value);
        });
      },
    };
  }
  return (
    <Context.Provider
      value={{
        loading: query.loading,
        layerSelections,
        layerSelectionMutations,
      }}
    >
      {children}
    </Context.Provider>
  );
}
