import styled from "styled-components/macro";

const FullScreenMessage = styled.div`
  padding-top: 3em;
  height: 100%;
  background-color: #fff;
  color: ${(props) => props.theme.colors.mutedTextColor};
  text-align: center;
`;

export default FullScreenMessage;
