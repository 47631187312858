import React from "react";
import { useContext, useEffect } from "react";
import { MapContext } from "react-mapbox-gl";
import { NavigationControl } from "mapbox-gl";
import { createGlobalStyle } from "styled-components";
import plusIcon from "./icons/plus.svg";
import minusIcon from "./icons/minus.svg";

const DarkStyle = createGlobalStyle`
  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in span.mapboxgl-ctrl-icon {
    background-image: url("${plusIcon}");
  }
  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out span.mapboxgl-ctrl-icon {
    background-image: url("${minusIcon}");
  }
`;

export default function MapboxNavigationControl({
  dark = false,
  position = "top-left",
}) {
  const map = useContext(MapContext);

  useEffect(() => {
    const Navigation = new NavigationControl({
      showCompass: false,
    });

    map.addControl(Navigation, position);
  }, [map, position]);

  return dark ? <DarkStyle /> : null;
}
