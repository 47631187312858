import React, { useState } from "react";
import styled from "styled-components/macro";
import { TextareaInput } from "components/TextInput";
import Button from "components/Button";

const Actions = styled.div`
  display: flex;
  align-items: flex-end;
`;

const CommentInput = ({ onSubmit }) => {
  const [value, setValue] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(value);
    setValue("");
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextareaInput
        placeholder="Type a comment"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <Actions>
        <Button>Send</Button>
      </Actions>
    </form>
  );
};

export default CommentInput;
