import React from "react";
import { useQuery } from "@apollo/react-hooks";
import LoadingScreen from "components/LoadingScreen";
import ProfilePic from "components/ProfilePic";
import gql from "graphql-tag";
import { useAuth } from "hooks/data/auth";
import {
  PanelContainer,
  PanelContent,
  PanelHeader,
  PanelSection,
  PanelSectionContainer,
  PanelButton,
} from "./layout";
import { FieldGroup, Field, TextInput } from "../components/Input";
import { UploadSection } from "../components/UploadSection";
import styled from "styled-components/macro";
import { useMutation } from "@apollo/react-hooks";
import { useForm } from "utils/form";
import * as v from "utils/validation";

const GET_CUSTOMER = gql`
  query getMe {
    me {
      id
      picture
      user {
        id
        firstName
        lastName
        username
        email
      }
    }
  }
`;

const UPDATE_CUSTOMER = gql`
  mutation updateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      success
      customer {
        id
        user {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

const CHANGE_CUSTOMER_PASSWORD = gql`
  mutation changeCustomerPassword($input: ChangeCustomerPasswordInput!) {
    changeCustomerPassword(input: $input) {
      success
    }
  }
`;

const CHANGE_CUSTOMER_PICTURE = gql`
  mutation changeCustomerPicture($input: ChangeCustomerPictureInput!) {
    changeCustomerPicture(input: $input) {
      success
      customer {
        id
        picture
      }
    }
  }
`;

const Logout = styled.div`
  padding: 16px 0;
  font-weight: 600;
  margin-bottom: 8px;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
`;

function useChangePasswordForm() {
  const [changeCustomerPassword] = useMutation(CHANGE_CUSTOMER_PASSWORD);
  const form = useForm({
    fields: ["password", "password2"],
    validate: v.createValidator({
      password: [v.required, v.minLength(8)],
      password2: [v.required, v.minLength(8), v.match("password")],
    }),
    onSubmit: async (data) => {
      await changeCustomerPassword({
        variables: { input: { newPassword: data.password } },
      });
    },
  });
  return form;
}

function useUserDetailsForm({ customer }) {
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER);
  const form = useForm({
    fields: ["firstName", "lastName", "email"],
    initialValues: {
      firstName: customer.user.firstName,
      lastName: customer.user.lastName,
      email: customer.user.email,
    },
    validate: v.createValidator({
      firstName: [v.required],
      lastName: [v.required],
      email: [v.required, v.email],
    }),
    onSubmit: async ({ firstName, lastName, email }) => {
      const userData = {
        id: customer.id,
        firstName: firstName,
        lastName: lastName,
        email: email,
      };
      await updateCustomer({ variables: { input: userData } });
    },
  });
  return form;
}

function UserProfileForm({ customer }) {
  const [changeCustomerPicture] = useMutation(CHANGE_CUSTOMER_PICTURE);
  const { logout } = useAuth();
  const userDetailsForm = useUserDetailsForm({ customer });
  const changePasswordForm = useChangePasswordForm();

  const onUpload = async (file) => {
    await changeCustomerPicture({
      variables: { input: { file: file, id: customer.id } },
    });
  };

  async function onSubmit(e) {
    if (userDetailsForm.touched) {
      await userDetailsForm.onSubmit(e);
    }
    if (changePasswordForm.touched) {
      await changePasswordForm.onSubmit(e);
    }
  }

  return (
    <>
      <PanelHeader
        title={"Profile"}
        iconRight={
          <PanelButton
            onClick={onSubmit}
            disabled={!(userDetailsForm.touched || changePasswordForm.touched)}
          >
            Save
          </PanelButton>
        }
      />
      <PanelContent>
        <PanelSection title="edit profile">
          <PanelSectionContainer>
            <FieldGroup align="center">
              <Field flex={0.1}>
                <ProfilePic
                  size={48}
                  src={customer && customer.picture}
                  border={false}
                  margin="0"
                />
              </Field>
              <Field>
                <UploadSection
                  onChange={onUpload}
                  accept={"image/jpg, image/png"}
                />
              </Field>
            </FieldGroup>
            <Field label="First name">
              <TextInput type="text" {...userDetailsForm.fields.firstName} />
            </Field>
            <Field label="Last name">
              <TextInput type="text" {...userDetailsForm.fields.lastName} />
            </Field>
            <Field label="Email address">
              <TextInput type="email" {...userDetailsForm.fields.email} />
            </Field>
          </PanelSectionContainer>
        </PanelSection>
        <PanelSection title="change password">
          <PanelSectionContainer>
            <Field label="New password">
              <TextInput
                type="password"
                placeholder={"8+ characters"}
                {...changePasswordForm.fields.password}
              />
            </Field>
            <Field label="Repeat new password">
              <TextInput
                type="password"
                placeholder={"Repeat new password"}
                {...changePasswordForm.fields.password2}
              />
            </Field>
            {changePasswordForm.touched &&
              !changePasswordForm.isValid &&
              "Passwords should match and have more than 8 characters"}
            {changePasswordForm.success && "Password changed"}
          </PanelSectionContainer>
        </PanelSection>
        <PanelSectionContainer>
          <Logout onClick={logout}>Log out</Logout>
        </PanelSectionContainer>
      </PanelContent>
    </>
  );
}

export default function UserProfilePanel() {
  const { data, loading } = useQuery(GET_CUSTOMER);

  const customer = data?.me;

  if (loading) {
    return (
      <PanelContainer>
        <LoadingScreen />
      </PanelContainer>
    );
  }

  return (
    <PanelContainer>
      <UserProfileForm customer={customer} />
    </PanelContainer>
  );
}
