import { useContext, useEffect } from "react";
import { MapContext } from "react-mapbox-gl";
import { MouseCoordsContext } from "./MouseCoordsContext";

export default function MouseCoordsOnMove() {
  const map = useContext(MapContext);
  const { setCoords } = useContext(MouseCoordsContext);

  useEffect(() => {
    function onMouseMove(e) {
      setCoords(e.lngLat);
    }

    window.map = map;

    map.on("mousemove", onMouseMove);
    return () => map.off("mousemove", onMouseMove);
  }, [map, setCoords]);

  return null;
}
