import React, { useState } from "react";
import styled from "styled-components/macro";
import Icon from "./Icon";
import { isPDF, isImage, isCSV } from "../../../../utils/validation";
import Previewer from "../components/Previewer";
import download from "downloadjs";

const FilesList = styled.ul`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr
  margin: 0;
  padding: 0;
`;

const FileItem = styled.li`
  position: relative;
  list-style: none;
  width: 136px;
`;

const FileIcon = styled(Icon)`
  cursor: pointer;
  width: ${(props) => `${props.size}px` || "136px"};
  height: ${(props) => `${props.size}px` || "136px"};
`;

const NoPreviewIcon = styled.div`
  width: ${(props) => `${props.size}px` || "136px"};
  height: ${(props) => `${props.size}px` || "136px"};
  color: ${(props) => props.theme.colors.muted};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FileImage = styled.img`
  display: block;
  width: ${(props) => `${props.size}px` || "136px"};
  height: ${(props) => `${props.size}px` || "136px"};
  background: ${(props) => props.theme.colors.darkerBackground};
  overflow: hidden;
  color: ${(props) => props.theme.colors.muted};
  cursor: pointer;
`;

const FileName = styled.div`
  overflow: hidden;
  margin-top: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const FileDetails = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DownloadIcon = styled(Icon).attrs({
  children: "save_alt",
  title: "Download",
})`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.85);
  }
`;

const DeleteIcon = styled(Icon).attrs({
  children: "delete",
})`
  cursor: pointer;
`;

export const PreviewIcon = ({ url, thumbnail, size, onClick }) => {
  return isPDF(url) ? (
    <FileIcon svg size={size} onClick={onClick}>
      pdf
    </FileIcon>
  ) : isImage(url) ? (
    <FileImage src={thumbnail} size={size} alt={url} onClick={onClick} />
  ) : isCSV(url) ? (
    <FileIcon size={size} onClick={onClick}>
      fa-file-csv
    </FileIcon>
  ) : (
    <NoPreviewIcon size={75}>No preview available</NoPreviewIcon>
  );
};

export function Attachment({
  file: { id, name, image, thumbnailLarge },
  onDelete,
  onSelectAttachment,
  index,
}) {
  return (
    <FileItem>
      <DownloadIcon onClick={() => download(image)} />
      <PreviewIcon
        url={name}
        thumbnail={thumbnailLarge}
        key={id}
        size={136}
        onClick={() => onSelectAttachment(index)}
      />
      <FileName>{name}</FileName>
      <FileDetails>
        {/* TODO: Include attachment creation date after updating ep-api to include it in the schema */}
        {onDelete && <DeleteIcon onClick={() => onDelete(id)} />}
      </FileDetails>
    </FileItem>
  );
}

function Attachments({ files, onDelete }) {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [initialPreviewIndex, setInitialPreviewIndex] = useState(false);
  const onSelectAttachment = (index) => {
    setPreviewOpen(true);
    setInitialPreviewIndex(index);
  };
  return (
    <FilesList>
      {files.map((file, index) => (
        <Attachment
          file={file}
          index={index}
          key={file.id}
          onDelete={onDelete}
          onSelectAttachment={onSelectAttachment}
        />
      ))}
      {previewOpen && (
        <Previewer
          src={files[initialPreviewIndex].image}
          nextSrc={files[(initialPreviewIndex + 1) % files.length]}
          prevSrc={
            files[(initialPreviewIndex + files.length - 1) % files.length]
          }
          onCloseRequest={() => setPreviewOpen(false)}
          onMovePrevRequest={() =>
            setInitialPreviewIndex(
              (initialPreviewIndex + files.length - 1) % files.length
            )
          }
          onMoveNextRequest={() =>
            setInitialPreviewIndex((initialPreviewIndex + 1) % files.length)
          }
        />
      )}
    </FilesList>
  );
}

export default Attachments;
