import { useEffect, useRef } from "react";

export default function useOnClickOutside(callback) {
  const ref = useRef();

  useEffect(() => {
    const onBodyClick = (e) => {
      try {
        let currentElement = e.target;
        while (true) {
          if (currentElement === ref.current) {
            break;
          }
          if (currentElement === document.body) {
            callback();
            break;
          }
          currentElement = currentElement.parentElement;
        }
      } catch (e) {}
    };
    setTimeout(() => {
      document.body.addEventListener("click", onBodyClick);
    }, 1);
    return () => {
      setTimeout(() => {
        document.body.removeEventListener("click", onBodyClick);
      }, 1);
    };
  }, [callback]);

  return ref;
}
