import React, { useContext, useState } from "react";

const ScreenshotContext = React.createContext();

export const useScreenshot = () => useContext(ScreenshotContext);

export function ScreenshotProvider({ children }) {
  const [screenShotMap, setScreenShotMap] = useState(null);
  const [screenShotLoading, setScreenShotLoading] = useState(true);

  const value = {
    screenShotMap,
    screenShotLoading,
    setScreenShotMap,
    setScreenShotLoading,
  };

  return (
    <ScreenshotContext.Provider value={value}>
      {children}
    </ScreenshotContext.Provider>
  );
}
