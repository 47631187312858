import React, { useState, useContext, useRef, useEffect } from "react";
import SidePanel, { HeaderTitle } from "components/SidePanel";
import { Context as MapStateContext } from "../../../../state";
import styled from "styled-components";
import RTCheckbox from "components/Checkbox";
import intersects from "@turf/boolean-intersects";
import Icon from "components/Icon";
import SecondaryButton from "components/SecondaryButton";
import bboxPolygon from "@turf/bbox-polygon";
import useToggle from "hooks/useToggle";
import { HoveredLayerContext } from "../HoveredLayerContext";
import { PreviewLayerContext } from "../PreviewLayerContext";
import { Context as AppStateContext } from "appState";
import moment from "moment";
import bbox from "@turf/bbox";
import { withRouter } from "react-router-dom";
import Restricted, { isSlingshot, layerPermissionType } from "utils/Restricted";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import produce from "immer";
import useDebouncedValue from "hooks/useDebouncedValue";
import { sendEvent } from "utils/ga";

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
`;

const LayersList = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const Checkbox = styled(RTCheckbox)`
  margin-bottom: 0 !important;
`;

const LayerWrapper = styled.div`
  user-select: none;
  cursor: default;
  border-left: 4px solid ${(props) => props.color};
  display: grid;
  grid-template-columns: min-content auto min-content;
  grid-template-rows: auto auto;
  padding: 0.7em 0.9em;

  :not(:last-child) {
    border-bottom: 1px solid #eee;
  }

  :hover {
    background-color: hsl(0, 0%, 95%);
  }
`;

const LeftIcons = styled.div`
  grid-row: 1 / 3;
  margin-right: 1em;
`;

const LayerTitle = styled.div`
  grid-column: 2 / 3;
  font-weight: 500;
  letter-spacing: 0.022em;
  align-self: end;
  user-select: text;
`;

const LayerMeta = styled.div`
  grid-column: 2 / 3;
  font-size: 0.9em;
`;

const LayerActions = styled.div`
  grid-column: 3 / 4;
  grid-row: 1 / 3;
  display: flex;
  align-items: center;
`;

const LayerAction = styled(Icon)`
  color: ${(props) =>
    props.disabled
      ? "#bbb"
      : props.selected
      ? "hsla(206, 100%, 41%, 1)"
      : "#333"};
  font-size: 22px;
  cursor: ${(props) => (props.disabled ? "inherit" : "pointer")};

  :not(:last-child) {
    margin-right: 0.5em;
  }
`;

const FilterInput = styled.input`
  padding: 1.5em 1em;
  flex: 1;
  border: none;
  margin-right: 1em;
  outline: none;
`;

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding: 0 1em;
`;

const LayerDescription = styled.div`
  margin: 0.2em 0;
  grid-column: 2 / 4;
  word-break: break-all;
  user-select: text;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const BackIcon = styled(Icon)`
  margin-right: 1em;
  cursor: pointer;
`;

function Layer({
  layer,
  previewLayer,
  onAdd,
  onPreview,
  setExtent,
  onSettingsClick,
  ...props
}) {
  const [expanded, toggleExpanded] = useToggle(false);
  const color = layerColor(layer);
  const canManage = (customer) => {
    return (
      isSlingshot(customer) ||
      (layer.type === "V" && layerPermissionType(layer) === "ow")
    );
  };
  const disablePreview =
    (layer.type === "V" && !layer.metadata.geometry_type) || !layer.extent;
  return (
    <LayerWrapper data-test="layer-browser-item" color={color} {...props}>
      <LeftIcons>
        <Icon size={20} color={color}>
          {layerIcon(layer)}
        </Icon>
        {layer.description && (
          <Icon
            size={20}
            onClick={toggleExpanded}
            style={{ cursor: "pointer" }}
          >
            {expanded ? "keyboard_arrow_down" : "keyboard_arrow_right"}
          </Icon>
        )}
      </LeftIcons>
      <LayerTitle>{layer.title}</LayerTitle>
      <LayerMeta>Created on {moment(layer.created).format("LL")}</LayerMeta>
      <LayerActions>
        <Restricted to={canManage}>
          <LayerAction onClick={onSettingsClick} data-test="settings-layer">
            settings
          </LayerAction>
        </Restricted>
        <LayerAction
          disabled={disablePreview}
          selected={previewLayer}
          onClick={!disablePreview ? onPreview : undefined}
          onMouseEnter={() => setExtent(layer.extent)}
          onMouseLeave={() => setExtent(null)}
        >
          remove_red_eye
        </LayerAction>
        <LayerAction
          disabled={layer.alreadySelected}
          onClick={() => {
            if (!layer.alreadySelected) onAdd();
            sendEvent("layers", "addLayer");
          }}
          data-test="add-layer"
        >
          add_box
        </LayerAction>
      </LayerActions>
      {expanded && <LayerDescription>{layer.description}</LayerDescription>}
    </LayerWrapper>
  );
}

function layerColor(layer) {
  return {
    R: "hsl(172, 48%, 41%)",
    V: "hsl(261, 48%, 41%)",
  }[layer.type];
}
function layerIcon(layer) {
  return {
    V: "timeline",
    R: "map",
  }[layer.type];
}

export const GET_ALREADY_SELECTED_LAYER_SELECTIONS = gql`
  query getAlreadySelectedLayerSelections($scenarioId: ID!) {
    scenario(id: $scenarioId) {
      id
      layerSelections {
        id
        layer {
          id
        }
      }
    }
  }
`;

export const GET_LAYERS = gql`
  query getLayers($filter: LayersFilter, $first: Int, $after: String) {
    layers(filter: $filter, first: $first, after: $after) {
      edges {
        node {
          id
          type
          name
          title
          description
          extent
          metadata
          myPermission
          created
        }
      }
      pageInfo {
        totalCount
        hasNextPage
        endCursor
      }
    }
  }
`;

function scrollFromBottom(element) {
  return element.scrollHeight - (element.offsetHeight + element.scrollTop);
}

export function useShouldLoadMore(ref, offset) {
  const [position, setPosition] = useState(0);

  useEffect(() => {
    const refElement = ref.current;

    const handleScroll = (e) => {
      setPosition(scrollFromBottom(refElement));
    };

    refElement.addEventListener("scroll", handleScroll, { passive: true });
    return () => refElement.removeEventListener("scroll", handleScroll);
  }, [ref]);

  if (!ref.current) {
    return false;
  }
  // There's content behind the fold and we're near the bottom
  return (
    ref.current.offsetHeight < ref.current.scrollHeight && position < offset
  );
}

export const CREATE_LAYER_SELECTION = gql`
  mutation createLayerSelection($input: CreateLayerSelectionInput!) {
    createLayerSelection(input: $input) {
      success
    }
  }
`;

const LayerBrowserPanel = withRouter(
  ({ onClose, openLayersPanel, history }) => {
    const [filterToViewport, toggleFilterToViewport] = useToggle(true);
    const [textFilter, setTextFilter] = useState("");
    const { view, setParams } = useContext(MapStateContext);
    const { setExtent } = useContext(HoveredLayerContext);
    const { scenario } = useContext(AppStateContext);
    const layersListRef = useRef();
    const shouldLoadMore = useShouldLoadMore(layersListRef, 300);

    const debouncedTextFilter = useDebouncedValue(textFilter, 200);
    const debouncedView = useDebouncedValue(view, 200);

    const [createLayerSelection] = useMutation(CREATE_LAYER_SELECTION, {
      refetchQueries: ["getLayerSelections", "getLayersPanelData"],
    });

    const { previewLayer, setPreviewLayer } = useContext(PreviewLayerContext);

    const layers = useQuery(GET_LAYERS, {
      variables: {
        filter: {
          text: debouncedTextFilter,
          bbox: filterToViewport
            ? debouncedView.split(",").map(Number)
            : undefined,
        },
        first: 20,
      },
    });
    const alreadySelected = useQuery(GET_ALREADY_SELECTED_LAYER_SELECTIONS, {
      variables: { scenarioId: scenario },
    });

    useEffect(() => {
      if (
        shouldLoadMore &&
        !layers.loading &&
        layers.data &&
        layers.data.layers.pageInfo.hasNextPage
      ) {
        layers.fetchMore({
          variables: {
            after: layers.data.layers.pageInfo.endCursor,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newEdges = fetchMoreResult.layers.edges;
            const newPageInfo = fetchMoreResult.layers.pageInfo;

            if (newEdges.length > 0) {
              return produce(previousResult, (draft) => {
                draft.layers.edges.push(...newEdges);
                draft.layers.pageInfo = newPageInfo;
              });
            } else {
              return previousResult;
            }
          },
        });
      }
    }, [shouldLoadMore, layers.loading, layers]);

    const viewportPolygon = bboxPolygon(view.split(",").map(Number));
    let results = [];
    if (layers.data) {
      results = layers.data.layers.edges.map((edge) => edge.node);

      if (alreadySelected.data) {
        const alreadySelectedIds = alreadySelected.data.scenario.layerSelections.map(
          (ls) => ls.layer.id
        );
        results = results.map((layer) => ({
          ...layer,
          alreadySelected: alreadySelectedIds.includes(layer.id),
        }));
      }
    }

    return (
      <SidePanel
        width="550px"
        title={
          <TitleWrapper>
            <BackIcon size={18} onClick={openLayersPanel}>
              arrow_back
            </BackIcon>
            <HeaderTitle style={{ fontSize: "15px" }}>
              Layer browser
            </HeaderTitle>
            <Restricted to={isSlingshot}>
              <SecondaryButton
                onClick={() => history.push("/app/layers/create")}
                data-test="create-layer-button"
              >
                Create layer
              </SecondaryButton>
            </Restricted>
          </TitleWrapper>
        }
        onClose={onClose}
      >
        <Content>
          <FilterWrapper>
            <Icon size={24} color="#bbb">
              search
            </Icon>
            <FilterInput
              data-test="search-layer-input"
              placeholder="Type to search"
              value={textFilter}
              onChange={(e) => {
                setTextFilter(e.target.value);
                sendEvent("layers", "searchBrowserLayer");
              }}
            />
            <Checkbox
              data-test="filter-layer-checkbox"
              label="Filter to Current View"
              checked={filterToViewport}
              onChange={(e) => {
                toggleFilterToViewport(e);
                sendEvent("layers", "toggleFilterToViewport");
              }}
            />
          </FilterWrapper>
          <LayersList ref={layersListRef}>
            {results.map((layer) => (
              <Layer
                key={layer.id}
                layer={layer}
                setExtent={setExtent}
                previewLayer={previewLayer && previewLayer.id === layer.id}
                onSettingsClick={() => {
                  layer.type === "V"
                    ? history.push(`/app/layers/${layer.id}`)
                    : history.push(`/app/layers/old/${layer.id}`);
                }}
                onPreview={() => {
                  if (previewLayer && previewLayer.id === layer.id) {
                    setPreviewLayer(null);
                  } else {
                    setPreviewLayer(layer);
                    setParams({ view: bbox(layer.extent).join(",") });
                  }
                }}
                onAdd={async () => {
                  await createLayerSelection({
                    variables: {
                      input: { scenarioId: scenario, layerId: layer.id },
                    },
                  });
                  if (
                    layer.extent &&
                    !intersects(layer.extent, viewportPolygon)
                  ) {
                    setParams({ view: bbox(layer.extent).join(",") });
                  }
                  setPreviewLayer(null);
                }}
              />
            ))}
          </LayersList>
        </Content>
      </SidePanel>
    );
  }
);

export default LayerBrowserPanel;
