import history from "utils/history";

// Response parsing helpers
export const parseJSON = (response) => response.json();
export const checkStatus = (response) => {
  if (!response.ok) {
    if (response.status === 401) {
      // If unauthorized, logout
      localStorage.removeItem("token");
      history.push("/login?inactivity=true");
    }
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
  return response;
};

// Tile grid helpers
export const tile2long = (x, z) => (x / Math.pow(2, z)) * 360 - 180;

export const tile2lat = (y, z) => {
  const n = Math.PI - (2 * Math.PI * y) / Math.pow(2, z);
  return (180 / Math.PI) * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n)));
};
