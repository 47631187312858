import React, { useRef, useReducer, useState } from "react";
import {
  PanelContainer,
  PanelContent,
  PanelHeader,
  PanelSection,
  PanelIcon,
  PanelButton,
  PanelSectionContainer,
} from "../layout";
import { TextInput, TextAreaInput } from "../../components/Input";
import { useForm } from "utils/form";
import * as v from "utils/validation";
import { uploadLayer } from "routes/app/routes/map/components/maps/Base/UploadLayerModal";
import { useSelectedProject, useViewport } from "../../URLState";
import bbox from "@turf/bbox";
import { proportionalBuffer } from "utils/geometry";
import { sendEvent } from "utils/ga";
import { useNotification } from "./../../components/Notification";

const layerUploadInitialState = {
  details: null,
  editingDetails: false,
  defaultTitle: null,
  file: null,
};
function layerUploadReducer(state, action) {
  switch (action.type) {
    case "upload":
      return {
        ...state,
        file: action.file,
        defaultTitle: action.file.name,
        editingDetails: true,
      };
    case "save":
      return { ...state, editingDetails: false, details: action.details };
    case "cancel":
      return layerUploadInitialState;
    default:
      throw Error("Unexpected action");
  }
}

export function useLayerUpload() {
  const [{ editingDetails, defaultTitle, file }, dispatch] = useReducer(
    layerUploadReducer,
    layerUploadInitialState
  );
  const [status, setStatus] = useState("not_started");
  const [progress, setProgress] = useState(0);
  const { projectId } = useSelectedProject();
  const { updateViewport } = useViewport();
  const cancelUploadRef = useRef();
  const { fireNotification } = useNotification();

  function onSave(details) {
    dispatch({ type: "save", details });
    cancelUploadRef.current = uploadLayer({
      file,
      layer: details,
      scenarioId: projectId,
      refetchQueries: ["getLayersPanelData", "getLayerSelections"],
      onProgress: (e) => {
        if (status !== e.type) setStatus(e.type);

        if (e.type === "uploading" && progress !== e.progress) {
          setProgress(e.progress);
        }

        if (e.type === "error") {
          setStatus("done_with_errors");
          fireNotification({
            type: "error",
            content: e.message || "An error occurred",
          });
        }

        if (e.type === "success") {
          updateViewport(proportionalBuffer(bbox(e.layer.extent), 0.1));
          fireNotification({
            type: "success",
            content: "Layer uploaded successfully",
          });
          sendEvent("uploadLayerModal", "layerFileUploaded");
        }
      },
    });
  }

  return {
    editingDetails,
    defaultTitle,
    onUpload: (file) => {
      return dispatch({ type: "upload", file });
    },
    onSave,
    onCancel: () => dispatch({ type: "cancel" }),
    status,
    progress,
  };
}

export function EditLayerDetailsPanel({ onCancel, onSave, defaultTitle }) {
  const { fields, onSubmit, isValid } = useForm({
    initialValues: { title: defaultTitle },
    fields: ["title", "description"],
    validate: v.createValidator({ title: v.required }),
    onSubmit: onSave,
  });
  return (
    <PanelContainer>
      <PanelHeader
        title={"Add Layer"}
        iconLeft={
          <PanelIcon muted onClick={onCancel}>
            chevron_left
          </PanelIcon>
        }
        iconRight={
          <PanelButton disabled={!isValid} onClick={onSubmit}>
            Save layer
          </PanelButton>
        }
      />
      <PanelContent>
        <PanelSection title="Name">
          <PanelSectionContainer>
            <TextInput {...fields.title} />
          </PanelSectionContainer>
        </PanelSection>
        <PanelSection title="Description">
          <PanelSectionContainer>
            <TextAreaInput {...fields.description} />
          </PanelSectionContainer>
        </PanelSection>
      </PanelContent>
    </PanelContainer>
  );
}
