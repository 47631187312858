import React from "react";
import Sidebar from "components/Sidebar";
import Topbar from "components/Topbar";
import styled from "styled-components/macro";
import { Snackbar } from "components/Snackbar";

const MainContainer = styled.div`
  display: grid;
  height: 100%;

  grid-template-rows: fit-content(15px) 1fr;
  grid-template-columns: fit-content(15px) 1fr;
  grid-template-areas:
    "topbar  topbar"
    "sidebar content";
`;

const Content = styled.div`
  grid-area: content;
  overflow-y: auto;
`;

const MainApp = ({ children }) => (
  <MainContainer>
    <Snackbar />
    <Sidebar />
    <Topbar />
    <Content>{children}</Content>
  </MainContainer>
);

export default MainApp;
