import React, { useCallback, useContext, useState } from "react";
import styled, { css } from "styled-components/macro";

import ButtonLink from "components/ButtonLink";
import Icon from "components/Icon";
import Lightbox from "react-image-lightbox";
import { Context as MapStateContext } from "routes/app/routes/map/state";
import ProfilePic from "components/ProfilePic";
import { getOverviewBbox } from "utils/geometry";
import moment from "moment";
import { orderBy } from "lodash-es";
import produce from "immer";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { getName } from "components/UserSelect";
import { sendEvent } from "utils/ga";

function AttachmentPreview({ images, onClose }) {
  const [index, setIndex] = useState(0);

  return (
    <Lightbox
      mainSrc={images[index]}
      nextSrc={images[(index + 1) % images.length]}
      prevSrc={images[(index + images.length - 1) % images.length]}
      onCloseRequest={onClose}
      onMovePrevRequest={() =>
        setIndex((index + images.length - 1) % images.length)
      }
      onMoveNextRequest={() => setIndex((index + 1) % images.length)}
    />
  );
}

const isAttachment = (action) =>
  action.actionObject && action.actionObject.__typename === "Attachment";

// Sorts by timestamp and groups attachments that are close by ten minutes
export const cleanActions = (actions) =>
  orderBy(
    orderBy(actions, [(action) => moment(action.timestamp)], ["asc"]).reduce(
      (acc, action) => {
        if (isAttachment(action)) {
          const attachmentActions = acc.filter(isAttachment);
          if (attachmentActions.length === 0) {
            return [
              ...acc,
              {
                ...action,
                objects: [action.actionObject],
              },
            ];
          } else {
            const lastAttachmentGroup =
              attachmentActions[attachmentActions.length - 1];
            const lastAttachmentGroupIndex = acc.findIndex(isAttachment);
            const groupDate = moment(lastAttachmentGroup.timestamp);
            if (moment(action.timestamp) < groupDate.add(10, "minutes")) {
              // Append action.actionObject to last attachment group
              // in an immutable way
              return produce(acc, (draft) => {
                draft[lastAttachmentGroupIndex].objects.push(
                  action.actionObject
                );
              });
            } else {
              return [
                ...acc,
                {
                  ...action,
                  objects: [action.actionObject],
                },
              ];
            }
          }
        } else {
          return [...acc, action];
        }
      },
      []
    ),
    [(action) => moment(action.timestamp)],
    ["desc"]
  );

const ColorIndicator = styled.span`
  vertical-align: middle;
  margin-left: 0.2em;
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  border-radius: 0.3em;
  background-color: ${(props) => props.color};
`;

const EventWrapper = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr auto;
  grid-template-rows: auto auto auto;
  ${(props) =>
    props.datePosition === "bottom"
      ? css`
          grid-template-areas:
            "pic label   . "
            "pic date    . "
            " .  content . ";
        `
      : css`
          grid-template-areas:
            "pic label   date"
            " .  content   . "
            " .  content   . ";
        `};
  align-items: center;
  padding: 0.8em 0;
  border-bottom: 1px solid #eee;
`;

const EventLabel = styled.div`
  font-size: 0.95em;
  grid-area: label;
`;

const EventDate = styled.div`
  grid-area: date;
  font-size: 0.9em;
  white-space: nowrap;
  color: ${(props) => props.theme.colors.mutedTextColor};
`;

const Content = styled.div`
  grid-area: content;
  margin-top: 1em;
`;

const EventProfilePic = styled(ProfilePic)`
  margin: 0;
  grid-area: pic;
`;

const AttachmentGrid = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fit, 100px);
`;

const Attachment = styled.img`
  cursor: pointer;
`;

const ChangedIcon = styled(Icon)`
  vertical-align: sub;
`;

const LocateIcon = styled(Icon)`
  cursor: pointer;
  font-size: 16px;
  vertical-align: text-top;
  color: ${(props) => props.theme.colors.input};
`;

function Verb({ action }) {
  if (action.data && "key" in action.data) {
    let value = action.data.value;
    if (action.data.key === "icon") {
      value = <ChangedIcon size={18}>{action.data.value}</ChangedIcon>;
    }
    if (action.data.key === "color") {
      value = <ColorIndicator color={action.data.value} />;
    }
    if (action.data.key === "status") {
      value = action.data.value;
    }

    return (
      <>
        updated {action.data.key} to <em>{value}</em>
      </>
    );
  }

  if (action.verb.includes("tag")) {
    return (
      <>
        {action.verb} <em>{action.data.tag}</em>
      </>
    );
  }

  return action.verb;
}

const TargetWrapper = styled.span`
  white-space: nowrap;
`;

const GET_CUSTOMER = gql`
  query getMe {
    me {
      id
      user {
        id
      }
    }
  }
`;

const Event = ({
  action,
  showTarget,
  datePosition = "right",
  onTargetClick,
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [initialPreviewIndex, setInitialPreviewIndex] = useState(false);
  const { data } = useQuery(GET_CUSTOMER);

  const mapState = useContext(MapStateContext);

  const logBbox = useCallback(() => {
    const overviewBbox = getOverviewBbox(action.target.geometry);
    mapState.setParams({ view: overviewBbox.join(",") });
  }, [action.target.geometry, mapState]);

  let target = null;
  if (showTarget) {
    target = (
      <TargetWrapper>
        {!action.verb.includes("created") && "on "}
        <ButtonLink
          onClick={(e) => {
            onTargetClick(e);
            sendEvent("activityEvent", "clickTaskTitleActivity");
          }}
        >
          {action.target.title || "Untitled"}
        </ButtonLink>{" "}
        <LocateIcon
          onClick={(e) => {
            logBbox(e);
            sendEvent("activityEvent", "locateActivity");
          }}
        >
          gps_fixed
        </LocateIcon>
      </TargetWrapper>
    );
  }
  if (!data) return null;
  const customer = data.me;
  let label = (
    <span>
      <strong>
        {String(action.actor.id) === customer.user.id
          ? "You"
          : getName(action.actor)}
      </strong>{" "}
      <Verb action={action} /> {target}
    </span>
  );

  let content = null;
  if (action.verb.includes("attachment")) {
    content = (
      <AttachmentGrid>
        {previewOpen && (
          <AttachmentPreview
            images={action.objects.map((at) => at.image)}
            onClose={() => setPreviewOpen(false)}
            initialPreviewIndex={initialPreviewIndex}
          />
        )}
        {action.objects &&
          action.objects.filter(Boolean).map((attachment, index) => (
            <Attachment
              key={attachment.id}
              src={attachment.thumbnailLarge}
              onClick={() => {
                setPreviewOpen(true);
                setInitialPreviewIndex(index);
              }}
            />
          ))}
      </AttachmentGrid>
    );
  }
  if (action.verb === "commented") {
    content = action.actionObject.text;
  }

  return (
    <EventWrapper datePosition={datePosition}>
      <EventProfilePic src={action.actor.customer.picture} />
      <EventLabel>{label}</EventLabel>
      <EventDate>{moment(action.timestamp).fromNow()}</EventDate>
      {content && <Content>{content}</Content>}
    </EventWrapper>
  );
};

export default Event;
