import React, { useContext, useState } from "react";
import styled from "styled-components/macro";
import { useHistory } from "react-router";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useBaseUrl, useSelectedProject } from "../URLState";
import { useForm } from "utils/form";
import { useTasksMutations } from "hooks/mutations/useTasksMutations";
import { TemporaryFeatureContext } from "./TemporaryFeatureContext";
import {
  PanelContainer,
  PanelContent,
  PanelHeader,
  PanelSection,
  PanelSectionContainer,
  PanelIcon,
  PanelButton,
} from "./layout";
import { Field, TextInput, TextAreaInput } from "../components/Input";
import Spinner from "../components/Spinner";
import UsersSelect, { ComboboxWrapper } from "../components/UsersSelect";
import Tags from "../components/Tags";
import { sendEvent } from "utils/ga";
import { UploadSection } from "../components/UploadSection";
import { Select } from "../components/ListBox";
import DatePicker from "../components/DatePicker";

const GET_CUSTOMERS_IN_WORKSPACE = gql`
  query getCustomersInWorkspace($scenarioId: ID!) {
    workspace(scenarioId: $scenarioId) {
      id
      customersWithAccess {
        id
        picture
        user {
          id
          username
          firstName
          lastName
          email
        }
      }
    }
  }
`;

const GET_CUSTOMER = gql`
  query getMe {
    me {
      id
    }
  }
`;

const ASSIGN_TO = gql`
  mutation assignTo($input: AssignTaskToInput!) {
    assignTaskTo(input: $input) {
      success
      error
      task {
        id
        assignedTo {
          id
          picture
          user {
            id
            username
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const Loading = styled(Spinner).attrs(({ theme }) => ({
  size: "12",
  color: theme.colors.text,
}))`
  margin-left: 10px;
`;

const useCustomers = (projectId) => {
  const { data } = useQuery(GET_CUSTOMERS_IN_WORKSPACE, {
    variables: {
      scenarioId: projectId,
    },
  });

  return data?.workspace.customersWithAccess;
};

const useMe = () => {
  const { data } = useQuery(GET_CUSTOMER);
  return data?.me;
};

const useAssignTo = () => {
  const [assignTo] = useMutation(ASSIGN_TO);
  return (taskId, customerId) =>
    assignTo({
      variables: { input: { id: taskId, customerId: customerId } },
    });
};

function AddTasksPanel() {
  const { projectId } = useSelectedProject();
  const baseUrl = useBaseUrl();
  const history = useHistory();
  const assignTo = useAssignTo();
  const customers = useCustomers(projectId);
  const me = useMe();
  const { temporaryFeature, setTemporaryFeature } = useContext(
    TemporaryFeatureContext
  );
  const [isCreatingTask, setIsCreatingTask] = useState(false);
  const { createTask, updateTask, addAttachment } = useTasksMutations();
  const { fields, onSubmit } = useForm({
    fields: [
      "taskName",
      "description",
      "dueDate",
      "file",
      "asignee",
      "tags",
      "priority",
    ],
    initialValues: { taskName: "Untitled", priority: "MEDIUM" },
    onSubmit: async (taskDetails) => {
      const {
        data: {
          createTask: { task },
        },
      } = await createTask({
        geometry: temporaryFeature.geometry,
        scenario: projectId,
        radius: temporaryFeature.properties.radius,
      });

      setTemporaryFeature(null);
      setIsCreatingTask(true);
      const data = {
        taskName: taskDetails.taskName,
        description: taskDetails.description,
        priority: taskDetails.priority,
        tags: taskDetails.tags,
        status: "S",
      };
      if (taskDetails.dueDate) data.dueDate = taskDetails.dueDate;

      await updateTask(task.id, data);

      await assignTo(task.id, taskDetails.asignee[0]);

      if (taskDetails.file !== "") {
        await addAttachment(task.id, taskDetails.file);
      }

      sendEvent("task", "createNewTask");
      history.push(`${baseUrl}/tasks`);
    },
  });

  const priorityOptions = [
    { value: "MEDIUM", label: "Medium" },
    { value: "LOW", label: "Low" },
    { value: "HIGH", label: "High" },
  ];

  return (
    <PanelContainer>
      <PanelHeader
        title={"Add Task"}
        iconLeft={
          <PanelIcon muted onClick={() => history.push(`${baseUrl}/tasks`)}>
            chevron_left
          </PanelIcon>
        }
        iconRight={
          <PanelButton
            onClick={onSubmit}
            disabled={!temporaryFeature}
            title={
              !temporaryFeature
                ? "You need to create a feature in order to save a new task"
                : null
            }
          >
            {isCreatingTask ? "Saving" : "Save task"}
            {isCreatingTask && <Loading />}
          </PanelButton>
        }
      />
      <PanelContent>
        <PanelSection borderless>
          <PanelSectionContainer>
            <Field label="Task name">
              <TextInput placeholder="task name" {...fields.taskName} />
            </Field>
            <Field label="Task description">
              <TextAreaInput
                placeholder="Task description"
                {...fields.description}
              />
            </Field>
            <Field label="Asignee">
              {me ? (
                <UsersSelect
                  data={customers}
                  initialSelectedUsers={[me.id]}
                  maxUsers={1}
                  resultsMaxLength={3}
                  {...fields.asignee}
                />
              ) : (
                <ComboboxWrapper />
              )}
            </Field>
            <Field label="Due date">
              <DatePicker
                value={fields.dueDate.value}
                placeholderText="Select date"
                dateFormat="MMMM DD, YYYY"
                {...fields.dueDate}
              />
            </Field>
            <Field label="Attachments">
              <UploadSection {...fields.file} />
            </Field>
            <Field label="Tags">
              <Tags {...fields.tags} />
            </Field>
            <Field label="Priority">
              <Select options={priorityOptions} {...fields.priority} />
            </Field>
          </PanelSectionContainer>
        </PanelSection>
      </PanelContent>
    </PanelContainer>
  );
}

export default AddTasksPanel;
