import React from "react";
import MobilePanel from "./MobilePanel";
import { Categories } from "../panels/LayersPanel";

function CategoriesPanel() {
  return (
    <MobilePanel>
      <Categories />
    </MobilePanel>
  );
}

export default CategoriesPanel;
