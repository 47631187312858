import {
  useLocation,
  useHistory as useOriginalHistory,
} from "react-router-dom";

function useHistory() {
  const history = useOriginalHistory();
  const location = useLocation();
  return {
    ...history,
    push: (url, preserveSearch = true, ...args) => {
      if (preserveSearch) {
        history.push(url + location.search, ...args);
      } else {
        history.push(url, ...args);
      }
    },
  };
}

export default useHistory;
