import React, { useState, useRef } from "react";
import styled from "styled-components/macro";
import Popup from "components/Popup";
import Icon from "components/Icon";

const statuses = {
  S: {
    label: "Not Started",
    color: "rgb(74,74,74)",
  },
  IP: {
    label: "In Progress",
    color: "rgb(31,173,245)",
  },
  OH: {
    label: "On Hold",
    color: "rgb(143,165,54)",
  },
  C: {
    label: "Complete",
    color: "rgb(128,208,54)",
  },
};

const Wrapper = styled.div`
  position: relative;
`;

const Indicator = styled.div`
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  padding: 0.5em 1em;
  color: white;
  border-radius: 1.5em;
  background-color: ${(props) => props.color};
  text-transform: uppercase;
  font-weight: 600;
`;

const Status = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9em;
  cursor: pointer;

  &:hover {
    background-color: #eee;
  }

  > * {
    padding: 0.5em;
  }
`;

const SelectorWrapper = styled.div`
  width: 150px;
`;

function SelectorPopup({ value, onChange, onClose, ...rest }) {
  return (
    <Popup onClose={onClose} {...rest}>
      <SelectorWrapper>
        {Object.keys(statuses).map((status) => (
          <Status key={status} onClick={() => onChange(status)}>
            <Icon color={statuses[status].color} size={18}>
              play_arrow
            </Icon>
            <span>{statuses[status].label}</span>
          </Status>
        ))}
      </SelectorWrapper>
    </Popup>
  );
}

function StatusSection({ status, onChange, disabled }) {
  const [statusSelectorOpen, setStatusSelectorOpen] = useState(false);
  let wrapper = useRef();
  return (
    <Wrapper ref={wrapper}>
      {statusSelectorOpen && (
        <SelectorPopup
          parent={wrapper.current}
          value={status}
          onChange={(value) => {
            onChange(value);
            setStatusSelectorOpen(false);
          }}
          onClose={() => setStatusSelectorOpen(false)}
        />
      )}
      <Indicator
        disabled={disabled}
        color={statuses[status].color}
        onClick={() => !disabled && setStatusSelectorOpen(true)}
      >
        {statuses[status].label}
      </Indicator>
    </Wrapper>
  );
}

export default StatusSection;
