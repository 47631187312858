import React from "react";
import styled from "styled-components/macro";
import t from "prop-types";
import { TextInput } from "./Input";

const RangeInput = styled.input.attrs({ type: "range" })`
  background: none;

  &:focus {
    outline: none;
  }

  /* Chrome */
  -webkit-appearance: none;

  &::-webkit-slider-runnable-track {
    height: 1px;
    background: ${(props) => props.theme.colors.border};
    border: none;
    border-radius: 3px;
    background-image: linear-gradient(
      to right,
      ${(props) => props.theme.colors.text},
      ${(props) => props.theme.colors.text} ${(props) => props.value * 100}%,
      ${(props) => props.theme.colors.border} ${(props) => props.value * 100}%,
      ${(props) => props.theme.colors.border}
    );
  }

  &:hover::-webkit-slider-runnable-track {
    background-image: linear-gradient(
      to right,
      ${(props) => props.theme.colors.primary},
      ${(props) => props.theme.colors.primary} ${(props) => props.value * 100}%,
      ${(props) => props.theme.colors.border} ${(props) => props.value * 100}%,
      ${(props) => props.theme.colors.border}
    );
  }

  &::-webkit-slider-thumb {
    height: 12px;
    width: 12px;
    margin-top: -6px;
    border: none;
    border-radius: 50%;
    background: ${(props) => props.theme.colors.text};
    cursor: grab;
    -webkit-appearance: none;
  }

  &:hover::-webkit-slider-thumb {
    background: ${(props) => props.theme.colors.primary};
  }

  &:active::-webkit-slider-thumb {
    cursor: grabbing;
  }

  /* Firefox */
  &::-moz-range-thumb {
    height: 12px;
    width: 12px;
    border: none;
    border-radius: 50%;
    background: ${(props) => props.theme.colors.primary};
  }

  &::-moz-range-track {
    background: ${(props) => props.theme.colors.border};
  }

  &::-moz-range-progress {
    background: ${(props) => props.theme.colors.primary};
  }
`;

export function Slider({ onChange, ...props }) {
  return (
    <RangeInput onChange={(e) => onChange(Number(e.target.value))} {...props} />
  );
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const SliderFlex = styled(Slider)`
  flex: 1;
  margin-right: 16px;
`;

const InputNumber = styled(TextInput)`
  width: 56px;

  ::after {
    content: "%";
    color: ${(props) => props.theme.colors.text};
  }
`;

export function SliderPercentage({ value, onChange }) {
  return (
    <Wrapper>
      <SliderFlex
        value={value}
        onChange={onChange}
        min={0}
        max={1}
        step={0.01}
      />
      <InputNumber
        type={"number"}
        // Avoid displaying values as `5.99`
        value={Math.round(value * 100)}
        onChange={(e) => onChange(e.target.value / 100)}
        min={0}
        max={100}
        step={1}
      />
    </Wrapper>
  );
}

Slider.propTypes = {
  value: t.number.isRequired,
  onChange: t.func.isRequired,
};

SliderPercentage.propTypes = {
  value: t.number.isRequired,
  onChange: t.func.isRequired,
};
