import * as v from "utils/validation";

import React from "react";
import TextInput, { TextareaInput } from "components/TextInput";

import Button from "components/Button";
import { Form } from "./base";
import Label from "components/Label";
import Select from "react-select";
import history from "utils/history";
import styled from "styled-components/macro";
import { useForm } from "utils/form";
import { CREATE_LAYER } from "./CreateLayer";
import { useMutation } from "@apollo/react-hooks";
import { sendEvent } from "utils/ga";

const Error = styled.div`
  font-size: 0.9em;
  color: red;
  margin-top: -20px;
  margin-bottom: 20px;
`;

const Template = styled(Select)`
  margin-bottom: 20px;
`;

const templates = {
  "Satellite Imagery (4 band)": {
    type: "4-band",
    description:
      "Selects only the first three bands. Treats the absolute black color (RGB 0,0,0) as the no-data value, setting it as transparent in the final render. Meta tiling for caching is set at 4,4.",
    metadata: {
      "4band": true,
      mapserver: {
        layer_params: {
          OFFSITE: "#000000",
        },
      },
      mapproxy: { meta_size: [4, 4] },
    },
  },
  "Satellite Imagery": {
    type: "3-band",
    description:
      "Treats the absolute black color (RGB 0,0,0) as the no-data value, setting it as transparent in the final render. Meta tiling for caching is set at 4,4.",
    metadata: {
      mapserver: {
        layer_params: {
          OFFSITE: "#000000",
        },
      },
      mapproxy: { meta_size: [4, 4] },
    },
  },
};

export default function CreateRasterForm() {
  const [createLayer] = useMutation(CREATE_LAYER);
  const { onSubmit, fields, success } = useForm({
    fields: ["title", "description", "template"],
    validate: v.createValidator({
      title: [v.required],
      template: [v.required, v.oneOf(Object.keys(templates))],
    }),
    onSubmit: async ({ title, description, template }) => {
      const result = await createLayer({
        variables: {
          input: {
            title,
            description,
            metadataType: templates[template].type,
            type: "R",
          },
        },
      });
      const layer = result.data.createLayer.layer;
      sendEvent("layers", "createNewRasterLayer");
      return history.push(`/app/layers/${layer.id}/`);
    },
  });
  return (
    <Form onSubmit={onSubmit}>
      <h4>Raster Layer</h4>
      <p>
        Images must be properly georeferenced 8 bit, 16 bit or float GeoTIFFs.
        It will work with any number of bands, any image size, any projection.
      </p>
      <TextInput label="Title" {...fields.title} />
      <TextareaInput label="Description" {...fields.description} />
      <Label>Template</Label>
      <Template
        options={Object.keys(templates).map((v) => ({
          value: v,
          label: v,
        }))}
        {...fields.template}
        onChange={(v) => {
          fields.template.onChange(v.value);
          sendEvent({
            eventCategory: "layers",
            eventAction: "changeCreateRasterTemplate",
            eventValue: v.value,
          });
        }}
        value={{ label: fields.template.value }}
      />
      {fields.template.errorText && <Error>{fields.template.errorText}</Error>}
      {fields.template.value in templates && [
        <p key={1}>{templates[fields.template.value].description}</p>,
        <pre key={2}>
          {JSON.stringify(templates[fields.template.value].metadata, null, 2)}
        </pre>,
      ]}
      <Button type="submit">Create</Button> {success && "Done!"}
    </Form>
  );
}
