import React, { useState, useMemo, useEffect } from "react";
import panels from "panels";

export const SkywatchContext = React.createContext({
  drawingSkywatchAOI: false,
  polygon: null,
});

export function SkywatchProvider({ children, openPanel }) {
  const [drawingSkywatchAOI, setDrawingSkywatchAOI] = useState(false);
  const [polygon, setPolygon] = useState(null);

  // Remove the polygon from the map when closing these panels
  useEffect(() => {
    if (![panels.SKYWATCH, panels.ANALYTICS].includes(openPanel)) {
      setDrawingSkywatchAOI(false);
      setPolygon(null);
    }
  }, [openPanel]);

  const value = useMemo(
    () => ({
      drawingSkywatchAOI: drawingSkywatchAOI,
      polygon: polygon,
      setDrawingSkywatchAOI: (value) => {
        setDrawingSkywatchAOI(value);
      },
      setPolygon: (value) => {
        setPolygon(value);
      },
    }),
    [drawingSkywatchAOI, polygon]
  );

  return (
    <SkywatchContext.Provider value={value}>
      {children}
    </SkywatchContext.Provider>
  );
}
