import { Layers } from "../routes/map/components/maps/Base/layers";
import React, { useContext, useEffect, useState } from "react";
import Legend, {
  LayersLegend,
} from "../routes/map/components/maps/Base/Legend";
import styled from "styled-components/macro";
import useMapPopupHeight from "hooks/useMaxPopupHeight";
import { GeoJSONLayer, Marker } from "react-mapbox-gl";
import { HoveredLayerContext } from "../routes/map/components/maps/Base/HoveredLayerContext";
import { TemporaryFeatureContext } from "./panels/TemporaryFeatureContext";
import { SkywatchContext } from "../routes/map/components/maps/Base/SkywatchContext";
import MapResizeObserver from "../routes/map/components/maps/Base/MapResizeObserver";
import PointerCursorOnInteractiveLayers from "../routes/map/components/maps/Base/PointerCursorOnInteractiveLayers";
import PopupOnVectorLayers from "./PopupOnVectorLayers";
import { useBaseUrl, useViewport, useSelectedProject } from "./URLState";
import LoadingScreen from "components/LoadingScreen";
import {
  MaxPopupHeightContext,
  HoveredLayerExtentGeoJSON,
  basemaps,
  Map as MapboxGLMap,
} from "../routes/map/components/maps/Base/Map";
import { useLocation, useHistory } from "react-router";
import Icon from "./components/Icon";
import { useAuth } from "hooks/data/auth";
import { useLayerSelections } from "hooks/useLayerSelections";
import { useScreenshot } from "../../../hooks/useScreenshot";

//---------------------------------------------tasks
import { HasWorkspacePermission } from "utils/Restricted";
import useMapPopup from "hooks/useMapPopup";
import Popup from "../routes/map/components/maps/Tasks/Popup";
import TaskModal from "components/TaskModal";
import { useTasksMutations } from "hooks/mutations/useTasksMutations";
import { useSelectedTask } from "../SelectedTaskContext";
import MapboxDrawControl from "../routes/map/components/maps/Base/MapboxDrawControl";
import MapboxNavigationControl from "../routes/map/components/maps/Base/MapboxNavigationControl";
import { TasksLayers } from "../routes/map/components/maps/Tasks/Map";
import { useTasks } from "hooks/useTasks";
//--------------------------------------------/tasks

import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useAnnotationsTasksVisibility } from "./AnnotationsTasksVisibility";
import { useIsMobile } from "hooks/useMatchMedia";
import { useSelectedFeature } from "hooks/useSelectedFeature";
import { SearchResultContext } from "./panels/SearchResultContext";

export const GET_SCENARIO_DATA = gql`
  query getScenarioData($scenarioId: ID!) {
    scenario(id: $scenarioId) {
      id
      defaultView
      basemap
      name
    }
  }
`;

const MapContainer = styled.div`
  flex: 1;
`;

export const darkBasemaps = {
  ...basemaps,
  Streets: "mapbox://styles/mapbox/dark-v10",
};

const MarkerIcon = styled(Icon).attrs({ svg: true, size: 30 })`
  color: ${({ color }) => color || "green"};
`;

const TemporaryFeature = ({ id, feature }) =>
  feature.geometry.type === "Point" ? (
    <Marker coordinates={feature.geometry.coordinates} anchor="bottom">
      <MarkerIcon color={feature.layer?.paint["circle-color"]}>
        marker
      </MarkerIcon>
    </Marker>
  ) : (
    <GeoJSONLayer
      data={feature}
      id={id}
      linePaint={{ "line-color": "green", "line-width": 2 }}
      fillPaint={{ "fill-color": "green", "fill-opacity": 0.2 }}
    />
  );

function Map() {
  const { loggedIn } = useAuth();
  const { projectId } = useSelectedProject();
  const { viewport, updateViewport } = useViewport();
  const { extent: hoveredLayerExtent } = useContext(HoveredLayerContext);
  const { searchResultLocation } = useContext(SearchResultContext);
  const { layerSelections } = useLayerSelections();
  const { feature } = useSelectedFeature();

  const isMobile = useIsMobile();

  const { setScreenShotMap, setScreenShotLoading } = useScreenshot();

  const location = useLocation();
  const history = useHistory();
  const baseUrl = useBaseUrl();

  const {
    filteredTasks,
    annotations,
    editingFeature,
    setEditingFeature,
  } = useTasks();
  const { data } = useQuery(GET_SCENARIO_DATA, {
    variables: { scenarioId: projectId },
    skip: !projectId,
  });

  let filteredLayerSelections = [];
  let basemap;
  let scenario;
  let features;

  if (layerSelections) {
    filteredLayerSelections = layerSelections.filter((ls) => ls.on);
  }

  if (data?.scenario) {
    scenario = data.scenario;
    basemap = scenario.basemap || "Streets";
  }

  if (filteredTasks) {
    features = [...filteredTasks, ...annotations];
  }

  function updateView(map, event) {
    if (event.fitboundUpdate) {
      return;
    }
    const bounds = map.getBounds();
    const view = [
      bounds.getWest(),
      bounds.getSouth(),
      bounds.getEast(),
      bounds.getNorth(),
    ];
    updateViewport(view);
  }
  const { containerRef, maxPopupHeight } = useMapPopupHeight();
  const { polygon } = useContext(SkywatchContext);

  //---------------------------------------------tasks
  const [taskModalOpen, setTaskModalOpen] = useState(false);
  const {
    tasks: shouldDisplayTasks,
    annotations: shouldDisplayAnnotations,
  } = useAnnotationsTasksVisibility();
  const { deleteTask, createTask, updateTaskGeometry } = useTasksMutations();
  const { selectTask } = useSelectedTask();
  const taskPopup = useMapPopup();
  const { temporaryFeature, setTemporaryFeature } = useContext(
    TemporaryFeatureContext
  );
  const { drawingSkywatchAOI, setPolygon, setDrawingSkywatchAOI } = useContext(
    SkywatchContext
  );

  useEffect(() => {
    if (editingFeature) {
      taskPopup.close();
    }
  }, [editingFeature, taskPopup]);

  const onFeatureClick = (feature) => {
    if (feature.properties.status === "null") {
      history.push(`${baseUrl}/annotation/${feature.id}`);
    } else {
      selectTask(feature.id);
      setTaskModalOpen(true);
    }
  };

  const onGeometrySave = (feature) => {
    updateTaskGeometry(feature.id, feature.geometry, feature.properties.radius);
    setEditingFeature(null);
  };

  const onObjectDelete = (id) => {
    deleteTask(id);
    taskPopup.close();
  };

  const onDrawCreated = async (feature, selectedScenarioId) => {
    const { pathname } = location;

    const isOnTasksPanel = pathname.endsWith("/tasks");
    const isOnCreateTaskPanel = pathname.endsWith("/tasks/new");

    if (isOnTasksPanel || isOnCreateTaskPanel) {
      setTemporaryFeature(feature, "/tasks");
      isOnTasksPanel && history.push(`${baseUrl}/tasks/new`);
    } else {
      const {
        data: {
          createTask: {
            task: { id },
          },
        },
      } = await createTask({
        geometry: feature.geometry,
        scenario: selectedScenarioId,
        radius: feature.properties.radius,
      });

      history.push(`${baseUrl}/annotation/add/${id}`);
    }
  };

  //--------------------------------------------/tasks

  if (!(scenario && viewport)) {
    return (
      <MapContainer>
        <LoadingScreen />
      </MapContainer>
    );
  }

  let view = viewport.split(",").map(Number);

  const preventScrollDrag = (map, e) => {
    const isTouchEvent = (e) => e.originalEvent && "touches" in e.originalEvent;
    const isTwoFingerTouch = (e) => e.originalEvent.touches.length >= 2;
    if (isTouchEvent(e) && isTwoFingerTouch(e)) {
      map.dragPan.disable();
    }
    if (isTouchEvent(e) && !isTwoFingerTouch(e)) {
      map.dragPan.enable();
    }
  };

  if (scenario) {
    return (
      <MaxPopupHeightContext.Provider value={maxPopupHeight}>
        <MapContainer ref={containerRef}>
          <Legend layerSelections={filteredLayerSelections} />
          {!isMobile && (
            <LayersLegend layerSelections={filteredLayerSelections} />
          )}
          <HasWorkspacePermission type="rw">
            {(hasWorkspaceWritePermission) => (
              <MapboxGLMap
                style={darkBasemaps[basemap]}
                containerStyle={{ height: "100%" }}
                onZoomEnd={(map, event) => {
                  updateView(map, event);
                  setScreenShotLoading(false);
                }}
                onDragEnd={updateView}
                fitBounds={[
                  [view[0], view[1]],
                  [view[2], view[3]],
                ]}
                onZoomStart={() => setScreenShotLoading(true)}
                onStyleData={(map) => {
                  setScreenShotMap(map);
                  setScreenShotLoading(false);
                }}
                onTouchStart={(map, e) => preventScrollDrag(map, e)}
              >
                {taskModalOpen && (
                  <TaskModal
                    isOpen={taskModalOpen}
                    onClose={() => setTaskModalOpen(false)}
                  />
                )}
                {taskPopup.value !== null && (
                  <Popup
                    {...taskPopup.popupProps}
                    hasWorkspaceWritePermission={hasWorkspaceWritePermission}
                    onView={() => {
                      selectTask(taskPopup.value.feature.id);
                      setTaskModalOpen(true);
                    }}
                    onEdit={() => {
                      const task = features.find(
                        (t) => t.id === String(taskPopup.value.feature.id)
                      );
                      setEditingFeature({
                        type: "Feature",
                        geometry: task.geometry,
                        id: task.id,
                        properties: {
                          radius: task.radius,
                        },
                      });
                    }}
                    onDelete={() => {
                      if (window.confirm("Are you sure?")) {
                        onObjectDelete(String(taskPopup.value.feature.id));
                      }
                    }}
                  />
                )}
                {(shouldDisplayTasks || shouldDisplayAnnotations) && features && (
                  <TasksLayers
                    tasks={features.filter((t) => {
                      // Don't show if we're currently editing it
                      if (editingFeature && t.id === editingFeature.id) {
                        return false;
                      }

                      const isAnnotation = t.status === null;
                      if (isAnnotation && !shouldDisplayAnnotations) {
                        return false;
                      }
                      if (!isAnnotation && !shouldDisplayTasks) {
                        return false;
                      }

                      return true;
                    })}
                    onFeatureClick={onFeatureClick}
                    onFeatureHover={taskPopup.open}
                  />
                )}
                {loggedIn ? (
                  <MapboxDrawControl
                    onCreate={(feature) => {
                      if (drawingSkywatchAOI) {
                        setPolygon(feature);
                        setDrawingSkywatchAOI(false);
                      } else onDrawCreated(feature, projectId);
                    }}
                    editFeature={editingFeature}
                    onEdit={onGeometrySave}
                    controls={{
                      point: true,
                      polygon: true,
                      rectangle: true,
                    }}
                    mode={drawingSkywatchAOI ? "draw_rectangle" : null}
                    dark={true}
                  />
                ) : (
                  <MapboxDrawControl dark={true} />
                )}
                {hoveredLayerExtent && (
                  <HoveredLayerExtentGeoJSON data={hoveredLayerExtent} />
                )}
                <MapResizeObserver />
                <PointerCursorOnInteractiveLayers />
                <PopupOnVectorLayers />
                <Layers layerSelections={filteredLayerSelections} />
                {feature && (
                  <TemporaryFeature
                    id="temporary-feature-click"
                    feature={feature}
                  />
                )}
                {searchResultLocation !== null && (
                  <Marker coordinates={searchResultLocation} anchor="bottom">
                    <MarkerIcon>marker</MarkerIcon>
                  </Marker>
                )}
                {temporaryFeature && (
                  <TemporaryFeature
                    id="temporary-feature"
                    feature={temporaryFeature}
                  />
                )}
                {polygon && (
                  <TemporaryFeature id="skywatch-draw" feature={polygon} />
                )}
                <MapboxNavigationControl
                  dark
                  position={isMobile ? "bottom-right" : "top-left"}
                />
              </MapboxGLMap>
            )}
          </HasWorkspacePermission>
        </MapContainer>
      </MaxPopupHeightContext.Provider>
    );
  } else return null;
}

export default Map;
