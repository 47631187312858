import { useContext, useEffect, useCallback } from "react";
import { MapContext } from "react-mapbox-gl";
import { isEqual } from "lodash-es";

export default function LayerOrderingManager({ layerSelections }) {
  const map = useContext(MapContext);

  const reorderLayers = useCallback(() => {
    let layers;
    try {
      layers = map.getStyle().layers;
    } catch (e) {
      // There seems to be a bug in mapbox-gl-js that makes getStyle() fail
      // with "Cannot read property 'version' of undefined". It's probably here:
      // https://github.com/mapbox/mapbox-gl-js/blob/00ceca1645d4f7e91051619db2f9da31f59172dc/src/style/style.js#L988
      // Given that this is executed on the 'styledata' event, this component seems
      // to work even if we skip this effect when the call to getStyle() fails.
      return;
    }

    const layersWithZIndex = layers.filter(
      (layer) => layer.metadata && layer.metadata["slingshot:zIndex"]
    );

    const sortedLayers = layersWithZIndex
      .slice()
      .sort(
        (a, b) =>
          a.metadata["slingshot:zIndex"] - b.metadata["slingshot:zIndex"]
      );

    const layersWithZIndexIds = layersWithZIndex.map((l) => l.id);
    const sortedLayersIds = sortedLayers.map((l) => l.id);

    if (!isEqual(layersWithZIndexIds, sortedLayersIds)) {
      sortedLayersIds.forEach((layer, i) =>
        map.moveLayer(layer, sortedLayersIds[i + 1])
      );
    }
  }, [map]);

  useEffect(() => {
    reorderLayers();
  }, [layerSelections, reorderLayers]);

  useEffect(() => {
    map.on("load", reorderLayers);
    map.on("styledata", reorderLayers);

    return () => {
      map.off("load", reorderLayers);
      map.off("styledata", reorderLayers);
    };
  }, [map, reorderLayers]);

  return null;
}
