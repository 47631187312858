import React, { useMemo } from "react";
import useToggle from "hooks/useToggle";

export const WorkLayerVisibleContext = React.createContext();

export function WorkLayerVisibleProvider({ children }) {
  const [workLayerVisible, toggleWorkLayerVisible] = useToggle(true);
  return (
    <WorkLayerVisibleContext.Provider
      value={useMemo(() => ({ workLayerVisible, toggleWorkLayerVisible }), [
        toggleWorkLayerVisible,
        workLayerVisible,
      ])}
    >
      {children}
    </WorkLayerVisibleContext.Provider>
  );
}
