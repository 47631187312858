import React, { useContext, useState } from "react";
import styled from "styled-components";
import Icon from "./Icon";
import t from "prop-types";
import omit from "lodash/fp/omit";
import { TunnelPlaceholder, Tunnel } from "utils/tunnels";

export const ToastWrapper = styled.div`
  position: relative;
  display: flex;
  position: relative;
  align-items: center;
  min-width: 320px;
  padding: 16px;
  font-weight: 600;
  border: solid 2px #111518;
  border-radius: 6px 4px 4px 6px;
  background: #111518;

  &:before {
    content: "";
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    width: 5px;
    position: absolute;
    border-radius: 4px 0 0 4px;
    background-color: ${({ type, theme }) => theme.colors[type]};
  }

  & + & {
    margin-top: 16px;
  }
`;

export const ToastIcon = styled(Icon)`
  margin-right: 16px;
  font-weight: 800;
  color: ${({ type, theme }) => theme.colors[type]};
`;

export function Toast({ type, children }) {
  const Icons = {
    success: "done",
    warning: "warning",
    error: "not_interested",
    info: "info",
  };
  return (
    <ToastWrapper type={type || "info"}>
      {type && <ToastIcon type={type}>{Icons[type]}</ToastIcon>}
      {children}
    </ToastWrapper>
  );
}

const NotificationContext = React.createContext();

export const useNotification = () => useContext(NotificationContext);

export function NotificationProvider({ children }) {
  const [notifications, setNotifications] = useState({});
  const [timeouts] = useState({});

  const showNotification = ({ id, type, content }) => {
    setNotifications((notifications) => ({
      ...notifications,
      [id]: { type, content },
    }));
  };

  const closeNotification = (id) => setNotifications(omit(id));

  const fireNotification = (
    { id = Math.random(), ...notification },
    delay = 3000
  ) => {
    clearTimeout(timeouts[id]);
    showNotification({ id, ...notification });
    timeouts[id] = setTimeout(() => closeNotification(id), delay);
  };

  const value = {
    notifications: Object.values(notifications),
    showNotification,
    closeNotification,
    fireNotification,
  };

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
}

const NotificationsToastWrapper = styled.div`
  position: absolute;
  top: 64px;
  right: 16px;
  z-index: 1;
`;

export function NotificationTunnel({ children, ...props }) {
  return (
    <Tunnel id="notifications">
      <Toast {...props}>{children}</Toast>
    </Tunnel>
  );
}

export function Notification() {
  const { notifications } = useContext(NotificationContext);
  return (
    <>
      <NotificationsToastWrapper>
        <TunnelPlaceholder id="notifications" />
        {notifications.length > 0 &&
          notifications.map(({ type, content }, index) => (
            <Toast key={index} type={type}>
              {content}
            </Toast>
          ))}
      </NotificationsToastWrapper>
    </>
  );
}

Toast.propTypes = {
  type: t.string,
};
