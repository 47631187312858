import * as v from "utils/validation";

import TextInput, { TextareaInput } from "components/TextInput";

import Button from "components/Button";
import { Form } from "./base";
import React from "react";
import history from "utils/history";
import { useForm } from "utils/form";
import { CREATE_LAYER } from "./CreateLayer";
import { useMutation } from "@apollo/react-hooks";
import { sendEvent } from "utils/ga";

export default function CreateVectorForm() {
  const [createLayer] = useMutation(CREATE_LAYER);
  const { onSubmit, fields, success } = useForm({
    fields: ["title", "description"],
    validate: v.createValidator({
      title: [v.required],
    }),
    onSubmit: async ({ title, description }) => {
      const result = await createLayer({
        variables: {
          input: {
            title,
            description,
            type: "v",
          },
        },
      });
      const layer = result.data.createLayer.layer;
      sendEvent("layers", "createNewVectorLayer");
      history.push(`/app/layers/${layer.id}/`);
    },
  });
  return (
    <div>
      <Form onSubmit={onSubmit}>
        <h4>Vector Layer</h4>
        <TextInput
          label="Title"
          {...fields.title}
          data-test="create-vector-layer-title-input"
        />
        <TextareaInput
          label="Description"
          {...fields.description}
          data-test="create-vector-layer-description-input"
        />
        <Button type="submit" data-test="create-vector-layer-button">
          Create
        </Button>{" "}
        {success && "Done!"}
      </Form>
    </div>
  );
}
