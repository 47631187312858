import React, { useRef, useState } from "react";
import styled from "styled-components";
import { PanelListItemIcon } from "../panels/layout";

const DropWrapper = styled.div`
  padding: 16px;
  border: 2px dashed
    ${({ theme, dragging }) =>
      dragging ? theme.colors.primary : theme.colors.border};
  border-radius: 4px;
  text-align: center;
  margin-top: 0;

  :not(:last-child) {
    margin-bottom: 8px;
  }
`;

const BrowseButton = styled.span.attrs({ role: "button" })`
  cursor: pointer;
  color: ${(props) => props.theme.colors.primary};
`;

const SecondaryText = styled.div`
  font-size: 11px;
  color: ${(props) => props.theme.colors.muted};
`;

const UploadedFileInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export function UploadSection({
  title,
  secondaryText,
  value,
  onChange,
  multiple,
  ...props
}) {
  const fileInput = useRef();
  const [dragging, setDragging] = useState(false);

  const hasValue = value instanceof Array ? value.length > 0 : Boolean(value);

  const onDrop = (e) => {
    e.preventDefault();
    onChange(multiple ? e.dataTransfer.files : e.dataTransfer.files[0]);
  };
  const onDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };
  const onDragLeave = () => {
    setDragging(false);
  };
  const onDeleteFile = () => {
    onChange("");
    fileInput.current.value = "";
    fileInput.current.files = null;
    setDragging(false);
  };

  return (
    <DropWrapper
      dragging={dragging}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
    >
      <input
        type="file"
        style={{ display: "none" }}
        onChange={(e) =>
          onChange(multiple ? e.target.files : e.target.files[0])
        }
        ref={fileInput}
        multiple={multiple}
        {...props}
      />
      <div>
        Drag and drop or{" "}
        <BrowseButton onClick={() => fileInput.current.click()}>
          browse
        </BrowseButton>
      </div>
      <SecondaryText>{secondaryText}</SecondaryText>

      {hasValue && (
        <UploadedFileInfo>
          {multiple
            ? [...value].map(({ name }) => name).join(", ")
            : value.name}
          <PanelListItemIcon title="Remove file" onClick={onDeleteFile}>
            delete
          </PanelListItemIcon>
        </UploadedFileInfo>
      )}
    </DropWrapper>
  );
}
