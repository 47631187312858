import React, { useState, useContext } from "react";
import SidePanel, { Content } from "components/SidePanel";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import LoadingScreen from "components/LoadingScreen";
import FullScreenMessage from "components/FullScreenMessage";
import Event, { cleanActions } from "components/TaskModal/ActivityEvent";
import TaskModal from "components/TaskModal";
import { Context as AppStateContext } from "appState";
import styled from "styled-components";
import { useSelectedTask } from "routes/app/SelectedTaskContext";

const NoActivity = styled(FullScreenMessage)`
  background-color: unset;
`;

export const GET_SCENARIO_ACTIVITY = gql`
  query getScenarioActivity($id: ID!) {
    scenario(id: $id) {
      id
      actions {
        id
        verb
        timestamp
        data
        actor {
          id
          username
          firstName
          lastName
          customer {
            id
            picture
          }
        }
        actionObject {
          __typename
          ... on Comment {
            id
            text
          }
          ... on Attachment {
            id
            image
            thumbnailLarge
          }
        }
        target {
          id
          status
          geometry
          title
        }
      }
    }
  }
`;

function ScenarioActivityPanel({ onClose }) {
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const { scenario: selectedScenario } = useContext(AppStateContext);
  const { selectTask } = useSelectedTask();
  const { data, loading } = useQuery(GET_SCENARIO_ACTIVITY, {
    variables: { id: String(selectedScenario) },
  });

  if (!data) return null;
  const scenario = data.scenario;

  return (
    <>
      {isTaskModalOpen && (
        <TaskModal
          isOpen={isTaskModalOpen}
          onClose={() => setIsTaskModalOpen(false)}
        />
      )}
      <SidePanel title="Scenario Activity" onClose={onClose}>
        {loading ? (
          <LoadingScreen />
        ) : scenario.actions.length === 0 ? (
          <NoActivity style={{ backgroundColor: "unset" }}>
            No activity
          </NoActivity>
        ) : (
          <Content>
            {cleanActions(scenario.actions).map((action, index) => (
              <Event
                key={index}
                action={action}
                showTarget
                datePosition="bottom"
                onTargetClick={() => {
                  selectTask(action.target.id);
                  setIsTaskModalOpen(true);
                }}
              />
            ))}
          </Content>
        )}
      </SidePanel>
    </>
  );
}

export default ScenarioActivityPanel;
