import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback,
} from "react";
import history from "utils/history";
import { useAuth } from "hooks/data/auth";

const NewUIContext = React.createContext();

export function useUISwitch() {
  return useContext(NewUIContext);
}

export function DisplayNewUIProvider({ children }) {
  const { loggedIn } = useAuth();
  const [displayNewUI, setDisplayNewUI] = useState(() =>
    loggedIn ? Boolean(localStorage.getItem("shouldDisplayNewUI")) : true
  );

  useEffect(() => {
    if (loggedIn && !Boolean(localStorage.getItem("shouldDisplayNewUI"))) {
      setDisplayNewUI(false);
    }
  }, [loggedIn]);

  const switchToNewUI = useCallback(() => {
    localStorage.setItem("shouldDisplayNewUI", true);
    setDisplayNewUI(true);
    history.push("/app/");
  }, []);

  const switchToOldUI = useCallback(() => {
    localStorage.removeItem("shouldDisplayNewUI");
    setDisplayNewUI(false);
    history.push("/app/");
  }, []);

  const value = useMemo(
    () => ({ displayNewUI, switchToNewUI, switchToOldUI }),
    [displayNewUI, switchToNewUI, switchToOldUI]
  );

  return (
    <NewUIContext.Provider value={value}> {children} </NewUIContext.Provider>
  );
}
