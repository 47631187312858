import React, { useState } from "react";
import styled, { css } from "styled-components/macro";

import Coordinates from "coordinate-parser";
import Downshift from "downshift";
import Icon from "components/Icon";
import { BingProvider } from "leaflet-geosearch";
import useDebouncedCallback from "hooks/useDebouncedCallback";
import { getOverviewBbox } from "utils/geometry";
import { validBbox } from "utils/validation";
import { sendEvent } from "utils/ga";

export const provider = new BingProvider({
  params: {
    key: process.env.REACT_APP_BING_KEY,
  },
});

const Wrapper = styled.div`
  width: 500px;
  align-self: center;
  position: relative;
`;

const InputWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const Input = styled.input`
  flex: 1;
  border: none;
  outline: none;

  &::placeholder {
    color: #999;
  }
`;

const Autocomplete = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  z-index: 1000;
  background-color: white;
  border: none 1px 1px 1px;
  border-color: #eee;
`;

const AutocompleteItem = styled.div`
  font-size: 0.9em;
  padding: 1em;
  ${(props) =>
    props.highlighted &&
    css`
      background-color: #eee;
    `};
`;

const SearchIcon = styled(Icon)`
  font-size: 22px;
  color: #999;
  padding: 0.5em;
`;

const CloseIcon = styled(SearchIcon)`
  cursor: pointer;
`;

export const isValidPosition = (position) => {
  try {
    const coords = new Coordinates(position);
    return coords;
  } catch (error) {
    return null;
  }
};

const getBounds = (bbox) => [
  [bbox[1], bbox[0]],
  [bbox[3], bbox[2]],
];

function Search({ mapState, setSearchResultLocation, searchResultLocation }) {
  const [items, setItems] = useState([]);

  const onSelect = (item) => {
    if (item) {
      const bounds = [
        item.bounds[0][1],
        item.bounds[0][0],
        item.bounds[1][1],
        item.bounds[1][0],
      ];
      mapState.setParams({ view: bounds.join(",") });
      setSearchResultLocation([item.x, item.y]);
      sendEvent("search", "selectSearchResult", item.label);
    }
  };

  const [onChange] = useDebouncedCallback((query) => {
    if (validBbox(query)) {
      setItems([{ label: query, bounds: getBounds(query.split(",")) }]);
    } else {
      const position = isValidPosition(query);
      if (position) {
        const latitude = position.getLatitude();
        const longitude = position.getLongitude();
        const bbox = getOverviewBbox({
          type: "Point",
          coordinates: [longitude, latitude],
        });
        setItems([{ label: query, bounds: getBounds(bbox) }]);
      } else provider.search({ query }).then((items) => setItems(items));
    }
    sendEvent("search", "typeSearch", query);
  }, 300);

  return (
    <Downshift
      onInputValueChange={onChange}
      onChange={onSelect}
      items={items}
      itemToString={(item) => (item ? item.label : "")}
      render={({
        getInputProps,
        getItemProps,
        getRootProps,
        isOpen,
        inputValue,
        selectedItem,
        highlightedIndex,
        setState,
      }) => (
        // https://github.com/paypal/downshift/issues/604
        <Wrapper {...getRootProps({}, { suppressRefError: true })}>
          <InputWrapper>
            {searchResultLocation ? (
              <CloseIcon
                onClick={() => {
                  setSearchResultLocation(null);
                  setState({
                    inputValue: "",
                    selectedItem: null,
                    isOpen: false,
                  });
                }}
              >
                close
              </CloseIcon>
            ) : (
              <SearchIcon>search</SearchIcon>
            )}
            <Input
              {...getInputProps({
                placeholder:
                  "Type to search coordinates, bounding box, places, objects or tasks.",
              })}
            />
          </InputWrapper>
          {isOpen && (
            <Autocomplete>
              {items.map((item, index) => (
                <AutocompleteItem
                  key={index}
                  highlighted={index === highlightedIndex}
                  {...getItemProps({ item })}
                >
                  {item.label}
                </AutocompleteItem>
              ))}
            </Autocomplete>
          )}
        </Wrapper>
      )}
    />
  );
}

export default Search;
