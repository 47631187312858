import React, { useContext } from "react";
import { useId } from "@reach/auto-id";
import styled from "styled-components";

const RadioGroupNameContext = React.createContext();

export function RadioGroup({ children, value, onChange }) {
  const id = useId();
  return (
    <RadioGroupNameContext.Provider
      value={{ groupId: id, onChange, checkedValue: value }}
    >
      {children}
    </RadioGroupNameContext.Provider>
  );
}

const RadioOptionWrapper = styled.div`
  input[type="radio"] {
    display: none;
  }
  label {
    cursor: pointer;
    display: flex;
    align-items: center;

    ::before {
      content: "";
      display: block;
      height: 16px;
      width: 16px;
      margin-right: 8px;
      border-radius: 8px;
      border: 1px solid ${({ theme }) => theme.colors.border};
      transition: background-color 0.4s ease-out;
    }
  }

  input[type="radio"]:checked + label::before {
    background-color: ${({ theme }) => theme.colors.primary};
    border: none;
  }
`;

export function RadioOption({ label, value, ...props }) {
  const { groupId, checkedValue, onChange } = useContext(RadioGroupNameContext);

  const id = useId();
  return (
    <RadioOptionWrapper {...props}>
      <input
        id={id}
        type="radio"
        name={groupId}
        value={value}
        checked={checkedValue === value}
        onChange={(e) => e.target.checked && onChange(value)}
      />
      <label for={id}>{label}</label>
    </RadioOptionWrapper>
  );
}
