import React, { useState } from "react";
import styled from "styled-components";
import { PanelContainer, PanelContent, PanelHeader, PanelIcon } from "./layout";
import {
  FixedPopup,
  FixedPopupSection,
  FixedPopupSectionIcon,
} from "../components/FixedPopup";
import { RadioGroup, RadioOption } from "../components/Radio";
import { SliderPercentage } from "../components/Slider";
import DateRangePicker from "../components/DateRangePicker";
import MarketplacePanelCard from "./MarketplacePanelCard";

const FilterIcon = styled(PanelIcon)`
  color: ${({ theme }) => theme.colors.muted};

  :hover {
    color: ${({ theme }) => theme.colors.text};
  }
`;

const RadioWrapper = styled.div`
  display: flex;

  > *:not(:last-child) {
    margin-right: 16px;
  }
`;

function FilterPopup() {
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [resolution, setResolution] = useState("high");
  const [cloudiness, setCloudiness] = useState(0.25);
  return (
    <FixedPopup>
      <FixedPopupSection
        icon={
          <FixedPopupSectionIcon muted>calendar_today</FixedPopupSectionIcon>
        }
        title="Date"
      >
        <DateRangePicker
          dateFrom={dateFrom}
          setDateFrom={(date) => {
            setDateFrom(date);
          }}
          dateTo={dateTo}
          setDateTo={(date) => {
            setDateTo(date);
          }}
        />
      </FixedPopupSection>
      <FixedPopupSection
        icon={<FixedPopupSectionIcon muted>cloud_queue</FixedPopupSectionIcon>}
        title="Cloudiness"
      >
        <SliderPercentage value={cloudiness} onChange={setCloudiness} />
      </FixedPopupSection>
      <FixedPopupSection
        icon={
          <FixedPopupSectionIcon svg muted>
            resolution
          </FixedPopupSectionIcon>
        }
        title="Resolution"
      >
        <RadioGroup value={resolution} onChange={setResolution}>
          <RadioWrapper>
            <RadioOption value="high" label="0m - 3m" />
            <RadioOption value="mid" label="4m - 8m" />
            <RadioOption value="low" label="9m +" />
          </RadioWrapper>
        </RadioGroup>
      </FixedPopupSection>
    </FixedPopup>
  );
}

function MarketplacePanel() {
  const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
  return (
    <PanelContainer data-test="marketplace-panel">
      {isFilterPopupOpen && <FilterPopup />}
      <PanelHeader
        title="Imagery Marketplace"
        iconRight={
          isFilterPopupOpen ? (
            <FilterIcon onClick={() => setIsFilterPopupOpen(false)}>
              clear
            </FilterIcon>
          ) : (
            <FilterIcon svg onClick={() => setIsFilterPopupOpen(true)}>
              filter
            </FilterIcon>
          )
        }
      />
      <PanelContent>
        <MarketplacePanelCard />
        <MarketplacePanelCard />
        <MarketplacePanelCard />
      </PanelContent>
    </PanelContainer>
  );
}

export default MarketplacePanel;
