import React, { useState, useRef } from "react";
import styled, { css } from "styled-components/macro";
import Icon from "components/Icon";
import PropTypes from "prop-types";
import useToggle from "hooks/useToggle";

const Wrapper = styled.div`
  order: 1;
  width: ${(props) => props.width || props.theme.dimensions.sidePanelWidth};
  background-color: ${(props) => props.theme.colors.lightBg};
  border-right: 1px solid #eee;
  display: flex;
  flex-direction: column;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  border-bottom: 1px solid #eee;
`;

export const HeaderTitle = styled.div`
  flex: 1;
  font-size: 1.1em;
`;

export const CloseIcon = styled(Icon).attrs({
  color: "#777",
  size: 20,
  children: "close",
})`
  cursor: pointer;
  margin-left: 1em;
`;

export const Content = styled.div`
  padding: 1em;
  height: 100%;
`;

const SectionHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  ${(props) =>
    props.collapsible &&
    css`
      user-select: none;
      cursor: pointer;
    `};

  cursor: {
  }
`;

const CollapseIcon = styled(Icon).attrs({ size: 18 })`
  cursor: pointer;
  user-select: none;
`;

const SectionHeaderTitle = styled.div`
  text-transform: uppercase;
  font-size: 1em;
  margin-left: 0.5em;
`;

export function SectionHeader({
  title,
  icon,
  children,
  collapseIcon,
  collapsible,
  onCollapseChange,
}) {
  return (
    <SectionHeaderWrapper onClick={onCollapseChange} collapsible={collapsible}>
      {collapsible && <CollapseIcon>{collapseIcon}</CollapseIcon>}
      <Icon size={18}>{icon}</Icon>
      <SectionHeaderTitle>{title}</SectionHeaderTitle>
      {children}
    </SectionHeaderWrapper>
  );
}

export function CollapsibleSection({ title, icon, children }) {
  const [collapsed, toggleCollapsed] = useToggle(false);
  return (
    <>
      <SectionHeader
        title={title}
        icon={icon}
        collapseIcon={
          collapsed ? "keyboard_arrow_right" : "keyboard_arrow_down"
        }
        collapsible="true"
        onCollapseChange={toggleCollapsed}
      />
      {!collapsed && children}
    </>
  );
}

export const List = styled.div`
  border: 1px solid #eee;
  overflow-y: auto;
  background-color: white;
  margin-bottom: 1em;
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
  padding: 0.8em;
  font-size: 1em;

  ${(props) =>
    props.selected &&
    css`
      background-color: #377a9b;
      color: white;
    `};
`;

export const ActionIcon = styled(Icon).attrs({ size: 18 })`
  margin: 0 0.25em;
  cursor: pointer;
  user-select: none;
`;

export const ListItemLabel = styled.div`
  flex: 1;
`;

export const ContentWrapper = styled.div`
  overflow-y: auto;
  flex: 1;
`;

const Input = styled.input`
  flex: 1;
  border: none;
  color: #222;

  &:focus {
    outline: none;
  }
`;

export function EditableItem({
  onSubmit,
  renderNotEditing,
  renderWrapper,
  inputProps = {},
}) {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState("");

  let input = useRef();

  const reset = () => {
    setEditing(false);
    setValue("");
  };

  const done = () => {
    onSubmit(value);
    reset();
  };

  if (editing) {
    return renderWrapper({
      children: [
        <Input
          key={1}
          ref={input}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          {...inputProps}
        />,
        value.length > 0 && (
          <ActionIcon key={2} onClick={done}>
            done
          </ActionIcon>
        ),
        <ActionIcon onClick={reset} key={3}>
          close
        </ActionIcon>,
      ],
    });
  } else {
    return renderNotEditing({
      onClick: () => {
        setEditing(true);
        setTimeout(() => input.current.focus(), 0);
      },
    });
  }
}

EditableItem.propTypes = {
  onSubmit: PropTypes.func,
  renderNotEditing: PropTypes.func,
  renderWrapper: PropTypes.func,
};

function Header({ onClose, title }) {
  return (
    <HeaderWrapper>
      <HeaderTitle>{title}</HeaderTitle>
      {onClose && <CloseIcon onClick={onClose}>close</CloseIcon>}
    </HeaderWrapper>
  );
}

function SidePanel({ title, children, onClose, headerContent, ...props }) {
  return (
    <Wrapper {...props}>
      {headerContent ? (
        <HeaderWrapper>{headerContent}</HeaderWrapper>
      ) : (
        <Header onClose={onClose} title={title} />
      )}
      <ContentWrapper>{children}</ContentWrapper>
    </Wrapper>
  );
}

export default SidePanel;
