import styled, { css } from "styled-components/macro";

import EditableValue from "components/EditableValue";
import React from "react";

const TitleWrapper = styled.h5`
  width: 100%;
  padding: 0.5em;
  font-size: 1.4em;
  font-weight: 400;
  margin: 0;
  margin-right: 1em;
  border-radius: 3px;
  color: ${(props) =>
    props.isPlaceholder ? "inherit" : props.theme.colors.mutedTextColor};

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        background-color: #eee;
      }
    `};
`;

const TitleValue = ({ children, disabled, ...props }) => (
  <TitleWrapper
    {...props}
    disabled={disabled}
    isPlaceholder={Boolean(children)}
  >
    {children ? children : disabled ? "No title" : "Type a title"}
  </TitleWrapper>
);

const TitleInput = styled.input`
  padding: 0.5em;
  border: none;
  font-size: 1.4em;
  font-weight: 400;
  margin: 0;
  margin-right: 1em;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

const Title = ({ value, onChange, disabled }) => (
  <EditableValue
    value={value}
    disabled={disabled}
    onChange={onChange}
    renderValue={(props) => (
      <TitleValue data-test="task-title" disabled={disabled} {...props} />
    )}
    renderInput={(props) => <TitleInput data-test="task-title" {...props} />}
  />
);

export default Title;
