import Icon from "./Icon";
import React from "react";
import styled from "styled-components/macro";

export const Wrapper = styled.div`
  overflow-y: auto;
  max-height: ${(props) => props.maxHeight}px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
`;

export const PlaceIcon = styled(Icon)`
  font-size: 24px;
  padding: 1rem;
`;

export const CloseIcon = styled(Icon)`
  color: #777;
  font-size: 18px;
  padding: 1rem;
  cursor: pointer;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 1.1em;
  flex: 1;
`;

const SectionWrapper = styled.div`
  border-bottom: 1px solid #eee;
  padding: 1em;
  color: black;

  &:last-child {
    border-bottom: none;
  }
`;

const SectionTitle = styled.div`
  font-size: 0.9;
  color: #3b5381;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 5px;
`;

export const Section = ({ title, children, ...rest }) => (
  <SectionWrapper {...rest}>
    <SectionTitle>{title}</SectionTitle>
    {children}
  </SectionWrapper>
);

export const Tags = styled.div``;

export const Tag = styled.div`
  display: inline-block;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  padding: 0.5em;
  border: 1px solid #eee;
  background-color: #fafcfe;
  color: #3b5381;
`;

export const Button = styled.button`
  margin-left: 0.5em;
  background-color: ${(props) => props.color || "#3b5381"};
  color: white;
  padding: 0.5em 1em;
  border-radius: 2px;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const Footer = styled.div`
  background-color: #fafcfe;
  display: flex;
  padding: 0.5em;
`;

export const Fill = styled.div`
  flex: 1;
`;

const KVWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5em 0;
  padding-left: ${(props) => props.nested * 1.5 + "em"};
  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
`;
const Key = styled.div`
  font-weight: 600;
  text-transform: ${(props) => (props.capitalize ? "capitalize" : "unset")};
`;
const Value = styled.div`
  margin-left: 1em;
  word-break: break-all;
`;

export const KeyValue = ({ label, value, capitalize = true, nested = 0 }) => (
  <KVWrapper nested={nested}>
    <Key capitalize={capitalize}>{label}</Key>
    <Value>{value}</Value>
  </KVWrapper>
);
