import React from "react";
import styled from "styled-components";

const ToggleTrack = styled.div`
  cursor: ${({ disabled }) => (disabled ? "initial" : "pointer")};
  width: 24px;
  height: 16px;
  padding: 2px;
  transition: all 0.3s ease;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.primary : theme.colors.border};
  border-radius: 8px;
  display: flex;
`;

const ToggleThumb = styled.div`
  transition: inherit;
  margin-left: ${({ active }) => (active ? "8px" : 0)};
  border-radius: 8px;
  height: 12px;
  width: 12px;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.text : theme.colors.background};
`;

const Label = styled.div`
  padding-left: 8px;
`;

export default function Toggle({
  value,
  onChange = () => {},
  disabled,
  label,
}) {
  const active = disabled ? false : value;
  return (
    <>
      <ToggleTrack
        disabled={disabled}
        active={active}
        onClick={() => !disabled && onChange(!value)}
      >
        <ToggleThumb active={active} />
      </ToggleTrack>
      {label && <Label>{label}</Label>}
    </>
  );
}
