import React, { Fragment, useState, useContext, useEffect } from "react";
import Restricted, { hasWorkspacePermission } from "utils/Restricted";
import SidePanel, {
  CollapsibleSection,
  Content,
  List,
} from "components/SidePanel";

import AddScenarioItem from "./AddScenarioItem";
import AddWorkspaceItem from "./AddWorkspaceItem";
import { Context as AppStateContext } from "appState";
import ScenarioItem from "./ScenarioItem";
import WorkspaceItem from "./WorkspaceItem";
import { groupBy } from "lodash-es";

import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import LoadingScreen from "components/LoadingScreen";

import { sendEvent } from "utils/ga";

function isWorkspaceSelected(workspace, scenario) {
  return workspace.scenarios.some((s) => s.id === scenario);
}

const WORKSPACE_DATA_FRAGMENT = gql`
  fragment WorkspaceData on Workspace {
    id
    name
    myPermission
    customer {
      id
    }
    company {
      id
      slug
    }
    owner {
      __typename
      ... on Customer {
        id
        user {
          id
          firstName
          lastName
        }
      }
      ... on Company {
        id
        slug
      }
    }
    scenarios {
      id
      name
    }
  }
`;

const CREATE_WORKSPACE = gql`
  mutation createWorkspace($input: CreateWorkspaceInput!) {
    createWorkspace(input: $input) {
      success
      workspace {
        ...WorkspaceData
      }
    }
  }

  ${WORKSPACE_DATA_FRAGMENT}
`;

const RENAME_WORKSPACE = gql`
  mutation renameWorkspace($input: RenameWorkspaceInput!) {
    renameWorkspace(input: $input) {
      success
      workspace {
        id
        name
      }
    }
  }
`;

const DELETE_WORKSPACE = gql`
  mutation deleteWorkspace($input: DeleteWorkspaceInput!) {
    deleteWorkspace(input: $input) {
      success
    }
  }
`;

const CREATE_SCENARIO = gql`
  mutation createScenario($input: CreateScenarioInput!) {
    createScenario(input: $input) {
      success
      scenario {
        id
        name
      }
    }
  }
`;

const RENAME_SCENARIO = gql`
  mutation renameScenario($input: RenameScenarioInput!) {
    renameScenario(input: $input) {
      success
      scenario {
        id
        name
      }
    }
  }
`;

const DELETE_SCENARIO = gql`
  mutation deleteScenario($input: DeleteScenarioInput!) {
    deleteScenario(input: $input) {
      success
    }
  }
`;

function useWorkspacesMutations() {
  const [createWorkspaceMutation] = useMutation(CREATE_WORKSPACE);
  const [renameWorkspaceMutation] = useMutation(RENAME_WORKSPACE);
  const [deleteWorkspaceMutation] = useMutation(DELETE_WORKSPACE);

  return {
    async createWorkspace({ name, companyId }) {
      const result = await createWorkspaceMutation({
        variables: { input: { name, companyId } },
        refetchQueries: ["getWorkspacesPanelData"],
        awaitRefetchQueries: true,
      });

      return result;
    },
    renameWorkspace({ id, name }) {
      return renameWorkspaceMutation({
        variables: { input: { id, name } },
        optimisticResponse: {
          renameWorkspace: {
            __typename: "RenameWorkspacePayload",
            success: true,
            workspace: { id, name, __typename: "Workspace" },
          },
        },
      });
    },
    deleteWorkspace({ id }) {
      return deleteWorkspaceMutation({
        variables: { input: { id } },
        refetchQueries: ["getWorkspacesPanelData"],
      });
    },
  };
}

export function useScenariosMutations() {
  const [createScenarioMutation] = useMutation(CREATE_SCENARIO);
  const [renameScenarioMutation] = useMutation(RENAME_SCENARIO);
  const [deleteScenarioMutation] = useMutation(DELETE_SCENARIO);

  return {
    async createScenario({ name, workspaceId }) {
      const result = await createScenarioMutation({
        variables: {
          input: {
            name,
            workspaceId,
          },
        },
        refetchQueries: ["getWorkspacesPanelData"],
        awaitRefetchQueries: true,
      });

      return result;
    },
    renameScenario({ id, name }) {
      return renameScenarioMutation({
        variables: { input: { id, name } },
        optimisticResponse: {
          renameScenario: {
            __typename: "RenameScenarioPayload",
            success: true,
            scenario: { __typename: "Scenario", id, name },
          },
        },
      });
    },
    deleteScenario({ id }) {
      return deleteScenarioMutation({
        variables: { input: { id } },
        refetchQueries: ["getWorkspacesPanelData", "getProjects"],
      });
    },
  };
}

function WorkspacesList({
  workspaces,
  selectedScenario,
  openWorkspace,
  setOpenWorkspace,
  selectScenario,
  mode,
  disableAddWorkspace,
  sharedWithMe,
  companyId,
}) {
  const {
    createWorkspace,
    deleteWorkspace,
    renameWorkspace,
  } = useWorkspacesMutations();
  const {
    createScenario,
    deleteScenario,
    renameScenario,
  } = useScenariosMutations();

  const selectNextScenario = (selectedWorkspace) => {
    const nextWorkspace = workspaces.find((w) => w.id !== selectedWorkspace.id);
    selectScenario(nextWorkspace.scenarios[0].id);
    setOpenWorkspace(nextWorkspace.id);
  };

  return (
    <List>
      {workspaces.map((workspace) => (
        <Fragment key={workspace.id}>
          <WorkspaceItem
            workspace={workspace}
            selected={isWorkspaceSelected(workspace, selectedScenario)}
            lastWorkspace={workspaces.length === 1}
            onSelect={() => {
              setOpenWorkspace(workspace.id);
              sendEvent("workspace", "setOpenWorkspace", workspace.id);
            }}
            onDelete={() => {
              if (isWorkspaceSelected(workspace, selectedScenario)) {
                selectNextScenario(workspace);
              }
              deleteWorkspace({ id: workspace.id });
              sendEvent("workspace", "deleteWorkspace", workspace.id);
            }}
            onRename={(name) => {
              renameWorkspace({ id: workspace.id, name });
              sendEvent("workspace", "renameWorkspace", workspace.id, name);
            }}
            sharedWithMe={sharedWithMe}
            selectNextScenario={selectNextScenario}
          />
          {openWorkspace === workspace.id &&
            workspace.scenarios.map((scenario) => (
              <ScenarioItem
                key={scenario.id}
                scenario={scenario}
                selected={selectedScenario === scenario.id}
                disableDeletion={workspace.scenarios.length === 1}
                onSelect={() => {
                  selectScenario(scenario.id);
                  sendEvent("workspace", "selectScenario", scenario.id);
                }}
                onDelete={() => {
                  if (selectedScenario === scenario.id) {
                    selectScenario(
                      workspace.scenarios.find((s) => s.id !== selectedScenario)
                        .id
                    );
                  }
                  deleteScenario({ id: scenario.id });
                  sendEvent("workspace", "deleteScenario", scenario.id);
                }}
                onRename={(name) => {
                  renameScenario({ id: scenario.id, name });
                  sendEvent("workspace", "renameScenario", scenario.id, name);
                }}
                workspace={workspace}
              />
            ))}
          {openWorkspace === workspace.id && (
            <Restricted to={hasWorkspacePermission(workspace, "ad")}>
              <AddScenarioItem
                key="add-scenario-item"
                onSubmit={async (name) => {
                  const result = await createScenario({
                    name,
                    workspaceId: workspace.id,
                  });
                  selectScenario(result.data.createScenario.scenario.id);
                  sendEvent("workspace", "addScenarioItem");
                }}
              />
            </Restricted>
          )}
        </Fragment>
      ))}
      {!disableAddWorkspace && (
        <AddWorkspaceItem
          key="add-workspace-item"
          onSubmit={async ({ name }) => {
            const result = await createWorkspace({
              name,
              companyId: companyId,
            });
            selectScenario(
              result.data.createWorkspace.workspace.scenarios[0].id
            );
            setOpenWorkspace(result.data.createWorkspace.workspace.id);
            sendEvent("workspace", "addWorkspaceItem");
          }}
          mode={mode}
        />
      )}
    </List>
  );
}

const GET_WORKSPACES_PANEL_DATA = gql`
  query getWorkspacesPanelData {
    workspaces {
      edges {
        node {
          ...WorkspaceData
        }
      }
    }
    me {
      id
      companies {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }

  ${WORKSPACE_DATA_FRAGMENT}
`;

export default function WorkspacesPanel({ onClose }) {
  const { mode, scenario, setParams: setAppStateParams } = useContext(
    AppStateContext
  );
  const { data } = useQuery(GET_WORKSPACES_PANEL_DATA);
  const [openWorkspace, setOpenWorkspace] = useState(null);
  const selectScenario = (scenario) => {
    setAppStateParams({ scenario: String(scenario) });
  };

  // When the data is first loaded, expand the workspace that contains
  // the selected scenario (what we also call the "selected workspace")
  useEffect(() => {
    if (data && data.workspaces && openWorkspace === null) {
      setOpenWorkspace(
        data.workspaces.edges.find((e) => isWorkspaceSelected(e.node, scenario))
          .node.id
      );
    }

    // Don't specify scenario or openWorkspace in dependencies since we
    // only want this to execute when the data loads (after mount).
    // To execute it, we need that extra data, but if that data changes,
    // it's not needed to rerun this.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (!data) {
    return (
      <SidePanel title="Workspaces" onClose={onClose}>
        <LoadingScreen />
      </SidePanel>
    );
  }
  if (data && data.workspaces && data.me) {
    const customer = data.me;
    const workspaces = data.workspaces.edges.map((e) => e.node);
    const companies = customer.companies.edges.map((e) => e.node);
    const myWorkspaces = workspaces.filter(
      (w) => w.owner.__typename === "Customer" && w.customer.id === customer.id
    );
    const emptyByCompany = {};
    companies.forEach(({ id }) => {
      emptyByCompany[id] = [];
    });
    const byCompany = {
      ...emptyByCompany,
      ...groupBy(
        workspaces.filter(
          (w) =>
            w.owner.__typename === "Company" &&
            companies.map((e) => e.id).includes(w.company.id)
        ),
        (w) => w.company.id
      ),
    };
    const sharedWithMe = workspaces.filter((w) => w.myPermission !== "ow");
    return (
      <SidePanel title={"Workspaces"} onClose={onClose}>
        <Content>
          {Object.entries(byCompany).map(([companyId, workspaces]) => (
            <CollapsibleSection
              title={`${
                companies.find(({ id }) => id === companyId).name
              }'s workspaces`}
              icon="folder"
              key={companyId}
            >
              <WorkspacesList
                workspaces={workspaces}
                selectedScenario={scenario}
                openWorkspace={openWorkspace}
                selectScenario={selectScenario}
                mode={mode}
                setOpenWorkspace={setOpenWorkspace}
                companyId={companyId}
              />
            </CollapsibleSection>
          ))}
          {sharedWithMe.length > 0 && (
            <CollapsibleSection title="Shared with me" icon="folder_shared">
              <WorkspacesList
                workspaces={sharedWithMe}
                selectedScenario={scenario}
                openWorkspace={openWorkspace}
                selectScenario={selectScenario}
                mode={mode}
                setOpenWorkspace={setOpenWorkspace}
                disableAddWorkspace
                sharedWithMe={true}
              />
            </CollapsibleSection>
          )}
          <CollapsibleSection title="My personal workspaces" icon="folder">
            <WorkspacesList
              workspaces={myWorkspaces}
              selectedScenario={scenario}
              openWorkspace={openWorkspace}
              selectScenario={selectScenario}
              mode={mode}
              setOpenWorkspace={setOpenWorkspace}
              workspaceCreateMetadata={{ customer: customer.id }}
            />
          </CollapsibleSection>
        </Content>
      </SidePanel>
    );
  } else return null;
}
