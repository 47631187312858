import Icon from "./Icon";
import React from "react";
import styled from "styled-components/macro";

const Img = styled.img`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  border-radius: 50%;
  border: ${(props) => (props.border === false ? 0 : "2px solid #eee")};
  margin: ${(props) => props.margin};
`;

const PersonIcon = styled(Icon).attrs({ color: "#777" })`
  margin: 0 15px;
`;

const ProfilePic = ({
  size = 32,
  border = true,
  src,
  margin = "0 15px",
  ...rest
}) => {
  return src ? (
    <Img src={src} border={border} size={size} margin={margin} {...rest} />
  ) : (
    <PersonIcon size={size} {...rest}>
      account_circle
    </PersonIcon>
  );
};

export default ProfilePic;
