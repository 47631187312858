import { useCallback } from "react";
import usePersistedState from "hooks/usePersistedState";

export default function useRecentList({
  key,
  length = 5,
  identity = (i) => i,
}) {
  const [items, setItems] = usePersistedState(key, []);

  const add = useCallback(
    (itemToAdd) => {
      setItems((items) => {
        // If the new item was already in the array, remove it
        // first so we don't have duplicates in the array.
        items = items.filter((item) => identity(item) !== identity(itemToAdd));
        if (items.length > length) {
          return [itemToAdd, ...items];
        } else {
          return [itemToAdd, ...items.slice(0, length - 1)];
        }
      });
    },
    [identity, length, setItems]
  );

  const remove = useCallback(
    (itemToRemove) => {
      setItems((items) =>
        items.filter((item) => identity(item) !== identity(itemToRemove))
      );
    },
    [identity, setItems]
  );

  return { items, add, remove };
}
