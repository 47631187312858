import React from "react";
import styled from "styled-components";
import Icon from "./components/Icon";
import { IconWrapper as SidebarIconWrapper } from "./Sidebar";
import { useLocation, matchPath } from "react-router";
import useHistory from "hooks/useHistory";
import { useRouteMatch } from "react-router-dom";
import { useBaseUrl } from "./URLState";
import { Logos } from "./Topbar";
import { useSelectedFeature } from "hooks/useSelectedFeature";

const IconWrapper = styled(SidebarIconWrapper)`
  width: 56px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  grid-area: topbar;
  background-color: ${props => props.theme.colors.background};
  padding-left: 16px;
`;

const Fill = styled.div`
  flex: 1;
`;

function Topbar() {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const baseUrl = useBaseUrl();
  const { close: closeMetadataPanel } = useSelectedFeature();

  const changeSelection = option => {
    closeMetadataPanel();
    const optionUrl = `${baseUrl}/${option}`;
    history.push(location.pathname === optionUrl ? baseUrl : optionUrl);
  };

  return (
    <Wrapper>
      <Logos />
      <Fill />
      <IconWrapper
        onClick={() => changeSelection("project")}
        selected={matchPath(location.pathname, `${match.path}/project`)}
      >
        <Icon svg size={26}>
          project
        </Icon>
      </IconWrapper>
      <IconWrapper
        onClick={() => changeSelection("search")}
        selected={matchPath(location.pathname, `${match.path}/search`)}
      >
        <Icon svg size={26}>
          search
        </Icon>
      </IconWrapper>
    </Wrapper>
  );
}

export default Topbar;
