import React, { Fragment } from "react";
import styled from "styled-components/macro";
import { Section, KeyValue, Header, Title } from "components/MapPopup";
import { isEmpty, isObject } from "lodash-es";

import MapboxPopup from "./MapboxPopup";
export const ObjectAsTable = ({ data, nested = 0 }) =>
  Object.entries(data).map(([k, v]) => {
    // TODO: Formalize this
    if (k === "possible_matches") {
      const matches = v.split(",").map((m) =>
        m
          .trim()
          .split(/:(.+)/)
          .slice(0, 2)
          .map((v) => v.trim())
      );
      return (
        <Fragment key={k}>
          <KeyValue
            label={k}
            key={k + "_label"}
            capitalize={false}
            nested={nested}
          />
          {matches.map((m) => (
            <KeyValue
              nested={nested + 1}
              label={m[0]}
              key={m[0]}
              value={
                <a href={m[1]} target="_blank" rel="noopener noreferrer">
                  {m[1]}
                </a>
              }
            />
          ))}
        </Fragment>
      );
    }

    return (
      k !== "createdFrom" &&
      (isObject(v) ? (
        <>
          <KeyValue label={k} key={k} capitalize={false} nested={nested} />
          <ObjectAsTable data={v} nested={nested + 1} />
        </>
      ) : (
        <KeyValue
          label={k}
          key={k}
          value={v}
          capitalize={false}
          nested={nested}
        />
      ))
    );
  });

const Content = ({ data }) => {
  return (
    <Section>
      <ObjectAsTable data={data} />
    </Section>
  );
};

const Message = styled.div`
  text-align: center;
  padding: 4em 1em;
  color: #999;
`;

export function parseProperties(properties) {
  if (Object.keys(properties).length === 1 && "metadata" in properties) {
    return JSON.parse(properties.metadata);
  }
  return properties;
}

const PTitle = styled(Title)`
  font-size: 0.9;
  color: #3b5381;
  font-weight: bold;
  text-transform: uppercase;
`;

const Popup = ({ coordinates, onClose, feature }) => (
  <MapboxPopup {...{ coordinates, onClose, feature }}>
    <Header>
      <PTitle> Metadata </PTitle>
    </Header>
    {isEmpty(feature.properties) ? (
      <Message>This object has no metadata.</Message>
    ) : (
      <Content data={parseProperties(feature.properties)} />
    )}
  </MapboxPopup>
);

export default Popup;
