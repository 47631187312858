import React from "react";
import MobilePanel from "./MobilePanel";
import { PanelSectionWrapper } from "../panels/layout";
import { SearchContent } from "../panels/SearchPanel";
import styled from "styled-components/macro";

const SearchWrapper = styled(PanelSectionWrapper)`
  padding: 0;
`;

function Search() {
  return (
    <MobilePanel>
      <SearchWrapper>
        <SearchContent />
      </SearchWrapper>
    </MobilePanel>
  );
}

export default Search;
