import ConfirmationModal, {
  Content,
  Header,
  ModalButton,
} from "components/ConfirmationModal";
import { OptionWrapper, Subtitle, Title } from "utils/SelectOption";
import React, { useState } from "react";

import Modal from "components/Modal";
import Select from "react-select";
import styled from "styled-components/macro";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { sendEvent } from "utils/ga";

const ItemSelectInput = styled(Select)`
  margin-bottom: 260px;
  width: 330px;
`;

const Option = (props) => {
  const { innerProps, innerRef } = props;
  return (
    <OptionWrapper ref={innerRef} {...innerProps} className="custom-option">
      {props.data.username ? (
        <>
          <Title>{props.data.username}</Title>
          <Subtitle>{props.data.label}</Subtitle>
        </>
      ) : (
        <Title>{props.data.label}</Title>
      )}
    </OptionWrapper>
  );
};

const itemToValue = (item) => ({
  value: item.user ? `user-${item.id}` : `company-${item.id}`,
  label: item.user ? item.user.email || item.user.username : item.name,
  username: item.user
    ? item.user.firstName
      ? `${item.user.firstName} ${item.user.lastName}`
      : item.user.username
    : null,
});

const GET_ITEMS = gql`
  query getItems {
    customers {
      id
      user {
        id
        username
        firstName
        lastName
      }
    }
    companies {
      id
      name
    }
  }
`;

function ItemSelect({ value, onChange, ...rest }) {
  const { data } = useQuery(GET_ITEMS);
  if (data) {
    const customers = data.customers;
    const companies = data.companies;
    const items = [...customers, ...companies];
    const groupedOptions = [
      {
        label: "Companies",
        options: companies.map(itemToValue),
      },
      {
        label: "Users",
        options: customers.map(itemToValue),
      },
    ];
    return (
      <ItemSelectInput
        openOnClick={false}
        isClearable
        options={groupedOptions}
        components={{ Option }}
        value={
          value
            ? itemToValue(items.find((c) => c.id === value.split("-")[1]))
            : null
        }
        onChange={(value) => onChange(value && value.value)}
        {...rest}
      />
    );
  } else {
    return null;
  }
}

const SelectionWrapper = styled.div`
  margin: 1em;
`;

const CHANGE_WORKSPACE_OWNER = gql`
  mutation changeWorkspaceOwner($input: ChangeWorkspaceOwnerInput!) {
    changeWorkspaceOwner(input: $input) {
      workspace {
        id
      }
    }
  }
`;

function useWorkspaceMutations() {
  const [changeWorkspaceOwnerMutation] = useMutation(CHANGE_WORKSPACE_OWNER, {
    refetchQueries: ["getWorkspacesPanelData"],
  });
  return {
    changeWorkspaceOwner: ({ id, customerId, companyId }) => {
      changeWorkspaceOwnerMutation({
        variables: { input: { id, customerId, companyId } },
      });
    },
  };
}

function ChangingOwnerModal({
  workspaceId,
  isOpen,
  onClose,
  selectNextScenario,
}) {
  const [item, setItem] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const { changeWorkspaceOwner } = useWorkspaceMutations();

  async function setOwnership() {
    selectNextScenario();
    const [itemType, itemId] = item.split("-");
    const customerId = itemType === "user" ? itemId : null;
    const companyId = itemType === "user" ? null : itemId;
    changeWorkspaceOwner({ id: workspaceId, customerId, companyId });
  }

  return (
    <Modal
      title="Change Workspace's ownership"
      isOpen={isOpen}
      size="flex"
      onClose={onClose}
    >
      <Content>
        <Header>
          Select a Company or a User as the new owner of this workspace
        </Header>
        <SelectionWrapper>
          <ItemSelect
            placeholder="Type a company or user name"
            value={item}
            onChange={(item) => setItem(item)}
          />
          <ModalButton
            primary
            disabled={!item}
            onClick={() => setConfirmModal(true)}
          >
            Select
          </ModalButton>
        </SelectionWrapper>
      </Content>
      <ConfirmationModal
        onAccept={(e) => {
          setOwnership(e);
          sendEvent("changeOwnModal", "confirmChangeOwnership");
        }}
        onClose={() => {
          setConfirmModal(false);
          onClose();
        }}
        isOpen={confirmModal}
        title={"Are you sure?"}
        header={"You are about to change the workspace owner"}
        accept={"Change Ownership"}
      />
    </Modal>
  );
}

export default ChangingOwnerModal;
