import React, { useContext } from "react";
import { booleanColors, categoryColors } from "utils/colors";
import { PreviewLayerContext } from "./PreviewLayerContext";
import VectorMetadataPopup from "./VectorMetadataPopup";
import ExtentsPopup from "./ExtentsPopup";
import colors from "utils/colors/themes";
import { retrieveToken } from "../../../../../../../api/auth";
import useMapPopup from "hooks/useMapPopup";
import { memoize, get, isEmpty } from "lodash-es";
import { Layer, Source } from "react-mapbox-gl";
import LayerOrderingManager from "./LayerOrderingManager";
import bbox from "@turf/bbox";
import { useSelectedFeature } from "hooks/useSelectedFeature";
import { useUISwitch } from "hooks/useUISwitch";
import { sendEvent } from "utils/ga";

const SUBDOMAINS = "abcdefghijkl";
function parseSubdomain(url) {
  return SUBDOMAINS.split("").map((s) => url.replace("{s}", s));
}

const getSource = memoize((layer) => {
  const urls = layerUrls(layer);

  if (layer.type === "V") {
    return {
      id: `${layer.name}.pbf`,
      tileJsonSource: {
        type: "vector",
        tiles: parseSubdomain(urls.pbf),
        bounds: bbox(layer.extent),
      },
    };
  } else {
    return {
      id: `${layer.name}.raster`,
      tileJsonSource: {
        type: "raster",
        tiles: parseSubdomain(urls.xyz),
        tileSize: 256,
        bounds: bbox(layer.extent),
      },
    };
  }
}, JSON.stringify);

function getDynamicColorRule({ layer, layerSelection }) {
  const propertyName = layerSelection.metadata.styling.property;
  const property = layer.metadata.styling.properties.find(
    (p) => p.name === propertyName
  );

  const valuesMapping = dynamicStylingItems(
    property,
    layerSelection.metadata.styling
  )
    .map((item) => [item.label, item.color])
    .flat();

  if (property.type === "number") {
    return [
      "interpolate",
      ["linear"],
      ["get", property.name],
      ...valuesMapping,
    ];
  } else {
    return [
      "match",
      ["get", property.name],
      ...valuesMapping,
      get(layerSelection.metadata.styling.base, "fillColor", "#3388ff"),
    ];
  }
}

const getFillLayer = ({ layer, layerSelection }) => ({
  id: `${layer.name}.fill`,
  type: "fill",
  sourceId: `${layer.name}.pbf`,
  sourceLayer: layer.name,
  minzoom: layer.metadata.leafletVectorOptions.minZoom,
  metadata: {
    "slingshot:interactive": true,
    "slingshot:zIndex": layerSelection.metadata.zIndex,
  },
  paint: {
    "fill-color": isEmpty(get(layerSelection.metadata, "styling.property"))
      ? layerSelection.metadata.styling.base.fillColor
      : getDynamicColorRule({ layer, layerSelection }),
    "fill-opacity": getOpacity(layerSelection),
    "fill-outline-color": "transparent",
  },
});

const getLineLayer = ({ layer, layerSelection }) => ({
  id: `${layer.name}.line`,
  type: "line",
  sourceId: `${layer.name}.pbf`,
  sourceLayer: layer.name,
  minzoom: layer.metadata.leafletVectorOptions.minZoom,
  metadata: {
    "slingshot:interactive": true,
    "slingshot:zIndex": layerSelection.metadata.zIndex + 0.2,
  },
  layout: {
    "line-cap": "round",
  },
  paint: {
    "line-opacity": getOpacity(layerSelection),
    "line-color": isEmpty(get(layerSelection.metadata, "styling.property"))
      ? layerSelection.metadata.styling.base.color
      : getDynamicColorRule({ layer, layerSelection }),
    "line-width": parseFloat(layerSelection.metadata.styling.base.weight),
  },
});

const getCircleLayer = ({ layer, layerSelection }) => ({
  id: `${layer.name}.circle`,
  type: "circle",
  sourceId: `${layer.name}.pbf`,
  sourceLayer: layer.name,
  minzoom: layer.metadata.leafletVectorOptions.minZoom,
  metadata: {
    "slingshot:interactive": true,
    "slingshot:zIndex": layerSelection.metadata.zIndex + 0.4,
  },
  paint: {
    "circle-opacity": getOpacity(layerSelection),
    "circle-radius": parseInt(layerSelection.metadata.styling.base.radius),
    "circle-color": isEmpty(get(layerSelection.metadata, "styling.property"))
      ? layerSelection.metadata.styling.base.fillColor
      : getDynamicColorRule({ layer, layerSelection }),
  },
});

function getRasterLayer({ layer, layerSelection }) {
  return {
    id: `${layer.name}.raster`,
    type: "raster",
    sourceId: `${layer.name}.raster`,
    metadata: { "slingshot:zIndex": layerSelection.metadata.zIndex },
  };
}

function getLayers({ layer, layerSelection }) {
  if (layer.type === "R") {
    return [getRasterLayer({ layer, layerSelection })];
  }

  let layers = [];

  let geomType = layer.metadata.geometry_type;
  if (
    /Polygon/.test(geomType) &&
    (!isEmpty(get(layerSelection.metadata, "styling.property")) ||
      "fillColor" in layerSelection.metadata.styling.base)
  ) {
    layers.push(getFillLayer({ layer, layerSelection }));
  }
  if (
    /(LineString|Polygon)/.test(geomType) &&
    "weight" in layerSelection.metadata.styling.base &&
    ("color" in layerSelection.metadata.styling.base ||
      !isEmpty(get(layerSelection.metadata, "styling.property")))
  ) {
    layers.push(getLineLayer({ layer, layerSelection }));
  }
  if (
    /Point/.test(geomType) &&
    "radius" in layerSelection.metadata.styling.base &&
    ("fillColor" in layerSelection.metadata.styling.base ||
      !isEmpty(get(layerSelection.metadata, "styling.property")))
  ) {
    layers.push(getCircleLayer({ layer, layerSelection }));
  }

  return layers;
}

function createSourcesLayers(layerSelections) {
  const sources = [];
  const layers = [];
  for (let layerSelection of layerSelections) {
    const layer = layerSelection.layer;
    if (layer.extent) {
      sources.push(getSource(layer));
      layers.push(...getLayers({ layer, layerSelection }));
    }
  }
  return { sources, layers };
}

const getOpacity = (layerSelection) =>
  get(layerSelection.metadata, "opacity", 1);

const TILESERVER_HOST = process.env.REACT_APP_TILESERVER_HOST;

function layerUrls(layer) {
  const jwt = retrieveToken();

  if (layer.type === "V") {
    return {
      xyz: `https://${TILESERVER_HOST}/tiles/${layer.name}/webmercator/{z}/{x}/{y}.png?jwt=${jwt}`, // prettier-ignore
      pbf: `https://${TILESERVER_HOST}/tiles/${layer.name}/{z}/{x}/{y}.pbf?jwt=${jwt}` // prettier-ignore
    };
  } else {
    return {
      wms: `https://{s}.wms.${TILESERVER_HOST}/?jwt=${jwt}&`,
      xyz: `https://{s}.tms.${TILESERVER_HOST}/tiles/${layer.name}/webmercator/{z}/{x}/{y}.png?jwt=${jwt}`, // prettier-ignore
      pbf: `https://{s}.vectors.${TILESERVER_HOST}/tiles/${layer.name}/{z}/{x}/{y}.pbf?jwt=${jwt}` // prettier-ignore
    };
  }
}

export const dynamicStylingItems = (property, layerSelectionStyling) => {
  let items = [];
  const DEFAULT_RAMP_LENGTH = 10;
  if (property.type === "string") {
    items = property.categories.map((category, i) => ({
      color: property.themeOverride
        ? property.themeOverride[category]
        : categoryColors[i],
      label: category,
    }));
  } else if (property.type === "number") {
    const min = Number(layerSelectionStyling.min || property.min);
    const max = Number(layerSelectionStyling.max || property.max);
    const distance = max - min === 0 ? 1 : max - min;
    if (Number.isInteger(distance) && distance <= DEFAULT_RAMP_LENGTH) {
      for (let i = 0; i <= distance; i++) {
        const color =
          colors[layerSelectionStyling.theme][Math.floor((255 / distance) * i)];
        items.push({
          color: `rgb(${color[0] * 255}, ${color[1] * 255}, ${color[2] * 255})`,
          label: min + i,
        });
      }
    } else {
      const step = (max - min) / DEFAULT_RAMP_LENGTH;
      for (let i = 0; i < DEFAULT_RAMP_LENGTH; i++) {
        const color =
          colors[layerSelectionStyling.theme][
            Math.floor(
              (255 / DEFAULT_RAMP_LENGTH) * i + 255 / DEFAULT_RAMP_LENGTH / 2
            )
          ];
        items.push({
          color: `rgb(${color[0] * 255}, ${color[1] * 255}, ${color[2] * 255})`,
          label: parseFloat((min + step * i).toFixed(2)),
        });
      }
    }
  } else if (property.type === "boolean") {
    items = [
      { color: booleanColors[0], label: "True" },
      { color: booleanColors[1], label: "False" },
    ];
  }
  return items;
};

const createExtentSource = memoize((layerSelection) => {
  return {
    type: "vector",
    tiles: parseSubdomain(layerUrls(layerSelection.layer).pbf),
  };
}, JSON.stringify);

function getFeatures(e, layerId) {
  const params = [
    e.point,
    {
      layers: [layerId],
    },
  ];
  return e.target.queryRenderedFeatures(...params);
}

export function Layers({ layerSelections }) {
  const { previewLayer } = useContext(PreviewLayerContext);
  const newLayerSelections = [...layerSelections];
  const extentPopup = useMapPopup();
  const { displayNewUI } = useUISwitch();
  const {
    setFeature,
    featurePopup: vectorMetadataPopup,
  } = useSelectedFeature();
  if (previewLayer) {
    newLayerSelections.push({
      layer: previewLayer,
      metadata: {
        styling: previewLayer.type === "V" && {
          base: get(previewLayer, "metadata.styling.base", {
            radius: previewLayer.metadata.geometry_type.includes("Point") && 5,
            fillColor: "#3388ff",
            color: "#3388ff",
            weight: previewLayer.metadata.geometry_type.includes("Polygon")
              ? 0.1
              : previewLayer.metadata.geometry_type.includes("Line")
              ? 3
              : undefined,
          }),
        },
      },
    });
  }

  function onLayerClick(e, layerId) {
    const features = getFeatures(e, layerId);
    if (features.length > 0) {
      setFeature(features[0], e.lngLat);
      sendEvent("layers", "clickOnVector");
    }
  }

  function onExtentClick(e, layerId) {
    const features = getFeatures(e, layerId);
    if (features.length > 0) {
      extentPopup.open(features[0], e.lngLat);
    }
  }

  const { sources, layers } = createSourcesLayers(
    newLayerSelections
      .slice()
      .sort((a, b) => a.metadata.zIndex - b.metadata.zIndex)
  );

  return (
    <>
      <LayerOrderingManager layerSelections={layerSelections} />
      {sources.map((source) => (
        <Source key={source.id} {...source} />
      ))}
      {layers.map((layer, i) => (
        <Layer
          key={layer.id}
          {...layer}
          onClick={(e) => onLayerClick(e, layer.id)}
          onTouchEnd={(e) => onLayerClick(e, layer.id)}
        />
      ))}
      {newLayerSelections
        .filter((ls) => ls.layer.type === "R" && ls.metadata.seeExtents)
        .map((ls) => (
          <>
            <Source
              key={`${ls.layer.name}.pbf`}
              id={`${ls.layer.name}.pbf`}
              tileJsonSource={createExtentSource(ls)}
            />
            <Layer
              key={`${ls.layer.name}.fill`}
              id={`${ls.layer.name}.fill`}
              sourceId={`${ls.layer.name}.pbf`}
              sourceLayer={ls.layer.name}
              onClick={(e) => onExtentClick(e, `${ls.layer.name}.fill`)}
              metadata={{
                "slingshot:interactive": true,
                "slingshot:zIndex": ls.metadata.zIndex + 0.2,
              }}
              type="fill"
              paint={{
                "fill-color": "#7044C9",
                "fill-opacity": 0.2,
              }}
            />
            <Layer
              key={`${ls.layer.name}.line`}
              id={`${ls.layer.name}.line`}
              sourceId={`${ls.layer.name}.pbf`}
              sourceLayer={ls.layer.name}
              onClick={(e) => onExtentClick(e, `${ls.layer.name}.line`)}
              type="line"
              metadata={{
                "slingshot:interactive": true,
                "slingshot:zIndex": ls.metadata.zIndex + 0.4,
              }}
              paint={{
                "line-color": "#7044C9",
                "line-width": 4,
              }}
            />
          </>
        ))}
      {vectorMetadataPopup.value !== null && !displayNewUI && (
        <VectorMetadataPopup {...vectorMetadataPopup.popupProps} />
      )}
      {extentPopup.value !== null && (
        <ExtentsPopup {...extentPopup.popupProps} />
      )}
    </>
  );
}
