import React, { useContext, useEffect, useState, useRef } from "react";
import { MapContext } from "react-mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import DrawRectangle from "mapbox-gl-draw-rectangle-mode";
import {
  CircleMode,
  DragCircleMode,
  DirectMode,
  SimpleSelectMode,
} from "mapbox-gl-draw-circle";
import circle from "@turf/circle";
import { sendEvent } from "utils/ga";

import polylineIcon from "./icons/polyline.svg";
import circleIcon from "./icons/circle.svg";
import markerIcon from "./icons/marker.svg";
import rectangleIcon from "./icons/rectangle.svg";
import polygonIcon from "./icons/polygon.svg";

import markerIconNewUI from "./icons/marker-new-ui.svg";
import rectangleIconNewUI from "./icons/rectangle-new-ui.svg";
import polygonIconNewUI from "./icons/polygon-new-ui.svg";

import { createGlobalStyle } from "styled-components";

const DarkStyle = createGlobalStyle`
  div .mapboxgl-ctrl-group {
    background: ${(props) => props.theme.colors.background};
    width: 32px;
  }
  div .mapboxgl-ctrl-group:not(:empty) {
    border: 1px solid ${(props) => props.theme.colors.background};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.32);
    border-radius: 4px;
  }
  div .mapboxgl-ctrl-group button + button {
    border-top: 1px solid ${(props) => props.theme.colors.darkerBackground};
  }
`;

function addControlButton(title, icon, onClick) {
  const buttonGroup = document.querySelector(".mapboxgl-ctrl");
  const el = document.createElement("button");
  el.classList.add("mapbox-gl-draw_ctrl-draw-btn");
  el.style.backgroundImage = `url(${icon})`;
  el.title = title;
  el.addEventListener("click", onClick);
  buttonGroup.appendChild(el);
}

function standardToDrawCircle(editFeature) {
  return {
    type: "Feature",
    id: editFeature.id,
    geometry: circle(editFeature.geometry, editFeature.properties.radius / 1000)
      .geometry,
    properties: {
      ...editFeature.properties,
      isCircle: true,
      radiusInKm: editFeature.properties.radius / 1000,
      center: editFeature.geometry.coordinates,
    },
  };
}

function drawToStandardCircle(drawCircle) {
  return {
    type: "Feature",
    id: drawCircle.id,
    geometry: {
      type: "Point",
      coordinates: drawCircle.properties.center,
    },
    properties: {
      radius: drawCircle.properties.radiusInKm * 1000,
    },
  };
}

/**
 * Add a mapbox draw control to the mapbox-gl map.
 * Circles don't exist in geojson, so the interface that this controls uses for a circle
 * is a geojson point with a numeric property "radius" which is the radius in meters.
 * This interface is both used by onCreate, onEdit and should be used when editing a circle
 * in editFeature.
 * @param {function} onCreate - Function to be called when a geometry is created.
 * @param {function} onEdit - Function to be called when a geometry is edited.
 * @param {function} editFeature - Edit a feature by passing a geojson feature on this prop.
 * @param {function} controls - Object<string,boolean> with the controls that will be enabled in the UI. Options are: circle, point, polygon, rectangle, line_string.
 * @param {function} mode - If null, the drawing mode is handled by the control UI. But can be set manually to force a specific mode. Options are: simple_select, direct_select, draw_polygon, draw_rectangle, draw_point, draw_line_string, draw_circle.
 */
export default function MapboxDrawControl({
  onCreate,
  onEdit,
  editFeature,
  controls = {},
  mode,
  dark = false,
}) {
  const map = useContext(MapContext);
  const [drawControl, setDrawControl] = useState(null);
  const editing = useRef(null);

  // Initialize draw control
  useEffect(() => {
    const Draw = new MapboxDraw({
      displayControlsDefault: false,
      controls,
      modes: {
        ...MapboxDraw.modes,
        draw_circle: CircleMode,
        drag_circle: DragCircleMode,
        direct_select: DirectMode,
        simple_select: SimpleSelectMode,
        draw_rectangle: DrawRectangle,
      },
    });
    map.addControl(Draw, "top-left");
    setDrawControl(Draw);

    if (controls.rectangle) {
      addControlButton(
        "Draw rectangle",
        dark ? rectangleIconNewUI : rectangleIcon,
        () => {
          Draw.changeMode("draw_rectangle");
        }
      );
    }

    if (controls.circle) {
      addControlButton("Draw circle", circleIcon, () => {
        Draw.changeMode("drag_circle");
      });
    }

    // Replace icons
    for (let [name, icon] of [
      ["line", polylineIcon],
      ["polygon", dark ? polygonIconNewUI : polygonIcon],
      ["point", dark ? markerIconNewUI : markerIcon],
    ]) {
      let button = document.querySelector(`.mapbox-gl-draw_${name}`);
      if (button) {
        button.style.backgroundImage = `url(${icon})`;
      }
    }
    // We had some problems with the cleanup function removing the control
    // from the map, so this effect is not propertly cleaning up. As such,
    // we can't add "dark" and "controls" to the effect dependencies because
    // they would require the cleanup to work properly. Given that we're not
    // dynamically changing those, we're ignoring this for now.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  useEffect(() => {
    if (drawControl) {
      if (mode) {
        drawControl.changeMode(mode);
      } else {
        drawControl.changeMode("simple_select");
      }
    }
  }, [drawControl, mode]);

  // onCreate handler up to date
  useEffect(() => {
    if (onCreate) {
      function handleCreate(e) {
        const feature = e.features[0].properties.isCircle
          ? drawToStandardCircle(e.features[0])
          : e.features[0];
        onCreate(feature);
        drawControl.deleteAll();
        sendEvent("mapBoxDrawControls", "createFeature", feature.geometry.type);
      }
      map.on("draw.create", handleCreate);
      return () => map.off("draw.create", handleCreate);
    }
  }, [onCreate, map, drawControl]);

  // Editing/onEdit up to date
  useEffect(() => {
    if (editFeature && onEdit) {
      if (!editing.current) {
        const feature =
          typeof editFeature.properties.radius === "number"
            ? standardToDrawCircle(editFeature)
            : editFeature;

        drawControl.set({ type: "FeatureCollection", features: [feature] });
        if (feature.geometry.type === "Point") {
          drawControl.changeMode("simple_select", {
            featureIds: [feature.id],
          });
        } else {
          drawControl.changeMode("direct_select", {
            featureId: feature.id,
          });
        }
        editing.current = feature;
      }

      function handleEdit(e) {
        const feature = e.features[0];
        if (feature.geometry.type === "Point") {
          onEdit(feature);
          drawControl.deleteAll();
          editing.current = false;
        } else {
          editing.current = e.features[0];
        }
        sendEvent("mapBoxDrawControls", "editFeature", feature.geometry.type);
      }

      function handleModeChange(e) {
        const feature = editing.current.properties.isCircle
          ? drawToStandardCircle(editing.current)
          : editing.current;
        onEdit(feature);
        drawControl.deleteAll();
        editing.current = false;
      }

      map.on("draw.modechange", handleModeChange);
      map.on("draw.update", handleEdit);

      return () => {
        map.off("draw.update", handleEdit);
        map.off("draw.modechange", handleModeChange);
      };
    }
  }, [drawControl, editFeature, map, onEdit]);

  return dark ? <DarkStyle /> : null;
}
