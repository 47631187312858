import React, { useEffect, useContext } from "react";
import { getClient } from "apollo";
import { useParams, useRouteMatch } from "react-router-dom";
import useHistory from "hooks/useHistory";
import gql from "graphql-tag";

const ProjectViewportContext = React.createContext();

function replaceProjectInUrl(url, id) {
  const parts = url.split("/");
  parts[2] = id;
  return parts.join("/");
}

function replaceViewportInUrl(url, viewport) {
  const parts = url.split("/");
  parts[1] = viewport;
  return parts.join("/");
}

const GET_PROJECT_DEFAULT_VIEWPORT = gql`
  query getProjectDefaultViewport($projectId: ID!) {
    scenario(id: $projectId) {
      id
      defaultView
    }
  }
`;

async function getDefaultProject() {
  const client = getClient();
  const recentProjects =
    JSON.parse(localStorage.getItem("recentProjects")) || [];

  for (let projectId of recentProjects) {
    const { data } = await client.query({
      query: GET_PROJECT_DEFAULT_VIEWPORT,
      variables: { projectId },
    });
    if (data.scenario) {
      return data.scenario;
    }
  }

  const result = await client.query({
    query: gql`
      query getFirstProject {
        scenarios(first: 1) {
          edges {
            node {
              id
              defaultView
            }
          }
        }
      }
    `,
  });
  return result.data.scenarios.edges[0].node;
}

async function getDefaultViewport(projectId) {
  const client = getClient();
  const result = await client.query({
    query: GET_PROJECT_DEFAULT_VIEWPORT,
    variables: { projectId },
  });
  return result.data.scenario.defaultView;
}

function viewportFromDefaultView(defaultView) {
  return defaultView
    ? defaultView.map((n) => n.toFixed(6)).join(",")
    : "-180,-90,180,90";
}

export function ProjectViewportProvider(props) {
  const params = useParams();
  const history = useHistory();
  const match = useRouteMatch();

  const baseUrl = match.url;

  async function selectProject(id) {
    let updatedUrl = replaceProjectInUrl(baseUrl, id);
    const defaultView = await getDefaultViewport(id);
    if (defaultView) {
      updatedUrl = replaceViewportInUrl(
        updatedUrl,
        viewportFromDefaultView(defaultView)
      );
    }
    history.push(`${updatedUrl}/project`);
  }

  async function selectDefaultProject() {
    const project = await getDefaultProject();
    let updatedUrl = replaceProjectInUrl(baseUrl, project.id);
    if (project.defaultView) {
      updatedUrl = replaceViewportInUrl(
        updatedUrl,
        viewportFromDefaultView(project.defaultView)
      );
    }
    history.push(`${updatedUrl}/projects`);
  }

  function updateViewport(viewport) {
    if (Array.isArray(viewport)) {
      viewport = viewport.map((n) => n.toFixed(6)).join(",");
    }
    const newURL = replaceViewportInUrl(window.location.pathname, viewport);
    history.push(newURL);
  }

  useEffect(() => {
    (async function () {
      if (!params.viewport) {
        const defaultView = await getDefaultViewport("2");
        history.replace(`/${viewportFromDefaultView(defaultView)}`);
        return;
      }
    })();
  }, [history, params.project, params.viewport]);

  return (
    <ProjectViewportContext.Provider
      value={{
        viewport: params.viewport,
        updateViewport,
        projectId: "2",
        selectProject,
        selectDefaultProject,
        baseUrl,
      }}
      {...props}
    />
  );
}

export function useSelectedProject() {
  const { projectId, selectProject, selectDefaultProject } = useContext(
    ProjectViewportContext
  );
  return {
    projectId,
    selectProject,
    selectDefaultProject,
  };
}

export function useViewport() {
  const { viewport, updateViewport } = useContext(ProjectViewportContext);
  return { viewport, updateViewport };
}

export function useBaseUrl() {
  const { baseUrl } = useContext(ProjectViewportContext);
  return baseUrl;
}
