import React, { useEffect, useState, useContext } from "react";
import { useSelectedProject } from "./URLState";

const AnnotationsTasksVisibilityContext = React.createContext();

export function AnnotationsTasksVisibilityProvider({ children }) {
  const [annotations, setAnnotations] = useState(true);
  const [tasks, setTasks] = useState(true);
  const { projectId } = useSelectedProject();

  // Reset to true on scenario change
  useEffect(() => {
    setAnnotations(true);
    setTasks(true);
  }, [projectId]);

  return (
    <AnnotationsTasksVisibilityContext.Provider
      value={{ annotations, tasks, setAnnotations, setTasks }}
    >
      {children}
    </AnnotationsTasksVisibilityContext.Provider>
  );
}

export function useAnnotationsTasksVisibility() {
  return useContext(AnnotationsTasksVisibilityContext);
}
