import React from "react";
import t from "prop-types";
import MaterialIcon from "components/Icon";

import { ReactComponent as activity } from "../Icons/activity.svg";
import { ReactComponent as caret } from "../Icons/caret.svg";
import { ReactComponent as filter } from "../Icons/filter.svg";
import { ReactComponent as marketplace } from "../Icons/marketplace.svg";
import { ReactComponent as project } from "../Icons/project.svg";
import { ReactComponent as raster } from "../Icons/raster.svg";
import { ReactComponent as search } from "../Icons/search.svg";
import { ReactComponent as tasks } from "../Icons/tasks.svg";
import { ReactComponent as vector } from "../Icons/vector.svg";
import { ReactComponent as annotations } from "../Icons/annotations.svg";
import { ReactComponent as tasks_small } from "../Icons/tasks_small.svg";
import { ReactComponent as cloud } from "../Icons/cloud.svg";
import { ReactComponent as resolution } from "../Icons/resolution.svg";
import { ReactComponent as contact_support } from "../Icons/contact_support.svg";
import { ReactComponent as marker } from "../Icons/marker.svg";
import { ReactComponent as pdf } from "../Icons/pdf.svg";

export const svgs = {
  activity,
  caret,
  filter,
  marketplace,
  project,
  raster,
  search,
  tasks,
  vector,
  annotations,
  tasks_small,
  resolution,
  contact_support,
  cloud,
  marker,
  pdf,
};

export default function Icon({ children, svg, size = 18, color, ...props }) {
  if (svg) {
    const SvgIcon = svgs[children];

    if (!SvgIcon) {
      return children;
    }

    // Svg icons, unlike material's, have no margin, the -2 is to make
    // both types of icons appear the same size for a given size number.
    return (
      <SvgIcon
        style={{ height: size - 2, width: size - 2, color }}
        {...props}
      />
    );
  } else {
    return (
      <MaterialIcon size={size} color={color} {...props}>
        {children}
      </MaterialIcon>
    );
  }
}

Icon.propTypes = {
  children: t.string.isRequired,
  color: t.string,
  size: t.number,
  svg: t.bool,
};
