export const styledComponentsTheme = {
  colors: {
    primary: "#1B263B",
    secondary: "#9EA8BA",
    link: "#FF751A",
    placeholder: "#9EA8BA",
    border: "#EBEBEB",
    lightBg: "#FAFCFE",
    darkBg: "#1B263B",
    input: "#3B5381",
    mutedTextColor: "#6c757d",
    darkerBackground: "#FFFFFF",
    text: "#1B263B",
  },
  dimensions: {
    regularPadding: "20px",
    sidePanelWidth: "400px",
  },
  sizes: {
    primary: "14px",
    secondary: "16px",
    title: "18px",
    label: "12px",
  },
};
