import { ActionIcon, ListItemLabel } from "components/SidePanel";
import React from "react";

import { EditableItem } from "components/SidePanel";
import { WorkspaceItemWrapper } from "./WorkspaceItem";
import styled from "styled-components/macro";

const AddWorkspaceItemWrapper = styled(WorkspaceItemWrapper)`
  cursor: pointer;
`;

const AddWorkspaceLabel = styled(ListItemLabel)`
  color: orange;
`;

const AddWorkspace = ({ onSubmit }) => (
  <EditableItem
    inputProps={{ placeholder: "Type a name" }}
    onSubmit={(name) => onSubmit({ name })}
    renderNotEditing={({ onClick }) => (
      <AddWorkspaceItemWrapper onClick={onClick}>
        <ActionIcon color="orange">add_circle_outline</ActionIcon>
        <AddWorkspaceLabel>Add Workspace</AddWorkspaceLabel>
      </AddWorkspaceItemWrapper>
    )}
    renderWrapper={({ children }) => (
      <AddWorkspaceItemWrapper>{children}</AddWorkspaceItemWrapper>
    )}
  />
);

export default AddWorkspace;
