import { useContext, useEffect } from "react";
import { MapContext } from "react-mapbox-gl";

export default function PointerCursorOnInteractiveLayers() {
  const map = useContext(MapContext);

  useEffect(() => {
    function onMouseMove(e) {
      const interactiveLayersIds = map
        .getStyle()
        .layers.filter((l) => l.metadata && l.metadata["slingshot:interactive"])
        .map((l) => l.id);

      const features = map.queryRenderedFeatures(e.point, {
        layers: interactiveLayersIds,
      });

      if (features.length > 0) {
        map._canvasContainer.classList.add("mapboxgl-track-pointer");
      } else {
        map._canvasContainer.classList.remove("mapboxgl-track-pointer");
      }
    }

    window.map = map;

    map.on("mousemove", onMouseMove);

    return () => map.off("mousemove", onMouseMove);
  }, [map]);

  return null;
}
