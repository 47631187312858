import React, { useState, useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import Icon from "./Icon";
import useOnClickOutside from "hooks/useOnClickOutside";

const Label = styled.div`
  font-weight: 600;
  margin-bottom: 8px;
`;

const FieldWrapper = styled.div`
  margin-bottom: 16px;
  ${(props) =>
    props.flex &&
    css`
      flex: ${props.flex} !important;
    `}
`;

export function Field({ label, children, ...props }) {
  return (
    <FieldWrapper {...props}>
      {label && <Label>{label}</Label>}
      {children}
    </FieldWrapper>
  );
}

export const FieldGroup = styled.div`
  display: flex;
  ${(props) =>
    props.align &&
    css`
      align-items: ${props.align};
    `}
  > * {
    flex: 1;

    :not(:last-child) {
      margin-right: 16px;
    }
  }
`;

export const FieldIcon = styled(Icon)`
  cursor: default;
  font-size: 22px;
  color: ${(props) => props.theme.colors.muted};
`;

const TextInputWrapper = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.border};
  padding: 7px;
  border-radius: 4px;

  :focus-within {
    border-color: ${({ theme }) => theme.colors.text};
  }

  > *:first-child {
    margin-right: 8px;
  }
`;

const TextInputInput = styled.input`
  width: 100%;
  background-color: transparent;
  outline: none;
  border: none;
  color: ${({ theme }) => theme.colors.text};
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const TextAreaInput = styled.textarea`
  border: none;
  background-color: transparent;
  outline: 0;
  color: inherit;
  font-weight: inherit;
  width: 100%;
  ::placeholder {
    color: ${(props) => props.theme.colors.muted};
  }
  border: 1px solid ${(props) => props.theme.colors.border};
  borderbox-sizing: border-box;
  border-radius: 4px;
  resize: none;
  height: 146px;
  padding: 6px;
  line-height: 19px;
`;

export function TextInput({ leftIcon, className, rightIcon, ...props }) {
  return (
    <TextInputWrapper className={className}>
      {leftIcon}
      <TextInputInput {...props} />
      {rightIcon}
    </TextInputWrapper>
  );
}

const EditableInputForm = styled.form`
  display: flex;
  width: 100%;
`;

const EditableInputtext = styled(TextInputInput)`
  flex-grow: 2;
`;

const EditableInputButton = styled(Icon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.muted}
  
  &:hover {
    color: ${({ theme }) => theme.colors.text}
  }

  & + & {
    margin-left: 9px;
  }
`;

export function EditableInput({ onChange, onCancel, value, ...props }) {
  const formRef = useOnClickOutside(onCancel);
  const inputRef = useRef(null);
  const [text, setText] = useState(value ? value : "");

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <EditableInputForm
      ref={formRef}
      onSubmit={(e) => {
        e.preventDefault();
        onChange(text);
      }}
    >
      <EditableInputtext
        ref={inputRef}
        onChange={(e) => setText(e.target.value)}
        value={text}
        {...props}
      />
      <EditableInputButton title="Save" onClick={() => onChange(text)}>
        done
      </EditableInputButton>
      <EditableInputButton title="Cancel" onClick={onCancel}>
        close
      </EditableInputButton>
    </EditableInputForm>
  );
}
