import React, { useCallback, useState, useRef } from "react";
import styled, { css } from "styled-components/macro";

const ValueWrapper = styled.div`
  padding: 0.5em;
  margin-top: 1em;
  color: ${(props) => props.theme.colors.mutedTextColor};
  border-radius: 3px;

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        background-color: #eee;
      }
    `};
`;

export const BaseValue = ({ children, field, disabled, ...rest }) => (
  <ValueWrapper {...rest} disabled={disabled} isPlaceholder={Boolean(children)}>
    {children
      ? children.split("\n").map((line, i) => <p key={i}>{line}</p>)
      : disabled
      ? `No ${field}`
      : `Type a ${field}`}
  </ValueWrapper>
);

export const BaseInput = styled.input`
  margin-top: 1em;
  color: ${(props) => props.theme.colors.mutedTextColor};
  border: none;
  width: 100%;
  padding: 0.5em;

  &:focus {
    outline: none;
  }
`;

function EditableValue(props) {
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(props.value);
  const inputRef = useRef();

  const finishEditing = useCallback(() => {
    setEdit(false);
    props.onChange(value);
    inputRef.current.blur();
  }, [props, value]);

  const { renderValue, renderInput, disabled } = props;
  return edit ? (
    <form
      style={{ width: "100%" }}
      onSubmit={(e) => {
        e.preventDefault();
        finishEditing();
      }}
    >
      {renderInput({
        ref: inputRef,
        onBlur: finishEditing,
        value,
        onChange: (e) => setValue(e.target.value),
      })}
    </form>
  ) : (
    renderValue({
      onClick: disabled
        ? () => {}
        : () => {
            setEdit(true);
            setTimeout(() => inputRef.current.focus(), 0);
          },
      children: value,
    })
  );
}

export default EditableValue;
