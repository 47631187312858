import React, { useState, useMemo, useEffect, useRef } from "react";
import { useLocation } from "react-router";

export const TemporaryFeatureContext = React.createContext({
  temporaryFeature: null,
});

export function TemporaryFeatureProvider({ children }) {
  const { pathname } = useLocation();
  const activePathRef = useRef(null);
  const [temporaryFeature, setTemporaryFeature] = useState(null);

  useEffect(() => {
    if (activePathRef.current && !pathname.includes(activePathRef.current)) {
      setTemporaryFeature(null);
      activePathRef.current = null;
    }
  }, [pathname, activePathRef]);

  const value = useMemo(
    () => ({
      temporaryFeature,
      setTemporaryFeature: (value, activePath = null) => {
        activePathRef.current = activePath;
        setTemporaryFeature(value);
      },
    }),
    [temporaryFeature]
  );

  return (
    <TemporaryFeatureContext.Provider value={value}>
      {children}
    </TemporaryFeatureContext.Provider>
  );
}
