import React from "react";
import useToggle from "hooks/useToggle";
import { useMutation } from "@apollo/react-hooks";
import { useForm } from "utils/form";
import { PanelButton, PanelIcon } from "../panels/layout";
import * as v from "utils/validation";

import { TextAreaInput, Field, TextInput } from "../components/Input";
import { sendEvent } from "utils/ga";
import LoadingScreen from "components/LoadingScreen";
import styled from "styled-components";
import MobilePanel from "./MobilePanel";
import {
  SEND_FEEDBACK,
  StyledScreenMessage,
  HelpInfo
} from "../panels/GetHelpPanel";

const HelpWrapper = styled.div`
  padding: 8px 16px;
  display: flex;
  font-size: 13px;
  color: ${props => props.theme.colors.text};
`;

const Fill = styled.div`
  flex: 1;
`;

const FeedbackContainer = styled.div`
  padding: 16px;
`;

const HelpInfoWrapper = styled.div`
  padding-bottom: 8px;
`;

function SendFeedbackForm() {
  const [sendBugReport] = useMutation(SEND_FEEDBACK);
  const { fields, success, isValid, onSubmit, loading } = useForm({
    fields: ["feedback", "email"],
    validate: v.createValidator({
      feedback: v.required,
      email: [v.required, v.email]
    }),
    onSubmit: async ({ feedback, email }) => {
      await sendBugReport({
        variables: {
          input: { subject: `Feedback: sn`, body: feedback, email }
        }
      });
      sendEvent("support", "sendSupport", feedback);
    }
  });

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <FeedbackContainer>
      {success ? (
        <StyledScreenMessage>Thank you!</StyledScreenMessage>
      ) : (
        <>
          <HelpInfoWrapper>
            <HelpInfo />
          </HelpInfoWrapper>
          <Field label="Your email address">
            <TextInput type="email" {...fields.email} />
          </Field>
          <Field label="Feedback">
            <TextAreaInput
              type="text"
              placeholder="Your feedback"
              {...fields.feedback}
            />
          </Field>
          <PanelButton disabled={!isValid} onClick={onSubmit}>
            Submit
          </PanelButton>
        </>
      )}
    </FeedbackContainer>
  );
}

export default function GetHelpPanel() {
  const [collapsed, toggleCollapsed] = useToggle(true);

  return (
    <MobilePanel bottom>
      <HelpWrapper onClick={toggleCollapsed}>
        <strong>Need help?</strong>
        <Fill />
        <PanelIcon muted>
          {collapsed ? "keyboard_arrow_up" : "keyboard_arrow_down"}
        </PanelIcon>
      </HelpWrapper>
      {!collapsed && <SendFeedbackForm />}
    </MobilePanel>
  );
}
