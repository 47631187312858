import React, { useState } from "react";

import { ActionIcon } from "components/SidePanel";
import Button from "components/Button";
import CreateRasterForm from "./CreateRaster";
import CreateVectorForm from "./CreateVector";
import { Section } from "./base";
import styled from "styled-components/macro";
import { gql } from "apollo-boost";

const TYPE = {
  RASTER: "Raster",
  VECTOR: "Vector",
};

const LayerIcon = ({ type }) => (
  <ActionIcon>{type === TYPE.VECTOR ? "timeline" : "map"}</ActionIcon>
);

const TypeWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TypeButton = styled(Button)`
  margin-right: 1em;
  font-size 13px;
`;

export const CREATE_LAYER = gql`
  mutation createLayer($input: CreateLayerInput!) {
    createLayer(input: $input) {
      success
      layer {
        id
      }
    }
  }
`;

const TypeOption = ({ selected, type, setType }) => (
  <TypeButton
    primary={selected === type}
    onClick={() => setType(type)}
    data-test={
      type === TYPE.RASTER
        ? "layer-type-raster-button"
        : "layer-type-vector-button"
    }
  >
    <TypeWrapper>
      <LayerIcon type={type} />
      {type}
    </TypeWrapper>
  </TypeButton>
);

function CreateLayer() {
  const [type, setType] = useState(TYPE.RASTER);

  return (
    <Section title="Create Layer">
      <TypeOption selected={type} type={TYPE.RASTER} setType={setType} />
      <TypeOption selected={type} type={TYPE.VECTOR} setType={setType} />
      {type === TYPE.RASTER ? <CreateRasterForm /> : <CreateVectorForm />}
    </Section>
  );
}

export default CreateLayer;
