import React, { useRef } from "react";
import Button from "components/Button";

const FileInputButton = ({
  children,
  onChange,
  fileInputProps = {},
  ...rest
}) => {
  const inputRef = useRef(null);

  return (
    <>
      <input
        style={{ display: "none" }}
        type="file"
        ref={inputRef}
        onChange={onChange}
        {...fileInputProps}
      />
      <Button primary onClick={() => inputRef.current.click()} {...rest}>
        {children}
      </Button>
    </>
  );
};

export default FileInputButton;
