import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components/macro";
import ProfilePic from "components/ProfilePic";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import CustomerInput from "components/CustomerInput";
import { getName } from "components/UserSelect";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: min-content min-content;
  grid-template-rows: min-content min-content;
  white-space: nowrap;
  font-size: 0.9em;
`;

const Picture = styled(ProfilePic)`
  grid-row: 1 / 3;
  align-self: center;
`;

const UserAssigmentWrapper = styled.div`
  font-weight: 600;
`;

const AssignedToLabel = styled.span``;

const AssignedToButton = styled.span`
  cursor: ${({ disabled }) => (disabled ? "initial" : "pointer")};
  color: orange;
  position: relative;
  display: inline-block;
`;

const DueDateWrapper = styled.div`
  /* Hack for react-datepicker */
  > div {
    display: inline-block;
  }
`;

const DueDateLabel = styled.span`
  color: #777;
`;

const DateInput = styled.input`
  display: inline;
  border: none;
  outline: none;
  cursor: ${({ disabled }) => (disabled ? "initial" : "pointer")};
  background-color: transparent;
  color: orange;

  ::placeholder {
    color: orange;
  }
`;

const Popup = styled.div`
  left: calc(100% + 15px);
  position: absolute;
  top: -13px;
  border: 1px solid grey;
`;

const AssignTaskPopup = ({ onClose, onChange }) => {
  useEffect(() => {
    const maybeClose = (e) => {
      try {
        let currentElement = e.target;
        while (true) {
          if (currentElement.classList.contains("CustomerInputPopup")) {
            break;
          }
          if (currentElement === document.body) {
            onClose();
            break;
          }
          currentElement = currentElement.parentElement;
        }
      } catch (e) {}
    };

    document.body.addEventListener("click", maybeClose);
    return () => {
      document.body.removeEventListener("click", maybeClose);
    };
  }, [onClose]);

  return (
    <Popup className={"CustomerInputPopup"}>
      <CustomerInput onChange={onChange} />
    </Popup>
  );
};

const ASSIGN_TO = gql`
  mutation assignTo($input: AssignTaskToInput!) {
    assignTaskTo(input: $input) {
      success
      error
      task {
        id
        assignedTo {
          id
          picture
          user {
            id
            username
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const SET_DUE_DATE = gql`
  mutation setDueDate($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      success
      task {
        id
        dueDate
      }
    }
  }
`;

function AssignmentSection({ task, disabled }) {
  const [assignmentPopupOpen, setAssignmentPopupOpen] = useState(false);
  const buttonRef = useRef();
  const [assignTo] = useMutation(ASSIGN_TO);
  const [setDueDate] = useMutation(SET_DUE_DATE);

  return (
    <Wrapper>
      <Picture src={task.assignedTo && task.assignedTo.picture} />
      <UserAssigmentWrapper>
        <AssignedToLabel>
          {task.assignedTo ? "Assigned to " : "Not assigned "}
        </AssignedToLabel>
        <AssignedToButton disabled={disabled} ref={buttonRef}>
          {assignmentPopupOpen && (
            <AssignTaskPopup
              onChange={async (customer) => {
                await assignTo({
                  variables: {
                    input: { id: task.id, customerId: customer.id },
                  },
                });
                setAssignmentPopupOpen(false);
              }}
              onClose={() => setAssignmentPopupOpen(false)}
              parent={buttonRef.current}
            />
          )}
          <span onClick={() => !disabled && setAssignmentPopupOpen(true)}>
            {task.assignedTo
              ? getName(task.assignedTo.user)
              : "Click to assign"}
          </span>
        </AssignedToButton>
      </UserAssigmentWrapper>
      <DueDateWrapper>
        <DueDateLabel>Due Date </DueDateLabel>
        <DatePicker
          disabled={disabled}
          placeholderText="No due date"
          customInput={<DateInput disabled={disabled} />}
          selected={task.dueDate ? moment(task.dueDate) : null}
          onChange={(date) =>
            setDueDate({ variables: { input: { id: task.id, dueDate: date } } })
          }
          dateFormat="MMMM DD, YYYY"
        />
      </DueDateWrapper>
    </Wrapper>
  );
}

export default AssignmentSection;
