import Label from "./Label";
import React from "react";
import styled from "styled-components/macro";
import { withComponent } from "utils/styling";

export const Input = styled.input`
  width: 100%;
  background-color: ${(props) => props.theme.colors.lightBg};
  border: solid 1px ${(props) => props.theme.colors.border};
  height: 35px;
  font-size: ${(props) => props.theme.sizes.primary};
  color: ${(props) => props.theme.colors.input};
  padding: 0 10px;
  outline: none;

  &:focus {
    border-color: ${(props) => props.theme.colors.input};
  }
`;

const Textarea = withComponent(
  styled(Input)`
    padding: 1em;
    min-height: 5em;
  `,
  "textarea"
);

const Wrapper = styled.div`
  margin-bottom: 20px;
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Error = styled.div`
  font-size: 0.9em;
  color: red;
`;

const TextInput = ({ label, rightActions, errorText, className, ...rest }) => (
  <Wrapper className={className}>
    {label && (
      <LabelWrapper>
        <Label>{label}</Label>
        {rightActions}
      </LabelWrapper>
    )}
    <Input {...rest} />
    {errorText && <Error>{errorText}</Error>}
  </Wrapper>
);

export const TextareaInput = ({ label, rightActions, errorText, ...rest }) => (
  <Wrapper>
    {label && (
      <LabelWrapper>
        <Label>{label}</Label>
        {rightActions}
      </LabelWrapper>
    )}
    <Textarea {...rest} />
    {errorText && <Error>{errorText}</Error>}
  </Wrapper>
);

export default TextInput;
