import React, { useState } from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import useHistory from "hooks/useHistory";
import { useParams } from "react-router-dom";
import styled from "styled-components/macro";
import LoadingScreen from "components/LoadingScreen";
import { useBaseUrl } from "./../URLState";
import { get, isEmpty } from "lodash-es";
import {
  PanelContainer,
  PanelContent,
  PanelHeader,
  PanelSection,
  PanelSectionContainer,
  PanelIcon,
  PanelListItemLabel,
  PanelButton,
  PanelTextSection,
} from "./layout";
import {
  FieldGroup,
  Field,
  TextInput,
  TextAreaInput,
  FieldIcon,
} from "../components/Input";
import { sendEvent } from "utils/ga";
import ColorPicker from "../components/ColorPicker";
import { SliderPercentage } from "../components/Slider";
import useDebouncedCallback from "hooks/useDebouncedCallback";
import moment from "moment";
import Toggle from "../components/Toggle";
import ThemeSelect from "../components/ThemeSelect";
import { Select } from "../components/ListBox";
import ProfilePic from "../../../../components/ProfilePic";
import { useAuth } from "hooks/data/auth";
import { useLayerSelections } from "hooks/useLayerSelections";

const GET_LAYER_DETAIL_PANEL_DATA = gql`
  query getLayerDetailPanelData($layerSelectionId: ID!) {
    layerSelection(id: $layerSelectionId) {
      id
      layer {
        id
        type
        metadata
        downloadLink
        title
        description
        created
        owner {
          ... on Customer {
            picture
          }
          ... on Company {
            picture
          }
        }
      }
    }
  }
`;

const GET_PUBLIC_LAYER_DETAIL_PANEL_DATA = gql`
  query getPublicLayerDetailPanelData($layerSelectionId: ID!) {
    layerSelection(id: $layerSelectionId) {
      id
      layer {
        id
        type
        metadata
        title
        description
        created
      }
    }
  }
`;

const UPDATE_LAYER = gql`
  mutation updateLayer($input: UpdateLayerInput!) {
    updateLayer(input: $input) {
      success
      layer {
        id
        title
        description
      }
    }
  }
`;

const LayerTitle = styled(PanelListItemLabel)`
  font-weight: 600;
`;

const EditIcon = styled(PanelIcon)`
  font-size: 18px;
  margin-bottom: 3px;
  margin-right: 5px;
`;

const DownloadLink = styled.a`
  text-decoration: none;
`;

const RangeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RangeSeparator = styled.span`
  font-size: 13px;
  margin: 0 8px;
  color: ${(props) => props.theme.colors.muted};
`;

const TextInputTitle = styled(TextInput)`
  width: 100%;
`;

const UploadedProfilePic = styled(ProfilePic)`
  margin: 0 8px;
`;

const UploadSection = styled.span`
  display: flex;
  align-items: center;
  padding: 0 6px;
`;

function getFullProperty(layerSelection) {
  const { properties } = layerSelection.layer.metadata.styling;
  return properties.find(
    (l) => layerSelection.metadata.styling.property === l.name
  );
}

function DynamicStylingSection({ layerSelection }) {
  const { layer } = layerSelection;
  const {
    layerSelectionMutations: {
      enableDynamicStyling,
      setDynamicStylingProperty,
      setDynamicStylingTheme,
    },
  } = useLayerSelections();

  if (!(layer.metadata.styling?.properties?.length > 0)) {
    return <PanelSection title="Dynamic styles" icon={<Toggle disabled />} />;
  }

  const isStyledByProperty = Boolean(
    !isEmpty(get(layerSelection.metadata, "styling.property"))
  );

  const handleStyleByProperty = (checked) => {
    if (checked) {
      enableDynamicStyling({
        ls: layerSelection,
        enable: true,
        firstProperty: layer.metadata.styling.properties[0],
      });
    } else {
      enableDynamicStyling({ ls: layerSelection, enable: false });
    }
  };

  const handlePropertySelect = (value) => {
    const { properties } = layer.metadata.styling;
    const property = properties.find((p) => p.name === value);
    setDynamicStylingProperty({ ls: layerSelection, property });
  };

  const handleThemeChange = (value) => {
    setDynamicStylingTheme({ ls: layerSelection, theme: value });
  };

  const handleRangeChange = ({ min, max }) => {
    setDynamicStylingProperty({ ls: layerSelection, min, max });
  };

  const property = getFullProperty(layerSelection);

  return (
    <PanelSection
      title="Dynamic styles"
      icon={
        <Toggle value={isStyledByProperty} onChange={handleStyleByProperty} />
      }
    >
      {isStyledByProperty && (
        <PanelSectionContainer>
          <Field label="Field">
            <Select
              value={property.name}
              options={layer.metadata.styling.properties.map((property) => ({
                value: property.name,
                label: property.name,
              }))}
              onChange={handlePropertySelect}
            />
          </Field>
          {property.type === "number" && (
            <FieldGroup>
              <Field label="Range">
                <RangeWrapper>
                  <TextInput
                    value={layerSelection.metadata.styling.min}
                    onChange={(e) =>
                      handleRangeChange({
                        min: e.target.value,
                        max: layerSelection.metadata.styling.max,
                      })
                    }
                  />
                  <RangeSeparator>-</RangeSeparator>
                  <TextInput
                    value={layerSelection.metadata.styling.max}
                    onChange={(e) =>
                      handleRangeChange({
                        min: layerSelection.metadata.styling.min,
                        max: e.target.value,
                      })
                    }
                  />
                </RangeWrapper>
              </Field>
              <Field label="Color">
                <ThemeSelect
                  value={layerSelection.metadata.styling.theme}
                  onChange={handleThemeChange}
                />
              </Field>
            </FieldGroup>
          )}
        </PanelSectionContainer>
      )}
    </PanelSection>
  );
}

const getQuery = (loggedIn) => {
  return loggedIn
    ? GET_LAYER_DETAIL_PANEL_DATA
    : GET_PUBLIC_LAYER_DETAIL_PANEL_DATA;
};

function LayerDetailPanel() {
  const {
    loading,
    layerSelectionMutations: { setLayerSelectionOpacity, setStaticStylingBase },
    layerSelections,
  } = useLayerSelections();
  const { loggedIn } = useAuth();
  const [editing, setEditing] = useState(false);
  const baseUrl = useBaseUrl();
  const { id: layerId } = useParams();
  const { data, loading: layerLoading } = useQuery(getQuery(loggedIn), {
    variables: { layerSelectionId: layerId },
  });
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [updateLayer] = useMutation(UPDATE_LAYER);
  const history = useHistory();

  const [sendDebouncedEvent] = useDebouncedCallback(sendEvent, 1000);

  if (loading || layerLoading) {
    return (
      <PanelContainer>
        <LoadingScreen />
      </PanelContainer>
    );
  }
  const layerSelection = layerSelections.find((ls) => ls.id === layerId);
  const layer = data?.layerSelection.layer;
  const owner = layer.owner;
  function updateLayerSelectionStyle({ prop, value }) {
    setStaticStylingBase({ ls: layerSelection, property: prop, value });
  }

  const activateEditing = () => {
    setTitle(layerSelection.layer.title);
    setDescription(layerSelection.layer.description);
    setEditing(true);
  };

  const saveLayer = (id) => {
    updateLayer({
      variables: {
        input: {
          id,
          title,
          description,
        },
      },
    });
    setEditing(false);
  };

  return (
    <PanelContainer>
      <PanelHeader
        title={"Layer details"}
        iconLeft={
          <PanelIcon muted onClick={() => history.push(`${baseUrl}/project`)}>
            chevron_left
          </PanelIcon>
        }
        iconRight={
          loggedIn ? (
            editing ? (
              <PanelButton onClick={() => saveLayer(layer.id)}>
                Save layer
              </PanelButton>
            ) : (
              [
                <EditIcon muted title="Edit layer" onClick={activateEditing}>
                  create
                </EditIcon>,
                <DownloadLink
                  href={layer.downloadLink}
                  download
                  target="_blank"
                  onClick={() =>
                    sendEvent("downloadLayer", "downloadLayerAsGeoJSONL")
                  }
                >
                  <PanelIcon muted title="Download layer as GeoJSONL file">
                    save_alt
                  </PanelIcon>
                </DownloadLink>,
              ]
            )
          ) : null
        }
      />
      <PanelContent>
        <PanelSection title="Name">
          <PanelTextSection>
            {editing ? (
              <TextInputTitle
                onChange={(e) => setTitle(e.target.value)}
                value={title}
              />
            ) : (
              <LayerTitle>{layer.title}</LayerTitle>
            )}
          </PanelTextSection>
        </PanelSection>
        <PanelSection title="Description">
          <PanelTextSection>
            {editing ? (
              <TextAreaInput
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
            ) : (
              layer.description
            )}
          </PanelTextSection>
        </PanelSection>

        <PanelSection title="Uploaded">
          <UploadSection>
            <UploadedProfilePic
              src={loggedIn && owner.picture}
              border={false}
              size={16}
            />
            {moment(layer.created).format("MMMM DD, YYYY · hh:mm a")}
          </UploadSection>
        </PanelSection>

        <PanelSection title="Opacity">
          <PanelSectionContainer>
            <SliderPercentage
              value={layerSelection.metadata.opacity}
              onChange={(opacity) => {
                setLayerSelectionOpacity({
                  id: layerSelection.id,
                  metadata: layerSelection.metadata,
                  opacity: opacity,
                });
                sendDebouncedEvent({
                  eventCategory: "layerOptions",
                  eventAction: "changeLayerOpacity",
                  eventValue: opacity,
                });
              }}
            />
          </PanelSectionContainer>
        </PanelSection>
        {layer.type === "V" && (
          <>
            <PanelSection title="Static Styles">
              <PanelSectionContainer>
                <FieldGroup>
                  <Field label="Fill" flex={0.1}>
                    <ColorPicker
                      value={get(
                        layerSelection.metadata.styling.base,
                        "fillColor",
                        "transparent"
                      ).toLowerCase()}
                      onChange={(color) => {
                        updateLayerSelectionStyle({
                          prop: "fillColor",
                          value: color,
                        });
                        sendEvent(
                          "layers",
                          "updateLayerSelectionStyle",
                          "fillCollor",
                          color
                        );
                      }}
                      disabled={Boolean(
                        get(layerSelection.metadata, "styling.property")
                      )}
                    />
                  </Field>
                  {["Polygon", "Line"].some((geom) =>
                    layer.metadata.geometry_type.includes(geom)
                  ) && (
                    <Field label="Border" flex={0.7}>
                      <FieldGroup>
                        <ColorPicker
                          value={get(
                            layerSelection.metadata.styling.base,
                            "color",
                            "transparent"
                          ).toLowerCase()}
                          onChange={(color) => {
                            updateLayerSelectionStyle({
                              prop: "color",
                              value: color,
                            });
                            sendEvent(
                              "layers",
                              "updateLayerSelectionStyle",
                              "color",
                              color
                            );
                          }}
                        />
                        <TextInput
                          leftIcon={<FieldIcon>line_weight</FieldIcon>}
                          type="number"
                          step="0.1"
                          value={get(
                            layerSelection.metadata.styling.base,
                            "weight",
                            layer.metadata.geometry_type.includes("Polygon")
                              ? 0.1
                              : 3
                          )}
                          onChange={(e) => {
                            updateLayerSelectionStyle({
                              prop: "weight",
                              value: e.target.value,
                            });
                            sendEvent(
                              "layers",
                              "updateLayerSelectionStyle",
                              "weight",
                              e.target.value
                            );
                          }}
                        />
                      </FieldGroup>
                    </Field>
                  )}
                  {layer.metadata.geometry_type.includes("Point") && (
                    <Field label="Radius" flex={0.4}>
                      <TextInput
                        type="number"
                        leftIcon={<FieldIcon>crop_free</FieldIcon>}
                        value={get(
                          layerSelection.metadata.styling.base,
                          "radius",
                          5
                        )}
                        onChange={(e) => {
                          updateLayerSelectionStyle({
                            prop: "radius",
                            value: e.target.value,
                          });
                          sendEvent(
                            "layers",
                            "updateLayerSelectionStyle",
                            "radius",
                            e.target.value
                          );
                        }}
                      />
                    </Field>
                  )}
                </FieldGroup>
              </PanelSectionContainer>
            </PanelSection>
            <DynamicStylingSection layerSelection={layerSelection} />
          </>
        )}
      </PanelContent>
    </PanelContainer>
  );
}

export default LayerDetailPanel;
