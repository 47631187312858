import Button from "components/Button";
import Modal from "components/Modal";
import React from "react";
import styled from "styled-components/macro";

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  border-bottom: 1px solid #eee;
  padding: 0.5em 0;
  margin: 0 1em;
  max-width: 350px;
  word-wrap: break-word;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  margin: 0 1em 1em 1em;
  justify-content: space-between;
`;

export const ModalButton = styled(Button)`
  margin-top: 1em;
`;

const ConfirmationModal = ({
  onAccept,
  onClose,
  isOpen,
  title,
  header,
  accept,
}) => (
  <Modal title={title} isOpen={isOpen} size="flex" onClose={onClose}>
    <Content>
      <Header>{header}</Header>
      <ButtonsWrapper>
        <ModalButton
          primary
          onClick={() => {
            onAccept();
            onClose();
          }}
        >
          {accept}
        </ModalButton>
        <ModalButton secondary onClick={onClose}>
          Cancel
        </ModalButton>
      </ButtonsWrapper>
    </Content>
  </Modal>
);

export default ConfirmationModal;
