export default {
  LAYERS: "layers",
  TOOLS: "tools",
  TRAINING: "training",
  WORKSPACES: "workspaces",
  ACTIVITY: "activity",
  SKYWATCH: "skywatch-ingest",
  TRACKED_OBJECTS: "tracked-objects",
  LAYER_BROWSER: "layer-browser",
  ANALYTICS: "analytics",
};
