import {
  HasWorkspacePermission,
  RestrictedByWorkspacePermission,
} from "utils/Restricted";
import React, { useRef } from "react";
import Button from "components/Button";
import moment from "moment";
import styled from "styled-components/macro";
import { useTasksMutations } from "hooks/mutations/useTasksMutations";
import { sendEvent } from "utils/ga";
import Icon from "../../routes/app/new-ui/components/Icon";
import { isPDF, isImage } from "../../utils/validation";

const HeaderWrapper = styled.div`
  border-bottom: 1px solid #eee;
  padding-bottom: 0.5em;
  margin-bottom: 1em;
`;

const HeaderTitle = styled.h6`
  font-size: 0.9em;
  margin: 0;
  text-transform: uppercase;
`;

const Wrapper = styled.div`
  margin-top: 1em;
`;

const AttachmentWrapper = styled.div`
  margin-bottom: 0.5em;
`;

const Image = styled.img`
  width: 100px;
  height: 100px;
  margin-right: 0.5em;
  cursor: pointer;
`;

const Label = styled.div`
  font-weight: 600;
  font-size: 0.9em;
  margin-bottom: 0.5em;
`;

const Date = styled.div`
  color: #777;
  font-size: 0.9em;
`;

const Attachment = ({ thumbnail, onDelete, onClick, label, date }) => (
  <AttachmentWrapper>
    {isPDF(label) ? (
      <NewAttachmentWrapper onClick={onClick}>
        <Icon svg size={26} onClick={onClick}>
          pdf
        </Icon>
      </NewAttachmentWrapper>
    ) : isImage(label) ? (
      <Image src={thumbnail} alt={label} onClick={onClick} />
    ) : (
      <NewAttachmentWrapper onClick={onClick}>
        <Icon size={26} onClick={onClick}>
          insert_drive_file
        </Icon>
      </NewAttachmentWrapper>
    )}
    <Date>{date}</Date>
    <Label>{label}</Label>
    <RestrictedByWorkspacePermission type="rw">
      <Button onClick={onDelete}>delete</Button>
    </RestrictedByWorkspacePermission>
  </AttachmentWrapper>
);

const Attachments = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, 100px);
`;

const NewAttachmentWrapper = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  background-color: ${(props) => props.theme.colors.lightBg};
  border: 2px dashed ${(props) => props.theme.colors.border};
  > * {
    margin: auto;
  }
`;

export const NewAttachment = ({ buttonText, onChange, disabled }) => {
  const inputRef = useRef(null);

  return (
    <NewAttachmentWrapper>
      <input
        style={{ display: "none" }}
        type="file"
        ref={inputRef}
        accept="image/jpg, image/png, application/pdf"
        onChange={(e) => {
          onChange(e.target.files[0]);
        }}
      />
      <Button
        primary
        disabled={disabled}
        onClick={() => inputRef.current.click()}
      >
        {buttonText}
      </Button>
    </NewAttachmentWrapper>
  );
};

const NoAttachmentsMessage = styled.div`
  text-color: #ddd;
`;

const AttachmentsSection = ({ task, onAttachmentClick }) => {
  const { removeAttachment, addAttachment } = useTasksMutations();
  return (
    <Wrapper>
      <HeaderWrapper>
        <HeaderTitle>Attachments</HeaderTitle>
      </HeaderWrapper>
      <HasWorkspacePermission type="rw">
        {(hasWorkspacePermission) =>
          task.attachments.length === 0 &&
          !hasWorkspacePermission && (
            <NoAttachmentsMessage>No attachments</NoAttachmentsMessage>
          )
        }
      </HasWorkspacePermission>
      <Attachments>
        {task.attachments.map((at, index) => (
          <Attachment
            key={index}
            thumbnail={at.thumbnailLarge}
            label={at.name || "No name"}
            date={moment(at.date).format("MM/DD/YYYY")}
            onClick={() => onAttachmentClick(index)}
            onDelete={() => {
              if (window.confirm("Delete image. Are you sure?")) {
                removeAttachment(at.id);
              }
            }}
          />
        ))}
        <RestrictedByWorkspacePermission type="rw">
          <NewAttachment
            buttonText={"Add"}
            onChange={(file) => {
              addAttachment(task.id, file);
              sendEvent("task", "addAttachment");
            }}
          />
        </RestrictedByWorkspacePermission>
      </Attachments>
    </Wrapper>
  );
};

export default AttachmentsSection;
