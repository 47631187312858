import React from "react";

import * as RLB from "@reach/listbox";
import "@reach/listbox/styles.css";
import { positionMatchWidth } from "@reach/popover";

import Icon from "./Icon";
import styled from "styled-components/macro";

export const ItemIcon = styled(Icon).attrs({ children: "check", size: 14 })`
  justify-self: center;
`;

export const ItemName = styled.div`
  grid-column-start: 2;
  padding-left: 5px;
`;

export const ListboxButton = styled(RLB.ListboxButton)`
  &[data-reach-listbox-button] {
    width: 100%;
    padding: 5px;
    background: ${(props) => props.theme.colors.background};
    border: 1px solid ${(props) => props.theme.colors.border};
    border-radius: 4px;

    :hover {
      border-color: ${(props) => props.theme.colors.text};
    }
  }

  &[data-reach-listbox-arrow] {
    display: flex;
  }
  &[data-reach-listbox-button]:focus-within {
    box-shadow: none;
    outline: none;
  }
`;

const SmallListboxButton = styled(RLB.ListboxButton)`
  &[data-reach-listbox-button] {
    color: ${(props) => props.theme.colors.muted};
    font-weight: 600;
    font-size: 11px;
    border: 0;
    display: inline-flex;
    :hover {
      border-color: ${(props) => props.theme.colors.text};
    }
    white-space: nowrap;
    padding: 0;
  }

  &[data-reach-listbox-arrow] {
    display: flex;
  }
  &[data-reach-listbox-button]:focus-within {
    box-shadow: none;
    outline: none;
  }
`;

export const ListboxOption = styled(RLB.ListboxOption)`
  &[data-reach-listbox-option] {
    font-family: Inter;
    font-size: 13px;
    display: grid;
    align-items: center;
    grid-template-columns: 20px 1fr;
    padding: 6px 10px;
  }
  &[data-reach-listbox-option][data-current] {
    box-shadow: inset 4px 0px 0px ${(props) => props.theme.colors.primary};
    font-weight: 600;
    background: ${(props) => props.theme.colors.lighterBackground};
  }
  &[data-reach-listbox-option][aria-selected="true"] {
    background: ${(props) => props.theme.colors.lighterBackground};
  }
`;

const ListboxPopover = styled(RLB.ListboxPopover)`
  &[data-reach-listbox-popover] {
    border: none;
    background: ${(props) => props.theme.colors.darkerBackground};
    border-radius: 4px;
    color: ${(props) => props.theme.colors.text};
    padding: 8px 0;
    font-size: 100%;
    z-index: 3;
  }
  &[data-reach-listbox-popover]:focus-within {
    box-shadow: none;
    outline: none;
  }

  [data-reach-listbox-list] {
    max-height: 320px;
    overflow-y: auto;
  }
`;

export const ButtonIcon = styled(Icon)`
  display: block;
`;

export const ListboxInput = RLB.ListboxInput;

export function ListboxOptions({ children, ...props }) {
  return (
    <ListboxPopover position={positionMatchWidth} {...props}>
      <RLB.ListboxList>{children}</RLB.ListboxList>
    </ListboxPopover>
  );
}

export function Select({ value, onChange, options, small = false }) {
  return (
    <ListboxInput value={value} onChange={onChange}>
      {small ? (
        <SmallListboxButton
          arrow={<ButtonIcon size={20}>keyboard_arrow_down</ButtonIcon>}
        />
      ) : (
        <ListboxButton
          arrow={<ButtonIcon size={20}>keyboard_arrow_down</ButtonIcon>}
        />
      )}
      <ListboxOptions>
        {options.map((option) => (
          <ListboxOption
            key={option.value}
            value={option.value}
            label={option.label}
          >
            {option.value === value && <ItemIcon />}
            <ItemName>{option.label}</ItemName>
          </ListboxOption>
        ))}
      </ListboxOptions>
    </ListboxInput>
  );
}
