import React, { useContext } from "react";
import styled, { css } from "styled-components/macro";
import ProfilePic from "components/ProfilePic";
import "react-datepicker/dist/react-datepicker.css";
import Icon from "components/Icon";
import Downshift from "downshift";
import { useQuery } from "@apollo/react-hooks";
import { getName } from "components/UserSelect";
import { Context as AppStateContext } from "../appState";
import gql from "graphql-tag";

const SearchWrapper = styled.div`
  display: flex;
  padding: 1em;
`;

const Input = styled.input`
  font-size: 1em;
  flex: 1;
  margin-left: 1em;
  border: none;
  outline: none;
  color: #777;
`;

const Results = styled.div`
  border-top: 1px solid #eee;
  background-color: #fafcfe;
  min-height: 1em;
  overflow-y: auto;

  position: absolute;
  width: 100%;
  bottom: -200px;
  height: 200px;
`;

const Result = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5em;

  ${(props) =>
    props.highlighted &&
    css`
      background-color: #eee;
    `};
`;

const ResultLabel = styled.div`
  font-size: 0.9em;
  color: #777;
`;

const GET_CUSTOMERS_IN_WORKSPACE = gql`
  query customersInWorkspace($scenarioId: ID!) {
    workspace(scenarioId: $scenarioId) {
      id
      customersWithAccess {
        id
        picture
        user {
          id
          username
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export default function CustomerAutocomplete({ onChange }) {
  const { scenario } = useContext(AppStateContext);
  const { data } = useQuery(GET_CUSTOMERS_IN_WORKSPACE, {
    variables: {
      scenarioId: scenario,
    },
  });
  if (!data) {
    return null;
  }
  const customers = data.workspace.customersWithAccess;
  return (
    <Downshift
      itemToString={(item) => (item && item.user ? getName(item.user) : "")}
      render={({
        getInputProps,
        getItemProps,
        getRootProps,
        isOpen,
        inputValue,
        selectedItem,
        highlightedIndex,
      }) => (
        <div style={{ position: "relative" }}>
          <SearchWrapper>
            <Icon size={22} color="#ddd">
              search
            </Icon>
            <Input
              type="text"
              {...getInputProps({ placeholder: "Type a name" })}
            />
          </SearchWrapper>
          {isOpen && (
            <Results>
              {customers
                .filter(
                  (customer) =>
                    !inputValue ||
                    getName(customer.user)
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                )
                .slice(0, 6)
                .map((customer, i) => (
                  <Result
                    key={customer.id}
                    highlighted={highlightedIndex === i}
                    {...getItemProps({ item: customer })}
                  >
                    <ProfilePic src={customer.picture} />
                    <ResultLabel>{getName(customer.user)}</ResultLabel>
                  </Result>
                ))}
            </Results>
          )}
        </div>
      )}
      onChange={onChange}
    />
  );
}
