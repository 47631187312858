import React, { useState } from "react";
import styled from "styled-components/macro";
import { TwitterPicker } from "react-color";

const Indicator = styled.div`
  cursor: ${({ disabled }) => (disabled ? "initial" : "pointer")};
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  background-color: ${(props) => props.color};
`;

const PickerWrapper = styled.div`
  position: absolute;
  top: 40px;
  left: -10px;
  z-index: 1000;
`;

const ColorPicker = ({ color, onChange, disabled }) => {
  const [open, setOpen] = useState(false);

  return (
    <Indicator
      disabled={disabled}
      color={color}
      onClick={() => setOpen((open) => !open)}
    >
      {open && (
        <PickerWrapper>
          <TwitterPicker
            onChangeComplete={(color) => {
              setOpen(false);
              onChange(color.hex);
            }}
          />
        </PickerWrapper>
      )}
    </Indicator>
  );
};

export default ColorPicker;
