import React, { useState } from "react";
import {
  PanelContainer,
  PanelContent,
  PanelHeader,
  PanelListItemLabel,
} from "./layout";
import styled, { css } from "styled-components/macro";
import ProfilePic from "../../../../components/ProfilePic";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { useSelectedProject, useViewport, useBaseUrl } from "../URLState";
import { useHistory } from "react-router";
import moment from "moment";
import Icon from "../components/Icon";
import { GET_SCENARIO_ACTIVITY } from "../../routes/map/components/maps/Base/panels/ScenarioActivityPanel";
import { cleanActions } from "components/TaskModal/ActivityEvent";
import { sendEvent } from "utils/ga";
import bbox from "@turf/bbox";
import { useSelectedTask } from "routes/app/SelectedTaskContext";
import TaskModal from "components/TaskModal";
import ScreenMessage from "../components/ScreenMessage";
import Spinner from "../components/Spinner";
import { PreviewIcon } from "../components/Attachments";
import Previewer from "../components/Previewer";

const TaskLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
`;

const ActivitySection = styled.div`
  padding-top: 16px;
`;

const ActivityWrapper = styled.div`
  user-select: none;
  display: grid;
  grid-template-columns: min-content auto min-content;
  grid-template-rows: min-content auto min-content;
  grid-template-areas:
    "left-icon title right-icon"
    "left-icon date right-icon";
  padding-bottom: 16px;
  ${(props) =>
    props.selected &&
    css`
      background-color: ${(props) => props.theme.colors.lighterBackground};
    `}
`;

const ActivityProfilePic = styled(ProfilePic)`
  align-self: center;
  margin-right: 9px;
  grid-area: left-icon;
`;

const LogTextItem = styled(PanelListItemLabel)`
  grid-area: date;
`;

const MainTextItem = styled(PanelListItemLabel)`
  white-space: normal;
  line-height: 19px;
`;

const MutedText = styled.span`
  color: ${(props) => props.theme.colors.muted};
`;

const AttachmentGrid = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fit, 50px);
`;

const ChangedIcon = styled(Icon)`
  vertical-align: sub;
`;

const ColorIndicator = styled.span`
  vertical-align: middle;
  margin-left: 0.2em;
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  border-radius: 0.3em;
  background-color: ${(props) => props.color};
`;

const Content = styled.div`
  grid-area: content;
  margin-top: 1em;
`;

const LocateIcon = styled(Icon)`
  cursor: pointer;
  font-size: 16px;
  vertical-align: text-top;
  color: ${(props) => props.theme.colors.input};
`;

const GET_CUSTOMER = gql`
  query getMe {
    me {
      id
      isSlingshotMember
    }
  }
`;

function AttachmentPreview({ images, onClose }) {
  const [index, setIndex] = useState(0);
  return (
    <Previewer
      src={images[index]}
      nextSrc={images[(index + 1) % images.length]}
      prevSrc={images[(index + images.length - 1) % images.length]}
      onCloseRequest={onClose}
      onMovePrevRequest={() =>
        setIndex((index + images.length - 1) % images.length)
      }
      onMoveNextRequest={() => setIndex((index + 1) % images.length)}
    />
  );
}

function Verb({ activity }) {
  if (activity.data && "key" in activity.data) {
    let value = activity.data.value;
    if (activity.data.key === "icon") {
      value = <ChangedIcon size={18}>{activity.data.value}</ChangedIcon>;
    }
    if (activity.data.key === "color") {
      value = <ColorIndicator color={activity.data.value} />;
    }
    if (activity.data.key === "status") {
      value = activity.data.value;
    }

    return (
      <>
        updated {activity.data.key} to <em>{value}</em>
      </>
    );
  }

  if (activity.verb.includes("tag")) {
    return (
      <>
        {activity.verb} <em>{activity.data.tag}</em>
      </>
    );
  }

  return activity.verb;
}

function Activity({ activity, me }) {
  const history = useHistory();
  const baseUrl = useBaseUrl();
  const { verb, actor, target } = activity;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [initialPreviewIndex, setInitialPreviewIndex] = useState(false);

  let content = null;
  if (verb.includes("attachment")) {
    content = (
      <AttachmentGrid>
        {previewOpen && (
          <AttachmentPreview
            images={activity.objects.map((at) => at.image)}
            onClose={() => setPreviewOpen(false)}
            initialPreviewIndex={initialPreviewIndex}
          />
        )}
        {activity.objects &&
          activity.objects.filter(Boolean).map((attachment, index) => {
            return (
              <PreviewIcon
                url={attachment.image}
                thumbnail={attachment.thumbnailLarge}
                key={attachment.id}
                size={26}
                onClick={() => {
                  setPreviewOpen(true);
                  setInitialPreviewIndex(index);
                }}
              />
            );
          })}
      </AttachmentGrid>
    );
  }
  if (verb === "commented") {
    content = activity.actionObject.text;
  }
  const { selectTask } = useSelectedTask();
  const { updateViewport } = useViewport();
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);

  return (
    <ActivityWrapper>
      {isTaskModalOpen && (
        <TaskModal
          isOpen={isTaskModalOpen}
          onClose={() => setIsTaskModalOpen(false)}
        />
      )}
      <ActivityProfilePic
        src={actor?.customer.picture}
        border={false}
        size={16}
      />
      <MainTextItem>
        <strong>
          {actor?.id === me.id
            ? "You"
            : actor?.firstName && actor?.lastName
            ? `${actor?.firstName} ${actor?.lastName}`
            : actor?.username}
        </strong>{" "}
        <MutedText>
          <Verb activity={activity} /> {!verb.includes("created") && " on "}
        </MutedText>
        <TaskLink
          onClick={() => {
            if (!target.status || target.status === "null") {
              history.push(`${baseUrl}/annotation/${target.id}`);
            } else {
              selectTask(target.id);
              setIsTaskModalOpen(true);
            }

            sendEvent("activityEvent", "clickTaskTitleActivity");
          }}
        >
          {target?.title || "Untitled"}
        </TaskLink>{" "}
        <LocateIcon
          onClick={(e) => {
            updateViewport(bbox(target.geometry));
            sendEvent("activityEvent", "locateActivity");
          }}
        >
          gps_fixed
        </LocateIcon>
        <LogTextItem muted>{moment(activity.timestamp).fromNow()}</LogTextItem>
        {content && <Content>{content}</Content>}
      </MainTextItem>
    </ActivityWrapper>
  );
}

function ActivityPanel() {
  const { projectId } = useSelectedProject();
  const { data: me } = useQuery(GET_CUSTOMER);
  const { data } = useQuery(GET_SCENARIO_ACTIVITY, {
    variables: { id: projectId },
    fetchPolicy: "cache-and-network",
  });

  return (
    <PanelContainer data-test="share-project-panel">
      <PanelHeader title="Activity Feed" />
      <PanelContent>
        {data && me ? (
          <ActivitySection>
            {cleanActions(data.scenario.actions).map((a) => (
              <Activity key={a.id} activity={a} me={me} />
            ))}
          </ActivitySection>
        ) : (
          <ScreenMessage>
            <Spinner />
          </ScreenMessage>
        )}
      </PanelContent>
    </PanelContainer>
  );
}

export default ActivityPanel;
