import React from "react";
import ColorThemeThumbnail from "utils/ColorThemeThumbnail";
import colors from "utils/colors/themes";
import styled from "styled-components";

import {
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  ItemName,
  ItemIcon,
  ButtonIcon,
  ListboxInput,
} from "./ListBox";

const Thumbnail = styled(ColorThemeThumbnail)`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`;

const ValueContainer = styled.div`
  display: flex;
  align-items: center;
`;

function Value({ value, label }) {
  return (
    <ValueContainer>
      <Thumbnail theme={colors[value]} />
      <span>{label}</span>
    </ValueContainer>
  );
}

export default function ThemeSelect({ value, onChange }) {
  return (
    <ListboxInput value={value} onChange={onChange}>
      <ListboxButton
        arrow={<ButtonIcon size={20}>keyboard_arrow_down</ButtonIcon>}
      >
        {Value}
      </ListboxButton>
      <ListboxOptions>
        {Object.keys(colors).map((color) => (
          <ListboxOption key={color} value={color} label={color}>
            {color === value && <ItemIcon />}
            <ItemName>
              <Value value={color} label={color} />
            </ItemName>
          </ListboxOption>
        ))}
      </ListboxOptions>
    </ListboxInput>
  );
}
