import React, { useState, useContext, useMemo, useCallback } from "react";
import {
  login as performLogin,
  logout as performLogout,
  isLoggedIn,
} from "api/auth";
import history from "utils/history";

const Context = React.createContext();

export function useAuth() {
  return useContext(Context);
}

export function AuthProvider(props) {
  const [loggedIn, setLoggedIn] = useState(isLoggedIn());

  const login = useCallback(async (username, password) => {
    await performLogin(username, password);
    setLoggedIn(true);
    const url = history.location.state && history.location.state.next_url;
    history.push(url || "/app");
  }, []);

  async function logout() {
    await performLogout();
    setLoggedIn(false);
    localStorage.clear();
    history.push("/login");
    window.location.reload(true);
  }

  const value = useMemo(() => ({ loggedIn, login, logout }), [loggedIn, login]);

  return <Context.Provider {...props} value={value} />;
}
