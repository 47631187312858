import React from "react";
import {
  PanelContainer,
  PanelContent,
  PanelHeaderWrapper,
  PanelHeaderTitle,
  PanelSection,
  PanelIcon,
  PanelTextSection as LPanelTextSection
} from "./layout";
import { useSelectedFeature } from "hooks/useSelectedFeature";
import { parseProperties } from "../../routes/map/components/maps/Base/VectorMetadataPopup";
import styled, { css } from "styled-components";
import sanitizeHtml from "sanitize-html";

const HeaderWrapper = styled(PanelHeaderWrapper)`
  padding: 16px;
  min-height: 70px;
  height: auto;
`;

const HeaderTitle = styled(PanelHeaderTitle)`
  flex: 1;
  margin: 0 7px;
  font-size: 15px;
  font-weight: 600;
  text-transform: capitalize;
`;

export const Text = styled.div`
  flex: 1;
  ${props =>
    props.italic &&
    css`
      font-style: italic;
    `}
  ${props =>
    props.bold &&
    css`
      font-weight: 600;
    `}
`;

const TextWrapper = styled.div`
  display: flex;
  &:not(:last-child) {
    padding-bottom: 8px;
  }
`;

const PhoneInfoWrapper = styled.div`
  > * {
    padding-bottom: 0;
  }
  &:not(:last-child) {
    padding-bottom: 8px;
  }
`;

export const PanelTextSection = styled(LPanelTextSection)`
  margin: 0;
  a {
    color: ${props => props.theme.colors.primary};
  }
  &:last-child {
    padding-bottom: 0;
  }
`;

function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return phoneNumberString;
}

export function parseFeatureProperties({ feature }) {
  return Object.entries(feature.properties).reduce((obj, [key, value]) => ({
    ...obj,
    [key]:
      value.startsWith("{") || value.startsWith("[") ? JSON.parse(value) : value
  }));
}

const List = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-weight: 600;
`;

const ListItem = styled.div`
  flex: 1;
`;

export const TwoElevenContent = ({ feature, mobile = false }) => {
  const properties = parseFeatureProperties({ feature });
  const overview = properties.services[0].overview || "No overview available";
  const services = properties.services
    .slice()
    .sort((a, b) => (a.name.includes("Disaster/Emergency Issues") ? -1 : 1));
  return (
    <>
      {!mobile && (
        <PanelSection title="address">
          <PanelTextSection>
            {properties.address ? (
              <>
                <Text bold>{properties.address.address}</Text>
                <Text bold>
                  {`${properties.address.city}, ${properties.address.county} ${properties.address.zip}`}
                </Text>
              </>
            ) : (
              <Text>No address available</Text>
            )}
          </PanelTextSection>
        </PanelSection>
      )}
      <PanelSection title="phone number">
        <PanelTextSection>
          {properties.phones
            .filter(p => p.phone_function.toLowerCase() !== "fax")
            .map((p, i) => (
              <PhoneInfoWrapper key={i}>
                <TextWrapper>
                  <Text>{p.phone_function || "Phone"}</Text>
                  <Text bold>{formatPhoneNumber(p.phone_number)}</Text>
                </TextWrapper>
                <Text>{p.description}</Text>
              </PhoneInfoWrapper>
            ))}
        </PanelTextSection>
      </PanelSection>
      <PanelSection title="Services">
        {services.map((s, i) => (
          <PanelTextSection key={i}>
            <Text bold>{s.name}</Text>
            <PanelTextSection>
              <Text bold>Hours</Text>
              {s.hours.length > 0
                ? s.hours.map((h, i) => <Text key={i}>{h.hours}</Text>)
                : "No hours available"}
            </PanelTextSection>
            {s.conditions.length > 0 && (
              <PanelTextSection>
                <Text bold>Conditions</Text>
                {s.conditions
                  .filter(c => c.condition_type !== "Hours")
                  .map((c, i) => (
                    <TextWrapper key={i}>
                      <Text>{c.condition_type}</Text>
                      <List>
                        {c.condition_values.map((cv, i) => (
                          <ListItem key={i}>{cv}</ListItem>
                        ))}
                      </List>
                    </TextWrapper>
                  ))}
              </PanelTextSection>
            )}
          </PanelTextSection>
        ))}
      </PanelSection>
      <PanelSection title="Overview" borderless>
        <PanelTextSection
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(overview.replace(/<h ref=/g, "<a href="), {
              allowedTags: ["br", "a"],
              selfClosing: ["br"],
              allowedAttributes: {
                a: ["href"]
              }
            })
          }}
        />
      </PanelSection>
    </>
  );
};

const VectorContent = ({ feature }) =>
  Object.entries(parseProperties(feature.properties)).map(([k, v]) => (
    <PanelSection key={v} title={k}>
      <PanelTextSection>{v}</PanelTextSection>
    </PanelSection>
  ));

function VectorMetadataPanel() {
  const { feature, close } = useSelectedFeature();
  return (
    <PanelContainer>
      <HeaderWrapper>
        <PanelIcon muted onClick={close}>
          chevron_left
        </PanelIcon>
        <HeaderTitle>
          {feature.properties.name?.toLowerCase() || "metadata"}
        </HeaderTitle>
      </HeaderWrapper>
      <PanelContent>
        {feature.properties.hash ? (
          <TwoElevenContent feature={feature} />
        ) : (
          <VectorContent feature={feature} />
        )}
      </PanelContent>
    </PanelContainer>
  );
}

export default VectorMetadataPanel;
