import styled, { css } from "styled-components/macro";

export const ButtonGroup = styled.div`
  display: flex;
`;

export const SecondaryButton = styled.button`
  font-size: 0.95em;
  font-weight: 500;
  color: ${(props) => props.theme.colors.link};
  border-color: ${(props) => props.theme.colors.link};
  border-style: solid;
  border-width: 0.15em;
  background-color: white;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
  padding-top: 0.3em;
  border-radius: 1em;
  padding-bottom: 0.3em;
  padding-right: 1em;
  padding-left: 1em;
  &:hover {
    background-color: ${(props) => props.theme.colors.link};
    color: white;
    border-color: ${(props) => props.theme.colors.link};
  }
  ${(props) =>
    props.disabled &&
    css`
      color: #ddd;
      border-color: #ddd;
      background-color: white;
      cursor: inherit;
      &:hover {
        color: #ddd;
        background-color: white;
        border-color: #ddd;
      }
    `};

  ${ButtonGroup} > &:first-child {
    border-radius: 1em 0 0 1em;
    border-right: none;
  }

  ${ButtonGroup} > &:last-child {
    border-radius: 0em 1em 1em 0;
  }
`;

export default SecondaryButton;
