import React, { useContext } from "react";
import Restricted, { hasWorkspacePermission } from "utils/Restricted";

import Icon from "components/Icon";
import { Context as MapStateContext } from "../../../state";
import Tooltip from "components/Tooltip";
import styled from "styled-components/macro";
import { useSnackbar } from "components/Snackbar";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { sendEvent } from "utils/ga";

const Wrapper = styled.div`
  position: absolute;
  z-index: 2;
  top: 1em;
  right: 1em;
  display: flex;
  align-items: center;
  user-select: none;
`;

const IndicatorsWrapper = styled.div`
  border-radius: 3px;
  border: 1px solid #ccc;
  align-items: center;
  display: flex;
  background: white;
  padding: 0.5em 1em;
`;

const ActionButton = styled.button`
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid #ccc;
  outline: none;
  display: flex;
  align-items: center;
  align-self: stretch;
  margin-right: 1em;
  background-color: white;

  > * {
    margin: 0 0.2em;
  }
`;

const Indicator = styled.div`
  display: flex;
  align-items: center;

  :not(:last-child) {
    margin-right: 1em;
  }
`;

const Label = styled.div`
  margin-left: 1em;
`;

const GET_SCENARIO_INDICATOR = gql`
  query getScenarioIndicator($scenarioId: ID!) {
    scenario(id: $scenarioId) {
      id
      name
      defaultView
      workspace {
        id
        name
        myPermission
      }
    }
  }
`;

const SET_SCENARIO_VIEW = gql`
  mutation setScenarioView($input: SetScenarioViewInput!) {
    setScenarioView(input: $input) {
      scenario {
        id
        defaultView
      }
    }
  }
`;

export function useScenarioMutations() {
  const [setScenarioViewMutation] = useMutation(SET_SCENARIO_VIEW);

  return {
    setScenarioView: ({ id, defaultView }) =>
      setScenarioViewMutation({
        variables: { input: { id, defaultView } },
        optimisticResponse: {
          setScenarioView: {
            scenario: {
              id,
              defaultView,
              __typename: "Scenario",
            },
            __typename: "ScenarioMutationPayload",
          },
        },
      }),
  };
}

export default function WorkspaceScenarioIndicator({ scenarioId }) {
  const { view } = useContext(MapStateContext);
  const { showSnackbar } = useSnackbar();

  const { data } = useQuery(GET_SCENARIO_INDICATOR, {
    variables: { scenarioId: String(scenarioId) },
  });
  const { setScenarioView } = useScenarioMutations();

  if (data) {
    const scenario = data.scenario;
    return (
      <Wrapper>
        <Restricted to={hasWorkspacePermission(scenario.workspace, "rw")}>
          <Tooltip position="left" content="Save view as default">
            <ActionButton
              onClick={() => {
                setScenarioView({
                  id: scenario.id,
                  defaultView: decodeURIComponent(view).split(",").map(Number),
                });
                showSnackbar("Map viewport saved as default for this scenario");
                sendEvent("workspaceScenarioIndicator", "Save view as default");
              }}
              primary
            >
              <Icon size={20} color="#999">
                tv
              </Icon>
            </ActionButton>
          </Tooltip>
        </Restricted>
        <IndicatorsWrapper>
          <Indicator>
            <Icon color="#999" size={20}>
              folder
            </Icon>
            <Label>{scenario.workspace.name}</Label>
          </Indicator>
          <Indicator>
            <Icon color="#999" size={24}>
              map
            </Icon>
            <Label>{scenario.name}</Label>
          </Indicator>
        </IndicatorsWrapper>
      </Wrapper>
    );
  } else return null;
}
