import { Link as RRLink, Route } from "react-router-dom";

import Icon from "components/Icon";
import React, { useState } from "react";
import Tooltip from "components/Tooltip";
import { TunnelPlaceholder } from "utils/tunnels";
import styled from "styled-components/macro";
import BugReportModal from "./BugReportModal";
import { sendEvent } from "utils/ga";

const Wrapper = styled.div`
  grid-area: sidebar;
  width: 60px;
  background-color: #444;
  display: flex;
  flex-direction: column;
`;

const SidebarButton = styled.div`
  position: relative;
  height: 60px;
  display: grid;
  align-items: center;
  justify-items: center;
`;

const LinkWrapper = styled(SidebarButton)`
  cursor: pointer;
  transition: all 0.3s;
  background-color: ${(props) => (props.active ? "#5ec9ff" : "inherit")};
  text-decoration: none;

  &:hover {
    text-decoration: none;
    background-color: #555;
  }

  &:active,
  &:focus {
    text-decoration: none !important;
  }
`;

const Separator = styled.div`
  width: 50px;
  height: 1px;
  margin: 0 auto;
  background-color: #777;
`;

const Fill = styled.div`
  flex: 1;
`;

const PanelToggleWrapper = styled(LinkWrapper)`
  background-color: ${(props) => (props.active ? "#377A9B" : "inherit")};
`;

export const Link = ({ to, icon, name, ...props }) => (
  <Route
    path={to}
    children={({ match }) => (
      <Tooltip position="right" content={name}>
        <LinkWrapper name={name} as={RRLink} to={to} active={match} {...props}>
          <Icon size={24} color="white">
            {icon}
          </Icon>
        </LinkWrapper>
      </Tooltip>
    )}
  />
);

export const PanelToggle = ({ icon, name, tooltipProps, ...props }) => (
  <Tooltip content={name} {...tooltipProps}>
    <PanelToggleWrapper as="div" {...props}>
      <Icon size={24} color="white">
        {icon}
      </Icon>
    </PanelToggleWrapper>
  </Tooltip>
);

const TooltipLink = styled.a`
  color: white;
`;

const Copyright = () => (
  <PanelToggle
    tooltipProps={{ delay: 1000 }}
    icon="copyright"
    name={
      <>
        Copyright ©{" "}
        <TooltipLink href="http://slingshotaerospace.com/" target="blank">
          SLINGSHOT
        </TooltipLink>{" "}
        2018
      </>
    }
  />
);

function BugReportButton() {
  const [isOpen, setIsOpen] = useState(false);
  const onClick = () => {
    setIsOpen(true);
    sendEvent("sidebar", "showReportBugModal");
  };
  return (
    <>
      {isOpen && <BugReportModal onClose={() => setIsOpen(false)} />}
      <PanelToggle name="Report a bug" icon="bug_report" onClick={onClick} />
    </>
  );
}

export const Sidebar = () => (
  <Wrapper>
    <TunnelPlaceholder id="sidebar-links" />
    <Separator />
    <TunnelPlaceholder id="sidebar-toggles" />
    <Fill />
    <BugReportButton />
    <Copyright />
  </Wrapper>
);

export default Sidebar;
