import React from "react";
import {
  PanelContainer,
  PanelContent,
  PanelHeader,
  PanelIcon,
  PanelSection,
  PanelSectionContainer,
} from "../layout";
import { useHistory } from "react-router";
import { useBaseUrl } from "./../../URLState";
import { EditLayerDetailsPanel, useLayerUpload } from "./UploadLayerSection";
import LayerBrowserSection from "./LayerBrowserSection";
import ProgressBar from "react-toolbox/lib/progress_bar/ProgressBar";
import { NotificationTunnel } from "./../../components/Notification";
import { UploadSection } from "../../components/UploadSection";

function AddLayerPanel() {
  const baseUrl = useBaseUrl();
  const history = useHistory();
  const {
    editingDetails,
    defaultTitle,
    onUpload,
    onSave,
    onCancel,
    status,
    progress,
  } = useLayerUpload();

  if (editingDetails) {
    return (
      <EditLayerDetailsPanel
        onCancel={onCancel}
        onSave={onSave}
        defaultTitle={defaultTitle}
      />
    );
  }

  const statusMessages = {
    creating: "Creating layer",
    processing_upload: "Processing layer upload",
    finished_upload: "Layer upload finished",
    adding: "Adding layer",
  };

  return (
    <PanelContainer>
      {statusMessages[status] && (
        <NotificationTunnel>
          <ProgressBar type="circular" />
          {statusMessages[status]}
        </NotificationTunnel>
      )}
      {status === "uploading" && (
        <NotificationTunnel>
          <ProgressBar
            type="circular"
            mode="determinate"
            min={0}
            max={100}
            value={progress}
          />
          {`Upload in progress ${progress}%`}
        </NotificationTunnel>
      )}
      <PanelHeader
        title={"Add Layer"}
        iconLeft={
          <PanelIcon
            muted
            onClick={() => {
              history.push(`${baseUrl}/project`);
            }}
          >
            chevron_left
          </PanelIcon>
        }
        iconRight={<PanelIcon muted>more_vert</PanelIcon>}
      />
      <PanelContent>
        <PanelSection title="Upload layer">
          <PanelSectionContainer>
            <UploadSection
              onChange={onUpload}
              secondaryText={"(GeoJSON, CSV, or zipped shapefiles)"}
              accept={".json,.geojson,.csv,.zip"}
            />
          </PanelSectionContainer>
        </PanelSection>
        <LayerBrowserSection />
      </PanelContent>
    </PanelContainer>
  );
}

export default AddLayerPanel;
