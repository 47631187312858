import React, { useState } from "react";
import styled, { css } from "styled-components/macro";

import Icon from "components/Icon";

const Arrow = styled(Icon).attrs({ size: 40, color: "#e2dce7" })`
  cursor: pointer;
  position: fixed;
  top: 40%;
  &:hover {
    background-color: black;
    opacity: 0.5;
    border-radius: 1em;
  }
  &:focus {
    outline: none;
  }
  user-select: none;
  ${(props) =>
    props.right
      ? css`
          right: 0.5rem;
        `
      : css`
          left: 0.5rem;
        `};
`;

export const ImageSlide = styled.img`
  width: 100%;
  height: 100%;
  max-height: 300px;
  min-height: 200px;
  cursor: pointer;
  transition: background-image 0.3s ease-in-out;
  object-fit: cover;
`;

const Carousel = ({ images, onImageClick }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const previousSlide = () => {
    const lastIndex = images.length - 1;
    const shouldResetIndex = currentIndex === 0;
    const index = shouldResetIndex ? lastIndex : currentIndex - 1;
    setCurrentIndex(index);
  };

  const nextSlide = () => {
    const lastIndex = images.length - 1;
    const shouldResetIndex = currentIndex === lastIndex;
    const index = shouldResetIndex ? 0 : currentIndex + 1;

    setCurrentIndex(index);
  };

  return (
    <div>
      <Arrow onClick={previousSlide}>keyboard_arrow_left</Arrow>
      <ImageSlide
        src={images[currentIndex]}
        alt={currentIndex}
        onClick={onImageClick}
      />
      <Arrow right onClick={nextSlide}>
        keyboard_arrow_right
      </Arrow>
    </div>
  );
};

export default Carousel;
