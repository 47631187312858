import "react-datepicker/dist/react-datepicker.css";

import EditableValue, { BaseInput, BaseValue } from "components/EditableValue";

import AssignmentSection from "./AssignmentSection";
import Button from "components/Button";
import React from "react";
import StatusSection from "./StatusSection";
import moment from "moment";
import styled from "styled-components/macro";
import { getName } from "components/UserSelect";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { sendEvent } from "utils/ga";

const HeaderWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  padding-bottom: 0.5em;
  margin-bottom: 1em;
`;

const HeaderTitle = styled.h6`
  font-size: 0.9em;
  margin: 0;
  text-transform: uppercase;
`;

const Wrapper = styled.div`
  margin-top: 1em;
`;

const TaskNameInput = styled(BaseInput)`
  min-height: 2em;
  margin-bottom: 1em;
`;

const TaskNameValue = styled(BaseValue)`
  margin-bottom: 1em;
`;

const TaskCreationInfo = styled.div`
  text-align: right;
  font-size: 0.9em;
  color: #777;
`;

const Indicators = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const START_TASK = gql`
  mutation startTask($id: ID!) {
    startTask(id: $id) {
      success
      task {
        id
        status
        assignedTo {
          id
          user {
            id
            username
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const SET_STATUS = gql`
  mutation setStatus($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      success
      task {
        id
        status
      }
    }
  }
`;

const SET_TASK_NAME = gql`
  mutation setStatus($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      success
      task {
        id
        taskName
      }
    }
  }
`;

function Task({ task, disabled }) {
  const [startTask] = useMutation(START_TASK);
  const [setStatus] = useMutation(SET_STATUS);
  const [setTaskname] = useMutation(SET_TASK_NAME);
  return (
    <Wrapper>
      <HeaderWrapper>
        <HeaderTitle>Task</HeaderTitle>
      </HeaderWrapper>
      {!task.status ? (
        <Button
          data-test="start-task"
          onClick={() => startTask({ variables: { id: task.id } })}
          disabled={disabled}
        >
          Start task
        </Button>
      ) : (
        <div>
          <Indicators>
            <AssignmentSection task={task} disabled={disabled} />
            <StatusSection
              disabled={disabled}
              status={task.status}
              onChange={(status) => {
                setStatus({ variables: { input: { id: task.id, status } } });
                sendEvent({
                  eventCategory: "task",
                  eventAction: "updateStaus",
                  eventValue: status,
                });
              }}
            />
          </Indicators>
          <EditableValue
            disabled={disabled}
            value={task.taskName}
            onChange={(value) => {
              setTaskname({
                variables: { input: { id: task.id, taskName: value } },
                optimisticResponse: {
                  updateTask: {
                    __typename: "TaskMutationPayload",
                    success: true,
                    task: { __typename: "Task", id: task.id, taskName: value },
                  },
                },
              });
              sendEvent("task", "changeTaskName");
            }}
            renderValue={(props) => (
              <TaskNameValue field="task name" disabled={disabled} {...props}>
                {task.taskName}
              </TaskNameValue>
            )}
            renderInput={(props) => <TaskNameInput as="textarea" {...props} />}
          />
          <TaskCreationInfo>{`Created by ${getName(
            task.createdBy.user
          )} on ${moment(task.created).format(
            "MMMM DD, YYYY"
          )}`}</TaskCreationInfo>
        </div>
      )}
    </Wrapper>
  );
}

export default Task;
